import { CohortInsightsReportDTO } from '@om1/falcon-api'

export interface InsightsLibraryState {
    loading: boolean
    cohortInsightsReports: CohortInsightsReportDTO[]
}

export const initialInsightsLibraryState: InsightsLibraryState = {
    loading: true,
    cohortInsightsReports: []
}
