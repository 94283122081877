import { CohortDTO, ExplorerCohortsService, PaginatedDTO_CohortDTO_, falconEndpoints } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put } from 'redux-saga/effects'
import { CohortListItem, CohortOwner, cohortListActions } from '../state'

export function createGetCohortsSaga() {
    return function* (
        action:
            | ReturnType<typeof cohortListActions.cohortsGetUserCohorts>
            | ReturnType<typeof cohortListActions.cohortsGetSystemCohorts>
            | ReturnType<typeof cohortListActions.cohortsGetAllCohorts>
    ) {
        yield put(cohortListActions.loadingSet({ loading: true }))
        try {
            if (action.payload.owner !== undefined) {
                const paginatedCohortsDTO: PaginatedDTO_CohortDTO_ = yield call(ExplorerCohortsService.indexExplorerCohortsGet, {
                    page: action.payload.page ?? falconEndpoints.cohorts.queryParams.page,
                    limit: action.payload.pageSize ?? falconEndpoints.cohorts.queryParams.limit,
                    isSystem: action.payload.owner === CohortOwner.System
                })
                yield put(
                    cohortListActions.cohortsSet({
                        cohorts: paginatedCohortsDTO.data.map((cohortDTO: CohortDTO): CohortListItem => {
                            return cohortDTO as CohortListItem
                        }),
                        meta: paginatedCohortsDTO.meta,
                        owner: action.payload.owner === CohortOwner.System ? CohortOwner.System : CohortOwner.User
                    })
                )
            } else {
                const paginatedSystemCohortsDTO: PaginatedDTO_CohortDTO_ = yield call(ExplorerCohortsService.indexExplorerCohortsGet, {
                    page: 1,
                    limit: 1000,
                    isSystem: true
                })
                yield put(
                    cohortListActions.cohortsSet({
                        cohorts: paginatedSystemCohortsDTO.data.map((cohortDTO) => {
                            return cohortDTO as CohortListItem
                        }),
                        meta: paginatedSystemCohortsDTO.meta,
                        owner: CohortOwner.System
                    })
                )
                const paginatedUserCohortsDTO: PaginatedDTO_CohortDTO_ = yield call(ExplorerCohortsService.indexExplorerCohortsGet, {
                    page: 1,
                    limit: 1000,
                    isSystem: false
                })
                yield put(
                    cohortListActions.cohortsSet({
                        cohorts: paginatedUserCohortsDTO.data.map((cohortDTO) => {
                            return cohortDTO as CohortListItem
                        }),
                        meta: paginatedSystemCohortsDTO.meta,
                        owner: CohortOwner.User
                    })
                )
            }
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(cohortListActions.loadingSet({ loading: false }))
        }
    }
}

export type GetCohortsSaga = ReturnType<typeof createGetCohortsSaga>
