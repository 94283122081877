import { FrameworkComponentProps } from '@om1/platform-utils'
import React from 'react'
import { CohortDraggingState, CohortNode, CriteriaOperation, CriterionNode, OperationNode, cohortBlocksEditActions } from '../../../state'
import { CohortCriteria } from './CohortCriteria'
import { CohortOperation } from './CohortOperation'

export type CohortNodeRendererProps = FrameworkComponentProps<
    { node: CohortNode; parentOperation: CriteriaOperation; dragState: CohortDraggingState },
    typeof cohortBlocksEditActions,
    { readOnly?: boolean }
>

/**
 * A switch renderer that determines which type of node to next render in the cohort depending on its properties.
 */
export const CohortNodeRenderer: React.FunctionComponent<CohortNodeRendererProps> = ({
    state: { node, parentOperation, dragState },
    actions,
    props: { readOnly }
}) => {
    if (isOperation(node)) {
        return <CohortOperation state={{ node, dragState }} actions={actions} props={{ readOnly }} />
    } else if (isCriterion(node)) {
        return <CohortCriteria state={{ node, parentOperation, dragState }} actions={actions} props={{ readOnly }} />
    } else {
        return null
    }
}

const isOperation = (node: CohortNode): node is OperationNode => {
    return (node as OperationNode).operation !== undefined
}

const isCriterion = (node: CohortNode): node is CriterionNode => {
    return (node as OperationNode).operation === undefined
}
