import { ExplorerCohortsService } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { notificationActions } from '@om1/platform-notifications'
import { call, put, select } from 'redux-saga/effects'
import { CohortState, cohortDeleteActions, cohortListActions } from '../state'

export function createDeleteCohortSaga() {
    return function* (action: ReturnType<typeof cohortDeleteActions.cohortDelete>) {
        try {
            yield put(cohortDeleteActions.loadingSet({ loading: true }))
            yield call(ExplorerCohortsService.deleteExplorerCohortsCohortIdDelete, {
                cohortId: action.payload.id
            })
            yield put(notificationActions.success())
            if (action.payload.refreshList) {
                const paginationMeta = yield select((store: { cohort: CohortState }) => store.cohort.list.paginationMeta)
                const { currentPage, itemsPerPage } = paginationMeta
                yield put(
                    // Reload current cohort page. Check in the list component to see if we need to go back a page
                    // TODO should handle this with a connected routing action in the future
                    cohortListActions.cohortsGetUserCohorts({
                        query: { USER: { page: currentPage, pageSize: itemsPerPage } },
                        path: {}
                    })
                )
            }
        } catch (error: any) {
            // Handle lock error by posting the error message
            if (error.status === 423) {
                yield put(notificationActions.error(error.body['detail']))
            } else {
                yield handleApiError(error)
            }
        } finally {
            yield put(cohortDeleteActions.loadingSet({ loading: false }))
        }
    }
}
