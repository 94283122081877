export interface KeycloakConfigInterface {
    keycloakRealm: string
    keycloakUrl: string
    keycloakClientId: string
}

export const keycloakConfig: KeycloakConfigInterface = {
    keycloakRealm: process.env.REACT_APP_KEYCLOAK_REALM_NAME as string,
    keycloakUrl: process.env.REACT_APP_KEYCLOAK_URL as string,
    keycloakClientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string
}
