/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeliveryCreateDTO } from '../models/DeliveryCreateDTO'
import type { DeliveryDTO } from '../models/DeliveryDTO'
import type { DeliveryPatchDTO } from '../models/DeliveryPatchDTO'
import type { PaginatedDTO_DeliveryDTO_ } from '../models/PaginatedDTO_DeliveryDTO_'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class DataDeliveryAdminDeliveryService {
    /**
     * Index
     * @returns PaginatedDTO_DeliveryDTO_ Successful Response
     * @throws ApiError
     */
    public static indexDataDeliveryDeliveryGet({
        page = 1,
        limit = 10
    }: {
        /**
         * Page number for pagination, starting at 1
         */
        page?: number
        /**
         * Number of items per page, maximum 1000
         */
        limit?: number
    }): CancelablePromise<PaginatedDTO_DeliveryDTO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/data_delivery/delivery',
            query: {
                page: page,
                limit: limit
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Create
     * @returns DeliveryDTO Successful Response
     * @throws ApiError
     */
    public static createDataDeliveryDeliveryPost({ requestBody }: { requestBody: DeliveryCreateDTO }): CancelablePromise<DeliveryDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/data_delivery/delivery',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Get
     * @returns DeliveryDTO Successful Response
     * @throws ApiError
     */
    public static getDataDeliveryDeliveryDeliveryIdGet({ deliveryId }: { deliveryId: string }): CancelablePromise<DeliveryDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/data_delivery/delivery/{delivery_id}',
            path: {
                delivery_id: deliveryId
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Patch
     * @returns DeliveryDTO Successful Response
     * @throws ApiError
     */
    public static patchDataDeliveryDeliveryDeliveryIdPatch({
        deliveryId,
        requestBody
    }: {
        deliveryId: string
        requestBody: DeliveryPatchDTO
    }): CancelablePromise<DeliveryDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/data_delivery/delivery/{delivery_id}',
            path: {
                delivery_id: deliveryId
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Delete
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteDataDeliveryDeliveryDeliveryIdDelete({ deliveryId }: { deliveryId: string }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/data_delivery/delivery/{delivery_id}',
            path: {
                delivery_id: deliveryId
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
}
