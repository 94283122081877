import { InsightsReportOrganizationService } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put } from 'redux-saga/effects'
import { insightsLibraryActions } from '../state/insights-library'

export function createDeleteInsightsLbraryReportsSaga() {
    return function* (action: ReturnType<typeof insightsLibraryActions.deleteInsightsLibraryReport>) {
        try {
            yield call(InsightsReportOrganizationService.deleteRwaInsightsInsightsReportOrganizationOrganizationReportIdDelete, {
                organizationReportId: action.payload.organization_report_id
            })
            yield put(insightsLibraryActions.deleteInsightsLibraryReportSuccess({ organization_report_id: action.payload.organization_report_id }))
        } catch (error) {
            yield handleApiError(error)
        } finally {
            yield put(insightsLibraryActions.setCohortInsightsLibraryReportsLoading({ loading: false }))
        }
    }
}
