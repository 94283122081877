import { LanguageActions, LanguageActionTypes } from './language-actions'
import { LocaleEnum } from './language-config'
import { LanguageStateInterface } from './language-state'

export const initialLanguageState: LanguageStateInterface = { locale: LocaleEnum.English }

export function languageReducer(previousState: LanguageStateInterface | undefined, action: LanguageActions): LanguageStateInterface {
    const state = previousState || initialLanguageState

    switch (action.type) {
        case LanguageActionTypes.SET_LOCALE: {
            return {
                ...state,
                locale: action.payload.locale
            }
        }
        default:
            return state
    }
}
