import { InsightsReportOrganizationService, PaginatedDTO_CohortInsightsReportDTO_ } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put } from 'redux-saga/effects'
import { insightsLibraryActions } from '../state/insights-library'

export function createGetInsightsLbraryReportsSaga() {
    return function* (action: ReturnType<typeof insightsLibraryActions.getInsightsLibraryReports>) {
        try {
            yield put(insightsLibraryActions.setCohortInsightsLibraryReportsLoading({ loading: true }))
            const paginatedInsightsReports: PaginatedDTO_CohortInsightsReportDTO_ = yield call(
                InsightsReportOrganizationService.indexRwaInsightsInsightsReportOrganizationGet,
                {
                    cohortId: undefined,
                    insightsReportId: undefined,
                    page: undefined,
                    limit: undefined
                }
            )
            yield put(insightsLibraryActions.setCohortInsightsLibraryReports({ cohortInsightsReports: paginatedInsightsReports.data }))
        } catch (error) {
            yield handleApiError(error)
        } finally {
            yield put(insightsLibraryActions.setCohortInsightsLibraryReportsLoading({ loading: false }))
        }
    }
}
