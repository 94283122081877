import { CohortInsightsReportDTO, InsightsReportOrganizationService } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put } from 'redux-saga/effects'
import { insightsLibraryActions } from '../state'

export function createCreateInsightsLbraryReportsSaga() {
    return function* (action: ReturnType<typeof insightsLibraryActions.createInsightsLibraryReport>) {
        try {
            const cohortInsightsReport: CohortInsightsReportDTO = yield call(
                InsightsReportOrganizationService.createRwaInsightsInsightsReportOrganizationPost,
                {
                    requestBody: action.payload.cohortInsightsReport
                }
            )
            yield put(insightsLibraryActions.createInsightsLibraryReportSuccess({ cohortInsightsReport: cohortInsightsReport }))
        } catch (error) {
            yield handleApiError(error)
        }
    }
}
