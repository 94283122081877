import { CohortConditionsService } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put } from 'redux-saga/effects'
import { rwaConditionsActions } from '../state/rwa-conditions'

export function createDeleteRWAConditionsSaga() {
    return function* (action: ReturnType<typeof rwaConditionsActions.deleteRwaCondition>) {
        try {
            yield call(CohortConditionsService.deleteRwaInsightsCohortConditionsConditionIdDelete, {
                conditionId: action.payload.id
            })
            yield put(rwaConditionsActions.deleteRwaConditionSuccess(action.payload))
        } catch (error) {
            yield handleApiError(error)
        }
    }
}
