/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RegistryTypeDTO } from '../models/RegistryTypeDTO'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class DataDeliveryRegistryTypesService {
    /**
     * Index
     * @returns RegistryTypeDTO Successful Response
     * @throws ApiError
     */
    public static indexDataDeliveryRegistryTypesGet(): CancelablePromise<Array<RegistryTypeDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/data_delivery/registry_types'
        })
    }
}
