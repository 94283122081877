import { Box, SxProps } from '@mui/material'
import { ReactNode } from 'react'

export interface CohortCriterionObservationPeriodSummaryProps {
    dataType: ReactNode
    sx?: SxProps
}

export const CohortCriterionObservationPeriodSummary = (props: CohortCriterionObservationPeriodSummaryProps) => {
    const { dataType } = props
    return (
        <Box
            px={1}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            fontSize={12}
            sx={{
                ...props.sx,
                p: 1,
                color: 'text.primary',
                textAlign: 'left'
            }}
        >
            {dataType}
        </Box>
    )
}
