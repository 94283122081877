import { CohortCommonActionTypes, CohortCommonActions } from './common-actions'
import { CommonState, initialCommonState } from './common-state'

export function commonReducer(previousState: CommonState | undefined, action: CohortCommonActions) {
    const state = previousState || initialCommonState

    switch (action.type) {
        case CohortCommonActionTypes.COHORT_SIZE_LOADING_SET: {
            return {
                ...state,
                ui: { ...state.ui, cohortSizeLoading: { ...state.ui.cohortSizeLoading, [action.payload.cohortId]: action.payload.loading } }
            }
        }
        case CohortCommonActionTypes.COHORT_SUMMARY_REPORT_LOADING_SET: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    cohortSummaryReportLoading: { ...state.ui.cohortSummaryReportLoading, [action.payload.cohortId]: action.payload.loading }
                }
            }
        }
        default:
            return state
    }
}
