import { Trans } from '@lingui/macro'
import { Box } from '@mui/system'
import { isNull, isUndefined } from 'lodash'
import { FunctionComponent, useMemo } from 'react'

export interface DatasetSizeProps {
    size: number | null | undefined
    name: string | null | undefined
    justifyEnd?: boolean
}
export const DatasetSize: FunctionComponent<DatasetSizeProps> = ({ size, name, justifyEnd = false }) => {
    const renderDatasetSize = useMemo(() => {
        if (isNull(size) || isUndefined(size)) {
            return '-'
        }

        if (size === 1) {
            return (
                <>
                    (1 <Trans>Patient</Trans>)
                </>
            )
        }

        const numberFormatter = new Intl.NumberFormat(undefined)

        return (
            <>
                ({numberFormatter.format(size)} <Trans>Patients</Trans>)
            </>
        )
    }, [size])

    return (
        <Box
            display='flex'
            columnGap={1}
            rowGap={0}
            flexDirection='row'
            flexWrap='wrap'
            justifyContent={justifyEnd ? 'flex-end' : 'flex-start'}
            data-testid='dataset-size-wrapper'
        >
            {name && (
                <>
                    <Box component='span' data-testid='dataset-size-name-value'>
                        {name}
                    </Box>
                    <Box component='span' data-testid='dataset-size-size-value'>
                        {renderDatasetSize}
                    </Box>
                </>
            )}
        </Box>
    )
}
