import { Trans } from '@lingui/macro'
import BarChartIcon from '@mui/icons-material/BarChart'
import DownloadIcon from '@mui/icons-material/Download' // Import an appropriate download icon
import TableViewIcon from '@mui/icons-material/TableView' // Icon for table view
import { Box, IconButton } from '@mui/material'
import { LightTooltip } from './Tooltip'

interface ReportChartButtonsProps {
    view: 'barChart' | 'tableChart'
    setView: (view: 'barChart' | 'tableChart') => void
    onDownload: () => void
    isDrilledDown: boolean
    disabled?: boolean
}

export default function ReportChartButtons(props: ReportChartButtonsProps) {
    return (
        <Box height={'48px'} flexDirection={'row'} alignItems={'center'} sx={{ display: props.disabled ? 'none' : 'flex' }}>
            {props.view === 'tableChart' && (
                <Box>
                    <LightTooltip key={'reportTooltip'} title={<Trans>Download CSV</Trans>} placement='top'>
                        <IconButton
                            key='distribution'
                            color='primary'
                            aria-label='download csv'
                            onClick={props.onDownload}
                            sx={{ borderRadius: '5px' }}
                        >
                            <DownloadIcon />
                        </IconButton>
                    </LightTooltip>
                </Box>
            )}
            <Box>
                {props.view === 'barChart' ? (
                    <IconButton
                        key='distribution'
                        color='primary'
                        onClick={() => {
                            props.setView('barChart')
                        }}
                        aria-label='graph view'
                        sx={{ borderRadius: '5px' }}
                        disabled={true}
                    >
                        <BarChartIcon style={props.isDrilledDown ? {} : { transform: 'rotate(90deg)' }} />
                    </IconButton>
                ) : (
                    <LightTooltip key={'reportTooltip'} title={<Trans>Switch to chart view</Trans>} placement='top'>
                        <IconButton
                            key='distribution'
                            color='primary'
                            onClick={() => {
                                props.setView('barChart')
                            }}
                            aria-label='graph view'
                            sx={{ borderRadius: '5px' }}
                        >
                            <BarChartIcon style={props.isDrilledDown ? { transform: 'rotate(90deg)' } : {}} />
                        </IconButton>
                    </LightTooltip>
                )}
            </Box>
            <Box>
                {props.view === 'tableChart' ? (
                    <IconButton
                        key='distribution'
                        color='primary'
                        onClick={() => {
                            props.setView('tableChart')
                        }}
                        aria-label='table view'
                        sx={{ borderRadius: '5px' }}
                        disabled={true}
                    >
                        <TableViewIcon />
                    </IconButton>
                ) : (
                    <LightTooltip key={'reportTooltip'} title={<Trans>Switch to table view</Trans>} placement='top'>
                        <IconButton
                            key='distribution'
                            color='primary'
                            onClick={() => {
                                props.setView('tableChart')
                            }}
                            aria-label='table view'
                            sx={{ borderRadius: '5px' }}
                        >
                            <TableViewIcon />
                        </IconButton>
                    </LightTooltip>
                )}
            </Box>
        </Box>
    )
}
