import { t } from '@lingui/macro'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import PrintIcon from '@mui/icons-material/Print'
import { Box, CircularProgress, IconButton } from '@mui/material'
import { LightTooltip } from '@om1/platform-components/Tooltip'
import { FrameworkComponentProps, PlatformPermissions } from '@om1/platform-utils'
import { FunctionComponent } from 'react'
import { cohortCommonActions, CohortListItem, CohortLoading, CohortOwner, Cohorts } from '../../state'

export type GenerateReportComponentProps = FrameworkComponentProps<
    { isOM1User: boolean; cohortSummaryReportLoading: CohortLoading; cohorts: Cohorts; permissions: string[] },
    typeof cohortCommonActions,
    { cohort?: CohortListItem }
>

export const GenerateReportComponent: FunctionComponent<GenerateReportComponentProps> = ({ state, actions, props }) => {
    const cohortId = props.cohort?.id
    const cohortHasReport =
        props.cohort?.summaryReportTaskId !== null &&
        props.cohort?.summaryReportTaskId !== undefined &&
        (state.cohorts[CohortOwner.User].find((cohort) => cohort.id === cohortId)?.summaryReportTaskId !== null ||
            state.cohorts[CohortOwner.System].find((cohort) => cohort.id === cohortId)?.summaryReportTaskId !== undefined)
    const isLoading =
        Object.keys(state.cohortSummaryReportLoading).includes(props.cohort?.id || '') &&
        state.cohortSummaryReportLoading[props.cohort?.id || ''] === true
    const hasAccess = state.permissions.includes(PlatformPermissions.ACCESS_SUMMARY_REPORTS_FEATURES)
    return (
        <Box id={`cohort-generate-report-${props.cohort?.id}`} key={`cohort-generate-report-${props.cohort?.id}`}>
            {isLoading && (
                <Box color='grey.500' sx={{ backgroundColor: '#D6E4F2', color: '#012D72', borderRadius: '2px', margin: '5px' }}>
                    <LightTooltip title={t`Loading report...`} placement='top'>
                        <span>
                            <IconButton disabled={true} aria-label={t`Report loading`}>
                                <CircularProgress color='inherit' size='1em' sx={{ backgroundColor: 'transparent', color: 'grey.500' }} />
                            </IconButton>
                        </span>
                    </LightTooltip>
                </Box>
            )}
            {!isLoading &&
                hasAccess &&
                (!cohortHasReport ? (
                    <LightTooltip title={!cohortHasReport ? t`Generate Report` : t`Report Already Generated`} placement='top'>
                        <span>
                            <IconButton
                                onClick={() => {
                                    if (props.cohort?.id && props.cohort?.name) {
                                        actions.generateSummaryReport({ cohortId: props.cohort?.id, cohortName: props.cohort?.name })
                                    }
                                }}
                                disabled={cohortHasReport || !props.cohort?.id}
                                aria-label={t`Generate Analytics Report`}
                                sx={{ backgroundColor: '#D6E4F2', color: '#012D72', borderRadius: '2px', margin: '5px' }}
                            >
                                <NoteAddIcon />
                            </IconButton>
                        </span>
                    </LightTooltip>
                ) : (
                    <LightTooltip title={cohortHasReport ? t`Print Report` : t`No Report Generated`} placement='top'>
                        <span>
                            <IconButton
                                disabled={!cohortHasReport || !props.cohort?.id}
                                onClick={() => window.open(`/cohort-builder/print/${props.cohort?.id}`, '_blank', 'noopener,noreferrer')}
                                aria-label={t`Print Report`}
                                sx={{ backgroundColor: '#D6E4F2', color: '#012D72', borderRadius: '2px', margin: '5px' }}
                            >
                                <PrintIcon />
                            </IconButton>
                        </span>
                    </LightTooltip>
                ))}
        </Box>
    )
}
