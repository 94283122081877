import { Box, Button, ButtonProps, Divider } from '@mui/material'
import { FunctionComponent, PropsWithChildren } from 'react'
import { LinkProps, RouteProps, useHistory, useLocation } from 'react-router-dom'

interface FilterTabBarProps {
    widthAuto?: boolean
}
export const FilterTabBar: FunctionComponent<PropsWithChildren<FilterTabBarProps>> = ({ children, widthAuto }) => (
    <Box bgcolor='white' display={widthAuto ? 'inline-flex' : 'flex'} boxShadow={widthAuto ? 2 : 0}>
        {children}
    </Box>
)

interface FilterTabBarButtonProps extends ButtonProps {
    active?: boolean
}
export const FilterTabBarButton: FunctionComponent<FilterTabBarButtonProps> = ({ active, ...props }) => (
    <Button
        {...props}
        disabled={props.disabled}
        aria-current={active ? 'true' : 'false'}
        sx={{
            minWidth: 128,
            borderRadius: 0,
            borderBottomWidth: 3,
            borderBottomStyle: 'solid',
            borderBottomColor: active ? 'primary.main' : 'transparent',
            '&:hover': {
                borderBottomColor: active ? 'primary.main' : 'secondary.main'
            }
        }}
    />
)

interface RoutedFilterTabBarButtonProps extends ButtonProps {
    active?: boolean
    preserveQueryParams?: boolean
    onClick?: () => void
}

export const RoutedFilterTabBarButton: FunctionComponent<RoutedFilterTabBarButtonProps & LinkProps & { route: RouteProps }> = ({
    children,
    ...props
}) => {
    const history = useHistory() // Get the history object
    const location = useLocation() // Get the current location

    // Handle the button click by preserving query parameters
    const handleClick = () => {
        history.push({
            pathname: props.route.path as string,
            search: props.preserveQueryParams ? location.search : ''
        })
    }

    return (
        <Button
            onClick={props.onClick ? props.onClick : handleClick}
            aria-current={props.active ? 'true' : 'false'}
            data-testid={props['data-testid']}
            disabled={props.disabled}
            sx={{
                minWidth: 128,
                borderRadius: 0,
                borderBottomWidth: 3,
                borderBottomStyle: 'solid',
                borderBottomColor: props.active ? 'primary.main' : 'transparent',
                '&:hover': {
                    borderBottomColor: props.active ? 'primary.main' : 'secondary.main'
                }
            }}
        >
            {children}
        </Button>
    )
}

interface FilterTabBarDividerProps {}
export const FilterTabBarDivider: FunctionComponent<FilterTabBarDividerProps> = () => (
    <Divider orientation='vertical' flexItem sx={{ borderColor: 'grey.100' }} />
)
