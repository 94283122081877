import { Trans } from '@lingui/macro'
import { ContentContainerStyle, ContentPadding } from '@om1/platform-ui-kit/src/components/Layout'
import { FunctionComponent } from 'react'

export const NotFoundPage: FunctionComponent = () => {
    return (
        <ContentContainerStyle>
            <ContentPadding padX padY>
                <Trans>404 Not Found</Trans>
            </ContentPadding>
        </ContentContainerStyle>
    )
}
