import { t } from '@lingui/macro'
import { Box, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { notificationActions } from '@om1/platform-notifications'
import { FrameworkComponentProps } from '@om1/platform-utils'
import { useSnackbar } from 'notistack'
import React, { useCallback } from 'react'

const StyledMoreButton = styled(Button)`
    color: #ffffff;
    border-color: #ffffff;
    min-width: 50px;
    padding: 0px;
    text-transform: none;
    text-decoration: underline;
    font-size: small;
    line-height: 1.25;
`

export type SeeMoreToastComponentProps = FrameworkComponentProps<{}, typeof notificationActions, { cohortId: string; cohortName: string }>

export const SeeMoreToastComponent: React.FC<SeeMoreToastComponentProps> = ({ props: { cohortId, cohortName }, actions }) => {
    const { closeSnackbar } = useSnackbar()
    const snackbarKey = `snackbar-${cohortId}`

    const handleViewMore = useCallback(() => {
        actions.showTimeoutDialog(cohortId, cohortName)
        closeSnackbar(snackbarKey)
    }, [actions, cohortId, cohortName, closeSnackbar, snackbarKey])

    const handleClose = useCallback(() => {
        closeSnackbar(snackbarKey)
    }, [closeSnackbar, snackbarKey])

    return (
        <Box display='flex' flexDirection='column' gap={1}>
            <Box>
                {t`Update patient count of cohort`} {cohortName} {t`timed out`}
            </Box>
            <Box display='flex' gap={2}>
                <StyledMoreButton variant='text' onClick={handleViewMore}>{t`View Details`}</StyledMoreButton>
                <StyledMoreButton variant='text' onClick={handleClose}>{t`Dismiss`}</StyledMoreButton>
            </Box>
        </Box>
    )
}
