import { notificationActions } from '@om1/platform-notifications'
import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { BackOfficeState } from '../state'
import { dataDeliveryActions } from '../state/data-delivery'
import { DataDeliveryListComponent } from './DataDeliveryListComponent'
import { Auth0State } from '@om1/platform-authentication'

export function createDataDeliveryListComponent<TState extends { backOffice: BackOfficeState; auth0: Auth0State }>() {
    return connectRedux(
        withRouter(DataDeliveryListComponent, { namespace: 'dataDelivery', dataFetchAction: 'dataDeliveriesGet' }),
        (state: TState) => ({
            dataDeliveries: state.backOffice.dataDelivery.list.data,
            paginationMeta: state.backOffice.dataDelivery.list.paginationMeta,
            isLoading: state.backOffice.dataDelivery.list.ui.loading,
            permissions: state.auth0.permissions,
            organizationId: state.auth0.user!.org_id as string
        }),
        { ...dataDeliveryActions, ...notificationActions }
    )
}

export type DataDeliveryList = ReturnType<typeof createDataDeliveryListComponent>
