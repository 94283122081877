import { t, Trans } from '@lingui/macro'
import CheckIcon from '@mui/icons-material/Check'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import TitleIcon from '@mui/icons-material/Title'
import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    OutlinedInput,
    Paper,
    Stack
} from '@mui/material'
import { FrameworkComponentProps, PlatformPermissions } from '@om1/platform-utils'
import React, { Fragment, ReactNode, useState } from 'react'
import { cohortBlocksEditActions, CohortDraggingState, CriteriaType, OperationNode } from '../../../state'
import { CohortNodeRenderer } from './CohortNodeRenderer'
import { EmptyDropZone } from './EmptyDropZone'
import { OrDropZone } from './OrDropZone'
import { hasCriteriaType } from '../utils/filter-utils'

export type CohortCriteriaBlockProps = FrameworkComponentProps<
    { node: OperationNode; dragState: CohortDraggingState },
    typeof cohortBlocksEditActions,
    { readOnly?: boolean; permissions: string[] }
>

/**
 * The primary container element used to group criteria and operations into logical chunks. It also provides some
 * metadata storage, such as a name and the option to exclude the block in queries. This component may also be
 * referred to as a "concept block".
 */
export const CohortCriteriaBlock: React.FunctionComponent<CohortCriteriaBlockProps> = ({
    state: { node, dragState },
    actions,
    props: { readOnly, permissions }
}) => {
    const hasChildren = node.children?.length > 0

    const hasExternalCohortNode = hasCriteriaType(node, CriteriaType.ExternalCohort)
    const editable = !readOnly && !(hasExternalCohortNode && !permissions.includes(PlatformPermissions.ACCESS_EXTERNAL_COHORTs))

    const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null)
    const menuOpen = Boolean(menuAnchor)
    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => setMenuAnchor(event.currentTarget)
    const handleMenuClose = () => setMenuAnchor(null)

    const menuId = `criteria-block-menu-${node.id}`
    const menuToggleId = `criteria-block-menu-toggle-${node.id}`

    const handleDelete = () => {
        actions.operationDelete({ nodeId: node.id })
    }

    const handleExclusionToggle = (value: boolean) => {
        actions.excludedUpdate({ target: { nodeId: node.id }, operation: { excluded: value } })
        handleMenuClose()
    }

    const [showTitleEdit, setShowTitleEdit] = useState(false)
    const hasTitle = node.name && node.name.length > 0
    const handleTitleEditStart = () => setShowTitleEdit(true)
    const handleTitleAdd = () => {
        setMenuAnchor(null)
        setTimeout(() => {
            setShowTitleEdit(true)
        })
    }
    const handleTitleDelete = () => {
        actions.operationUpdate({ target: { nodeId: node.id }, operation: { name: undefined } })
    }
    const handleTitleChange = (value: string) => {
        setShowTitleEdit(false)
        if (value && value.length > 0) {
            actions.operationUpdate({ target: { nodeId: node.id }, operation: { name: value } })
        } else {
            handleTitleDelete()
        }
    }

    let title: ReactNode
    if (showTitleEdit) {
        title = (
            <Box my={1}>
                <TitleInput value={node.name || ''} onChange={handleTitleChange} />
            </Box>
        )
    } else if (hasTitle) {
        if (editable) {
            title = (
                <Box display='flex' alignItems='center' gap={0.5} my={1}>
                    <Button sx={{ color: 'black', padding: 0, textAlign: 'start' }} onClick={handleTitleEditStart} data-testid='criteria-block-title'>
                        {node.name}
                    </Button>
                    <IconButton size='small' aria-label={t`Delete Title`} onClick={handleTitleDelete} data-testid='criteria-block-title-delete'>
                        <DeleteOutlineIcon fontSize='small' />
                    </IconButton>
                </Box>
            )
        } else {
            title = (
                <Box data-testid='criteria-block-title' my={1}>
                    {node.name}
                </Box>
            )
        }
    } else {
        title = <Box my={1}> </Box>
    }

    let editMenu: ReactNode
    if (editable && !dragState.disable) {
        editMenu = (
            <>
                <IconButton
                    id={menuToggleId}
                    size='small'
                    sx={{ padding: 1, marginTop: '10px', marginBottom: '5px' }}
                    aria-label={t`Block settings`}
                    aria-controls={menuOpen ? menuId : undefined}
                    aria-haspopup='true'
                    aria-expanded={menuOpen ? 'true' : undefined}
                    onClick={handleMenuOpen}
                    data-testid='criteria-block-menu-toggle'
                >
                    <MoreHorizIcon color='primary' />
                </IconButton>
                <Menu
                    id={menuId}
                    anchorEl={menuAnchor}
                    open={menuOpen}
                    onClose={handleMenuClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    MenuListProps={{
                        'aria-labelledby': menuToggleId,
                        // @ts-expect-error
                        'data-testid': 'criteria-block-menu'
                    }}
                >
                    <MenuItem onClick={handleTitleAdd} data-testid='criteria-block-menu-item-title'>
                        <ListItemIcon>
                            <TitleIcon fontSize='small' color='primary' />
                        </ListItemIcon>
                        <ListItemText>{!hasTitle ? <Trans>Add Title</Trans> : <Trans>Edit Title</Trans>}</ListItemText>
                    </MenuItem>
                    {hasChildren && (
                        <MenuItem onClick={() => handleExclusionToggle(!node.excluded)} data-testid='criteria-block-menu-item-exclusion'>
                            <ListItemIcon>
                                {node.excluded ? (
                                    <CheckCircleOutlineIcon fontSize='small' color='primary' />
                                ) : (
                                    <DoNotDisturbAltIcon fontSize='small' color='primary' />
                                )}
                            </ListItemIcon>
                            <ListItemText>
                                {node.excluded ? <Trans>Include block in count</Trans> : <Trans>Exclude block from count</Trans>}
                            </ListItemText>
                        </MenuItem>
                    )}
                    <MenuItem onClick={handleDelete} data-testid='criteria-block-menu-item-delete'>
                        <ListItemIcon>
                            <DeleteOutlineIcon fontSize='small' color='primary' />
                        </ListItemIcon>
                        <ListItemText>
                            <Trans>Delete block</Trans>
                        </ListItemText>
                    </MenuItem>
                </Menu>
            </>
        )
    }

    let dropZone: ReactNode
    if (editable && !node.excluded) {
        if (hasChildren) {
            dropZone = <OrDropZone actions={actions} state={{ node, dragState }} props={{}} />
        } else {
            dropZone = <EmptyDropZone actions={actions} state={{ node }} props={{}} />
        }
    }

    return (
        <>
            <Paper elevation={1}>
                <Stack spacing={0.25} pb={hasChildren ? 1 : 2.5} px={2.5} bgcolor='white'>
                    <Grid container spacing={0.5}>
                        <Grid item xs>
                            {title}
                        </Grid>

                        {editMenu && (
                            <Grid item xs='auto'>
                                {editMenu}
                            </Grid>
                        )}
                    </Grid>

                    <Box sx={node.excluded ? { opacity: 0.35, pointerEvents: 'none' } : undefined}>
                        {node.children?.map((criteria, index) => (
                            <Fragment key={criteria.id}>
                                {index > 0 && (
                                    <Box display='flex' flexDirection='column' alignItems='center'>
                                        <Box height={10} width='1px' bgcolor='secondary.light' />
                                        <span>{node.operation}</span>
                                        <Box height={10} width='1px' bgcolor='secondary.light' />
                                    </Box>
                                )}
                                <Box bgcolor='grey.100' p={2}>
                                    <CohortNodeRenderer
                                        state={{
                                            node: criteria,
                                            parentOperation: node.operation,
                                            dragState: { ...dragState, active: !node.excluded && dragState.active }
                                        }}
                                        actions={actions}
                                        props={{ readOnly }}
                                    />
                                </Box>
                            </Fragment>
                        ))}

                        {dropZone}
                    </Box>
                </Stack>
            </Paper>
        </>
    )
}

interface TitleInputProps {
    value: string
    onChange: (value: string) => void
}
const TitleInput = (props: TitleInputProps) => {
    const [localValue, setLocalValue] = useState(props.value)

    return (
        <>
            <FormControl variant='outlined' fullWidth>
                <OutlinedInput
                    autoFocus
                    size='small'
                    type='text'
                    fullWidth
                    value={localValue}
                    inputProps={{ 'data-testid': 'criteria-block-title-input' }}
                    onBlur={(event) => props.onChange(event.target.value)}
                    onChange={(event) => setLocalValue(event.target.value)}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            props.onChange(localValue)
                        }
                    }}
                    endAdornment={
                        <InputAdornment position='end'>
                            <IconButton
                                size='small'
                                aria-label={t`Save`}
                                onClick={() => props.onChange(localValue)}
                                onMouseDown={() => {}}
                                edge='end'
                            >
                                <CheckIcon fontSize='small' />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </>
    )
}
