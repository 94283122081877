import * as React from 'react'

import { AllLocaleData, AllMessages, i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { PropsWithChildren } from 'react'
import { LocaleEnum } from './language-config'

export const LanguageProvider: React.FunctionComponent<
    PropsWithChildren<{
        language: LocaleEnum
        messages: AllMessages
        plurals: AllLocaleData
    }>
> = ({
    children,
    language,
    messages,
    plurals
}: PropsWithChildren<{
    language: LocaleEnum
    messages: AllMessages
    plurals: AllLocaleData
}>) => {
    i18n.loadLocaleData(plurals)
    i18n.load(messages)
    i18n.activate(language)
    return <I18nProvider i18n={i18n}>{children}</I18nProvider>
}

export default LanguageProvider
