import { t } from '@lingui/macro'
import { AnalyticsRefDimension, CriteriaType, DiagnosisIcd10Ref } from '../../../../state'
import { DepthRefFieldMapper, DepthRefGetterCallback, RefFieldMapperType } from './interfaces'

const getItemLabel: DepthRefGetterCallback<DiagnosisIcd10Ref> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.chapterLabel
        case 1:
            return item.sectionLabel
        case 2:
            return item.categoryLabel
        case 3:
            return item.firstDecimalLabel
        case 4:
            return item.secondDecimalLabel
        case 5:
            return item.thirdDecimalLabel
    }
}

const getItemValue: DepthRefGetterCallback<DiagnosisIcd10Ref> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.chapterRange
        case 1:
            return item.sectionRange
        case 2:
            return item.categoryCode
        case 3:
            return item.firstDecimalCode
        case 4:
            return item.secondDecimalCode
        case 5:
            return item.thirdDecimalCode
    }
}

const getItemTooltip: DepthRefGetterCallback<DiagnosisIcd10Ref> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
            return `${getItemValue(item, depth)}: ${getItemLabel(item, depth)}`
    }
}

export const Icd10RefFieldMapper: DepthRefFieldMapper<DiagnosisIcd10Ref> = {
    type: RefFieldMapperType.Depth,
    dimension: AnalyticsRefDimension.DIAGNOSIS_ICD10,
    criteriaType: CriteriaType.Diagnosis,
    table: 'patient_diagnosis',
    dateField: {
        first: 'first_record_date',
        last: 'last_record_date',
        any: 'any_record_date'
    },
    dateFieldInverse: {
        first_record_date: 'first',
        last_record_date: 'last',
        any_record_date: 'any'
    },
    searchPlaceholder: (() => t`Search Diagnoses`)(),
    noResultsMessage: (() => t`No results`)(),
    searchHintMessage: (() => t`Search to browse diagnoses`)(),
    filterFieldToRefField: {
        chapter_range_icd10: 'chapterRange',
        section_range_icd10: 'sectionRange',
        category_code_icd10: 'categoryCode',
        first_decimal_code_icd10: 'firstDecimalCode',
        second_decimal_code_icd10: 'secondDecimalCode',
        third_decimal_code_icd10: 'thirdDecimalCode'
    },
    filterFieldOrder: [
        'chapter_range_icd10',
        'section_range_icd10',
        'category_code_icd10',
        'first_decimal_code_icd10',
        'second_decimal_code_icd10',
        'third_decimal_code_icd10'
    ],
    filterLabelOrder: [
        'chapter_label_icd10',
        'section_label_icd10',
        'category_label_icd10',
        'first_decimal_label_icd10',
        'second_decimal_label_icd10',
        'third_decimal_label_icd10'
    ],
    getItemLabel,
    getItemValue,
    getItemTooltip
}
