import { styled } from '@mui/system'
import { RestoreRouterLink } from '@om1/platform-routing'
import * as React from 'react'
import { TopNavigationItem } from '../TopNavigationItem'
import { ResponsiveAppBarItemProps } from './ResponsiveAppBarItem'

export type MainMenuItemProps = ResponsiveAppBarItemProps

export const MainMenuItem: React.FunctionComponent<MainMenuItemProps> = (props) => {
    return (
        <RestoreRouterLink {...props}>
            <TopNavigationItem active={!!props.match} handleCloseNavMenu={props.handleCloseNavMenu}>
                {props.children}
            </TopNavigationItem>
        </RestoreRouterLink>
    )
}

export const MenuItemLabel = styled('div')`
    height: 16px;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    color: white;
`
