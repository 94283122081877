import { DataDeliveryAdminDeliveryService, DeliveryCohortPatchDTO, DeliveryDTO } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { notificationActions } from '@om1/platform-notifications'
import { call, put } from 'redux-saga/effects'
import { dataDeliveryActions } from '../state/data-delivery'

export function createUpdateDataDeliverySaga() {
    return function* (action: ReturnType<typeof dataDeliveryActions.dataDeliveriesUpdate>) {
        yield put(dataDeliveryActions.dataDeliveryLoading({ loading: true }))
        try {
            const deliveryDTO: DeliveryDTO = yield call(DataDeliveryAdminDeliveryService.patchDataDeliveryDeliveryDeliveryIdPatch, {
                deliveryId: action.payload.deliveryId,
                requestBody: {
                    deliveredDatabaseId: action.payload.deliveredDatabaseId,
                    organizationId: action.payload.organizationId,
                    cohorts: action.payload.cohorts?.map((deliveryCohortPatchDTO: DeliveryCohortPatchDTO) => {
                        return { ...deliveryCohortPatchDTO, id: deliveryCohortPatchDTO.id || null }
                    })
                }
            })
            yield put(notificationActions.success())
            yield put(dataDeliveryActions.dataDeliveriesListAddEdit({ dataDelivery: deliveryDTO, isAdd: false }))
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(dataDeliveryActions.dataDeliveryLoading({ loading: false }))
        }
    }
}
