import { ExplorerAnalyticsRefService, PaginatedDictDTO_str_Any_ } from '@om1/falcon-api'
import camelcaseKeys from 'camelcase-keys'
import { call, put } from 'redux-saga/effects'
import { FIELD_MAPPERS_GENERIC, RefFieldMapper, SpecialtyRefFieldMapper, extractRefLabels } from '../components/edit/utils/ref-field-mappers'
import {
    QueryFilterCondition,
    QueryFilterOperator,
    RefLabelsState,
    SpecialtyQualifier,
    cohortBlocksEditActions,
    isApiAndOperation,
    isApiDateAwareFilterDTO,
    isApiExceptOperation,
    isApiFilterDTO,
    isApiOrOperation,
    isApiRelativeDateFilterDTO,
    isApiRelativeFollowUpFilterDTO,
    isSpecialtyQualifier
} from '../state'
import { AnalyticsDimensionToTableName } from './get-demographic-refs-saga'

export function createGetRefLabelsSaga() {
    return function* ({ payload: { query } }: ReturnType<typeof cohortBlocksEditActions.getRefLabels>) {
        yield put(cohortBlocksEditActions.setRefLabelsLoading({ loading: true }))
        if (query && query.filters && query.filters.length) {
            let refLabels: RefLabelsState = filtersToReadable(query.filters, {})
            for (let i = 0; i < FIELD_MAPPERS_GENERIC.length; i++) {
                const fieldMapper = FIELD_MAPPERS_GENERIC[i]
                if (refLabels[fieldMapper.table]) {
                    const tableRoot = refLabels[fieldMapper.table]
                    const filterValues: string[] = []
                    for (const field in tableRoot) {
                        // Only add query values if the field is included in the fieldMapper's fields
                        if (fieldMapper.filterFieldOrder.includes(field)) {
                            for (const value in tableRoot[field]) {
                                filterValues.push(value)
                            }
                        }
                    }
                    if (filterValues.length) {
                        try {
                            const paginatedDictDTOAny: PaginatedDictDTO_str_Any_ = yield call(
                                ExplorerAnalyticsRefService.indexExplorerAnalyticsRefRefTableNameGet,
                                {
                                    page: 1,
                                    limit: 100,
                                    filters: filterValues,
                                    refTableName: AnalyticsDimensionToTableName[fieldMapper.dimension]
                                }
                            )
                            const refs = camelcaseKeys(paginatedDictDTOAny.data)
                            const tableRefLabels = extractRefLabels<any>(refs, fieldMapper as RefFieldMapper<any>)
                            const tableName = Object.keys(tableRefLabels)[0]
                            // Since different refs can have the same table name, merge the table's ref labels if the key already exists
                            if (refLabels[tableName]) {
                                refLabels = { ...refLabels, [tableName]: { ...refLabels[tableName], ...tableRefLabels[tableName] } }
                            }
                        } catch (e) {
                            // continue after failure, allow UI to show values instead of labels
                        }
                    }
                }
            }
            yield put(cohortBlocksEditActions.mergeRefLabels(refLabels))
        }
        yield put(cohortBlocksEditActions.setRefLabelsLoading({ loading: false }))
    }
}

const parseSpecialtyQualifier = (q: SpecialtyQualifier, initialRefs: RefLabelsState) => {
    if (!initialRefs[SpecialtyRefFieldMapper.table]) {
        initialRefs[SpecialtyRefFieldMapper.table] = {}
    }

    SpecialtyRefFieldMapper.filterFieldOrder.forEach((filterField) => {
        if (!initialRefs[SpecialtyRefFieldMapper.table][filterField]) {
            initialRefs[SpecialtyRefFieldMapper.table][filterField] = {}
        }

        q.values.forEach((qVal) => {
            initialRefs[SpecialtyRefFieldMapper.table][filterField][qVal] = qVal
        })
    })
}

function filtersToReadable(filters: (QueryFilterOperator | QueryFilterCondition)[], initialRefs: RefLabelsState) {
    filters.forEach((f) => {
        if (isApiOrOperation(f)) {
            return filtersToReadable(f.or, initialRefs)
        } else if (isApiAndOperation(f)) {
            return filtersToReadable(f.and, initialRefs)
        } else if (isApiExceptOperation(f)) {
            return filtersToReadable(f.except, initialRefs)
        } else if (isApiFilterDTO(f) || isApiDateAwareFilterDTO(f)) {
            if (!initialRefs[f.table]) {
                initialRefs[f.table] = { [f.field]: {} }
            }
            if (!initialRefs[f.table][f.field]) {
                initialRefs[f.table][f.field] = {}
            }
            f.values.forEach((v) => {
                initialRefs[f.table][f.field][v] = v
            })

            // Specialist notes qualifier requires prefetching refs for display
            if (f.qualifiers && f.qualifiers.length > 0) {
                f.qualifiers.forEach((q) => {
                    if (isSpecialtyQualifier(q)) {
                        parseSpecialtyQualifier(q, initialRefs)
                    }
                })
            }
        } else if (isApiRelativeDateFilterDTO(f)) {
            if (!initialRefs[f.subjectFilter.table]) {
                initialRefs[f.subjectFilter.table] = { [f.subjectFilter.table]: {} }
            }
            if (!initialRefs[f.subjectFilter.table][f.subjectFilter.field]) {
                initialRefs[f.subjectFilter.table][f.subjectFilter.field] = {}
            }
            f.subjectFilter.values.forEach((v) => {
                initialRefs[f.subjectFilter.table][f.subjectFilter.field][v] = v
            })
            if (!initialRefs[f.referenceFilter.table]) {
                initialRefs[f.referenceFilter.table] = { [f.referenceFilter.table]: {} }
            }
            if (!initialRefs[f.referenceFilter.table][f.referenceFilter.field]) {
                initialRefs[f.referenceFilter.table][f.referenceFilter.field] = {}
            }
            f.referenceFilter.values.forEach((v) => {
                initialRefs[f.referenceFilter.table][f.referenceFilter.field][v] = v
            })

            if (f.referenceFilter.qualifiers && f.referenceFilter.qualifiers.length > 0) {
                f.referenceFilter.qualifiers.forEach((q) => {
                    if (isSpecialtyQualifier(q)) {
                        parseSpecialtyQualifier(q, initialRefs)
                    }
                })
            }

            if (f.subjectFilter.qualifiers && f.subjectFilter.qualifiers.length > 0) {
                f.subjectFilter.qualifiers.forEach((q) => {
                    if (isSpecialtyQualifier(q)) {
                        parseSpecialtyQualifier(q, initialRefs)
                    }
                })
            }
        } else if (isApiRelativeFollowUpFilterDTO(f)) {
            if (f.baseline) {
                if (!initialRefs[f.baseline.subjectFilter.table]) {
                    initialRefs[f.baseline.subjectFilter.table] = { [f.baseline.subjectFilter.table]: {} }
                }
                if (!initialRefs[f.baseline.subjectFilter.table][f.baseline.subjectFilter.field]) {
                    initialRefs[f.baseline.subjectFilter.table][f.baseline.subjectFilter.field] = {}
                }
                f.baseline.subjectFilter.values.forEach((v) => {
                    if (f?.baseline?.subjectFilter.table && f?.baseline?.subjectFilter.field) {
                        initialRefs[f.baseline.subjectFilter.table][f.baseline.subjectFilter.field][v] = v
                    }
                })
                if (!initialRefs[f.baseline.referenceFilter.table]) {
                    initialRefs[f.baseline.referenceFilter.table] = { [f.baseline.referenceFilter.table]: {} }
                }
                if (!initialRefs[f.baseline.referenceFilter.table][f.baseline.referenceFilter.field]) {
                    initialRefs[f.baseline.referenceFilter.table][f.baseline.referenceFilter.field] = {}
                }
                f.baseline.referenceFilter.values.forEach((v) => {
                    if (f?.baseline?.subjectFilter.table && f?.baseline?.subjectFilter.field) {
                        initialRefs[f.baseline.referenceFilter.table][f.baseline.referenceFilter.field][v] = v
                    }
                })

                if (f.baseline.referenceFilter.qualifiers && f.baseline.referenceFilter.qualifiers.length > 0) {
                    f.baseline.referenceFilter.qualifiers.forEach((q) => {
                        if (isSpecialtyQualifier(q)) {
                            parseSpecialtyQualifier(q, initialRefs)
                        }
                    })
                }

                if (f.baseline.subjectFilter.qualifiers && f.baseline.subjectFilter.qualifiers.length > 0) {
                    f.baseline.subjectFilter.qualifiers.forEach((q) => {
                        if (isSpecialtyQualifier(q)) {
                            parseSpecialtyQualifier(q, initialRefs)
                        }
                    })
                }
            }
            if (f.followUp) {
                if (!initialRefs[f.followUp.subjectFilter.table]) {
                    initialRefs[f.followUp.subjectFilter.table] = { [f.followUp.subjectFilter.table]: {} }
                }
                if (!initialRefs[f.followUp.subjectFilter.table][f.followUp.subjectFilter.field]) {
                    initialRefs[f.followUp.subjectFilter.table][f.followUp.subjectFilter.field] = {}
                }
                f.followUp.subjectFilter.values.forEach((v) => {
                    if (f?.baseline?.subjectFilter.table && f?.baseline?.subjectFilter.field) {
                        initialRefs[f.baseline.subjectFilter.table][f.baseline.subjectFilter.field][v] = v
                    }
                })
                if (!initialRefs[f.followUp.referenceFilter.table]) {
                    initialRefs[f.followUp.referenceFilter.table] = { [f.followUp.referenceFilter.table]: {} }
                }
                if (!initialRefs[f.followUp.referenceFilter.table][f.followUp.referenceFilter.field]) {
                    initialRefs[f.followUp.referenceFilter.table][f.followUp.referenceFilter.field] = {}
                }
                f.followUp.referenceFilter.values.forEach((v) => {
                    if (f?.baseline?.subjectFilter.table && f?.baseline?.subjectFilter.field) {
                        initialRefs[f.baseline.referenceFilter.table][f.baseline.referenceFilter.field][v] = v
                    }
                })

                if (f.followUp.referenceFilter.qualifiers && f.followUp.referenceFilter.qualifiers.length > 0) {
                    f.followUp.referenceFilter.qualifiers.forEach((q) => {
                        if (isSpecialtyQualifier(q)) {
                            parseSpecialtyQualifier(q, initialRefs)
                        }
                    })
                }

                if (f.followUp.subjectFilter.qualifiers && f.followUp.subjectFilter.qualifiers.length > 0) {
                    f.followUp.subjectFilter.qualifiers.forEach((q) => {
                        if (isSpecialtyQualifier(q)) {
                            parseSpecialtyQualifier(q, initialRefs)
                        }
                    })
                }
            }
        }
    })
    return initialRefs
}
