import { yupResolver } from '@hookform/resolvers/yup'
import { Trans, t } from '@lingui/macro'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { ReactNode } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import {
    DateRangeInclusion,
    DateRangeIntervalUnit,
    DateRangeOperator,
    DateRelationMetadata,
    DiagnosisCriterion,
    EhrNotesCriterion,
    ExternalCohortCriterion,
    LabTestCriterion,
    MedicationCriterion,
    ObservationCriterion,
    ObservationPeriodCriterion,
    ProcedureCriterion
} from '../../../state'
import { CriteriaTypeLabel } from '../../shared/CriteriaTypeLabel'
import { translateRangeInclusion } from '../utils/date-utils'

export type DateRelationDialogProps = {
    onCancel: () => void
    onSave: (dateRelation: DateRelationMetadata) => void
    initialValue: DateRelationMetadata
    criteria:
        | DiagnosisCriterion
        | ProcedureCriterion
        | LabTestCriterion
        | MedicationCriterion
        | ObservationCriterion
        | ObservationPeriodCriterion
        | EhrNotesCriterion
        | ExternalCohortCriterion
}

interface DateRelationFormValues {
    interval: number // x in x days
    intervalUnit: string // day month year
    rangeOperator: string // Before/After/Before or After
    rangeInclusion: string // More than, less than, equal, at least, at most
}

export const DateRelationValidationSchema = Yup.object().shape({
    interval: Yup.number()
        .required(() => {
            return t`Interval is required.`
        })
        .min(0),
    intervalUnit: Yup.string(),
    rangeOperator: Yup.string(),
    rangeInclusion: Yup.string()
})

/**
 * A dialog to modify the date relationship betweeen a subject and reference criterion.
 */
export const DateRelationDialog = ({ onCancel, onSave, criteria, initialValue }: DateRelationDialogProps) => {
    const { type: subjectType } = criteria
    let referenceType
    if (criteria.reference?.criteria) {
        referenceType = criteria.reference.criteria.type
    }

    const { handleSubmit, register, control } = useForm<DateRelationFormValues>({
        defaultValues: {
            interval: getInitialInterval(initialValue),
            intervalUnit: initialValue ? initialValue.intervalUnitFromReferenceDate : DateRangeIntervalUnit.Day,
            rangeOperator: initialValue ? initialValue.dateRangeOperator : DateRangeOperator.After,
            rangeInclusion: getInitialRangeInclusion(initialValue)
        },
        resolver: yupResolver(DateRelationValidationSchema)
    })
    const onSubmit: SubmitHandler<DateRelationFormValues> = (dateRelationValues: DateRelationFormValues) => {
        onSave(formValuesToDateRelationMetaData(dateRelationValues))
    }

    return (
        <Dialog open maxWidth='md' fullWidth>
            <DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={onCancel}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Box paddingTop={2} display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                        <Box flex={0} marginRight={1}>
                            <CriteriaTypeLabel criteriaType={subjectType} />
                        </Box>
                        <Controller
                            control={control}
                            name='rangeInclusion'
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    id='range-inclusion'
                                    labelId='range-inclusion-label'
                                    sx={{ flex: 1, marginRight: 1 }}
                                    required
                                    value={value}
                                    onChange={onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}
                                >
                                    <MenuItem value={DateRangeInclusion.AtLeast}>
                                        <Trans>At Least</Trans>
                                    </MenuItem>
                                    <MenuItem value={DateRangeInclusion.AtMost}>
                                        <Trans>At Most</Trans>
                                    </MenuItem>
                                    <MenuItem value={DateRangeInclusion.Exactly}>
                                        <Trans>Exactly</Trans>
                                    </MenuItem>
                                    <MenuItem value={DateRangeInclusion.LessThan}>
                                        <Trans>Less Than</Trans>
                                    </MenuItem>
                                    <MenuItem value={DateRangeInclusion.MoreThan}>
                                        <Trans>More Than</Trans>
                                    </MenuItem>
                                </Select>
                            )}
                        />
                        <TextField
                            id='interval'
                            {...register('interval')}
                            required
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            sx={{ flex: 1, marginRight: 1 }}
                        />
                        <Controller
                            control={control}
                            name='intervalUnit'
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    id='interval-unit'
                                    labelId='interval-unit-label'
                                    required
                                    sx={{ flex: 1, marginRight: 1 }}
                                    value={value}
                                    onChange={onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}
                                >
                                    <MenuItem value={DateRangeIntervalUnit.Day}>
                                        <Trans>Days</Trans>
                                    </MenuItem>
                                    <MenuItem value={DateRangeIntervalUnit.Month}>
                                        <Trans>Months</Trans>
                                    </MenuItem>
                                    <MenuItem value={DateRangeIntervalUnit.Year}>
                                        <Trans>Years</Trans>
                                    </MenuItem>
                                </Select>
                            )}
                        />
                        <Controller
                            control={control}
                            name='rangeOperator'
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    id='range-operator'
                                    labelId='range-operator-label'
                                    required
                                    sx={{ flex: 1, marginRight: 1 }}
                                    value={value}
                                    onChange={onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}
                                >
                                    <MenuItem value={DateRangeOperator.Before}>
                                        <Trans>Before</Trans>
                                    </MenuItem>
                                    <MenuItem value={DateRangeOperator.After}>
                                        <Trans>After</Trans>
                                    </MenuItem>
                                    <MenuItem value={DateRangeOperator.BeforeOrAfter}>
                                        <Trans>Before or After</Trans>
                                    </MenuItem>
                                </Select>
                            )}
                        />
                        <Box flex={0}>
                            <CriteriaTypeLabel criteriaType={referenceType} />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' color='primary' onClick={onCancel}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button variant='contained' type='submit'>
                        <Trans>Save</Trans>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

function formValuesToDateRelationMetaData(formValues: DateRelationFormValues): DateRelationMetadata {
    const { interval, intervalUnit, rangeInclusion, rangeOperator } = formValues
    let dateRelationMetadata: DateRelationMetadata = {
        dateRangeOperator: rangeOperator as DateRelationMetadata['dateRangeOperator'],
        intervalStartFromReferenceDate: 0,
        intervalEndFromReferenceDate: 0,
        intervalUnitFromReferenceDate: intervalUnit as DateRangeIntervalUnit,
        intervalIsInclusive: true
    }

    const diff = translateRangeInclusion(rangeInclusion, interval)
    return { ...dateRelationMetadata, ...diff }
}

export function getInitialRangeInclusion({
    intervalStartFromReferenceDate,
    intervalEndFromReferenceDate,
    intervalIsInclusive
}: DateRelationMetadata) {
    if (intervalEndFromReferenceDate === undefined) {
        if (intervalIsInclusive) {
            return DateRangeInclusion.AtLeast
        } else {
            return DateRangeInclusion.MoreThan
        }
    } else if (
        intervalEndFromReferenceDate &&
        intervalStartFromReferenceDate === 0 &&
        intervalEndFromReferenceDate > intervalStartFromReferenceDate
    ) {
        if (intervalIsInclusive) {
            return DateRangeInclusion.AtMost
        } else {
            return DateRangeInclusion.LessThan
        }
    } else if (intervalStartFromReferenceDate === intervalEndFromReferenceDate) {
        return DateRangeInclusion.Exactly
    } else {
        // Default return
        return DateRangeInclusion.MoreThan
    }
}

export function getInitialInterval(initialValue: DateRelationMetadata) {
    // Interval is the max of the start and end intervals
    return initialValue ? Math.max(initialValue.intervalStartFromReferenceDate || 0, initialValue.intervalEndFromReferenceDate || 0) : 0
}
