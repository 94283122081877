import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { FrameworkComponentProps } from '@om1/platform-utils'
import React, { useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { CohortDraggingState, CohortTreeActionType, OperationNode, cohortBlocksEditActions } from '../../../state'
import { useCriteriaDropZone } from '../utils/useCriteriaDropZone'
import { CRITERIA_DEFAULTS } from '../base/CohortEditComponent'

export type OrDropZoneProps = FrameworkComponentProps<
    { node: OperationNode; dragState: CohortDraggingState },
    typeof cohortBlocksEditActions,
    Record<string, never>
>

/**
 * A drop zone displayed in a criteria block when an element is being dragged, which allows adding new criterion to the
 * block.
 */
export const OrDropZone: React.FunctionComponent<OrDropZoneProps> = ({ state, actions }) => {
    const ref = useRef<HTMLDivElement>(null)

    const { dropProps, isDropTarget } = useCriteriaDropZone({
        ref,
        onAdd: (type) => {
            const uuid = uuidv4()
            if (Object.keys(CRITERIA_DEFAULTS).includes(type)) {
                actions.criteriaInsert({ target: { nodeId: state.node.id }, criteriaType: type, filters: CRITERIA_DEFAULTS[type], uuid })
            } else {
                actions.criteriaDialogTrigger({ type, action: { type: CohortTreeActionType.Insert, target: { nodeId: state.node.id }, uuid } })
            }
        },
        onCopy: (source) => actions.criteriaCopy({ source, target: { nodeId: state.node.id } }),
        onMove: (source) => actions.criteriaMove({ source, target: { nodeId: state.node.id } })
    })

    return (
        <>
            <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                visibility={state.dragState.active ? 'visible' : 'hidden'}
                height={state.dragState.active ? 38 : 0}
                overflow='hidden'
                sx={{ transition: 'all 150ms ease-in-out' }}
            >
                <Box height={10} width='1px' bgcolor='secondary.light' />
                <span>
                    <Trans>OR</Trans>
                </span>
                <Box height={10} width='1px' bgcolor='secondary.light' />
            </Box>
            <Box
                // Drag and drop
                ref={ref}
                role='button'
                tabIndex={0}
                {...dropProps}
                // Styles
                border={2}
                borderColor='primary.light'
                overflow='hidden'
                visibility={state.dragState.active ? 'visible' : 'hidden'}
                height={state.dragState.active ? 54 : 0}
                bgcolor={isDropTarget ? '#eee' : 'transparent'}
                display='flex'
                alignItems='center'
                justifyContent='center'
                sx={{ borderStyle: 'dashed', transition: 'all 150ms ease-in-out' }}
            >
                <Trans>Drag and drop here to add criteria</Trans>
            </Box>
        </>
    )
}
