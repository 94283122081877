import { Dialog } from '@mui/material'
import React from 'react'
import { createCreateEditCohortComponent } from './CreateEditCohort'
import { CohortListItem } from '../../state'
import { CohortSaveValues } from './CreateEditCohortComponent'

const CreateEditCohortComponent = createCreateEditCohortComponent()

interface EditCohortDialogComponentProps {
    cohort: CohortListItem
    onSave: (cohort: CohortSaveValues) => void
    onCancel: () => void
    isLoading: boolean
}

const EditCohortDialogComponent: React.FC<EditCohortDialogComponentProps> = ({ cohort, onCancel, onSave, isLoading }) => {
    return (
        <Dialog open maxWidth='sm' fullWidth onClose={onCancel}>
            <CreateEditCohortComponent cohort={cohort} onCancel={onCancel} onSave={onSave} isLoading={isLoading} />
        </Dialog>
    )
}

export default EditCohortDialogComponent
