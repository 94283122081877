import { debounce, takeEvery } from 'redux-saga/effects'
import { DataDeliveryActionTypes } from '../state/data-delivery'
import { createAddDataDeliveryTaskListenerSaga } from './add-data-delivery-task-listener-saga'
import createCreateDataDeliverySaga from './create-data-delivery-saga'
import { createExecuteDataDeliverySaga } from './execute-data-delivery-saga'
import { createFetchAndUpdateDataDeliveryInListSaga } from './fetch-and-update-data-delivery-in-list-saga'
import { createGetCohortsSaga } from './get-cohorts-saga'
import { createGetDataDeliveriesSaga } from './get-data-deliveries-saga'
import { createGetDeliveredDatabasesSaga } from './get-delivered-databases-saga'
import { createUpdateDataDeliverySaga } from './update-data-delivery-saga'
import { createGetOrganizationMetadataSaga } from './get-organization-metadata-saga'
import createCreateOrganizationMetadataSaga from './create-organization-metadata-saga'
import { createUpdateOrganizationMetadataSaga } from './update-organization-metadata-saga'
import { createGetDataDeliveryRegistryTypesSaga } from './get-data-delivery-registry-types-saga'

export function createBackOfficeSaga() {
    const createDataDeliverySaga = createCreateDataDeliverySaga()
    const updateDataDeliverySaga = createUpdateDataDeliverySaga()
    const getCohortsSaga = createGetCohortsSaga()
    const getDataDeliveriesSaga = createGetDataDeliveriesSaga()
    const getDeliveredDatabasesSaga = createGetDeliveredDatabasesSaga()
    const executeDataDeliverySaga = createExecuteDataDeliverySaga()
    const addDataDeliveryTaskListenerSaga = createAddDataDeliveryTaskListenerSaga()
    const fetchAndUpdateDataDeliveryInListSaga = createFetchAndUpdateDataDeliveryInListSaga()
    const getOrganizationMetadataSaga = createGetOrganizationMetadataSaga()
    const createOrganizationMetadataSaga = createCreateOrganizationMetadataSaga()
    const updateOrganizationMetadataSaga = createUpdateOrganizationMetadataSaga()
    const getDataDeliveryRegistryTypesSaga = createGetDataDeliveryRegistryTypesSaga()

    return function* () {
        yield debounce(0, DataDeliveryActionTypes.COHORTS_ACCESS, getCohortsSaga)
        yield debounce(0, DataDeliveryActionTypes.DATA_DELIVERIES_GET, getDataDeliveriesSaga)
        yield debounce(0, DataDeliveryActionTypes.DATA_DELIVERY_CREATE, createDataDeliverySaga)
        yield debounce(0, DataDeliveryActionTypes.DATA_DELIVERY_UPDATE, updateDataDeliverySaga)
        yield debounce(0, DataDeliveryActionTypes.DATA_DELIVERY_EXECUTE, executeDataDeliverySaga)
        yield takeEvery(DataDeliveryActionTypes.DATA_DELIVERY_ADD_TASK_LISTENER, addDataDeliveryTaskListenerSaga)
        yield takeEvery(DataDeliveryActionTypes.DATA_DELIVERY_FETCH_AND_UPDATE_IN_LIST, fetchAndUpdateDataDeliveryInListSaga)
        yield debounce(0, DataDeliveryActionTypes.DELIVERED_DATABASES_GET, getDeliveredDatabasesSaga)
        yield debounce(0, DataDeliveryActionTypes.ORGANIZATION_METADATA_GET, getOrganizationMetadataSaga)
        yield debounce(0, DataDeliveryActionTypes.ORGANIZATION_METADATA_CREATE, createOrganizationMetadataSaga)
        yield debounce(0, DataDeliveryActionTypes.ORGANIZATION_METADATA_UPDATE, updateOrganizationMetadataSaga)
        yield debounce(0, DataDeliveryActionTypes.DATA_DELIVERY_REGISTRY_TYPES_GET, getDataDeliveryRegistryTypesSaga)
    }
}
