import { Trans } from '@lingui/macro'
import { Box, Button, Grid } from '@mui/material'
import { FrameworkComponentProps } from '@om1/platform-utils'
import React, { ReactNode, useMemo } from 'react'
import {
    CohortDraggingState,
    CohortNodeTargetAddress,
    CriteriaOperation,
    CriteriaRelationType,
    CriteriaType,
    CriterionNode,
    cohortBlocksEditActions
} from '../../../state'
import {
    getDateRangeInclusion,
    getDateRangeInclusionTranslation,
    getDateRangeIntervalUnitTranslation,
    getDateRangeOperatorTranslation,
    getLargestInterval
} from '../utils/date-utils'
import { createCohortCriterionComponent } from './CohortCriterion'

const CohortCriterion = createCohortCriterionComponent()

export type CohortCriteriaProps = FrameworkComponentProps<
    { node: CriterionNode; parentOperation: CriteriaOperation; dragState: CohortDraggingState },
    typeof cohortBlocksEditActions,
    { readOnly?: boolean }
>

/**
 * Renders either a single criteron component or a subject and reference pair of criterion components, depending on the
 * properties of the criterion node.
 */
export const CohortCriteria: React.FunctionComponent<CohortCriteriaProps> = ({ state, actions, props: { readOnly } }) => {
    const { node, parentOperation, dragState } = state
    const forkAddress: CohortNodeTargetAddress = useMemo(() => ({ nodeId: node.id }), [node.id])
    const relateAddress: CohortNodeTargetAddress | undefined = useMemo(() => {
        if (dragState.allowRelate) {
            return {
                nodeId: node.id,
                relate: true,
                relateType: node.type === CriteriaType.ObservationPeriod ? CriteriaRelationType.FollowUp : CriteriaRelationType.Date
            }
        }
    }, [dragState, node.id, node.type])

    if (node.reference) {
        return (
            <Grid container spacing={2} alignItems='center'>
                <Grid item xs zeroMinWidth>
                    <CohortCriterion
                        node={node}
                        relatedNode={node.reference.criteria}
                        dragState={dragState}
                        parentOperation={parentOperation}
                        readOnly={readOnly}
                    />
                </Grid>

                <Grid item xs='auto'>
                    <CohortCriterionDateRelationButton state={state} actions={actions} props={{ readOnly }} />
                </Grid>

                <Grid item xs zeroMinWidth>
                    <CohortCriterion
                        node={node.reference.criteria}
                        relatedNode={node}
                        dragState={dragState}
                        parentOperation={parentOperation}
                        forkAddress={forkAddress}
                        readOnly={readOnly}
                    />
                </Grid>
            </Grid>
        )
    } else {
        return (
            <CohortCriterion
                node={node}
                dragState={dragState}
                parentOperation={parentOperation}
                forkAddress={forkAddress}
                relateAddress={relateAddress}
                readOnly={readOnly}
            />
        )
    }
}

type CohortCriterionDateRelationButtonProps = FrameworkComponentProps<{ node: CriterionNode }, typeof cohortBlocksEditActions, { readOnly?: boolean }>

/**
 * A clickable element that also provides a summary of the date relation between a subject and reference criteria.
 */
export const CohortCriterionDateRelationButton: React.FunctionComponent<CohortCriterionDateRelationButtonProps> = ({
    state: { node },
    actions,
    props: { readOnly }
}) => {
    const buttonText: ReactNode = useMemo(() => {
        if (node.reference?.dateRelation) {
            const rangeInclusion = getDateRangeInclusion(node.reference.dateRelation)
            const interval = getLargestInterval(node.reference.dateRelation)
            const rangeInclusionText = getDateRangeInclusionTranslation(rangeInclusion)
            const intervalUnitText = getDateRangeIntervalUnitTranslation(node.reference.dateRelation.intervalUnitFromReferenceDate)
            const rangeOperatorText = getDateRangeOperatorTranslation(node.reference.dateRelation.dateRangeOperator)
            // TODO: add this back once i18n is working with the cohort module
            // const message = t({
            //     message: '{rangeInclusionText} {interval} {intervalUnitText} {rangeOperatorText}',
            //     values: { rangeInclusionText, interval, intervalUnitText, rangeOperatorText }
            // })

            const message = `${rangeInclusionText} ${interval} ${intervalUnitText} ${rangeOperatorText}`

            return message
        } else if (node.reference?.followUpRelation) {
            return (
                <Box display={'flex'} flexDirection={'column'}>
                    {node.reference.followUpRelation.baseline && (
                        <Box>
                            <Trans>Baseline of at least</Trans> {node.reference.followUpRelation.baseline.intervalStartFromReferenceDate}{' '}
                            {node.reference.followUpRelation.baseline.intervalUnitFromReferenceDate}
                            {node.reference.followUpRelation.baseline.intervalStartFromReferenceDate &&
                            node.reference.followUpRelation.baseline.intervalStartFromReferenceDate === 1 ? (
                                <></>
                            ) : (
                                <Trans>(s)</Trans>
                            )}{' '}
                            <Trans>before</Trans>
                        </Box>
                    )}
                    {node.reference.followUpRelation.baseline && node.reference.followUpRelation.followUp && (
                        <Box>
                            <Trans>AND</Trans>
                        </Box>
                    )}
                    {node.reference.followUpRelation.followUp && (
                        <Box>
                            <Trans>Follow-up of at least</Trans> {node.reference.followUpRelation.followUp.intervalStartFromReferenceDate}{' '}
                            {node.reference.followUpRelation.followUp.intervalUnitFromReferenceDate}
                            {node.reference.followUpRelation.followUp.intervalStartFromReferenceDate &&
                            node.reference.followUpRelation.followUp.intervalStartFromReferenceDate === 1 ? (
                                <></>
                            ) : (
                                <Trans>(s)</Trans>
                            )}{' '}
                            <Trans>after</Trans>
                        </Box>
                    )}
                </Box>
            )
        } else {
            return ''
        }
    }, [node.reference])
    if (!readOnly) {
        return (
            <Button
                variant='contained'
                onClick={() => {
                    actions.criteriaRelationDialogTrigger({
                        type: node.reference?.followUpRelation ? CriteriaRelationType.FollowUp : CriteriaRelationType.Date,
                        target: { nodeId: node.id }
                    })
                }}
            >
                {buttonText}
            </Button>
        )
    } else {
        return (
            <Box display='flex' p={2} boxShadow={1} bgcolor='white'>
                {buttonText}
            </Box>
        )
    }
}
