import { CohortDTO, ExplorerCohortsService } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put, select } from 'redux-saga/effects'
import { CohortListItem, CohortState, cohortCommonActions, cohortEditActions } from '../state'

export function createGetCohortSaga() {
    return function* (action: ReturnType<typeof cohortEditActions.cohortGet>) {
        yield put(cohortEditActions.cohortEditLoadingSet({ loading: true }))
        try {
            const cohortDTO: CohortDTO = yield call(ExplorerCohortsService.getExplorerCohortsCohortIdGet, {
                cohortId: action.payload.params.path.cohortId!
            })
            const cohortListItem: CohortListItem = cohortDTO as CohortListItem
            yield put(cohortEditActions.cohortSet({ cohort: cohortListItem }))
            const storeCohort: CohortListItem = yield select((store: { cohort: CohortState }) => store.cohort.edit.base.cohort)
            if (storeCohort.cohortSize === undefined) {
                yield put(cohortCommonActions.cohortSizeGet({ cohortId: cohortListItem.id, cohortName: cohortListItem.name }))
            }
            yield put(cohortEditActions.cohortParseTree({ cohort: cohortListItem }))
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(cohortEditActions.cohortEditLoadingSet({ loading: false }))
        }
    }
}

export type GetCohortSaga = ReturnType<typeof createGetCohortSaga>
