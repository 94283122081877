import { InsightsReportDTO } from '@om1/falcon-api'

export interface RWAInsightsReportsState {
    loading: boolean
    insightReports: InsightsReportDTO[]
}

export const initialRWAInsightsReportsState: RWAInsightsReportsState = {
    loading: true,
    insightReports: []
}
