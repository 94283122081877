import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { Auth0State } from '@om1/platform-authentication'
import { ContentContainerStyle } from '@om1/platform-ui-kit/src/components/Layout'
import { FrameworkComponentProps, connectRedux } from '@om1/platform-utils'
import React from 'react'
import { PlatformState } from '../../platform-state'
import { languageActions } from '../../shared/providers/internationalization/language-actions'
import { LanguageState } from '../../shared/providers/internationalization/language-state'

export type LanguageComponentProps = FrameworkComponentProps<LanguageState, typeof languageActions, {}>

export const LanguageComponent: React.FunctionComponent<LanguageComponentProps> = ({ state }) => {
    return (
        <ContentContainerStyle>
            <div data-testid={'locale'}>
                <b>
                    <Trans>Locale</Trans>
                </b>
                :{state.locale}
            </div>
        </ContentContainerStyle>
    )
}

export function createLanguageComponent<TState extends { language: LanguageState; auth0: Auth0State }>() {
    return connectRedux(LanguageComponent, (state: TState) => state.language, languageActions)
}

const Language = createLanguageComponent<PlatformState>()

export type HomePageProfileComponentProps = FrameworkComponentProps<Auth0State, {}, {}>

export const HomePageProfileComponent: React.FunctionComponent<HomePageProfileComponentProps> = ({ state }) => {
    return (
        <>
            <b>
                <Trans>Profile Information:</Trans>
            </b>
            {state.user !== undefined &&
                Object.keys(state.user).map((value: string, index: number, array: string[]) => {
                    return (
                        <Box data-testid={`field${index}`} key={index}>
                            <b>{value}</b>:{state?.user?.[value]}
                        </Box>
                    )
                })}
            <Box data-testid={`field-permissions`} key={'permissions'}>
                <b>
                    <Trans>permissions</Trans>
                </b>
                :{state.permissions?.join(',')}
            </Box>
            <Language />
        </>
    )
}
