import { ExplorerCohortsService, SqlDTO } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put } from 'redux-saga/effects'
import { cohortEditActions } from '../state'

export function createGetCohortSqlSaga() {
    return function* (action: ReturnType<typeof cohortEditActions.cohortSqlGet>) {
        const cohortId: string = action.payload.cohortId!
        yield put(cohortEditActions.cohortSqlLoadingSet({ cohortId, loading: true }))
        try {
            const sqlDTO: SqlDTO = yield call(ExplorerCohortsService.distinctPatientsSqlExplorerCohortsCohortIdSqlGet, {
                cohortId: cohortId
            })
            yield put(cohortEditActions.cohortSqlSet({ cohortId, sql: sqlDTO.sql }))
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(cohortEditActions.cohortSqlLoadingSet({ cohortId, loading: false }))
        }
    }
}

export type GetCohortSaga = ReturnType<typeof createGetCohortSqlSaga>
