export interface CohortLoading {
    [cohortId: string]: boolean
}
export interface CommonState {
    ui: {
        cohortSizeLoading: CohortLoading
        cohortSummaryReportLoading: CohortLoading
    }
}

export const initialCommonState: CommonState = {
    ui: { cohortSizeLoading: {}, cohortSummaryReportLoading: {} }
}
