import { AnalyticsRefActions, AnalyticsRefActionTypes } from './analytics-refs-actions'
import { AnalyticsRefsState, initialAnalyticsRefsState } from './analytics-refs-state'

export function analyticsRefsReducer(previousState: AnalyticsRefsState | undefined, action: AnalyticsRefActions): AnalyticsRefsState {
    const state = previousState || initialAnalyticsRefsState

    switch (action.type) {
        case AnalyticsRefActionTypes.REFS_SET: {
            const { data, destination, dimension, paginationMeta } = action.payload
            let refRoot = { ...state[dimension], [destination]: { data, paginationMeta } }
            return { ...state, [dimension]: refRoot }
        }
        default: {
            return state
        }
    }
}
