import { Box, SxProps, useTheme } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { ComponentPropsWithoutRef, PropsWithChildren, ReactNode, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

export interface BasicCardProps {
    actions?: ReactNode[]
    isSelected: boolean
}

export const BasicCard = ({ children, isSelected }: PropsWithChildren<BasicCardProps>) => {
    const theme = useTheme()
    const cardStyle: SxProps = {
        borderRadius: 0,
        border: 0,
        backgroundColor: isSelected ? theme.palette.primary.main : '#FFFFFF',
        color: isSelected ? '#FFFFFF' : 'inherit',
        position: 'relative',
        minHeight: 'fit-content'
    }

    return (
        <Card sx={cardStyle} variant='outlined'>
            <CardContent>{children}</CardContent>
        </Card>
    )
}

interface BasicCardHeaderProps {
    label: ReactNode
}

export const BasicCardHeader = ({ label }: BasicCardHeaderProps) => {
    return (
        <Typography variant='h5' component='div'>
            {label}
        </Typography>
    )
}

interface BasicCardStatProps {
    id: string
    label: ReactNode
    labelProps?: ComponentPropsWithoutRef<typeof Box>
    value: ReactNode
    valueProps?: ComponentPropsWithoutRef<typeof Box>
    footer?: ReactNode
    footerProps?: ComponentPropsWithoutRef<typeof Box>
}

export const BasicCardStat = ({ id, label, labelProps, value, valueProps, footer, footerProps }: BasicCardStatProps) => {
    const labelId = useMemo(() => `${id}-${uuidv4()}`, [id])
    return (
        <Box display='flex' flexDirection='column' gap={1} minWidth={'fit-content'}>
            <Box id={labelId} fontSize={10} textTransform='uppercase' {...labelProps}>
                {label}
            </Box>
            <Box fontSize={14} overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap' aria-labelledby={labelId} {...valueProps}>
                {value}
            </Box>
            {footer && (
                <Box fontSize={12} {...footerProps}>
                    {footer}
                </Box>
            )}
        </Box>
    )
}
