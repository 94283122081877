import { connectRedux } from '@om1/platform-utils'
import { CohortState, CriterionNode, cohortBlocksEditActions } from '../../../state'
import { findNode } from '../../../state/edit/blocks/cohort-blocks-edit-reducer'
import { PatientAgeEditDialogComponent } from './PatientAgeEditDialogComponent'

export function createPatientAgeEditDialogComponent<TState extends { cohort: CohortState }>() {
    return connectRedux(
        PatientAgeEditDialogComponent,
        (state: TState) => {
            const nodeId = state.cohort.edit.blocks.ui.patientAgeEditDialog.target.nodeId

            return {
                criteria: findNode(state.cohort.edit.blocks.tree, nodeId) as CriterionNode
            }
        },
        cohortBlocksEditActions
    )
}

export type PatientAgeEditDialog = ReturnType<typeof createPatientAgeEditDialogComponent>
