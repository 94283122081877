import { call, put } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'
import {
    CountDistinctQualifier,
    CriteriaInsertPayload,
    CriteriaOperation,
    CriteriaType,
    DateAwareFilterDTO,
    DateQualifier,
    DateRelationMetadata,
    DateWindowFilterDTO,
    FilterDTO,
    FilterQualifier,
    FollowUpRelationMetadata,
    QualifierType,
    QueryFilter,
    QueryFilterBase,
    QueryFilterCondition,
    QueryFilterNode,
    QueryFilterOperator,
    RelativeDateFilterDTO,
    RelativeFollowUpFilterDTO,
    cohortBlocksEditActions,
    isApiAndOperation,
    isApiDateAwareFilterDTO,
    isApiDateWindowFilterDTO,
    isApiExceptOperation,
    isApiFilter,
    isApiFilterDTO,
    isApiOperation,
    isApiOrOperation,
    isApiRelativeDateFilterDTO,
    isApiRelativeFollowUpFilterDTO,
    isCountDistinctQualifier
} from '../../../state'
import { DEFAULT_DATE_FIELD } from './constants'
import { getFieldMapperFromType } from './filter-utils'
import { findQualifierByType } from './finders'
import { FIELD_MAPPERS, FIELD_MAPPERS_GENERIC, PhenotypeRefFieldMapper, SexRefFieldMapper } from './ref-field-mappers'

/*
 * Import query filters from the API in to the UI
 */

/**
 * Convert API filters to blocks usable in the UI. Also enforces certain guarantees when importing API query filters,
 * such as ensuring an outermost EXCEPT block containing the inclusion / exclusion areas.
 *
 * @param root the root nodes provided at the highest level of the query filter
 * @param actions redux actions used to build the tree
 */
export function* filtersToBlocksRoot(root: QueryFilterNode[], actions: typeof cohortBlocksEditActions) {
    // The tree should be in import mode
    yield put(actions.treeEditModeUpdate({ treeEditMode: 'import' }))
    // Always add a top level EXCEPT block
    const exceptRootId = uuidv4()
    yield put(actions.operationInsert({ uuid: exceptRootId, operation: CriteriaOperation.EXCEPT, target: { nodeId: undefined } }, true))

    // Always add a top level AND block for use as the inclusion root
    const includeRootId = uuidv4()
    yield put(actions.operationInsert({ uuid: includeRootId, operation: CriteriaOperation.AND, target: { nodeId: exceptRootId } }, true))

    // Always add a top level AND block for use as the exclusion root
    const excludeRootId = uuidv4()
    yield put(actions.operationInsert({ uuid: excludeRootId, operation: CriteriaOperation.AND, target: { nodeId: exceptRootId } }, true))

    let criteriaBlocksAdded = 0

    // Top level must have only one entry and it must be an except operation
    if (root.length === 1 && isApiExceptOperation(root[0])) {
        const exceptRoot = root[0]

        // The first entry in the except array must be an AND operator, and is the "include" root
        if (exceptRoot.except.length > 0 && isApiAndOperation(exceptRoot.except[0])) {
            const includeRoot = exceptRoot.except[0]

            for (let i = 0; i < includeRoot.and.length; i++) {
                const or = includeRoot.and[i]
                if (isApiOrOperation(or)) {
                    const uuid = uuidv4()
                    yield put(
                        actions.operationInsert(
                            {
                                uuid,
                                operation: CriteriaOperation.OR,
                                target: { nodeId: includeRootId }
                            },
                            true
                        )
                    )
                    if (or.name) {
                        yield put(actions.operationUpdate({ target: { nodeId: uuid }, operation: { name: or.name } }, true))
                    }
                    if (or.disabled) {
                        yield put(actions.excludedUpdate({ target: { nodeId: uuid }, operation: { excluded: or.disabled } }, true))
                    }
                    yield call(filtersToBlocks, or, uuid, actions)
                    criteriaBlocksAdded++
                }
            }
        }

        // The second entry in the except array must be an AND operator, and is the "exclude" root
        if (exceptRoot.except.length > 1 && isApiAndOperation(exceptRoot.except[1])) {
            const excludeRoot = exceptRoot.except[1]

            for (let i = 0; i < excludeRoot.and.length; i++) {
                const or = excludeRoot.and[i]
                if (isApiOrOperation(or)) {
                    const uuid = uuidv4()
                    yield put(
                        actions.operationInsert(
                            {
                                uuid,
                                operation: CriteriaOperation.OR,
                                target: { nodeId: excludeRootId }
                            },
                            true
                        )
                    )
                    if (or.name) {
                        yield put(actions.operationUpdate({ target: { nodeId: uuid }, operation: { name: or.name } }, true))
                    }
                    if (or.disabled) {
                        yield put(actions.excludedUpdate({ target: { nodeId: uuid }, operation: { excluded: or.disabled } }, true))
                    }
                    yield call(filtersToBlocks, or, uuid, actions)
                    criteriaBlocksAdded++
                }
            }
        }
    }

    // If we fell through and didn't parse any criteria blocks, make sure to at least show one criteria block in the UI
    if (criteriaBlocksAdded === 0) {
        const uuid = uuidv4()
        yield put(actions.operationInsert({ uuid, operation: CriteriaOperation.OR, target: { nodeId: includeRootId } }, true))
    }

    yield put(actions.treeEditModeUpdate({ treeEditMode: 'edit' }))
    yield put(actions.cleanTree())
}

/**
 * Converts API formatted query filters to a format that is consumable by the front-end. Initially called for each
 * outer criteria block in the UI (direct children of the exclusion / exclusion area).
 *
 * @param operator the parent operator containing the filters
 * @param parentId the id of the parent operator
 * @param actions redux actions used to build the tree
 */
function* filtersToBlocks(operator: QueryFilterOperator, parentId: string, actions: typeof cohortBlocksEditActions) {
    let children: QueryFilterNode[]

    if (isApiOrOperation(operator)) {
        children = operator.or
    } else if (isApiAndOperation(operator)) {
        children = operator.and
    } else {
        children = []
    }

    while (children.length > 0) {
        const filter = children[0]

        let blockId: number | undefined = undefined

        blockId = findPatientAttributesBlockId(filter)
        if (blockId === undefined) {
            blockId = findGenericBlockId(filter, new Set(FIELD_MAPPERS_GENERIC.map((m) => m.table)))
        }

        if (blockId !== undefined) {
            const [newChildren, blocks] = gatherBlocksById(children, blockId)
            children = newChildren
            yield call(insertGenericBlock, blocks, parentId, actions)
        } else if (isApiOperation(filter)) {
            const uuid = uuidv4()
            let operation: CriteriaOperation
            if (isApiOrOperation(filter)) {
                operation = CriteriaOperation.OR
            } else if (isApiAndOperation(filter)) {
                operation = CriteriaOperation.AND
            } else {
                operation = CriteriaOperation.AND
            }
            yield put(
                actions.operationInsert(
                    {
                        uuid,
                        operation,
                        target: { nodeId: parentId }
                    },
                    true
                )
            )
            yield call(filtersToBlocks, filter, uuid, actions)
            children = children.filter((child) => child !== filter)
        } else {
            children = children.filter((child) => child !== filter)
        }
    }
}

/*
 * Finder functions used to locate a possible UI block within API query filters
 */

/**
 * Determines if a patient attributes block exists in a node, and returns a block id if found.
 *
 * @param node an arbitrary query filter node
 * @returns a number if a block was found, undefined if not
 */
function findPatientAttributesBlockId(node: QueryFilterNode): number | undefined {
    // The root of all patient attributes block will always be an AND operation
    if (isApiAndOperation(node)) {
        return findGenericBlockId(node.and[0], new Set([SexRefFieldMapper.table]))
    }
}

/**
 * Determines if a phenotype block exists in a node.
 *
 * @param node an arbitrary query filter node
 * @returns true if a block was found, false if not
 */
export function hasPhenotypeBlock(node: QueryFilterNode): boolean | undefined {
    if (isApiFilterDTO(node) && PhenotypeRefFieldMapper.filterFieldOrder.includes(node.field)) {
        return true
    }

    if (isApiExceptOperation(node) && node.except.length > 0) {
        return node.except.map((filter) => hasPhenotypeBlock(filter)).some((fid) => fid)
    } else if (isApiAndOperation(node) && node.and.length > 0) {
        return node.and.map((filter) => hasPhenotypeBlock(filter)).some((fid) => fid)
    } else if (isApiOrOperation(node) && node.or.length > 0) {
        return node.or.map((filter) => hasPhenotypeBlock(filter)).some((fid) => fid)
    }

    return false
}

/**
 * Determines if a block exists in a node based on a set of table names, and returns a block id if found.

 * @param node the root node to search
 * @param tableSet a set of table names for which we are attempting to find a block
 * @returns a number if a block was found, undefined if not
 */
function findGenericBlockId(node: QueryFilterNode, tableSet: Set<string>): number | undefined {
    if ((isApiFilterDTO(node) || isApiDateAwareFilterDTO(node)) && tableSet.has(node.table)) {
        return node.blockId
    } else if (isApiRelativeDateFilterDTO(node) && tableSet.has(node.subjectFilter?.table)) {
        return node.blockId
    } else if (isApiDateWindowFilterDTO(node) && tableSet.has(node.table)) {
        return node.blockId
    } else if (isApiRelativeFollowUpFilterDTO(node)) {
        return node.blockId
    } else if (isApiOrOperation(node) && node.or.length > 0) {
        // An OR node may itself represent the concept of a UI block. This is only true if every single item under an
        // OR is a filter (not an operator), and all items have the same block ID and table.
        const allNodesFilters = node.or.every((x) => isApiFilter(x))
        if (allNodesFilters) {
            const blockIds = new Set<number>()
            const tables = new Set<string>()
            node.or.forEach((node) => {
                if (isApiFilter(node)) {
                    blockIds.add(node.blockId)
                }
                if (isApiFilterDTO(node) || isApiDateAwareFilterDTO(node)) {
                    tables.add(node.table)
                } else if (isApiRelativeDateFilterDTO(node)) {
                    tables.add(node.subjectFilter?.table)
                }
            })
            const intersection = new Set([...tables].filter((x) => tableSet.has(x)))
            if (blockIds.size === 1 && tables.size === 1 && intersection.size === 1) {
                return (node.or[0] as QueryFilterCondition).blockId
            }
        }
    }
}

/**
 * Takes a group of nodes that are pre-determined to be related by block id and adds them as a single block in the UI.
 * Determines which type of DTO we are parsing, which criteria type we are inspecting, etc.
 *
 * @param nodes query filter nodes from the API to convert into a UI block
 * @param initialNodeId the node id where the new block will be inserted
 * @param actions redux actions used to build the tree
 */
function* insertGenericBlock(
    nodes: (QueryFilterCondition | RelativeDateFilterDTO | RelativeFollowUpFilterDTO)[],
    initialNodeId: string,
    actions: typeof cohortBlocksEditActions
) {
    if (nodes.length === 0) {
        return
    }
    if (isApiFilterDTO(nodes[0])) {
        let filterConditions: FilterDTO[] = nodes as unknown as FilterDTO[]

        const qualifiers: FilterQualifier[] = flattenQualifierArrays(filterConditions[0].qualifiers)

        const filters: QueryFilterBase[] = filterConditions.map<QueryFilterBase>((node) => ({
            field: node.field,
            operator: node.operator,
            table: node.table,
            values: node.values
        }))

        const criteriaToInsert = {
            uuid: uuidv4(),
            criteriaType: deduceTypeFromTable(filters[0]),
            target: { nodeId: initialNodeId },
            filters,
            qualifiers
        }

        yield put(actions.criteriaInsert(criteriaToInsert, true))
    } else if (isApiDateAwareFilterDTO(nodes[0])) {
        let filterConditions: DateAwareFilterDTO[] = nodes as unknown as DateAwareFilterDTO[]

        const qualifiers: FilterQualifier[] = flattenQualifierArrays(filterConditions[0].qualifiers)

        const filters: QueryFilterBase[] = filterConditions.map<QueryFilterBase>((node) => ({
            field: node.field,
            operator: node.operator,
            table: node.table,
            values: node.values
        }))

        const criteriaType = deduceTypeFromTable(filters[0])
        const fieldMapper = getFieldMapperFromType(criteriaType, filters[0].field)

        const criteriaToInsert = {
            uuid: uuidv4(),
            criteriaType,
            target: { nodeId: initialNodeId },
            filters,
            dateField: fieldMapper.dateFieldInverse[filterConditions[0].dateField] || DEFAULT_DATE_FIELD,
            qualifiers
        }

        yield put(actions.criteriaInsert(criteriaToInsert, true))
    } else if (isApiDateWindowFilterDTO(nodes[0])) {
        let filterConditions: DateWindowFilterDTO[] = nodes as unknown as DateWindowFilterDTO[]

        const qualifiers: FilterQualifier[] = flattenQualifierArrays(filterConditions[0].qualifiers)

        const filters: QueryFilterBase[] = filterConditions.map<QueryFilterBase>((node) => ({
            field: node.field,
            operator: node.operator,
            table: node.table,
            values: node.values
        }))

        const criteriaType = deduceTypeFromTable(filters[0])
        const fieldMapper = getFieldMapperFromType(criteriaType, filters[0].field)

        const criteriaToInsert = {
            uuid: uuidv4(),
            criteriaType,
            target: { nodeId: initialNodeId },
            filters,
            startDateField: fieldMapper.dateFieldInverse[filterConditions[0].startDateField] || DEFAULT_DATE_FIELD,
            endDateField: fieldMapper.dateFieldInverse[filterConditions[0].endDateField] || DEFAULT_DATE_FIELD,
            qualifiers
        }

        yield put(actions.criteriaInsert(criteriaToInsert, true))
    } else if (isApiRelativeDateFilterDTO(nodes[0])) {
        let dateFilters: RelativeDateFilterDTO[] = nodes as unknown as RelativeDateFilterDTO[]

        const dateRelation: DateRelationMetadata = {
            dateRangeOperator: dateFilters[0].dateRangeOperator,
            intervalStartFromReferenceDate: dateFilters[0].intervalStartFromReferenceDate,
            intervalEndFromReferenceDate: dateFilters[0].intervalEndFromReferenceDate,
            intervalUnitFromReferenceDate: dateFilters[0].intervalUnitFromReferenceDate,
            intervalIsInclusive: dateFilters[0].intervalIsInclusive
        }

        const subjectFilters = getFiltersByUniqueField(dateFilters.map((f) => f.subjectFilter))
        const subjectCriteriaType = deduceTypeFromTable(subjectFilters[0])
        const subjectDateField = subjectFilters[0].dateField
        const subjectFieldMapper = getFieldMapperFromType(subjectCriteriaType, subjectFilters[0].field)
        const referenceFilters = getFiltersByUniqueField(dateFilters.map((f) => f.referenceFilter))
        const referenceCriteriaType = deduceTypeFromTable(referenceFilters[0])
        const referenceDateField = referenceFilters[0].dateField
        const referenceFieldMapper = getFieldMapperFromType(referenceCriteriaType, referenceFilters[0].field)

        const subjectQualifiers = flattenQualifierArrays(dateFilters[0].subjectFilter?.qualifiers)
        const referenceQualifiers = flattenQualifierArrays(dateFilters[0].referenceFilter?.qualifiers)

        const subjectFilterId = uuidv4()
        const referenceFilterId = uuidv4()

        const criteriaToInsert = {
            uuid: subjectFilterId,
            criteriaType: deduceTypeFromTable(subjectFilters[0]),
            target: { nodeId: initialNodeId },
            filters: subjectFilters.map((node) => ({
                field: node.field,
                operator: node.operator,
                table: node.table,
                values: node.values
            })),
            qualifiers: subjectQualifiers,
            dateField: subjectFieldMapper.dateFieldInverse[subjectDateField] || DEFAULT_DATE_FIELD
        }

        yield put(actions.criteriaInsert(criteriaToInsert, true))

        const criteriaToInsert2 = {
            uuid: referenceFilterId,
            criteriaType: deduceTypeFromTable(referenceFilters[0]),
            target: { nodeId: subjectFilterId, relate: true },
            filters: referenceFilters.map((node) => ({
                field: node.field,
                operator: node.operator,
                table: node.table,
                values: node.values
            })),
            qualifiers: referenceQualifiers,
            dateField: referenceFieldMapper.dateFieldInverse[referenceDateField] || DEFAULT_DATE_FIELD,
            dateRelation
        }

        yield put(actions.criteriaInsert(criteriaToInsert2, true))
    } else if (isApiRelativeFollowUpFilterDTO(nodes[0])) {
        let filters: RelativeFollowUpFilterDTO[] = nodes as unknown as RelativeFollowUpFilterDTO[]

        const relativeFollowUpFiltersWithBaseline: RelativeFollowUpFilterDTO[] = filters.filter(
            (f) => f.baseline !== undefined || f.baseline !== null
        )
        const baselineRelativeDateFilters: RelativeDateFilterDTO[] = relativeFollowUpFiltersWithBaseline
            .map((f) => f.baseline)
            .filter((f) => f !== undefined && f !== null) as RelativeDateFilterDTO[]
        const baselineFilters: RelativeDateFilterDTO[] = getRelativeDateFiltersByUniqueFields(baselineRelativeDateFilters)
        const relativeFollowUpFiltersWithFollowUp: RelativeFollowUpFilterDTO[] = filters.filter(
            (f) => f.followUp !== undefined && f.followUp !== null
        )
        const followUpRelativeDateFilters: RelativeDateFilterDTO[] = relativeFollowUpFiltersWithFollowUp
            .map((f) => f.followUp)
            .filter((f) => f !== undefined && f !== null) as RelativeDateFilterDTO[]
        const followUpFilters: RelativeDateFilterDTO[] = getRelativeDateFiltersByUniqueFields(followUpRelativeDateFilters)

        const allSubjectFilters: DateAwareFilterDTO[] = [
            ...baselineFilters.map((filter) => filter.subjectFilter),
            ...followUpFilters.map((filter) => filter.subjectFilter)
        ]
        const allReferenceFilters: DateAwareFilterDTO[] = [
            ...baselineFilters.map((filter) => filter.referenceFilter),
            ...followUpFilters.map((filter) => filter.referenceFilter)
        ]

        let relativeFilter
        if (baselineFilters.length > 0) {
            relativeFilter = baselineFilters[0]
        } else {
            relativeFilter = followUpFilters[0]
        }

        const baseline = baselineFilters[0]
            ? {
                  intervalStartFromReferenceDate: baselineFilters[0].intervalStartFromReferenceDate,
                  intervalEndFromReferenceDate: baselineFilters[0].intervalEndFromReferenceDate,
                  intervalIsInclusive: baselineFilters[0].intervalIsInclusive,
                  intervalUnitFromReferenceDate: baselineFilters[0].intervalUnitFromReferenceDate,
                  dateRangeOperator: baselineFilters[0].dateRangeOperator
              }
            : undefined

        const followUp = followUpFilters[0]
            ? {
                  intervalStartFromReferenceDate: followUpFilters[0].intervalStartFromReferenceDate,
                  intervalEndFromReferenceDate: followUpFilters[0].intervalEndFromReferenceDate,
                  intervalIsInclusive: followUpFilters[0].intervalIsInclusive,
                  intervalUnitFromReferenceDate: followUpFilters[0].intervalUnitFromReferenceDate,
                  dateRangeOperator: followUpFilters[0].dateRangeOperator
              }
            : undefined

        const followUpRelation: FollowUpRelationMetadata = { baseline, followUp }

        const subjectCriteriaType = deduceTypeFromTable(relativeFilter.subjectFilter)
        const subjectDateField = relativeFilter.subjectFilter?.dateField
        const subjectFieldMapper = getFieldMapperFromType(subjectCriteriaType, relativeFilter.subjectFilter?.field)

        const referenceCriteriaType = deduceTypeFromTable(relativeFilter.referenceFilter)
        const referenceDateField = relativeFilter.referenceFilter.dateField
        const referenceFieldMapper = getFieldMapperFromType(referenceCriteriaType, relativeFilter.referenceFilter.field)

        const subjectQualifiers = flattenQualifierArrays(relativeFilter.subjectFilter?.qualifiers)
        const referenceQualifiers = flattenQualifierArrays(relativeFilter.referenceFilter.qualifiers)

        const subjectFilterId = uuidv4()
        const referenceFilterId = uuidv4()

        const criteriaToInsert = {
            uuid: subjectFilterId,
            criteriaType: deduceTypeFromTable(relativeFilter.subjectFilter),
            target: { nodeId: initialNodeId },
            filters: allSubjectFilters.map((node) => ({
                field: node.field,
                operator: node.operator,
                table: node.table,
                values: node.values
            })),
            qualifiers: subjectQualifiers,
            dateField: subjectFieldMapper.dateFieldInverse[subjectDateField] || DEFAULT_DATE_FIELD
        }

        yield put(actions.criteriaInsert(criteriaToInsert, true))

        const payload: CriteriaInsertPayload = {
            uuid: referenceFilterId,
            criteriaType: deduceTypeFromTable(relativeFilter.referenceFilter),
            target: { nodeId: subjectFilterId, relate: true },
            filters: allReferenceFilters.map((node) => ({
                field: node.field,
                operator: node.operator,
                table: node.table,
                values: node.values
            })),
            qualifiers: referenceQualifiers,
            dateField: referenceFieldMapper.dateFieldInverse[referenceDateField] || DEFAULT_DATE_FIELD,
            followUpRelation: followUpRelation
        }

        yield put(actions.criteriaInsert(payload, true))
    }
}

/**
 * Inspects a filter and deduces its criteria type from the table it is filtering on.
 *
 * @param filter an arbitrary filter from the API
 * @returns the deduced criteria type
 */
function deduceTypeFromTable(filter: QueryFilterBase) {
    let type: CriteriaType = CriteriaType.PatientAttributes // Default to PatientAttributes if the type can't be deduced

    FIELD_MAPPERS.forEach((fm) => {
        if (filter.table === fm.table) {
            if (fm.table === 'patient') {
                if (filter.field === 'has_ehr_data' || filter.field === 'has_ehr_linked_claims_data' || filter.field === 'has_labs') {
                    type = fm.criteriaType
                }
                if (filter.field === 'has_labs') {
                    type = CriteriaType.LabTest
                }
            } else {
                type = fm.criteriaType
            }
        }
    })

    return type
}

/**
 * Deduplicates redundant filter data from RelativeDateFilterDTOs that may result from complex filters where the
 * subject and / or the reference have more than one field to filter on. Collapses the MxN occurrence of the DTOs into
 * the minimal set of filters that subject or reference need to represent themselves in the UI.
 *
 * @param filters
 * @returns
 */
function getFiltersByUniqueField(filters: DateAwareFilterDTO[]) {
    return filters.filter((value, index, array) => {
        return array.findIndex((v) => v.field === value.field) === index
    })
}

function getRelativeDateFiltersByUniqueFields(filters: RelativeDateFilterDTO[]) {
    return filters.filter((value, index, array) => {
        return (
            array.findIndex(
                (v) => v.subjectFilter?.field === value.subjectFilter?.field && v.referenceFilter.field === value.referenceFilter.field
            ) === index
        )
    })
}

/**
 * Removes all filters associated with blockId and returns a new filter with the found filters removed, along with the
 * found filters in a flat list.
 *
 * @param nodes The nodes to search through
 * @param blockId The block id to search for
 * @returns A tuple, the first element being the nodes with blockId's filters removed, the second being the removed filters
 */
function gatherBlocksById(nodes: QueryFilterNode[], blockId: number): [QueryFilterNode[], QueryFilter[]] {
    const matches: QueryFilter[] = []
    nodes = nodes.filter((node) => {
        if (isApiOrOperation(node) && node.or.length === 0) {
            // If we're at an OR operation and it has no children, discard it
            return false
        } else if (isApiAndOperation(node) && node.and.length === 0) {
            // If we're at an AND operation and it has no children, discard it
            return false
        } else if (!isApiOperation(node) && node.blockId === blockId) {
            // If we're at one of the filter DTO types with a matching block, add it to the match list and discard
            matches.push(node)
            return false
        } else if (isApiAndOperation(node) && isApiFilterDTO(node.and[0]) && node.and[0].table === 'patient' && node.and[0].blockId === blockId) {
            // If we're at the very specific shape of a patient attributes filter grouping, add it to the match list and discard

            /* Parsing patient attribute filters is rather odd, as we need to support a format that is very nested. The filters that
             * constitute patient attributes filters are something like:
             *
             * { and: [{ patient.sex }, { patient.race }, { or: [{ patient.region }, { patient.sub_region }, { patient.state }] }] }
             */
            for (let i = 0; i < node.and.length; i++) {
                const childNode = node.and[i]
                if (!isApiOperation(childNode)) {
                    matches.push(childNode)
                } else if (isApiOrOperation(childNode)) {
                    for (let j = 0; j < childNode.or.length; j++) {
                        const childChildNode = childNode.or[j]
                        if (!isApiOperation(childChildNode)) {
                            matches.push(childChildNode)
                        }
                    }
                }
            }
            return false
        } else if (isApiOrOperation(node) && node.or.every((x) => !isApiOperation(x))) {
            // If we are at an or operation and all children are filters, check if there is a block id match in the or.
            let childNodes: QueryFilter[] = node.or as QueryFilter[]
            if (childNodes.some((x) => x.blockId === blockId)) {
                childNodes = childNodes.filter((x) => {
                    if (x.blockId === blockId) {
                        matches.push(x)
                        return false
                    }
                    return true
                })
            }
            node.or = childNodes
            // Discard the or completely if it is empty after filtering out the related block ids
            return node.or.length > 0
        } else {
            return true
        }
    })
    return [nodes, matches]
}

/**
 * Flattens any nested qualifiers in a filter DTO.
 *
 * @example
 *
 * Pre-flatten:
 *
 * qualifiers:
 *  └─CountDistinctQualifier
 *     ├─DateQualifierDTO
 *     └─PatientAgeQualifierDTO
 *
 * Post-flatten:
 *
 * qualifiers:
 *  ├─CountDistinctQualifier
 *  ├─DateQualifierDTO
 *  └─PatientAgeQualifierDTO
 *
 * @param qualifiers a list of qualifiers with optional nested qualifiers
 * @returns a list of qualifiers without any nested qualifiers
 */
function flattenQualifierArrays(qualifiers?: FilterQualifier[]) {
    qualifiers = qualifiers || []
    const countDistinctQualifierIndex = qualifiers.findIndex((q) => isCountDistinctQualifier(q))
    const dateQualifier = findQualifierByType<DateQualifier>(qualifiers, QualifierType.DateQualifierDTO)
    if (countDistinctQualifierIndex > -1 && dateQualifier) {
        // if there is a count distinct qualifier along with a date qualifier, make them both exist in the top level in the array
        const { qualifiers: _qualifiers, ...countDistinctQualifier } = qualifiers[countDistinctQualifierIndex] as CountDistinctQualifier
        return [countDistinctQualifier, dateQualifier, ...qualifiers.filter((q) => !isCountDistinctQualifier(q))]
    } else {
        return qualifiers
    }
}
