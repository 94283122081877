import { debounce } from 'redux-saga/effects'
import { RWAConditionsActionTypes } from '../state/rwa-conditions'
import { RWAInsightsReportsActionTypes } from '../state/rwa-insights-reports'
import { createCreateRWAConditionsSaga } from './create-rwa-condition-saga'
import { createCreateRWAInsightsReportSaga } from './create-rwa-insights-report-saga'
import { createDeleteRWAConditionsSaga } from './delete-rwa-condition-saga'
import { createDeleteRWAInsightsReportSaga } from './delete-rwa-insights-report-saga'
import { createGetRWAConditionSaga } from './get-rwa-condition-saga'
import { createGetRWAConditionsSaga } from './get-rwa-conditions-saga'
import { createGetRWAInsightsReportsSaga } from './get-rwa-insights-reports-saga'

export function createPlatformSettingsSaga() {
    const getRWAConditionsSaga = createGetRWAConditionsSaga()
    const getRWAConditionSaga = createGetRWAConditionSaga()
    const createRWAConditionsSaga = createCreateRWAConditionsSaga()
    const deleteRWAConditionsSaga = createDeleteRWAConditionsSaga()
    const getRWAInsightsReportsSaga = createGetRWAInsightsReportsSaga()
    const getRWAInsightsReportSaga = createGetRWAInsightsReportsSaga()
    const createRWAInsightsReportSaga = createCreateRWAInsightsReportSaga()
    const deleteRWAInsightsReportSaga = createDeleteRWAInsightsReportSaga()

    return function* () {
        yield debounce(0, RWAConditionsActionTypes.INDEX_RWA_CONDITIONS, getRWAConditionsSaga)
        yield debounce(0, RWAConditionsActionTypes.GET_RWA_CONDITION, getRWAConditionSaga)
        yield debounce(0, RWAConditionsActionTypes.CREATE_RWA_CONDITION, createRWAConditionsSaga)
        yield debounce(0, RWAConditionsActionTypes.DELETE_RWA_CONDITION, deleteRWAConditionsSaga)
        yield debounce(0, RWAInsightsReportsActionTypes.INDEX_RWA_INSIGHTS_REPORTS, getRWAInsightsReportsSaga)
        yield debounce(0, RWAInsightsReportsActionTypes.GET_RWA_INSIGHTS_REPORT, getRWAInsightsReportSaga)
        yield debounce(0, RWAInsightsReportsActionTypes.CREATE_RWA_INSIGHTS_REPORT, createRWAInsightsReportSaga)
        yield debounce(0, RWAInsightsReportsActionTypes.DELETE_RWA_INSIGHTS_REPORT, deleteRWAInsightsReportSaga)
    }
}
