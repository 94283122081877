import { connectRedux } from '@om1/platform-utils'
import { CohortState, ReportAnalyticsType, cohortAnalyticsActions, reportsActions } from '../../state'
import { DemographicsReportChartComponent } from './DemographicsReportChartComponent'

export function createDemographicsReportChart<TState extends { cohort: CohortState }>() {
    return connectRedux(
        DemographicsReportChartComponent,
        (state: TState, ownProps) => ({
            reportsLoading: state.cohort.reports.ui.loading,
            analytics: state.cohort.analytics.data[ownProps.cohort.id]?.[ReportAnalyticsType.DEMOGRAPHICS],
            reports: state.cohort.reports
        }),
        { ...reportsActions, ...cohortAnalyticsActions }
    )
}

export type DemographicsReportChart = ReturnType<typeof createDemographicsReportChart>
