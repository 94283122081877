import { combineReducers } from 'redux'
import { CohortAnalyticsState } from './analytics'
import { cohortAnalyticsReducer } from './analytics/cohort-analytics-reducer'
import { CommonState } from './common'
import { commonReducer } from './common/common-reducer'
import { CohortCreateState } from './create'
import { cohortCreateReducer } from './create/cohort-create-reducer'
import { datasetsReducer } from './datasets/datasets-reducer'
import { DatasetsState } from './datasets/datasets-state'
import { cohortEditReducer } from './edit/base/cohort-base-edit-reducer'
import { cohortBlocksEditReducer } from './edit/blocks/cohort-blocks-edit-reducer'
import { CohortEditState } from './edit/cohort-edit-state'
import { cohortListReducer } from './list/cohort-list-reducer'
import { CohortListState } from './list/cohort-list-state'
import { AnalyticsRefsState } from './refs'
import { analyticsRefsReducer } from './refs/analytics-refs-reducer'
import { reportsReducer } from './reports/reports-reducer'
import { ReportsState } from './reports/reports-state'

export type CohortState = {
    common: CommonState
    create: CohortCreateState
    list: CohortListState
    edit: CohortEditState
    analytics: CohortAnalyticsState
    refs: AnalyticsRefsState
    datasets: DatasetsState
    reports: ReportsState
}

export const cohortReducer = combineReducers({
    common: commonReducer,
    create: cohortCreateReducer,
    list: cohortListReducer,
    edit: combineReducers({ base: cohortEditReducer, blocks: cohortBlocksEditReducer }),
    analytics: cohortAnalyticsReducer,
    refs: analyticsRefsReducer,
    datasets: datasetsReducer,
    reports: reportsReducer
})
