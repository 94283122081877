export enum Roles {
    ADMIN = 'admin',
    DATA_USER = 'data user'
}

export enum PlatformPermissions {
    // admin
    ADMIN_EXPLORER = 'admin:explorer',
    // access
    ACCESS_COHORTS = 'access:cohorts',
    ACCESS_DELIVERY = 'access:delivery',
    // index cohorts
    INDEX_COHORTS = 'index:cohorts',
    // crud cohorts
    GET_COHORT = 'get:cohort',
    UPDATE_COHORT = 'update:cohort',
    CREATE_COHORT = 'create:cohort',
    DELETE_COHORT = 'delete:cohort',
    GET_COHORTS_SQL = 'get:cohorts-sql',
    // execute
    EXECUTE_COHORT_TASKS = 'execute:cohort-tasks',
    // insights library
    ACCESS_INSIGHTS_LIBRARY = 'access:insights-library',
    // external cohort
    ACCESS_EXTERNAL_COHORTs = 'access:external-cohorts',
    // summary reports
    ACCESS_SUMMARY_REPORTS_FEATURES = 'access:summary-reports-features',
    // manage system cohort reports
    MANAGE_SYSTEM_COHORT_REPORTS = 'manage:system_cohort_reports',
    // organization metadata
    MANAGE_ORGANIZATION_METADATA = 'manage:organization_metadata',
    // platform settings
    ACCESS_PLATFORM_SETTINGS = 'access:platform-settings',
    ACCESS_RWA_INSIGHTS_CONDITIONS = 'access:rwa-insights-conditions',
    CREATE_RWA_INSIGHTS_CONDITIONS = 'create:conditions',
    DELETE_RWA_INSIGHTS_CONDITIONS = 'delete:conditions',
    ACCESS_RWA_INSIGHTS_REPORTS = 'access:rwa-insights-reports',
    CREATE_RWA_INSIGHTS_REPORTS = 'create:insights-reports',
    DELETE_RWA_INSIGHTS_REPORTS = 'delete:insights-reports'
}
