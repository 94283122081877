import {
    CountDistinctQualifier,
    DateRangeIntervalUnit,
    DateRangeOperator,
    DateRelationMetadata,
    QualifierOperator,
    QualifierType
} from '../../../state/cohort-state'
import { DateFieldOptions } from '../../../state/edit/blocks/cohort-blocks-edit-state'

export const DEFAULT_DATE_RELATION_METADATA: DateRelationMetadata = {
    dateRangeOperator: DateRangeOperator.After,
    intervalStartFromReferenceDate: 30,
    intervalEndFromReferenceDate: undefined,
    intervalUnitFromReferenceDate: DateRangeIntervalUnit.Day,
    intervalIsInclusive: false
}

export const DEFAULT_COUNT_DISTINCT_QUALIFIER: CountDistinctQualifier = {
    type: QualifierType.CountDistinctQualifierDTO,
    field: 'encounter_id',
    operator: QualifierOperator.GreaterThanOrEquals,
    value: 1
}

export const DEFAULT_DATE_FIELD: DateFieldOptions = 'any'
