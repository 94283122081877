import { Auth0State } from '@om1/platform-authentication'
import { connectRedux } from '@om1/platform-utils'
import { BackOfficeState } from '../state'
import { dataDeliveryActions } from '../state/data-delivery'
import { CreateEditDataDeliveryDialogComponent } from './CreateEditDataDeliveryDialogComponent'

export function createCreateEditDataDeliveryDialogComponent<TState extends { backOffice: BackOfficeState; auth0: Auth0State }>() {
    return connectRedux(
        CreateEditDataDeliveryDialogComponent,
        (state: TState) => ({
            cohorts: state.backOffice.dataDelivery.edit.cohorts,
            cohortsLoading: state.backOffice.dataDelivery.edit.ui.cohortsLoading,
            deliveredDatabases: state.backOffice.dataDelivery.edit.deliveredDatabases,
            deliveredDatabasesLoading: state.backOffice.dataDelivery.edit.ui.deliveredDatabasesLoading,
            isLoading: state.backOffice.dataDelivery.edit.ui.dataDeliveryLoading,
            auth0: state.auth0
        }),
        dataDeliveryActions
    )
}

export type CreateEditDataDeliveryDialog = ReturnType<typeof createCreateEditDataDeliveryDialogComponent>
