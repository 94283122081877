import { t } from '@lingui/macro'
import { AnalyticsRefDimension, CriteriaType, ProductCustomCohortRef } from '../../../../state'
import { DepthRefFieldMapper, DepthRefGetterCallback, RefFieldMapperType } from './interfaces'

const getItemLabel: DepthRefGetterCallback<ProductCustomCohortRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.cohort
    }
}

const getItemValue: DepthRefGetterCallback<ProductCustomCohortRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.cohort
    }
}

const getItemTooltip: DepthRefGetterCallback<ProductCustomCohortRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return getItemLabel(item, depth)
    }
}

export const ProductCustomCohortRefFieldMapper: DepthRefFieldMapper<ProductCustomCohortRef> = {
    type: RefFieldMapperType.Depth,
    dimension: AnalyticsRefDimension.PRODUCT_CUSTOM_COHORT,
    criteriaType: CriteriaType.ExternalCohort,
    table: 'patient_external_cohort',
    dateField: {},
    dateFieldInverse: {},
    disableRequireSearch: true,
    searchPlaceholder: (() => t`Search Product Custom Cohorts`)(),
    noResultsMessage: (() => t`No results`)(),
    searchHintMessage: (() => t`Search to browse product custom cohorts`)(),
    filterFieldToRefField: { product_custom_cohort: 'cohort' },
    filterFieldOrder: ['product_custom_cohort'],
    filterLabelOrder: ['product_custom_cohort'],
    getItemLabel,
    getItemValue,
    getItemTooltip
}
