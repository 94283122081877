import { yupResolver } from '@hookform/resolvers/yup'
import { Trans, t } from '@lingui/macro'
import CloseIcon from '@mui/icons-material/Close'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField
} from '@mui/material'
import { FrameworkComponentProps } from '@om1/platform-utils'
import { get } from 'lodash'
import { ReactNode, useMemo } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import {
    CriterionNode,
    DateRangeIntervalUnit,
    FollowUpLengthQualifier,
    QualifierOperator,
    QualifierType,
    cohortBlocksEditActions,
    isFollowUpLengthQualifier
} from '../../../state'

export interface FollowUpLengthEditFormValues {
    amount: number
    units: DateRangeIntervalUnit
}

export type FollowUpLengthEditDialogComponentProps = FrameworkComponentProps<
    {
        criteria?: CriterionNode
    },
    typeof cohortBlocksEditActions
>

export const FollowUpLengthEditDialogComponent = ({ state, actions }: FollowUpLengthEditDialogComponentProps) => {
    const { criteria } = state

    const validationSchema = Yup.object().shape({
        amount: Yup.number()
            .typeError(t`Must be a number`)
            .min(0, t`Must be 0 or greater`),
        units: Yup.mixed<DateRangeIntervalUnit>().oneOf(Object.values(DateRangeIntervalUnit))
    })

    const { amount, units }: { amount: number; units: DateRangeIntervalUnit } = useMemo(() => {
        const followUpQualifier = get(criteria, ['qualifiers'], []).find((qualifier) => isFollowUpLengthQualifier(qualifier))

        if (!followUpQualifier) {
            // return default values
            return { amount: 0, units: DateRangeIntervalUnit.Day }
        }

        return {
            amount: (followUpQualifier as FollowUpLengthQualifier).amount,
            units: (followUpQualifier as FollowUpLengthQualifier).units
        }
    }, [criteria])

    const handleDialogCancel = () => {
        actions.followUpLengthEditDialogTrigger({})
    }

    const {
        control,
        handleSubmit,
        register,
        formState: { errors }
    } = useForm<FollowUpLengthEditFormValues>({
        defaultValues: {
            amount,
            units
        },
        resolver: yupResolver(validationSchema)
    })

    const onSubmit: SubmitHandler<FollowUpLengthEditFormValues> = (formValues: FollowUpLengthEditFormValues) => {
        actions.followUpLengthEditDialogTrigger({})

        const qualifiers = get(criteria, 'qualifiers', []).filter((q) => !isFollowUpLengthQualifier(q))
        const resultQualifier: FollowUpLengthQualifier = {
            type: QualifierType.FollowUpLengthQualifierDTO,
            amount: formValues.amount,
            units: formValues.units,
            field: 'follow_up_days',
            operator: QualifierOperator.GreaterThanOrEquals,
            qualifiers: []
        }

        qualifiers.push(resultQualifier)

        if (criteria) {
            actions.criteriaUpdate({ target: { nodeId: criteria.id }, qualifiers })
        }
    }

    const onDone = () => {
        actions.followUpLengthEditDialogTrigger({})
    }

    return (
        <Dialog open maxWidth='sm' fullWidth>
            <DialogTitle>
                <Trans>Select Observation Period Length</Trans>
            </DialogTitle>
            <IconButton
                aria-label='close'
                onClick={handleDialogCancel}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500]
                }}
            >
                <CloseIcon />
            </IconButton>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent sx={{ pt: 0 }}>
                    <Box paddingTop={2} display='flex' columnGap={1} flexDirection='row' justifyContent='flex-start' alignItems='center'>
                        <Trans>At Least</Trans>
                        <TextField
                            id='amount'
                            type='number'
                            {...register('amount')}
                            required
                            inputProps={{ inputMode: 'numeric', pattern: '[1-9][0-9]*' }}
                            error={!!errors['amount']}
                            helperText={errors['amount']?.message}
                        />
                        <Controller
                            control={control}
                            name='units'
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    id='units'
                                    labelId='units-label'
                                    required
                                    sx={{ flex: 1, marginRight: 1 }}
                                    value={value}
                                    onChange={onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}
                                >
                                    <MenuItem value={DateRangeIntervalUnit.Day}>
                                        <Trans>Days</Trans>
                                    </MenuItem>
                                    <MenuItem value={DateRangeIntervalUnit.Month}>
                                        <Trans>Months</Trans>
                                    </MenuItem>
                                    <MenuItem value={DateRangeIntervalUnit.Year}>
                                        <Trans>Years</Trans>
                                    </MenuItem>
                                </Select>
                            )}
                        />
                        <Trans>Long</Trans>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' color='primary' onClick={onDone}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button variant='contained' type='submit'>
                        <Trans>Save</Trans>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
