import { CohortInsightsReportDTO } from '@om1/falcon-api'

export interface InsightsLibraryState {
    cohortInsightsReports: CohortInsightsReportDTO[]
    loading: boolean
}

export const initialInsightsLibraryState: InsightsLibraryState = {
    cohortInsightsReports: [],
    loading: true
}
