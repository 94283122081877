import { put } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'
import { DataType } from '../components/edit/blocks/ObservationPeriodDialogComponent'
import {
    CohortNodeSourceAddress,
    CohortTreeActionType,
    CriteriaInsertPayload,
    CriteriaType,
    CriteriaUpdatePayload,
    cohortBlocksEditActions
} from '../state'

function mapFieldToDataType(dataType: DataType) {
    if (
        dataType === 'EHR Linked Medical Claims Data' ||
        dataType === 'EHR Linked Medical and Rx Claims Data' ||
        dataType === 'EHR Linked Medical or Rx Claims Data' ||
        dataType === 'EHR Linked Rx Claims Data'
    ) {
        return 'linkage_type'
    } else if (dataType === 'EHR Data' || dataType === 'Medical Claims Data' || dataType === 'Rx Claims Data') {
        return 'data_source_type'
    } else {
        return ''
    }
}

function mapValueToDataType(dataType: DataType) {
    switch (dataType) {
        case 'EHR Data':
            return ['EHR']
        case 'Medical Claims Data':
            return ['Medical Claims']
        case 'Rx Claims Data':
            return ['Pharmacy Claims']
        case 'EHR Linked Medical and Rx Claims Data':
            return ['EHR, Medical Claims, and Pharmacy Claims']
        case 'EHR Linked Medical Claims Data':
            return ['EHR and Medical Claims']
        case 'EHR Linked Medical or Rx Claims Data':
            return ['EHR and Medical Claims', 'EHR and Pharmacy Claims']
        case 'EHR Linked Rx Claims Data':
            return ['EHR and Pharmacy Claims']
        default:
            return []
    }
}

export function createUpdateCohortObservationPeriodSaga() {
    return function* (action: ReturnType<typeof cohortBlocksEditActions.observationPeriodUpdate>) {
        if (action.payload.actionType === CohortTreeActionType.Insert) {
            const newCriteria: CriteriaInsertPayload = {
                target: action.payload.target,
                uuid: uuidv4(),
                criteriaType: CriteriaType.ObservationPeriod,
                filters: [
                    {
                        table: 'observation_period',
                        field: mapFieldToDataType(action.payload.state.value),
                        operator: 'in',
                        values: mapValueToDataType(action.payload.state.value)
                    }
                ],
                insertFirst: true
            }
            yield put(cohortBlocksEditActions.criteriaInsert(newCriteria))
        } else {
            const updatedCriteria: CriteriaUpdatePayload = {
                target: action.payload.target as CohortNodeSourceAddress,
                filters: [
                    {
                        table: 'observation_period',
                        field: mapFieldToDataType(action.payload.state.value),
                        operator: 'in',
                        values: mapValueToDataType(action.payload.state.value)
                    }
                ]
            }
            yield put(cohortBlocksEditActions.criteriaUpdate(updatedCriteria))
        }
    }
}
