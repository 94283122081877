import { faHeadset } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import CloseIcon from '@mui/icons-material/Close'
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor'
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { Box } from '@mui/system'
import { NotificationState, notificationActions } from '@om1/platform-notifications'
import { FrameworkComponentProps } from '@om1/platform-utils'
import { FunctionComponent } from 'react'

export type HelpDialogComponentProps = FrameworkComponentProps<NotificationState, typeof notificationActions, { getImage?: () => any }>

/**
 * A dialog the help.
 */
export const HelpDialogComponent: FunctionComponent<HelpDialogComponentProps> = ({ state, actions, props }) => {
    const { getImage } = props
    const { showHelpDialog } = state

    const handleClose = () => {
        actions.hideHelpDialog()
    }

    return (
        <Dialog open={showHelpDialog} maxWidth='lg' fullWidth aria-labelledby='help-dialog-title' onClose={handleClose}>
            <DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={handleClose}
                    sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                >
                    <CloseIcon />
                </IconButton>
                <Box id='help-dialog-title' pr={4}>
                    <Trans>Help and Support Resources</Trans>
                </Box>
            </DialogTitle>
            <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box position='relative' display='flex' flexDirection='column' flex={1} sx={{ overflowY: 'auto' }}>
                    <Box component='pre' p={2} m={0} bgcolor='grey.200' sx={{ overflow: 'auto' }}>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Button
                                aria-labelledby='help-dialog-contact-button'
                                data-testid='help-dialog-contact-button-testid'
                                sx={{ margin: '25px', backgroundColor: '#FFFFFF', padding: '40px' }}
                                onClick={handleClose}
                                target='_blank'
                                href='https://om1inc.atlassian.net/servicedesk/customer/portal/5'
                            >
                                <Box display={'flex'} alignItems={'flex-start'} justifyContent={'flex-start'} width={'100%'}>
                                    <Box>
                                        <FontAwesomeIcon icon={faHeadset} size='4x' />
                                    </Box>
                                    <Box display={'flex'} flexDirection={'column'} ml={2} paddingLeft={'50px'}>
                                        <Box sx={{ fontSize: '1.5em' }} alignSelf={'baseline'}>
                                            <Trans>Contact Support</Trans>
                                        </Box>
                                        <Box>
                                            <Trans>Report an issue or make a user suggestion.</Trans>
                                        </Box>
                                    </Box>
                                </Box>
                            </Button>
                            {getImage && (
                                <Button
                                    aria-labelledby='help-dialog-simple-screenshot-button'
                                    data-testid='help-dialog-simple-screenshot-button-testid'
                                    sx={{ margin: '25px', backgroundColor: '#FFFFFF', padding: '40px' }}
                                    onClick={() => {
                                        handleClose()
                                        getImage()
                                    }}
                                >
                                    <Box display={'flex'} alignItems={'flex-start'} justifyContent={'flex-start'} width={'100%'}>
                                        <Box>
                                            <ScreenshotMonitorIcon sx={{ fontSize: '4.5rem' }} />
                                        </Box>
                                        <Box display={'flex'} flexDirection={'column'} paddingLeft={'50px'}>
                                            <Box sx={{ fontSize: '1.5em' }} alignSelf={'baseline'}>
                                                <Trans>Capture Screenshot</Trans>
                                            </Box>
                                            <Box>
                                                <Trans>Capture a screenshot of your issue to send directly to the developers.</Trans>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
