import { Trans } from '@lingui/macro'
import { FrameworkComponentProps } from '@om1/platform-utils'
import React, { useEffect, useMemo } from 'react'
import { CohortListItem, OperationNode, cohortBlocksEditActions, cohortEditActions, isAndOperationNode } from '../../state'
import { CriteriaArea } from '../edit/blocks/CriteriaArea'

export type ReadOnlyCohortComponentProps = FrameworkComponentProps<
    { tree: OperationNode },
    typeof cohortEditActions & typeof cohortBlocksEditActions,
    { cohort: CohortListItem }
>

export const ReadOnlyCohortComponent: React.FunctionComponent<ReadOnlyCohortComponentProps> = ({ state: { tree }, actions, props: { cohort } }) => {
    useEffect(() => {
        return () => {
            actions.reset()
        }
    }, [actions])

    const [parsed, setParsed] = React.useState<string | undefined>()

    useEffect(() => {
        if (cohort && parsed !== cohort.id) {
            actions.cohortParseTree({ cohort })
            setParsed(cohort.id)
        }
    }, [actions, cohort, setParsed, parsed])

    const useExclusionRoot = (tree: OperationNode): { exclusionRoot?: OperationNode } => {
        return useMemo(() => {
            let exclusionRoot: OperationNode | undefined
            if (tree && tree.children && tree.children.length > 1 && isAndOperationNode(tree.children[1]) && tree.children[1].children.length > 0) {
                exclusionRoot = tree.children[1]
            }
            return { exclusionRoot }
        }, [tree])
    }

    function useInclusionRoot(tree: OperationNode | undefined): { inclusionRoot?: OperationNode } {
        let inclusionRoot: OperationNode | undefined

        if (
            !tree ||
            !tree.children ||
            tree.children.length === 0 ||
            !isAndOperationNode(tree.children[0]) ||
            tree.children[0].children.length === 0
        ) {
            return { inclusionRoot: undefined }
        }

        inclusionRoot = tree.children[0]
        return { inclusionRoot }
    }

    // Usage
    const { exclusionRoot } = useExclusionRoot(tree)
    const { inclusionRoot } = useInclusionRoot(tree)

    return (
        <div id='cohort-criteria'>
            <CriteriaArea
                title={<Trans>Inclusion Criteria</Trans>}
                root={inclusionRoot}
                actions={actions}
                dragState={{ active: false, allowRelate: false }}
                onAdd={() => {}}
                readOnly
                permissions={[]}
            />
            <CriteriaArea
                title={<Trans>Exclusion Criteria</Trans>}
                root={exclusionRoot}
                actions={actions}
                dragState={{ active: false, allowRelate: false }}
                onAdd={() => {}}
                readOnly
                permissions={[]}
            />
        </div>
    )
}
