import { ExplorerReportsService, ReportDTO, ReportPatchDTO, SectionDTO_Output } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { notificationActions } from '@om1/platform-notifications'
import { call, put } from 'redux-saga/effects'
import { getReportSectionsFromFilters, getReportTypeFromFilters } from '../components/edit/utils/filter-utils'
import { reportsActions } from '../state/reports/reports-actions'
import { Report, ReportSection, ReportType } from '../state/reports/reports-state'

export function createUpdateReportSaga() {
    return function* (action: ReturnType<typeof reportsActions.reportUpdate>) {
        const {
            payload: { reportId, cohortId, filters }
        } = action
        try {
            yield put(reportsActions.reportsLoadingSet({ loading: true }))
            const postBody = {
                id: reportId,
                cohortId,
                reportType: getReportTypeFromFilters(filters),
                sections: getReportSectionsFromFilters(filters)
            }

            const reportDTO: ReportDTO = yield call(ExplorerReportsService.patchExplorerReportsPatch, {
                requestBody: postBody as ReportPatchDTO
            })
            const report: Report = {
                id: reportDTO.id,
                reportType: reportDTO.reportType as ReportType,
                cohortId: reportDTO.cohortId,
                sections: reportDTO.sections.map((section: SectionDTO_Output) => {
                    return section as ReportSection
                }),
                createdDttm: reportDTO.createdDttm,
                updatedDttm: reportDTO.updatedDttm,
                createdById: reportDTO.createdById,
                lastUpdatedBy: reportDTO.updatedById,
                organizationId: reportDTO.organizationId
            }
            yield put(reportsActions.reportSet({ report: report }))
            yield put(notificationActions.success())
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(reportsActions.reportsLoadingSet({ loading: false }))
        }
    }
}
