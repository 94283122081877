import { yupResolver } from '@hookform/resolvers/yup'
import { Trans, t } from '@lingui/macro'
import CloseIcon from '@mui/icons-material/Close'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField
} from '@mui/material'
import { FrameworkComponentProps } from '@om1/platform-utils'
import { ReactNode, useMemo } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import {
    CountDistinctQualifier,
    CriteriaType,
    CriterionNode,
    DateFieldOptions,
    FilterQualifier,
    cohortBlocksEditActions,
    isCountDistinctQualifier
} from '../../../state'
import { DEFAULT_COUNT_DISTINCT_QUALIFIER, DEFAULT_DATE_FIELD } from '../utils/constants'

export enum OccurrenceType {
    First = 'First',
    Last = 'Last',
    AtLeast = 'AtLeast'
}

interface OccurrenceEditFormValues {
    type: OccurrenceType
    count: number
}

export type OccurrenceEditDialogComponentProps = FrameworkComponentProps<
    { criteria?: CriterionNode; relatedCriteria?: CriterionNode },
    typeof cohortBlocksEditActions
>

export const OccurrenceEditDialogComponent = ({ state, actions }: OccurrenceEditDialogComponentProps) => {
    const { criteria, relatedCriteria } = state
    const validationSchema = Yup.object().shape({
        recordSelection: Yup.string(),
        count: Yup.number()
            .typeError(t`Must be a number`)
            .min(1, t`Must be 1 or greater`)
    })

    const initialValue = useMemo(() => {
        const initialValue = { count: 1, recordSelection: OccurrenceType.AtLeast }
        if (criteria) {
            const existingCountDistinctQualifier = criteria.qualifiers.filter((q) => isCountDistinctQualifier(q))[0] as CountDistinctQualifier

            initialValue.count = DEFAULT_COUNT_DISTINCT_QUALIFIER.value
            initialValue.recordSelection = OccurrenceType.AtLeast

            if (existingCountDistinctQualifier) {
                initialValue.count = existingCountDistinctQualifier.value
            }

            if (criteria.dateField === 'first') {
                initialValue.recordSelection = OccurrenceType.First
            } else if (criteria.dateField === 'last') {
                initialValue.recordSelection = OccurrenceType.Last
            }
        }
        return initialValue
    }, [criteria])

    const options = useMemo(() => {
        let firstLabel = <Trans>First</Trans>
        let lastLabel = <Trans>Latest Encounter</Trans>
        const atLeastLabel = <Trans>At Least</Trans>
        if (criteria && criteria.type === CriteriaType.Diagnosis) {
            firstLabel = <Trans>Initial Diagnosis</Trans>
        } else if (criteria && criteria.type === CriteriaType.Medication) {
            firstLabel = <Trans>Medication Initiation</Trans>
        }
        if (criteria && criteria.type === CriteriaType.EhrNotes) {
            firstLabel = <Trans>First Note</Trans>
            lastLabel = <Trans>Latest Note</Trans>
        }

        // At least (any) should be disabled if the criteria is in a relationship and at least is already selected on
        // the other criteria
        const anyDisabled = relatedCriteria && relatedCriteria.dateField === 'any'

        return [
            <MenuItem key={OccurrenceType.First} value={OccurrenceType.First}>
                {firstLabel}
            </MenuItem>,
            <MenuItem key={OccurrenceType.Last} value={OccurrenceType.Last}>
                {lastLabel}
            </MenuItem>,
            <MenuItem key={OccurrenceType.AtLeast} value={OccurrenceType.AtLeast} disabled={anyDisabled}>
                {atLeastLabel}
            </MenuItem>
        ]
    }, [criteria, relatedCriteria])

    const atLeastUnit = useMemo(() => {
        let atLeastUnit = <Trans>Encounter(s)</Trans>
        if (criteria && criteria.type === CriteriaType.EhrNotes) {
            atLeastUnit = <Trans>Note(s)</Trans>
        }
        return atLeastUnit
    }, [criteria])

    const {
        control,
        handleSubmit,
        register,
        watch,
        formState: { errors }
    } = useForm<OccurrenceEditFormValues>({
        defaultValues: {
            type: initialValue.recordSelection ? initialValue.recordSelection : OccurrenceType.AtLeast,
            count: !isNaN(initialValue.count) ? initialValue.count : 1
        },
        resolver: yupResolver(validationSchema)
    })

    const onSubmit: SubmitHandler<OccurrenceEditFormValues> = (formValues: OccurrenceEditFormValues) => {
        actions.occurrenceEditDialogTrigger({})

        let dateField: DateFieldOptions = DEFAULT_DATE_FIELD
        let qualifiers: FilterQualifier[] = criteria && Array.isArray(criteria?.qualifiers) ? [...criteria.qualifiers] : []
        qualifiers = [...qualifiers.filter((q) => !isCountDistinctQualifier(q))]
        if (formValues.type === OccurrenceType.First) {
            dateField = 'first'
        } else if (formValues.type === OccurrenceType.Last) {
            dateField = 'last'
        } else if (formValues.type === OccurrenceType.AtLeast) {
            dateField = 'any'
            if (formValues.count !== DEFAULT_COUNT_DISTINCT_QUALIFIER.value) {
                qualifiers.push({ ...DEFAULT_COUNT_DISTINCT_QUALIFIER, value: formValues.count })
            }
        }
        if (criteria) {
            actions.criteriaUpdate({ target: { nodeId: criteria.id }, dateField, qualifiers })
        }
    }

    const onDone = () => {
        actions.occurrenceEditDialogTrigger({})
    }

    const recordSelectionWatcher = watch('type')

    return (
        <Dialog open maxWidth='xs' fullWidth>
            <DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={onDone}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <DialogContent>
                    <Box paddingTop={2} display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center'>
                        <Controller
                            control={control}
                            name='type'
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    id='record-selection'
                                    labelId='record-selection-label'
                                    sx={{ marginRight: 1 }}
                                    required
                                    value={value}
                                    onChange={onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}
                                >
                                    {options}
                                </Select>
                            )}
                        />
                        {recordSelectionWatcher === OccurrenceType.AtLeast ? (
                            <>
                                <TextField
                                    id='count'
                                    type='number'
                                    {...register('count')}
                                    required
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    sx={{ marginRight: 1 }}
                                    error={!!errors['count']}
                                    helperText={errors['count']?.message}
                                />
                                <Box flex={0}>{atLeastUnit}</Box>
                            </>
                        ) : null}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' color='primary' onClick={onDone}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button variant='contained' type='submit'>
                        <Trans>Save</Trans>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
