import { Auth0State } from '@om1/platform-authentication'
import { PlatformConfigState } from '@om1/platform-config/state'
import { createAction } from '@om1/platform-utils'
import { AnalyticsBrowser, EventProperties, SegmentEvent } from '@segment/analytics-next'
import { select, takeLeading } from 'redux-saga/effects'
import { PlatformState } from '../../apps/platform/src/platform-state'
import { generateKey } from './tracking'

export interface LocationChangeAction {
    type: string
    payload: Payload
}
export interface Payload {
    location: Location
    action: string
    isFirstRendering: boolean
}
export interface Location {
    pathname: string
    search: string
    hash: string
    key: string
}

export enum TrackingActionTypes {
    TRACK_EVENT = '@@tracking/track-event',
    TRACK_PAGE = '@@tracking/track-page'
}

export const trackingActions = {
    trackEvent: (eventName: string | SegmentEvent, properties?: EventProperties | undefined) => {
        return createAction(TrackingActionTypes.TRACK_EVENT, { eventName, properties })
    },
    trackPage: (route: string) => {
        return createAction(TrackingActionTypes.TRACK_PAGE, {
            location: { pathname: route, search: '', hash: '', key: generateKey(10) },
            action: 'REPLACE',
            isFirstRendering: false
        })
    }
}

export function createTrackingSaga(analytics: AnalyticsBrowser) {
    const routeTrackingSaga = function* (action: LocationChangeAction) {
        const store: { platformConfig: PlatformConfigState; auth0: Auth0State } = yield select((store: PlatformState) => {
            return { platformConfig: store.platformConfig, auth0: store.auth0 }
        })
        yield analytics.page(
            undefined,
            undefined,
            { properties: action.payload },
            {
                traits: {
                    environment: store.platformConfig.environmentTag,
                    buildNumber: store.platformConfig.buildNumber,
                    isOm1User: store.auth0.user?.email?.endsWith('@om1.com'),
                    email: store.auth0.user?.email,
                    org_id: store.auth0.user?.org_id,
                    org_name: store.auth0.user?.org_name,
                    user_id: store.auth0.user?.sub
                }
            }
        )
    }
    const eventTrackingSaga = function* (action: ReturnType<typeof trackingActions.trackEvent>) {
        const store: { platformConfig: PlatformConfigState; auth0: Auth0State } = yield select((store: PlatformState) => {
            return { platformConfig: store.platformConfig, auth0: store.auth0 }
        })

        try {
            yield analytics.track(
                action.payload.eventName,
                {
                    ...action.payload.properties
                },
                {
                    traits: {
                        environment: store.platformConfig.environmentTag,
                        buildNumber: store.platformConfig.buildNumber,
                        isOm1User: store.auth0.user?.email?.endsWith('@om1.com'),
                        email: store.auth0.user?.email,
                        org_id: store.auth0.user?.org_id,
                        org_name: store.auth0.user?.org_name,
                        user_id: store.auth0.user?.sub
                    }
                }
            )
        } catch (error) {
            console.error('Error tracking event', error)
        }
    }

    return function* () {
        yield takeLeading('@@router/LOCATION_CHANGE', routeTrackingSaga)
        yield takeLeading(TrackingActionTypes.TRACK_PAGE, routeTrackingSaga)
        yield takeLeading(TrackingActionTypes.TRACK_EVENT, eventTrackingSaga)
    }
}
