import { Trans } from '@lingui/macro'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import {
    Box,
    Button,
    Collapse,
    Divider,
    FormControlLabel,
    FormControlLabelProps,
    FormGroup,
    Grid,
    IconButton,
    Skeleton,
    Typography
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { FrameworkComponentProps } from '@om1/platform-utils'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import {
    CohortTreeActionType,
    CriteriaType,
    QueryFilterBase,
    RaceRef,
    SexRef,
    StateRef,
    cohortBlocksEditActions,
    cohortEditActions
} from '../../../state'
import { CheckTree } from '../../shared/CheckTree'
import { DepthCheckTreeManager } from '../../shared/DepthCheckTreeManager'
import { generateUICriteriaFilters } from '../utils/filter-utils'
import { RaceRefFieldMapper, SexRefFieldMapper, StateRefFieldMapper, extractRefLabels } from '../utils/ref-field-mappers'

interface PatientAttributes {
    sex: { any: boolean; values: Set<string> }
    race: { any: boolean; values: Set<string> }
    state: { any: boolean }
}

export type PatientAttributeDialogProps = FrameworkComponentProps<
    { sexRefs: SexRef[]; raceRefs: RaceRef[]; stateRefs: StateRef[]; refsLoading: boolean },
    typeof cohortEditActions & typeof cohortBlocksEditActions,
    {
        initialValue: QueryFilterBase[]
        action: CohortTreeActionType
        onSave: (filters: QueryFilterBase[]) => void
        onCancel: () => void
    }
>

/**
 * A dialog for editing the Patient Attributes criterion type.
 */
export const PatientAttributesDialogComponent = ({ state, props, actions }: PatientAttributeDialogProps) => {
    const { sexRefs, raceRefs, stateRefs, refsLoading } = state
    const { initialValue } = props

    const raceRefsSorted = useMemo(() => {
        const multiracial = raceRefs.find((x) => x.race === 'Multiracial')
        const unknownRace = raceRefs.find((x) => x.race === 'Unknown Race')
        const otherRace = raceRefs.find((x) => x.race === 'Other Race')
        const sorted = raceRefs.filter((x) => x !== multiracial && x !== otherRace && x !== unknownRace)
        sorted.sort()
        if (multiracial) {
            sorted.push(multiracial)
        }
        if (unknownRace) {
            sorted.push(unknownRace)
        }
        if (otherRace) {
            sorted.push(otherRace)
        }
        return sorted
    }, [raceRefs])

    const [formValue, setFormValue] = useState<PatientAttributes>(() => {
        const sexFilters = initialValue.filter((x) => x.field === 'sex')
        const sexValues = sexFilters.map((x) => x.values).flat()

        const raceFilters = initialValue.filter((x) => x.field === 'race')
        const raceValues = raceFilters.map((x) => x.values).flat()

        const stateFilters = initialValue.filter((x) => ['region', 'sub_region', 'state'].includes(x.field))
        const stateValues = stateFilters.map((x) => x.values).flat()

        const formValue: PatientAttributes = {
            sex: { any: sexFilters.length > 0 && sexValues.length === 0, values: new Set(sexValues) },
            race: { any: raceFilters.length > 0 && raceValues.length === 0, values: new Set(raceValues) },
            state: { any: stateFilters.length > 0 && stateValues.length === 0 }
        }
        return formValue
    })

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_renderTrigger, setRenderTrigger] = useState<number>(0)
    const regionManagers = useMemo(() => {
        const uniqueRegions = Array.from(new Set<string>(stateRefs.map((x) => x.region))).sort()
        const regionManagers: DepthCheckTreeManager<StateRef>[] = []
        for (const region of uniqueRegions) {
            const regionNodes = stateRefs.filter((x) => x.region === region)
            const regionManager = new DepthCheckTreeManager<StateRef>({
                collapseAtDepth: 4,
                getItemLabel: StateRefFieldMapper.getItemLabel,
                getItemValue: StateRefFieldMapper.getItemValue,
                getItemTooltip: StateRefFieldMapper.getItemTooltip
            })
            regionManager.registerChangeListener(() => {
                setRenderTrigger((prevValue) => prevValue + 1)
            })
            for (let i = 0; i < regionNodes.length; i++) {
                regionManager.addNode(regionNodes[i], '')
            }

            for (let i = 0; i < initialValue.length; i++) {
                const filter = initialValue[i]
                ;[
                    ['region', 'region'],
                    ['sub_region', 'subRegion'],
                    ['state', 'stateName']
                ].forEach(([filterField, refField], depth) => {
                    if (filter.field === filterField) {
                        for (let j = 0; j < filter.values.length; j++) {
                            const value = filter.values[j]
                            const node = regionNodes.find((x) => x[refField] === value)
                            if (node) {
                                regionManager.stage({ source: node, depth })
                            }
                        }
                    }
                })
            }
            regionManagers.push(regionManager)
        }
        return regionManagers
    }, [stateRefs, initialValue])

    useEffect(() => {
        actions.getDemographicsRefs()
    }, [actions])

    const handleSexChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setFormValue((prevValue) => {
            const eventValue = event.target.value
            const newValues = new Set(prevValue.sex.values)
            checked ? newValues.add(eventValue) : newValues.delete(eventValue)
            const anyChecked = newValues.size === sexRefs.length
            return { ...prevValue, sex: { any: anyChecked, values: newValues } } satisfies PatientAttributes
        })
    }

    const handleAnySexChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setFormValue((prevValue) => ({ ...prevValue, sex: { ...prevValue.sex, any: checked } } satisfies PatientAttributes))
    }

    const handleRaceChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setFormValue((prevValue) => {
            const eventValue = event.target.value
            const newValues = new Set(prevValue.race.values)
            checked ? newValues.add(eventValue) : newValues.delete(eventValue)
            const anyChecked = newValues.size === raceRefs.length
            return { ...prevValue, race: { any: anyChecked, values: newValues } } satisfies PatientAttributes
        })
    }

    const handleAnyRaceChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setFormValue((prevValue) => ({ ...prevValue, race: { ...prevValue.race, any: checked } } satisfies PatientAttributes))
    }

    const handleAnyStateChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setFormValue((prevValue) => ({ ...prevValue, state: { ...prevValue.state, any: checked } } satisfies PatientAttributes))
    }

    const handleSave = () => {
        const stateValues = regionManagers
            .map((x) => x.staged)
            .flat()
            .sort()
        const filters = generateUICriteriaFilters({
            type: CriteriaType.PatientAttributes,
            fields: {
                sex: formValue.sex.any ? [] : Array.from(formValue.sex.values).sort(),
                race: formValue.race.any ? [] : Array.from(formValue.race.values).sort(),
                region: formValue.state.any ? [] : stateValues.filter((x) => x.depth === 0).map((x) => x.source.region),
                sub_region: formValue.state.any ? [] : stateValues.filter((x) => x.depth === 1).map((x) => x.source.subRegion),
                state: formValue.state.any ? [] : stateValues.filter((x) => x.depth === 2).map((x) => x.source.stateName)
            }
        })

        actions.mergeRefLabels({
            patient: {
                ...(extractRefLabels(sexRefs, SexRefFieldMapper)?.patient ?? {}),
                ...(extractRefLabels(raceRefs, RaceRefFieldMapper)?.patient ?? {}),
                ...(extractRefLabels(stateRefs, StateRefFieldMapper)?.patient ?? {})
            }
        })

        props.onSave(filters)
    }

    const handleCancel = () => {
        props.onCancel()
    }

    return (
        <Dialog open maxWidth='lg' fullWidth>
            <DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={handleCancel}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Box pr={4}>
                    {props.action === CohortTreeActionType.Insert ? (
                        <Trans>Select Patient Attributes</Trans>
                    ) : (
                        <Trans>Update Patient Attributes</Trans>
                    )}
                </Box>
            </DialogTitle>

            <DialogContent dividers>
                <Box display='flex' flexDirection='column' gap={2}>
                    <div>
                        <Typography fontWeight='bold'>
                            <Trans>Sex</Trans>
                        </Typography>

                        <CheckTreeLabel
                            control={
                                <input type='checkbox' checked={formValue.sex.any} onChange={(e) => handleAnySexChange(e, !formValue.sex.any)} />
                            }
                            label={<Trans>Any</Trans>}
                        />

                        <Collapse in={!formValue.sex.any} role='group'>
                            <Grid container>
                                {sexRefs.map((item) => (
                                    <Grid item xs='auto' key={item.sex}>
                                        <CheckTreeLabel
                                            control={
                                                <input
                                                    value={item.sex}
                                                    type='checkbox'
                                                    checked={formValue.sex.values.has(item.sex)}
                                                    onChange={(e) => handleSexChange(e, !formValue.sex.values.has(item.sex))}
                                                />
                                            }
                                            label={item.sex}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Collapse>

                        {refsLoading && <Skeleton animation='wave' variant='rounded' width='100%' height={38} />}
                    </div>

                    <Divider />

                    <div>
                        <Typography fontWeight='bold'>
                            <Trans>Race</Trans>
                        </Typography>

                        <CheckTreeLabel
                            control={
                                <input type='checkbox' checked={formValue.race.any} onChange={(e) => handleAnyRaceChange(e, !formValue.race.any)} />
                            }
                            label={<Trans>Any</Trans>}
                        />

                        <Collapse in={!formValue.race.any} role='group'>
                            <Grid container>
                                {raceRefsSorted.map((item) => (
                                    <Grid item xs='auto' key={item.race}>
                                        <CheckTreeLabel
                                            control={
                                                <input
                                                    value={item.race}
                                                    type='checkbox'
                                                    checked={formValue.race.values.has(item.race)}
                                                    onChange={(e) => handleRaceChange(e, !formValue.race.values.has(item.race))}
                                                />
                                            }
                                            label={item.race}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Collapse>

                        {refsLoading && <Skeleton animation='wave' variant='rounded' width='100%' height={38} />}
                    </div>

                    <Divider />

                    <div>
                        <Typography fontWeight='bold'>
                            <Trans>Location</Trans>
                        </Typography>

                        <CheckTreeLabel
                            control={
                                <input
                                    type='checkbox'
                                    checked={formValue.state.any}
                                    onChange={(e) => handleAnyStateChange(e, !formValue.state.any)}
                                />
                            }
                            label={<Trans>Any location</Trans>}
                        />

                        <Collapse in={!formValue.state.any} role='group'>
                            <Grid container marginLeft={0.5}>
                                {regionManagers.map((manager, index) => (
                                    <Grid item xs={3} key={index}>
                                        <CheckTree manager={manager} tree={manager.tree} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Collapse>

                        {refsLoading && <Skeleton animation='wave' variant='rounded' width='100%' height={808} />}
                    </div>
                </Box>
            </DialogContent>

            <DialogActions>
                <Button variant='contained' startIcon={<AddIcon />} onClick={handleSave}>
                    <Trans>Save Criteria</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const CheckTreeLabel = ({ label, ...props }: FormControlLabelProps) => {
    return (
        <FormGroup>
            <FormControlLabel
                sx={{ marginLeft: 0, marginTop: 0.25, marginBottom: 0.25 }}
                label={
                    <Box component='span' pl={0.5}>
                        {label}
                    </Box>
                }
                {...props}
            />
        </FormGroup>
    )
}
