import { t } from '@lingui/macro'
import { AnalyticsRefDimension, CriteriaType, MedicationNdcRef } from '../../../../state'
import { DepthRefFieldMapper, DepthRefGetterCallback, RefFieldMapperType } from './interfaces'

const getItemLabel: DepthRefGetterCallback<MedicationNdcRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.ndcDisplayName
    }
}

const getItemValue: DepthRefGetterCallback<MedicationNdcRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.ndcCode
    }
}

const getItemTooltip: DepthRefGetterCallback<MedicationNdcRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return getItemLabel(item, depth)
    }
}

export const MedicationNdcRefFieldMapper: DepthRefFieldMapper<MedicationNdcRef> = {
    type: RefFieldMapperType.Depth,
    dimension: AnalyticsRefDimension.MEDICATION_NDC,
    criteriaType: CriteriaType.Medication,
    table: 'patient_medication',
    dateField: {
        first: 'first_record_date',
        last: 'last_record_date',
        any: 'any_record_date'
    },
    dateFieldInverse: {
        first_record_date: 'first',
        last_record_date: 'last',
        any_record_date: 'any'
    },
    searchPlaceholder: (() => t`Search National Drug Code Directory`)(),
    noResultsMessage: (() => t`No results`)(),
    searchHintMessage: (() => t`Search to browse National Drug Codes`)(),
    filterFieldToRefField: { ndc_code: 'ndcCode' },
    filterFieldOrder: ['ndc_code'],
    filterLabelOrder: ['ndc_name'],
    getItemLabel,
    getItemValue,
    getItemTooltip
}
