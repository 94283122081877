import { CohortConditionDTO } from '@om1/falcon-api'

export interface RWAConditionsState {
    loading: boolean
    conditions: CohortConditionDTO[]
}

export const initialRWAConditionsState: RWAConditionsState = {
    loading: true,
    conditions: []
}
