import { ExplorerReportsService, ReportCreateDTO, ReportDTO, ReportType, SectionDTO_Input, SectionDTO_Output } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { notificationActions } from '@om1/platform-notifications'
import { call, put } from 'redux-saga/effects'
import { getReportSectionsFromFilters, getReportTypeFromFilters } from '../components/edit/utils/filter-utils'
import { ReportQueryFilters } from '../state'
import { reportsActions } from '../state/reports/reports-actions'
import { Report, ReportType as StateReportType } from '../state/reports/reports-state'

export function createCreateReportSaga() {
    return function* (action: ReturnType<typeof reportsActions.reportCreate>) {
        const {
            payload: { cohortId, filters }
        } = action
        try {
            yield put(reportsActions.reportsLoadingSet({ loading: true }))
            const reportCreateDTO: ReportCreateDTO = {
                cohortId,
                reportType: getReportTypeFromFilters(filters) as ReportType,
                sections: getReportSectionsFromFilters(filters) as SectionDTO_Input[]
            }

            const reportDTO: ReportDTO = yield call(ExplorerReportsService.createExplorerReportsPost, {
                requestBody: reportCreateDTO
            })
            const report: Report = {
                id: reportDTO.id,
                reportType: reportDTO.reportType as StateReportType,
                cohortId: reportDTO.cohortId,
                sections: reportDTO.sections.map((section: SectionDTO_Output) => ({
                    id: section.id,
                    queries: section.queries as ReportQueryFilters[]
                })),
                createdDttm: reportDTO.createdDttm,
                updatedDttm: reportDTO.updatedDttm,
                createdById: reportDTO.createdById,
                lastUpdatedBy: reportDTO.updatedById,
                organizationId: reportDTO.organizationId
            }
            yield put(reportsActions.reportAdd({ report: report }))
            yield put(notificationActions.success())
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(reportsActions.reportsLoadingSet({ loading: false }))
        }
    }
}
