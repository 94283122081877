import { common } from '@mui/material/colors'
import { createTheme, Theme } from '@mui/material/styles'

const theme: Theme = createTheme({
    palette: {
        primary: {
            main: '#002D72',
            contrastText: '#C1C6C8',
            light: '#33578E',
            dark: '#001F4F'
        },
        secondary: {
            main: '#8BB8E8',
            contrastText: '#555559',
            light: '#A2C6EC',
            dark: '#6180A2'
        },
        success: {
            main: '#92BE0A'
        },
        warning: {
            main: '#ff5b10'
        }
    }
})

export const platformTheme: Theme = createTheme({
    palette: theme.palette,
    typography: {
        fontFamily: ['Metropolis', 'sans-serif'].join(',')
    },
    shape: {
        borderRadius: 4
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1024,
            lg: 1280,
            xl: 1920
        }
    },
    components: {
        MuiTypography: {
            variants: [
                {
                    props: { className: 'page-header' },
                    style: {
                        height: '24px',
                        color: theme.palette.primary.main,
                        fontSize: '24px',
                        fontWeight: 'bold',
                        letterSpacing: '0',
                        lineHeight: '24px'
                    }
                },
                {
                    props: { className: 'grid-content' },
                    style: {
                        fontSize: '14px',
                        letterSpacing: 0,
                        lineHeight: '22px'
                    }
                }
            ]
        },
        MuiToolbar: {
            styleOverrides: {
                dense: {
                    minHeight: '56px'
                }
            }
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    color: '#555559'
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                dividers: {
                    borderColor: theme.palette.grey['300']
                }
            }
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'contained', color: 'primary' },
                    style: {
                        textTransform: 'none',
                        padding: '12px',
                        color: common.white,
                        fontSize: '16px'
                    }
                },
                {
                    props: { variant: 'text', color: 'primary' },
                    style: {
                        textTransform: 'none',
                        padding: '12px',
                        color: theme.palette.primary.main,
                        fontSize: '16px'
                    }
                }
            ]
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    position: 'absolute',
                    bottom: '-20px'
                }
            }
        }
    }
})
