import { Trans, t } from '@lingui/macro'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Box, Breadcrumbs, IconButton } from '@mui/material'
import { Auth0State } from '@om1/platform-authentication'
import { LightTooltip } from '@om1/platform-components/Tooltip'
import { Routes, toPath } from '@om1/platform-routing'
import { BreadcrumbLink } from '@om1/platform-ui-kit/src/components/Layout'
import { PlatformPermissions, RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { FunctionComponent, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { PatientCount } from '../../components/edit/base/PatientCount'
import { getReportNameFromType } from '../../components/edit/utils/report-utils'
import { createCohortReports } from '../../components/reports/CohortReports'
import { CohortStickyHeader } from '../../components/shared/CohortStickyHeader'
import { CohortRoutes } from '../../routes'
import { CohortListItem, ReportAnalyticsType, cohortEditActions } from '../../state'
import { CohortDashboardCohortCard } from '../CohortDashboardPage/CohortDashboardCohortCard'

const CohortReports = createCohortReports<{ auth0: Auth0State }>()

export type ReportsPageComponentProps = RoutedFrameworkComponentProps<
    { maxRetries: number; retryDelay: number },
    { cohortId: string | undefined; reportType: string | undefined },
    { cohort: CohortListItem; cohortLoading: boolean; permissions: string[] | undefined },
    typeof cohortEditActions,
    {}
>

export const ReportsPageComponent: FunctionComponent<ReportsPageComponentProps> = ({
    actions,
    routing,
    state: { cohort, cohortLoading, permissions }
}) => {
    const history = useHistory()

    useEffect(() => {
        actions.cohortGet({ query: {}, path: { cohortId: routing.pathParams.cohortId } })
    }, [actions, routing.pathParams.cohortId])

    useEffect(() => {
        if (cohort && !routing.pathParams.reportType) {
            history.push(`/cohort-builder/reports/${routing.pathParams.cohortId}/${ReportAnalyticsType.DEMOGRAPHICS}`)
        }
    }, [history, routing.pathParams.cohortId, routing.pathParams.reportType, cohort])

    const breadcrumbs = (
        <Box sx={{ marginBottom: '20px' }}>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <BreadcrumbLink
                    to={`/${CohortRoutes.COHORTBUILD}/${CohortRoutes.DASHBOARD}/${cohort.isSystem ? CohortRoutes.DATASETS : CohortRoutes.CUSTOM}/${
                        cohort.id
                    }`}
                    sx={{ fontWeight: '600', fontSize: '16px', color: '#012D72' }}
                >
                    <Trans>Dashboard</Trans>
                </BreadcrumbLink>
                {!cohort.isSystem ? (
                    <BreadcrumbLink
                        to={`/${CohortRoutes.COHORTBUILD}/${CohortRoutes.CUSTOM}/${cohort.id}`}
                        sx={{ fontWeight: '600', fontSize: '16px', color: '#012D72' }}
                    >
                        {cohort.name.charAt(0).toUpperCase() + cohort.name.slice(1).toLowerCase()}
                    </BreadcrumbLink>
                ) : (
                    <Box sx={{ fontWeight: '600', fontSize: '16px', color: '#707171' }}>
                        {cohort.name.charAt(0).toUpperCase() + cohort.name.slice(1).toLowerCase()}
                    </Box>
                )}
                <Box sx={{ fontWeight: '600', fontSize: '16px', color: '#707171' }}>
                    {routing.pathParams.reportType &&
                        (routing.pathParams.reportType === 'cohort-tree' ? t`Cohort` : getReportNameFromType(routing.pathParams.reportType!))}
                </Box>
            </Breadcrumbs>
        </Box>
    )

    let cardActions: JSX.Element[] = []

    cardActions.push(<PatientCount key={'count'} cohort={cohort} loading={cohortLoading} />)

    if (!cohort.isSystem && permissions?.includes(PlatformPermissions.UPDATE_COHORT)) {
        cardActions.push(
            <LightTooltip key={'editTooltip'} title={<Trans>Edit Cohort</Trans>} placement='top'>
                <IconButton
                    key='edit'
                    color='primary'
                    component={Link}
                    to={toPath(Routes.COHORTBUILD) + `/custom/${cohort.id}/edit`}
                    aria-label={t`Edit`}
                    data-testid='edit-cohort-button'
                    sx={{ backgroundColor: '#D6E4F2', color: '#012D72', borderRadius: '2px', margin: '5px', marginLeft: '20px' }}
                >
                    <EditOutlinedIcon />
                </IconButton>
            </LightTooltip>
        )
    }

    return (
        <>
            <Box display='flex' flexDirection='column' gap={1}>
                {breadcrumbs}
                <CohortStickyHeader>
                    <Box sx={{}}>
                        <CohortDashboardCohortCard cohort={cohort} actions={cardActions} excludeTitleRowFromCard={true} hidDetails={true} />
                    </Box>
                </CohortStickyHeader>

                {routing.pathParams.reportType && cohort && (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CohortReports cohort={cohort} />
                    </Box>
                )}
            </Box>
        </>
    )
}
