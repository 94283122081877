import { CohortConditionCreateDTO, CohortConditionDTO, CohortConditionPatchDTO } from '@om1/falcon-api'
import { DataFetchParams } from '@om1/platform-routing'
import { ActionsUnion, createAction } from '@om1/platform-utils'

export enum RWAConditionsActionTypes {
    INDEX_RWA_CONDITIONS = '@@rwaConditions/index-rwa-conditions',
    CREATE_RWA_CONDITION = '@@rwaConditions/create-rwa-condition',
    CREATE_RWA_CONDITION_SUCCESS = '@@rwaConditions/create-rwa-condition-success',
    DELETE_RWA_CONDITION = '@@rwaConditions/delete-rwa-condition',
    DELETE_RWA_CONDITION_SUCCESS = '@@rwaConditions/delete-rwa-condition-success',
    GET_RWA_CONDITION = '@@rwaConditions/get-rwa-condition',
    UPDATE_RWA_CONDITION = '@@rwaConditions/update-rwa-condition',
    SET_RWA_INSIGHTS_REPORTS = '@@rwaConditions/set-rwa-conditions',
    SET_RWA_INSIGHTS_REPORTS_LOADING = '@@rwaConditions/set-rwa-conditions-loading'
}

export const rwaConditionsActions = {
    getRwaConditions: (payload: DataFetchParams<{}, {}>) => createAction(RWAConditionsActionTypes.INDEX_RWA_CONDITIONS, payload),
    createRwaCondition: (payload: { condition: CohortConditionCreateDTO }) => createAction(RWAConditionsActionTypes.CREATE_RWA_CONDITION, payload),
    deleteRwaCondition: (payload: { id: string }) => createAction(RWAConditionsActionTypes.DELETE_RWA_CONDITION, payload),
    getRwaCondition: (payload: { id: string }) => createAction(RWAConditionsActionTypes.GET_RWA_CONDITION, payload),
    updateRwaCondition: (payload: { condition: CohortConditionPatchDTO }) => createAction(RWAConditionsActionTypes.UPDATE_RWA_CONDITION, payload),
    setRwaConditions: (payload: { conditions: CohortConditionDTO[] }) => createAction(RWAConditionsActionTypes.SET_RWA_INSIGHTS_REPORTS, payload),
    createRwaConditionSuccess: (payload: CohortConditionDTO) => createAction(RWAConditionsActionTypes.CREATE_RWA_CONDITION_SUCCESS, payload),
    deleteRwaConditionSuccess: (payload: { id: string }) => createAction(RWAConditionsActionTypes.DELETE_RWA_CONDITION_SUCCESS, payload),
    setRwaConditionsLoading: (payload: { loading: boolean }) => createAction(RWAConditionsActionTypes.SET_RWA_INSIGHTS_REPORTS_LOADING, payload)
}

export type RWAConditionsActions = ActionsUnion<typeof rwaConditionsActions>
