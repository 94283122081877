import { InsightsReportCreateDTO, InsightsReportDTO, InsightsReportPatchDTO } from '@om1/falcon-api'
import { ActionsUnion, createAction } from '@om1/platform-utils'

export enum RWAInsightsReportsActionTypes {
    INDEX_RWA_INSIGHTS_REPORTS = '@@rwaInsightsReports/index-rwa-insights-reports',
    CREATE_RWA_INSIGHTS_REPORT = '@@rwaInsightsReports/create-rwa-insights-report',
    DELETE_RWA_INSIGHTS_REPORT = '@@rwaInsightsReports/delete-rwa-insights-report',
    GET_RWA_INSIGHTS_REPORT = '@@rwaInsightsReports/get-rwa-insights-report',
    UPDATE_RWA_INSIGHTS_REPORT = '@@rwaInsightsReports/update-rwa-insights-report',
    SET_RWA_INSIGHTS_REPORTS = '@@rwaInsightsReports/set-rwa-insights-reports',
    CREATE_RWA_INSIGHTS_REPORT_SUCCESS = '@@rwaInsightsReports/create-rwa-insights-report-success',
    DELETE_RWA_INSIGHTS_REPORT_SUCCESS = '@@rwaInsightsReports/delete-rwa-insights-report-success',
    SET_RWA_INSIGHTS_REPORTS_LOADING = '@@rwaInsightsReports/set-rwa-insights-reports-loading'
}

export const rwaInsightsReportActions = {
    getRwaInsightsReports: () => createAction(RWAInsightsReportsActionTypes.INDEX_RWA_INSIGHTS_REPORTS),
    createRwaInsightsReport: (payload: { report: InsightsReportCreateDTO }) =>
        createAction(RWAInsightsReportsActionTypes.CREATE_RWA_INSIGHTS_REPORT, payload),
    deleteRwaInsightsReport: (payload: { id: string }) => createAction(RWAInsightsReportsActionTypes.DELETE_RWA_INSIGHTS_REPORT, payload),
    getRwaInsightsReport: (payload: { id: string }) => createAction(RWAInsightsReportsActionTypes.GET_RWA_INSIGHTS_REPORT, payload),
    updateRwaInsightsReport: (payload: { report: InsightsReportPatchDTO }) =>
        createAction(RWAInsightsReportsActionTypes.UPDATE_RWA_INSIGHTS_REPORT, payload),
    setRwaInsightsReports: (payload: { reports: InsightsReportDTO[] }) =>
        createAction(RWAInsightsReportsActionTypes.SET_RWA_INSIGHTS_REPORTS, payload),
    createRwaInsightsReportSuccess: (payload: InsightsReportDTO) =>
        createAction(RWAInsightsReportsActionTypes.CREATE_RWA_INSIGHTS_REPORT_SUCCESS, payload),
    deleteRwaInsightsReportSuccess: (payload: { id: string }) =>
        createAction(RWAInsightsReportsActionTypes.DELETE_RWA_INSIGHTS_REPORT_SUCCESS, payload),
    setRwaInsightsReportsLoading: (payload: { loading: boolean }) =>
        createAction(RWAInsightsReportsActionTypes.SET_RWA_INSIGHTS_REPORTS_LOADING, payload)
}

export type RWAInsightsReportsActions = ActionsUnion<typeof rwaInsightsReportActions>
