import { FalconPaginationMeta } from '@om1/falcon-api'
import { DataFetchParams } from '@om1/platform-routing'
import { TrackingPlanEvents, track, trackingEvent } from '@om1/platform-tracking'
import { ActionsUnion, createAction } from '@om1/platform-utils'
import { ReportAnalyticsType } from '../analytics'
import { CohortListItem } from '../cohort-state'
import { CohortListQueryParams, CohortOwner } from './cohort-list-state'

export enum CohortListActionTypes {
    LOADING_SET = '@@cohort/list/loading-set',
    COHORTS_ACCESS = '@@cohort/list/get',
    COHORTS_SET = '@@cohort/list/set',
    COHORT_SET_SIZE = '@@cohort/list/set-one',
    COHORT_SET_SUMMARY_REPORT_TASK_ID = '@@cohort/list/set-summary-report-task-id',
    COHORTS_ADD = '@@cohort/list/add',
    COHORT_DUPLICATE = '@@cohort/list/duplicate'
}

export const cohortListActions = {
    loadingSet: (payload: { loading: boolean }) => createAction(CohortListActionTypes.LOADING_SET, payload),
    cohortsGetUserCohorts: (params: DataFetchParams<CohortListQueryParams, { cohortId: string }>) =>
        createAction(CohortListActionTypes.COHORTS_ACCESS, { ...params.query.USER, owner: CohortOwner.User }),
    cohortsDashboardCohorts: (params: DataFetchParams<{ reportAnalyticsType: ReportAnalyticsType }, { cohortId: string }>) =>
        createAction(CohortListActionTypes.COHORTS_ACCESS, { ...params, owner: undefined }),
    cohortDuplicate: (params: { cohortId: string }) =>
        track(
            createAction(CohortListActionTypes.COHORT_DUPLICATE, params),
            trackingEvent(TrackingPlanEvents.COHORT_DUPLICATE, { cohortId: params.cohortId })
        ),
    cohortsSet: (payload: { cohorts: CohortListItem[]; meta: FalconPaginationMeta; owner: CohortOwner }) =>
        createAction(CohortListActionTypes.COHORTS_SET, payload),
    cohortSetSize: (payload: { cohortId: string; cohortSize?: number }) => createAction(CohortListActionTypes.COHORT_SET_SIZE, payload),
    cohortSetSummaryReportTaskId: (payload: { cohortId: string; summaryReportTaskId: string | null }) =>
        createAction(CohortListActionTypes.COHORT_SET_SUMMARY_REPORT_TASK_ID, payload),
    cohortsAdd: (payload: { cohorts: CohortListItem[]; owner: CohortOwner }) => createAction(CohortListActionTypes.COHORTS_ADD, payload),
    cohortsGetSystemCohorts: (params: DataFetchParams<CohortListQueryParams, {}>) =>
        createAction(CohortListActionTypes.COHORTS_ACCESS, { ...params.query.SYSTEM, owner: CohortOwner.System }),
    cohortsGetAllCohorts: () => createAction(CohortListActionTypes.COHORTS_ACCESS, { owner: undefined })
}

export type CohortListActions = ActionsUnion<typeof cohortListActions>
