import { t } from '@lingui/macro'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { Box, Paper } from '@mui/material'
import { DragPreview, DragPreviewRenderer, useDrag } from '@react-aria/dnd'
import React, { useMemo, useRef } from 'react'
import { CohortNodeSourceAddress, CriteriaType, CriterionNode, cohortBlocksEditActions } from '../../../state'
import { CriteriaTypeIcon } from '../../shared/CriteriaTypeIcon'
import { CriteriaTypeLabel } from '../../shared/CriteriaTypeLabel'

export interface CohortCriterionDragHandleProps {
    node: CriterionNode
    actions: typeof cohortBlocksEditActions
}

/**
 * The primary representation of a single criterion. For example, Medication or Diagnosis. Contains the criterion type,
 * text summary, as well as interactive elements for editing / deleting / moving.
 */
export const CohortCriterionDragHandle: React.FunctionComponent<CohortCriterionDragHandleProps> = (props) => {
    const { node, actions } = props
    const ref = useRef<DragPreviewRenderer>(null)

    const { dragProps } = useDrag({
        getAllowedDropOperations() {
            return ['move', 'copy']
        },
        onDragStart() {
            // Nodes that already have "reference" and are related can not be related again
            // PatientAttributes nodes can not be related
            actions.cohortDragStart({
                allowRelate: !node.reference && node.type !== CriteriaType.PatientAttributes && node.type !== CriteriaType.ObservationPeriod
            })
        },
        onDragEnd() {
            actions.cohortDragEnd()
        },
        getItems() {
            const address: CohortNodeSourceAddress = { nodeId: node.id }
            return [{ custom: JSON.stringify(address) }]
        },
        preview: ref
    })

    // Simple OS detection to prompt the user for their OS browser's 'copy' key modifier.
    // TODO: Lingui i18n strings here are defined separately as string interpolation does not work in the cohort-module.
    const copyKeyPrompt = useMemo(() => {
        let key = t`Control`
        let os = navigator.userAgent
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        if (os && os.search('Mac') > -1) {
            key = t`Option`
        }
        const prefix = t`Hold`
        const suffix = t`to Copy`
        return (
            <>
                {prefix} <strong>{key}</strong> {suffix}
            </>
        )
    }, [])

    return (
        <>
            <Box
                // Drag and drop
                role='button'
                tabIndex={0}
                aria-label={t`Drag`}
                {...dragProps}
                // Styles
                display='flex'
                alignItems='center'
                justifyContent='center'
                width='32px'
                height='32px'
                color='grey.500'
                sx={{ cursor: 'grab' }}
            >
                <DragIndicatorIcon />
            </Box>

            <DragPreview ref={ref}>
                {() => (
                    <div>
                        <Paper elevation={1}>
                            <Box p={1} bgcolor='grey.100'>
                                <Box display='flex' alignItems='center' gap={0.5} color='primary.dark'>
                                    <CriteriaTypeIcon criteriaType={node.type} />
                                    <CriteriaTypeLabel criteriaType={node.type} />
                                </Box>
                            </Box>
                        </Paper>
                        <Box display='flex' justifyContent='center' mt={1}>
                            <Box px={1} py={0.25} border={1} bgcolor='white' borderRadius={8} sx={{ borderStyle: 'dashed' }}>
                                <small>{copyKeyPrompt}</small>
                            </Box>
                        </Box>
                    </div>
                )}
            </DragPreview>
        </>
    )
}
