import { DeliveredDatabaseDTO, DeliveryDTO, OrganizationMetadataDTO, PaginateMeta, RegistryTypeDTO } from '@om1/falcon-api'
import { CohortDTO, TaskStatusEnum, RegistryType } from '@om1/falcon-api/'

export enum CohortOwner {
    System = 'SYSTEM',
    User = 'USER'
}

export interface DataDeliveryState {
    edit: {
        ui: {
            cohortsLoading: boolean
            dataDeliveryLoading: boolean
            deliveredDatabasesLoading: boolean
        }
        cohorts: Cohorts
        cohortsPaginationMeta: PaginateMeta
        deliveredDatabases: DeliveredDatabaseDTO[]
        deliveredDatabasesPaginationMeta: PaginateMeta
        executingTaskIds: string[]
    }
    list: {
        data: DeliveryDTO[]
        paginationMeta: PaginateMeta
        ui: {
            loading: boolean
        }
    }
    organizationMetadata: OrganizationMetadataState
    registryTypes: RegistryTypeState
}

export const initialDataDeliveryState: DataDeliveryState = {
    edit: {
        ui: {
            cohortsLoading: false,
            deliveredDatabasesLoading: false,
            dataDeliveryLoading: false
        },
        cohorts: {
            [CohortOwner.System]: [],
            [CohortOwner.User]: []
        },
        cohortsPaginationMeta: {
            currentPage: 1,
            itemsPerPage: 10,
            totalItems: 0,
            totalPages: 0
        },
        deliveredDatabases: [],
        deliveredDatabasesPaginationMeta: {
            currentPage: 1,
            itemsPerPage: 10,
            totalItems: 0,
            totalPages: 0
        },
        executingTaskIds: []
    },
    list: {
        data: [],
        paginationMeta: { currentPage: 1, itemsPerPage: 1, totalItems: 0, totalPages: 0 },
        ui: { loading: false }
    },
    organizationMetadata: {
        metadata: {
            id: '',
            organizationId: '',
            deliveryShareName: null,
            deliveryDatabase: null
        },
        isLoading: true
    },
    registryTypes: {
        isLoading: false,
        registryTypes: []
    }
}

export const IN_PROGRESS_TASK_STATUSES: TaskStatusEnum[] = ['PENDING', 'STARTED', 'RETRY']

export type DataDeliveryCohortCreate = {
    id?: string | null
    cohortId: string
    registryType: RegistryType
    sampleSize?: number | null
}

export enum DataDeliveryTaskStatus {
    New = 'NEW',
    Candidate = 'CANDIDATE',
    Approved = 'APPROVED',
    Published = 'PUBLISHED'
}

export type Cohorts = {
    [key in CohortOwner]: CohortDTO[]
}

// DELIVERED DATABASES
export type DeliveredDatabase = {
    id: string
    number: number
    name: string
}

export interface OrganizationMetadataState {
    metadata: OrganizationMetadataDTO
    isLoading: boolean
}

export interface RegistryTypeState {
    isLoading: boolean
    registryTypes: RegistryTypeDTO[]
}
