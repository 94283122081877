import { DataDeliveryAdminOrganizationMetadataService, OrganizationMetadataCreateDTO, OrganizationMetadataDTO } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { notificationActions } from '@om1/platform-notifications'
import { call, put } from 'redux-saga/effects'
import { dataDeliveryActions } from '../state/data-delivery'

const createOrganizationMetadataDTO = (action: ReturnType<typeof dataDeliveryActions.createOrganizationMetadata>): OrganizationMetadataCreateDTO => ({
    organizationId: action.payload.organizationId,
    deliveryDatabase: action.payload.deliveryDatabase,
    deliveryShareName: action.payload.deliveryShareName
})

export default function createCreateOrganizationMetadataSaga() {
    return function* (action: ReturnType<typeof dataDeliveryActions.createOrganizationMetadata>) {
        yield put(dataDeliveryActions.setOrganizationMetadataLoading({ loading: true }))
        try {
            const organizationMetadataDTO: OrganizationMetadataDTO = yield call(
                DataDeliveryAdminOrganizationMetadataService.createDataDeliveryOrganizationMetadataPost,
                {
                    requestBody: createOrganizationMetadataDTO(action)
                }
            )
            yield put(notificationActions.success())
            yield put(dataDeliveryActions.setOrganizationMetadata({ data: organizationMetadataDTO }))
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(dataDeliveryActions.setOrganizationMetadataLoading({ loading: false }))
        }
    }
}
