import { RWAInsightsReportsActions, RWAInsightsReportsActionTypes } from './rwa-insights-reports-actions'
import { initialRWAInsightsReportsState, RWAInsightsReportsState } from './rwa-insights-reports-state'

export function rwaInsightsReportsReducer(
    previousState: RWAInsightsReportsState | undefined,
    action: RWAInsightsReportsActions
): RWAInsightsReportsState {
    const state = previousState || initialRWAInsightsReportsState
    switch (action.type) {
        case RWAInsightsReportsActionTypes.SET_RWA_INSIGHTS_REPORTS:
            return { ...state, insightReports: action.payload.reports }
        case RWAInsightsReportsActionTypes.CREATE_RWA_INSIGHTS_REPORT_SUCCESS:
            return { ...state, insightReports: [...state.insightReports, action.payload] }
        case RWAInsightsReportsActionTypes.DELETE_RWA_INSIGHTS_REPORT_SUCCESS:
            return { ...state, insightReports: state.insightReports.filter((r) => r.id !== action.payload.id) }
        case RWAInsightsReportsActionTypes.SET_RWA_INSIGHTS_REPORTS_LOADING:
            return { ...state, loading: action.payload.loading }
        default: {
            return state
        }
    }
}
