import { Box, Divider } from '@mui/material'
import React, { PropsWithChildren } from 'react'

export const CohortStickyHeader: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
    return (
        <Box position='sticky' top={56} bgcolor='#f5f6f6' zIndex={10} marginX={-7.5}>
            <Divider />
            <Box display='flex' flexDirection='column' gap={2} marginX={7.5} paddingY={2}>
                {children}
            </Box>
            <Divider />
        </Box>
    )
}
