import { Trans } from '@lingui/macro'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

export interface DataDeliveryExecutionConfirmationDialogProps {
    onCancel: () => void
    onConfirm: () => void
    action: string
}

export const DataDeliveryExecutionConfirmationDialog = ({ action, onCancel, onConfirm }: DataDeliveryExecutionConfirmationDialogProps) => {
    const handleCancel = () => onCancel()
    const handleConfirm = () => onConfirm()
    return (
        <Dialog open maxWidth='sm' fullWidth onClose={handleCancel}>
            <DialogTitle>
                {action} <Trans>Data Delivery</Trans>
            </DialogTitle>
            <DialogContent>
                <Box paddingTop={2}>
                    <Trans>Are you sure you want to perform this action?</Trans>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant='text' color='primary' onClick={handleCancel}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button variant='contained' onClick={handleConfirm}>
                    <Trans>Execute</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    )
}
