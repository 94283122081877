import { notificationActions } from '@om1/platform-notifications'
import { connectRedux } from '@om1/platform-utils'
import { ReactNode } from 'react'
import { SeeMoreToastComponent, SeeMoreToastComponentProps } from './SeeMoreToastComponent'

export function createSeeMoreToast() {
    return connectRedux(SeeMoreToastComponent, () => ({}), notificationActions)
}

export const renderSeeMoreToast = (props: SeeMoreToastComponentProps['props']): ReactNode => {
    const Component = createSeeMoreToast()
    return <Component {...props} />
}

export type SeeMoreToast = ReturnType<typeof createSeeMoreToast>
