/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Trans } from '@lingui/macro'
import { PlatformRoute, toRoutingConfigObject } from '@om1/cohort-module'
import { ContentContainerStyle, ContentPadding, createPages } from '@om1/platform-ui-kit/src/components/Layout'
import { PlatformPermissions } from '@om1/platform-utils'
import { FunctionComponent } from 'react'
import { Redirect, Switch, useRouteMatch } from 'react-router-dom'
import { PlatformState } from '../../platform-state'
import { createHomePageProfileComponent } from './HomePageProfile'

const HomePageProfile = createHomePageProfileComponent<PlatformState>()

export const HomePage: FunctionComponent = () => {
    const match = useRouteMatch()

    if (!match) {
        return null
    }

    const { path: matchPathValue, url: matchUrlValue } = match

    const routes: PlatformRoute[] = [
        {
            route: '/',
            name: <Trans>HomePage</Trans>,
            component: HomePageProfile,
            permissions: [PlatformPermissions.ADMIN_EXPLORER]
        }
    ]

    const routingConfigObject = toRoutingConfigObject(routes, matchPathValue, matchUrlValue)

    const pages = createPages(routingConfigObject, matchUrlValue)

    return (
        <ContentContainerStyle>
            <ContentPadding padX padY>
                <Switch>
                    {pages}
                    <Redirect key='baseRedirect' to={routingConfigObject[0].url} />
                </Switch>
            </ContentPadding>
        </ContentContainerStyle>
    )
}
