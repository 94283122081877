import { Trans } from '@lingui/macro'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { FunctionComponent, useState } from 'react'

type Order = 'asc' | 'desc'

type ChartData = Record<string, number>

function descendingComparator(a: [string, number], b: [string, number], orderBy: keyof ChartData) {
    if (orderBy === 'count') {
        return b[1] - a[1]
    } else {
        return b[0].localeCompare(a[0])
    }
}

function getComparator(order: Order, orderBy: keyof ChartData): (a: [string, number], b: [string, number]) => number {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array: [string, number][], comparator: (a: [string, number], b: [string, number]) => number): [string, number][] {
    const stabilizedThis = array.map((el, index) => [el, index] as [[string, number], number])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

interface BasicTableProps {
    chartData: ChartData
    valueLabel?: string
}

export const BasicTable: FunctionComponent<BasicTableProps> = (props) => {
    const { chartData } = props
    const [order, setOrder] = useState<Order>(props.valueLabel ? 'asc' : 'desc')
    const [orderBy, setOrderBy] = useState<keyof ChartData>(props.valueLabel ? props.valueLabel : 'count')

    const handleRequestSort = (property: keyof ChartData) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }
    const sortedData = stableSort(Object.entries(chartData), getComparator(order, orderBy))

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'value'}
                                    direction={orderBy === 'value' ? order : 'asc'}
                                    sx={{ fontWeight: 'bold', color: '#012D72', fontSize: '18px' }}
                                    onClick={() => handleRequestSort('value')}
                                >
                                    {props.valueLabel || <Trans>Value</Trans>}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align='right'>
                                <TableSortLabel
                                    active={orderBy === 'count'}
                                    direction={orderBy === 'count' ? order : 'asc'}
                                    onClick={() => handleRequestSort('count')}
                                    sx={{ fontWeight: 'bold', color: '#012D72', fontSize: '18px' }}
                                >
                                    <Trans>Count</Trans>
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.map(([value, count]) => (
                            <TableRow key={value}>
                                <TableCell component='th' scope='row'>
                                    {value}
                                </TableCell>
                                <TableCell align='right'>{count}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default BasicTable
