import { yupResolver } from '@hookform/resolvers/yup'
import { Trans, t } from '@lingui/macro'
import CloseIcon from '@mui/icons-material/Close'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { FrameworkComponentProps } from '@om1/platform-utils'
import { addDays, differenceInCalendarDays } from 'date-fns'
import { ReactNode, useMemo } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { AnyObject } from 'yup/lib/types'
import {
    CountDistinctQualifier,
    CriteriaType,
    CriterionNode,
    DateQualifier,
    DateRangeInclusion,
    DateRangeIntervalUnit,
    DateRangeOperator,
    DateRelationMetadata,
    DateWindowFilterDTO,
    FilterType,
    QualifierType,
    QueryFilterCondition,
    RelativeDateFilterDTO,
    cohortBlocksEditActions
} from '../../../state'
import { DEFAULT_COUNT_DISTINCT_QUALIFIER } from '../utils/constants'
import { DTODateStringToDate, dateToDTODateString, getDateRangeInclusion, getLargestInterval, translateRangeInclusion } from '../utils/date-utils'
import { findQualifierByType } from '../utils/finders'
import { ObservationPeriodFieldMapper } from '../utils/ref-field-mappers'

export enum DateQualifierRangeInclusion {
    MoreThan = 'More Than',
    LessThan = 'Less Than',
    Exactly = 'Exactly',
    AtLeast = 'At Least',
    AtMost = 'At Most',
    Between = 'Between'
}

interface RecencyEditFormValues {
    interval: number // x in x days
    intervalUnit: string // day month year
    rangeOperator: string // Before/After/Before or After/Between
    rangeInclusion: string // More than, less than, equal, at least, at most
    rangeStart: Date
    rangeEnd?: Date
}

export type RecencyEditDialogComponentProps = FrameworkComponentProps<{ criteria: CriterionNode }, typeof cohortBlocksEditActions>
export const RecencyEditDialogComponent = ({ state, actions }: RecencyEditDialogComponentProps) => {
    const { criteria } = state
    const validationSchema = Yup.object().shape({
        interval: Yup.number().when(
            'rangeInclusion',
            (rangeInclusion: string, schema: Yup.NumberSchema<number | undefined, AnyObject, number | undefined>) => {
                return rangeInclusion !== DateRangeInclusion.Between
                    ? schema.typeError(t`Must be a number`).min(1, t`Must be 1 or greater`)
                    : Yup.string().notRequired()
            }
        ),
        intervalUnit: Yup.string(),
        rangeOperator: Yup.string(),
        rangeInclusion: Yup.string(),
        rangeStart: Yup.date().typeError(t`Must be a valid date`),
        rangeEnd: Yup.date().when('rangeInclusion', {
            is: (rangeInclusion: string) => {
                return rangeInclusion === DateRangeInclusion.Between
            },
            then: (schema) => {
                return schema.typeError(t`Must be a valid date`).min(Yup.ref('rangeStart'), t`Must be after range start`)
            },
            otherwise: () => Yup.string().notRequired()
        })
    })

    const initialValue = useMemo(() => {
        const initialValue: RecencyEditFormValues = {
            interval: 1,
            intervalUnit: DateRangeIntervalUnit.Day,
            rangeOperator: DateRangeOperator.After,
            rangeInclusion: DateQualifierRangeInclusion.AtLeast,
            rangeStart: new Date(),
            rangeEnd: new Date()
        }
        const existingDateQualifier = findQualifierByType<DateQualifier>(criteria.qualifiers, QualifierType.DateQualifierDTO)
        if (existingDateQualifier) {
            // We need to set defaults for the interval and rangeOperator initial values if the value coming in is a Between value.
            // If we don't change them, the user will see a blank value for rangeOperator and a potentially large number (days between selected dates)
            // for interval when changing from a Between inclusion to another inclusion value. This ensures that the form has a default value when
            // selecting a value other than Between for rangeInclusion.
            initialValue.interval =
                existingDateQualifier.dateRangeOperator === DateRangeOperator.Between ? 1 : getLargestInterval(existingDateQualifier)
            initialValue.intervalUnit = existingDateQualifier.intervalUnitFromReferenceDate
            initialValue.rangeOperator =
                existingDateQualifier.dateRangeOperator === DateRangeOperator.Between
                    ? DateRangeOperator.After
                    : existingDateQualifier.dateRangeOperator
            initialValue.rangeInclusion =
                existingDateQualifier.dateRangeOperator === DateRangeOperator.Between
                    ? DateRangeInclusion.Between
                    : getDateRangeInclusion(existingDateQualifier)
            initialValue.rangeStart = DTODateStringToDate(existingDateQualifier.referenceDate)
            initialValue.rangeEnd = getInitialRangeEnd(existingDateQualifier)

            if (
                criteria.type === CriteriaType.ObservationPeriod &&
                existingDateQualifier.dateRangeOperator === DateRangeOperator.Between &&
                existingDateQualifier.intervalIsInclusive
            ) {
                initialValue.rangeOperator = DateRangeOperator.Between
                initialValue.rangeInclusion = DateRangeInclusion.Between
                initialValue.rangeEnd = getInitialRangeEnd({ ...existingDateQualifier, dateRangeOperator: DateRangeOperator.Between })
            }
        }
        return initialValue
    }, [criteria])

    const {
        control,
        handleSubmit,
        register,
        watch,
        formState: { errors }
    } = useForm<RecencyEditFormValues>({
        defaultValues: {
            interval: initialValue.interval,
            intervalUnit: initialValue.intervalUnit,
            rangeOperator: initialValue.rangeOperator,
            rangeInclusion: initialValue.rangeInclusion,
            rangeStart: initialValue.rangeStart,
            rangeEnd: initialValue.rangeEnd
        },
        resolver: yupResolver(validationSchema)
    })

    const onCancel = () => {
        actions.recencyEditDialogTrigger({})
    }

    const onSubmit: SubmitHandler<RecencyEditFormValues> = (recencyValues: RecencyEditFormValues) => {
        let qualifiers = [...criteria.qualifiers]
        const newQualifier: DateQualifier = formValuesToDateQualifier(recencyValues, criteria.type)

        let filters: Exclude<QueryFilterCondition, RelativeDateFilterDTO>[] | undefined = undefined
        if (criteria.type === CriteriaType.ObservationPeriod) {
            // Time range for Observation Period -- need to update filter as well
            const filter = [...criteria.filters][0]
            const newFilter: DateWindowFilterDTO = {
                blockId: 0,
                type: FilterType.DateWindowFilterDTO,
                table: filter.table,
                field: filter.field,
                values: filter.values,
                operator: filter.operator,
                startDateField: ObservationPeriodFieldMapper.dateField.start!,
                endDateField: ObservationPeriodFieldMapper.dateField.end!,
                qualifiers: []
            }
            filters = [newFilter]
        }

        qualifiers = qualifiers.filter((q) => q.type !== QualifierType.DateQualifierDTO)
        qualifiers.push(newQualifier)

        actions.recencyEditDialogTrigger({})
        if (filters) {
            actions.criteriaUpdate({ target: { nodeId: criteria.id }, qualifiers, filters })
        } else {
            actions.criteriaUpdate({ target: { nodeId: criteria.id }, qualifiers })
        }
    }

    const rangeInclusionWatcher = watch('rangeInclusion')

    return (
        <Dialog open maxWidth='lg' fullWidth>
            <DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={onCancel}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Box paddingTop={2} display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                        <Box flexShrink={0} marginRight={1}>
                            <RecencyCriteriaDateLabel criteria={criteria} />
                        </Box>
                        <Controller
                            control={control}
                            name='rangeInclusion'
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    id='range-inclusion'
                                    labelId='range-inclusion-label'
                                    sx={{ flex: 1, marginRight: 1 }}
                                    required
                                    value={value}
                                    onChange={onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}
                                >
                                    <MenuItem value={DateQualifierRangeInclusion.AtLeast}>
                                        <Trans>At Least</Trans>
                                    </MenuItem>
                                    <MenuItem value={DateQualifierRangeInclusion.AtMost}>
                                        <Trans>At Most</Trans>
                                    </MenuItem>
                                    <MenuItem value={DateQualifierRangeInclusion.Exactly}>
                                        <Trans>Exactly</Trans>
                                    </MenuItem>
                                    <MenuItem value={DateQualifierRangeInclusion.LessThan}>
                                        <Trans>Less Than</Trans>
                                    </MenuItem>
                                    <MenuItem value={DateQualifierRangeInclusion.MoreThan}>
                                        <Trans>More Than</Trans>
                                    </MenuItem>
                                    <MenuItem value={DateQualifierRangeInclusion.Between}>
                                        <Trans>Time Range</Trans>
                                    </MenuItem>
                                </Select>
                            )}
                        />
                        {rangeInclusionWatcher !== DateQualifierRangeInclusion.Between ? (
                            <>
                                <TextField
                                    id='interval'
                                    type='number'
                                    {...register('interval')}
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    sx={{ flex: 1, marginRight: 1 }}
                                    error={!!errors['interval']}
                                    helperText={errors['interval']?.message}
                                />
                                <Controller
                                    control={control}
                                    name='intervalUnit'
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            id='interval-unit'
                                            labelId='interval-unit-label'
                                            required
                                            sx={{ flex: 1, marginRight: 1 }}
                                            value={value}
                                            onChange={onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}
                                        >
                                            <MenuItem value={DateRangeIntervalUnit.Day}>
                                                <Trans>Days</Trans>
                                            </MenuItem>
                                            <MenuItem value={DateRangeIntervalUnit.Month}>
                                                <Trans>Months</Trans>
                                            </MenuItem>
                                            <MenuItem value={DateRangeIntervalUnit.Year}>
                                                <Trans>Years</Trans>
                                            </MenuItem>
                                        </Select>
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name='rangeOperator'
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            id='range-operator'
                                            labelId='range-operator-label'
                                            required
                                            sx={{ flex: 1, marginRight: 1 }}
                                            value={value}
                                            onChange={onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}
                                        >
                                            <MenuItem value={DateRangeOperator.Before}>
                                                <Trans>Before</Trans>
                                            </MenuItem>
                                            <MenuItem value={DateRangeOperator.After}>
                                                <Trans>After</Trans>
                                            </MenuItem>
                                            <MenuItem value={DateRangeOperator.BeforeOrAfter}>
                                                <Trans>Before or After</Trans>
                                            </MenuItem>
                                        </Select>
                                    )}
                                />
                            </>
                        ) : null}
                        <Controller
                            control={control}
                            name='rangeStart'
                            render={({ field: { onChange, ...restField } }) => (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        sx={{ marginRight: 1, flex: 1 }}
                                        label={rangeInclusionWatcher === DateQualifierRangeInclusion.Between ? t`From` : ''}
                                        defaultValue={initialValue.rangeStart}
                                        {...restField}
                                        onChange={(e) => {
                                            if (e) {
                                                // TODO: can we fix this typing?
                                                onChange(e)
                                            }
                                        }}
                                        slotProps={{
                                            textField: {
                                                error: !!errors['rangeStart'],
                                                helperText: errors['rangeStart']?.message
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                        {rangeInclusionWatcher === DateQualifierRangeInclusion.Between ? (
                            <Controller
                                control={control}
                                name='rangeEnd'
                                render={({ field: { onChange, ...restField } }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label={t`To`}
                                            sx={{ flex: 1 }}
                                            defaultValue={initialValue.rangeEnd}
                                            {...restField}
                                            onChange={(e) => {
                                                if (e) {
                                                    // TODO: can we fix this typing?
                                                    onChange(e)
                                                }
                                            }}
                                            slotProps={{
                                                textField: {
                                                    error: !!errors['rangeEnd'],
                                                    helperText: errors['rangeEnd']?.message
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                        ) : null}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' color='primary' onClick={onCancel}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button variant='contained' type='submit'>
                        <Trans>Save</Trans>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

interface RecencyCriteriaDateLabelProps {
    criteria: CriterionNode
}
export const RecencyCriteriaDateLabel = ({ criteria }: RecencyCriteriaDateLabelProps) => {
    const dateLabel = useMemo(() => {
        let label = t`First Encounter`
        if (criteria.dateField === 'first') {
            if (criteria.type === CriteriaType.Diagnosis) {
                label = t`Initial Diagnosis`
            } else if (criteria.type === CriteriaType.Medication) {
                label = t`Medication Initiation`
            }
        } else if (criteria.dateField === 'last') {
            label = t`Latest Encounter`
        } else if (criteria.type === CriteriaType.ObservationPeriod) {
            label = t`Observation Period starts`
        } else {
            const countDistinctQualifier = findQualifierByType<CountDistinctQualifier>(criteria.qualifiers, QualifierType.CountDistinctQualifierDTO)
            const count = countDistinctQualifier?.value || DEFAULT_COUNT_DISTINCT_QUALIFIER.value
            // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
            label = `At least ${count} Encounter(s)`
        }

        return label
    }, [criteria])
    return <>{dateLabel}</>
}

export function getInitialRangeEnd({ dateRangeOperator, referenceDate, intervalEndFromReferenceDate }: DateQualifier) {
    if (dateRangeOperator === DateRangeOperator.Between) {
        return addDays(DTODateStringToDate(referenceDate), intervalEndFromReferenceDate || 0)
    } else {
        // Always give the range end a value to prevent empty states
        return DTODateStringToDate(referenceDate)
    }
}

function formValuesToDateQualifier(formValues: RecencyEditFormValues, type: CriteriaType): DateQualifier {
    const { interval, intervalUnit, rangeInclusion, rangeOperator, rangeStart, rangeEnd } = formValues
    let qualifier: DateQualifier = {
        type: QualifierType.DateQualifierDTO,
        dateRangeOperator: rangeOperator as DateRelationMetadata['dateRangeOperator'],
        intervalStartFromReferenceDate: 0,
        intervalEndFromReferenceDate: 0,
        intervalUnitFromReferenceDate: intervalUnit as DateRangeIntervalUnit,
        referenceDate: dateToDTODateString(rangeStart),
        intervalIsInclusive: true
    }

    if (rangeInclusion === DateRangeInclusion.Between) {
        const intervalEndFromReferenceDate = differenceInCalendarDays(rangeEnd!, rangeStart)
        qualifier = {
            ...qualifier,
            intervalStartFromReferenceDate: 0,
            intervalUnitFromReferenceDate: DateRangeIntervalUnit.Day,
            intervalEndFromReferenceDate,
            dateRangeOperator: DateRangeOperator.Between
        }
    } else {
        const diff = translateRangeInclusion(rangeInclusion, interval)
        qualifier = {
            ...qualifier,
            ...diff
        }
    }

    return qualifier
}
