import { t } from '@lingui/macro'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { Card, CardActions, CardContent, Grid, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import EditableParagraph from '@om1/platform-components/EditableParagraph'
import { FunctionComponent, MouseEventHandler } from 'react'
import { CohortListItem } from '../../state'
import { CohortDashboardCohortCardDetails } from './CohortDashboardCohortCardDetails'
import { CohortListItemToTitle } from './DashboardCohortCard'

export interface CohortDashboardCohortCardProps {
    cohort?: CohortListItem
    onSettingsGearClick?: MouseEventHandler<HTMLButtonElement> | undefined
    actions?: JSX.Element[]
    hidDetails?: boolean
    excludeTitleRowFromCard?: boolean
    showDescription?: boolean
    sizeLoading?: boolean
    onDescriptionChange?: (value: string) => void
}

/**
 * A card that summarizes a single cohort.
 */
export const CohortDashboardCohortCard: FunctionComponent<CohortDashboardCohortCardProps> = ({
    cohort,
    onSettingsGearClick,
    actions,
    hidDetails,
    excludeTitleRowFromCard,
    showDescription,
    onDescriptionChange,
    sizeLoading
}) => {
    const cardTitleRow = (
        <Grid container spacing={0}>
            {cohort && (
                <Grid item xs={10} id={`cohort-grid-item-gear-${cohort.id}`}>
                    <Typography
                        sx={{
                            fontWeight: '500',
                            fontSize: '35px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            color: '#575A5C'
                        }}
                    >
                        <CohortListItemToTitle cohort={cohort} />
                        {onSettingsGearClick && (
                            <IconButton
                                color='primary'
                                aria-label={t`Edit`}
                                onClick={onSettingsGearClick}
                                sx={{ margin: '5px', borderRadius: '2px', color: '#012D72' }}
                            >
                                <SettingsOutlinedIcon sx={{ height: '30px', width: '30px' }} />
                            </IconButton>
                        )}
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: '500',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            color: '#575A5C'
                        }}
                    >
                        {cohort.description}
                    </Typography>
                </Grid>
            )}
            {cohort && (
                <Grid item xs={2} id={`cohort-grid-item-actions-${cohort.id}`}>
                    <CardActions disableSpacing sx={{ justifyContent: 'flex-end' }}>
                        {actions?.map((action, index) => {
                            return action && <Box key={index}>{action}</Box>
                        })}
                    </CardActions>
                </Grid>
            )}
        </Grid>
    )

    return (
        <Box>
            {cohort && excludeTitleRowFromCard && <Box sx={{ padding: '15px' }}>{cardTitleRow}</Box>}
            {cohort && (
                <Card
                    id={`cohort-dash-card-grid-item-card-${cohort.id}`}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        borderTopRightRadius: 2,
                        borderTopLeftRadius: 2,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        color: '#575A5C'
                    }}
                >
                    {!excludeTitleRowFromCard && <CardContent>{cardTitleRow}</CardContent>}
                    {showDescription && onDescriptionChange && (
                        <EditableParagraph
                            tooltip={t`Click to edit description`}
                            sx={{
                                alignSelf: 'flex-start',
                                marginLeft: 0
                            }}
                            text={cohort.description}
                            editable={!cohort.isSystem}
                            onSave={onDescriptionChange}
                        />
                    )}
                    {showDescription && cohort.description && !onDescriptionChange && (
                        <EditableParagraph
                            sx={{
                                alignSelf: 'flex-start',
                                marginLeft: 0
                            }}
                            text={cohort.description}
                            editable={false}
                        />
                    )}
                    {cohort && !hidDetails && <CohortDashboardCohortCardDetails cohort={cohort} />}
                </Card>
            )}
        </Box>
    )
}
