import { connectRedux } from '@om1/platform-utils'
import { CohortState, ReportAnalyticsType, cohortAnalyticsActions, reportsActions } from '../../state'
import { DataTypeReportChartComponent } from './DataTypeReportChartComponent'

export function createDataTypeReportChart<TState extends { cohort: CohortState }>() {
    return connectRedux(
        DataTypeReportChartComponent,
        (state: TState, ownProps) => ({
            reportsLoading: state.cohort.reports.ui.loading,
            analytics: state.cohort.analytics.data[ownProps.cohort.id]?.[ReportAnalyticsType.DATA_TYPE],
            reports: state.cohort.reports
        }),
        { ...reportsActions, ...cohortAnalyticsActions }
    )
}

export type DataTypeReportChart = ReturnType<typeof createDataTypeReportChart>
