import { t } from '@lingui/macro'
import { Box } from '@mui/material'
import { notificationActions } from '@om1/platform-notifications'
import { Routes, toPath } from '@om1/platform-routing'
import { FrameworkComponentProps } from '@om1/platform-utils'
import React from 'react'
import { Link } from 'react-router-dom'

export type CohortDuplicateSuccessToastComponentProps = FrameworkComponentProps<{}, typeof notificationActions, { id: string; name: string }>

export const CohortDuplicateSuccessToastComponent: React.FC<CohortDuplicateSuccessToastComponentProps> = ({ props: { id, name }, actions }) => {
    const url = toPath(Routes.COHORTBUILD) + `/custom/${id}/edit`

    const handleCopy = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()
        try {
            await navigator.clipboard.writeText(window.location.origin + url || '')
        } catch (err) {
            actions.error(t`Failed to copy`)
        }
    }

    return (
        <Box display='flex' flexDirection='column' gap={1}>
            <Box>
                {t`Created`} &quot;{name}&quot;
            </Box>
            <Box display='flex' gap={2}>
                <Link style={{ color: 'inherit' }} to={url}>
                    {t`View Criteria`}
                </Link>
                <Link style={{ color: 'inherit' }} to={url} role='button' onClick={handleCopy}>
                    {t`Copy Link`}
                </Link>
            </Box>
        </Box>
    )
}
