import { AnalyticsRefDimension, CriteriaType, DateFieldOptions } from '../../../../state'

type DateField = Record<DateFieldOptions, string>
type DateFieldInverse = Record<string, DateFieldOptions>
export enum RefFieldMapperType {
    Depth,
    Nested
}

export interface RefFieldMapperBase {
    type: RefFieldMapperType
    table: string
    dateField: Partial<DateField>
    dateFieldInverse: DateFieldInverse
    dimension: AnalyticsRefDimension
    searchPlaceholder: string
    noResultsMessage: string
    searchHintMessage: string
    filterFieldToRefField: Record<string, string>
    filterFieldOrder: string[]
    filterLabelOrder: string[]
    disableRequireSearch?: boolean
}

/**
 * A callback used to return a string value for a depth-based ref, given a certain "level" in the hierarchy.
 *
 * @example For a tree depth level of 3, return the propery `item_level_3_label` for its display label.
 */
export type DepthRefGetterCallback<T, U = string | null | undefined> = (item: T, depth: number) => U

/**
 * A callback used to return a string value for a nested ref with parent-child relationship.
 */
export type NestedRefGetterCallback<T, U = string | null | undefined> = (item: T) => U

export interface DepthRefFieldMapper<T> extends RefFieldMapperBase {
    type: RefFieldMapperType.Depth
    criteriaType: CriteriaType
    getItemLabel: DepthRefGetterCallback<T>
    getItemValue: DepthRefGetterCallback<T>
    getItemTooltip: DepthRefGetterCallback<T>
}

export interface NestedRefFieldMapper<T> extends RefFieldMapperBase {
    type: RefFieldMapperType.Nested
    criteriaType: CriteriaType
    getItemId: NestedRefGetterCallback<T, string>
    getItemParentId: NestedRefGetterCallback<T>
    getItemPathToRoot: NestedRefGetterCallback<T, string[]>
    getItemLabel: NestedRefGetterCallback<T>
    getItemValue: NestedRefGetterCallback<T>
    getItemTooltip: NestedRefGetterCallback<T>
    getItemChildCount?: NestedRefGetterCallback<T>
}

export type RefFieldMapper<T> = DepthRefFieldMapper<T> | NestedRefFieldMapper<T>

export function isNestedRefFieldMapper<T>(mapper: RefFieldMapper<T>): mapper is NestedRefFieldMapper<T> {
    return mapper.type === RefFieldMapperType.Nested
}
