import { DataFetchParams } from '@om1/platform-routing'
import { ActionsUnion, createAction } from '@om1/platform-utils'
import { CohortUpdateQueryValues } from '..'
import { CohortEditUrlParams } from '../../../components/edit/base/CohortEditComponent'
import { CohortSaveValues } from '../../../components/shared/CreateEditCohortComponent'
import { CohortListItem } from '../../cohort-state'

export enum CohortEditActionTypes {
    COHORT_EDIT_LOADING_SET = '@@cohort/edit/loading-set',
    COHORT_GET = '@@cohort/edit/cohort-get',
    COHORT_SET = '@@cohort/edit/cohort-set',
    COHORT_PARSE_TREE = '@@cohort/edit/cohort-parse-tree',
    COHORT_CLEAR = '@@cohort/edit/cohort-clear',
    COHORT_UPDATE = '@@cohort/edit/update',
    COHORT_UPDATE_QUERY = '@@cohort/edit/update-query',
    COHORT_SIZE_SET = '@@cohort/edit/cohort-size-set',
    COHORT_SIZE_LOADING_SET = '@@cohort/edit/cohort-size-loading-set',
    COHORT_UPDATE_LOADING_SET = '@@cohort/edit/cohort-update-loading-set',
    COHORT_SQL_GET = '@@cohort/edit/sql-get',
    COHORT_SQL_SET = '@@cohort/edit/sql-set',
    COHORT_SQL_LOADING_SET = '@@cohort/edit/sql-loading-set',
    DEMOGRAPHICS_REFS_GET = '@@cohort/edit/patient-demographics-refs-get',
    DEMOGRAPHICS_REFS_LOADING = '@@cohort/edit/patient-demographics-refs-loading',
    SET_ANALYTICS_REPORTS_TASK_ID = '@@cohort/edit/set-analytics-reports-task-id'
}

export const cohortEditActions = {
    cohortEditLoadingSet: (payload: { loading: boolean }) => createAction(CohortEditActionTypes.COHORT_EDIT_LOADING_SET, payload),
    cohortGet: (params: DataFetchParams<{}, CohortEditUrlParams>, options?: { refreshCohortMeta?: boolean }) =>
        createAction(CohortEditActionTypes.COHORT_GET, { params, options }),
    cohortParseTree: (payload: { cohort: CohortListItem }) => createAction(CohortEditActionTypes.COHORT_PARSE_TREE, payload),
    cohortClear: () => createAction(CohortEditActionTypes.COHORT_CLEAR),
    cohortSet: (payload: { cohort: CohortListItem }) => createAction(CohortEditActionTypes.COHORT_SET, payload),
    cohortUpdateLoadingSet: (payload: { loading: boolean }) => createAction(CohortEditActionTypes.COHORT_UPDATE_LOADING_SET, payload),
    cohortUpdate: (cohort: CohortSaveValues, refreshCohortMeta: boolean) =>
        createAction(CohortEditActionTypes.COHORT_UPDATE, { cohort, refreshCohortMeta }),
    cohortUpdateQuery: (cohort: CohortUpdateQueryValues, refreshCohortMeta: boolean = true) =>
        createAction(CohortEditActionTypes.COHORT_UPDATE_QUERY, { cohort, refreshCohortMeta }),
    cohortSizeSet: (payload: { cohortSize?: number; cohortId: string }) => createAction(CohortEditActionTypes.COHORT_SIZE_SET, payload),
    cohortSqlGet: (params: { cohortId: string }) => createAction(CohortEditActionTypes.COHORT_SQL_GET, params),
    cohortSqlSet: (payload: { cohortId: string; sql: string }) => createAction(CohortEditActionTypes.COHORT_SQL_SET, payload),
    cohortSqlLoadingSet: (payload: { cohortId: string; loading: boolean }) => createAction(CohortEditActionTypes.COHORT_SQL_LOADING_SET, payload),
    getDemographicsRefs: () => createAction(CohortEditActionTypes.DEMOGRAPHICS_REFS_GET),
    setDemographicsRefsLoading: (payload: { loading: boolean }) => createAction(CohortEditActionTypes.DEMOGRAPHICS_REFS_LOADING, payload),
    setAnalyticsReportsTaskId: (payload: { taskId: string }) => createAction(CohortEditActionTypes.SET_ANALYTICS_REPORTS_TASK_ID, payload)
}

export type CohortEditActions = ActionsUnion<typeof cohortEditActions>
