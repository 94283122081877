import { CountDistinctQualifier, CriteriaType, CriterionNode, FilterQualifier, QualifierType } from '../../../state'

// TODO: is there a better place for this function?
export function findQualifierByType<T>(qualifiers: FilterQualifier[], type: QualifierType): T | undefined {
    const countDistinctQualifier = qualifiers.find((q) => q.type === QualifierType.CountDistinctQualifierDTO)
    if (countDistinctQualifier) {
        if (Array.isArray((countDistinctQualifier as CountDistinctQualifier).qualifiers)) {
            return findQualifierByType<T>((countDistinctQualifier as CountDistinctQualifier).qualifiers!, type)
        }
    }

    return qualifiers.find((q) => q.type === type) as T
}

export function isGemMappedIcd10Qualifier(node: CriterionNode): boolean {
    return node?.type === CriteriaType.Diagnosis && node?.filters[0]?.field !== 'boc_cui' && !node.filters[0]?.field.endsWith('_icd10')
}

export function isIcd10Qualifier(node: CriterionNode): boolean {
    return node?.type === CriteriaType.Diagnosis && node?.filters[0]?.field.endsWith('_icd10')
}

export function isCuiQualifier(node: CriterionNode): boolean {
    return node?.type === CriteriaType.Diagnosis && node?.filters[0]?.field === 'boc_cui'
}
