import { PlatformRoute, toRoutingConfigObject } from '@om1/cohort-module'
import { InsightsLibraryRoutes } from '@om1/insights-library-module'
import { ContentContainerStyle } from '@om1/platform-ui-kit/src/components/Layout'
import { PlatformPermissions } from '@om1/platform-utils'
import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { AccessDeniedPage } from '../../../../apps/platform/src/pages/AccessDeniedPage'
import { createPermissionProtectedRouteComponent } from '../../../../apps/platform/src/pages/PermissionProtectedRoute'
import { PlatformState } from '../../../../apps/platform/src/platform-state'
import { createManageInsightsLibraryPage } from './ManageInsightsLibraryPage'
import { createPowerBIPageComponent } from './PowerBIPage'

const PowerBIPage = createPowerBIPageComponent<PlatformState>()
const ManageInsightsLibraryPage = createManageInsightsLibraryPage<PlatformState>()

export const InsightsLibraryPage: React.FunctionComponent = () => {
    const match = useRouteMatch()

    if (!match) {
        return null
    }
    const { path: matchPathValue, url: matchUrlValue } = match

    const routes: PlatformRoute[] = [
        {
            route: InsightsLibraryRoutes.BASE,
            component: PowerBIPage,
            permissions: [PlatformPermissions.ACCESS_INSIGHTS_LIBRARY]
        },
        {
            route: InsightsLibraryRoutes.MANAGE,
            component: ManageInsightsLibraryPage,
            permissions: []
        }
    ]

    const PermissionProtectedRoute = createPermissionProtectedRouteComponent<PlatformState>()

    const routingConfigObject = toRoutingConfigObject(routes, matchPathValue, matchUrlValue)

    const pages = (
        <Switch>
            {routingConfigObject.map((tab) => {
                const { route, component, permissions } = tab
                return (
                    <Route key={`route-${route}`} {...tab.matchRoute} exact={true}>
                        <PermissionProtectedRoute
                            permissions={permissions}
                            accessDeniedComponent={<AccessDeniedPage />}
                            protectedChild={React.createElement(component)}
                        />
                    </Route>
                )
            })}
            <Redirect key='insightsRedirect' to={routingConfigObject[0].url} />
        </Switch>
    )

    return <ContentContainerStyle>{pages}</ContentContainerStyle>
}
