import { InsightsReportsService } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put } from 'redux-saga/effects'
import { rwaInsightsReportActions } from '../state/rwa-insights-reports'

export function createDeleteRWAInsightsReportSaga() {
    return function* (action: ReturnType<typeof rwaInsightsReportActions.deleteRwaInsightsReport>) {
        try {
            yield call(InsightsReportsService.deleteRwaInsightsInsightsReportsReportIdDelete, {
                reportId: action.payload.id
            })
            yield put(rwaInsightsReportActions.deleteRwaInsightsReportSuccess(action.payload))
        } catch (error) {
            yield handleApiError(error)
        }
    }
}
