import { InsightsReportsService, PaginatedDTO_InsightsReportDTO_ } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put } from 'redux-saga/effects'
import { rwaConditionsActions } from '../state/rwa-conditions'
import { rwaInsightsReportActions } from '../state/rwa-insights-reports'

export function createGetRWAInsightsReportsSaga() {
    return function* (action: ReturnType<typeof rwaConditionsActions.getRwaConditions>) {
        try {
            yield put(rwaInsightsReportActions.setRwaInsightsReportsLoading({ loading: true }))
            const paginatedInsightsReports: PaginatedDTO_InsightsReportDTO_ = yield call(InsightsReportsService.indexRwaInsightsInsightsReportsGet, {
                name: undefined,
                powerbiReportId: undefined,
                powerbiDatasetId: undefined,
                powerbiWorkspaceId: undefined
            })
            yield put(rwaInsightsReportActions.setRwaInsightsReports({ reports: paginatedInsightsReports.data }))
        } catch (error) {
            yield handleApiError(error)
        } finally {
            yield put(rwaInsightsReportActions.setRwaInsightsReportsLoading({ loading: false }))
        }
    }
}
