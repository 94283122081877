import { AnalyticsRefDimension, CriteriaType, RaceRef } from '../../../../state'
import { DepthRefFieldMapper, DepthRefGetterCallback, RefFieldMapperType } from './interfaces'

const getItemLabel: DepthRefGetterCallback<RaceRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.race
    }
}

const getItemValue: DepthRefGetterCallback<RaceRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.race
    }
}

const getItemTooltip: DepthRefGetterCallback<RaceRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return getItemLabel(item, depth)
    }
}

export const RaceRefFieldMapper: DepthRefFieldMapper<RaceRef> = {
    type: RefFieldMapperType.Depth,
    dimension: AnalyticsRefDimension.RACE,
    criteriaType: CriteriaType.PatientAttributes,
    table: 'patient',
    dateField: {
        first: 'null_date',
        last: 'null_date',
        any: 'null_date'
    },
    dateFieldInverse: {},
    searchPlaceholder: '',
    noResultsMessage: '',
    searchHintMessage: '',
    filterFieldToRefField: { race: 'race' },
    filterFieldOrder: ['race'],
    filterLabelOrder: ['race'],
    getItemLabel,
    getItemValue,
    getItemTooltip
}
