import { t } from '@lingui/macro'
import { format, parse } from 'date-fns'
import { DateRangeInclusion, DateRangeInterval, DateRangeIntervalUnit, DateRangeOperator } from '../../../state'

export function getDateRangeInclusionTranslation(rangeInclusion: DateRangeInclusion) {
    switch (rangeInclusion) {
        case DateRangeInclusion.MoreThan:
            return t`More than`
        case DateRangeInclusion.LessThan:
            return t`Less than`
        case DateRangeInclusion.Exactly:
            return t`Exactly`
        case DateRangeInclusion.AtLeast:
            return t`At least`
        case DateRangeInclusion.AtMost:
            return t`At most`
        default:
            return ''
    }
}

export function getDateRangeOperatorTranslation(rangeOperator: DateRangeOperator) {
    switch (rangeOperator) {
        case DateRangeOperator.Before:
            return t`before`
        case DateRangeOperator.After:
            return t`after`
        case DateRangeOperator.BeforeOrAfter:
            return t`before or after`
        default:
            return ''
    }
}

export function getDateRangeIntervalUnitTranslation(intervalUnit: DateRangeIntervalUnit) {
    switch (intervalUnit) {
        case DateRangeIntervalUnit.Day:
            return t`day(s)`
        case DateRangeIntervalUnit.Month:
            return t`month(s)`
        case DateRangeIntervalUnit.Year:
            return t`year(s)`
        default:
            return ''
    }
}

export function getDateRangeInclusion({ intervalStartFromReferenceDate, intervalEndFromReferenceDate, intervalIsInclusive }: DateRangeInterval) {
    if (intervalEndFromReferenceDate === undefined) {
        if (intervalIsInclusive) {
            return DateRangeInclusion.AtLeast
        } else {
            return DateRangeInclusion.MoreThan
        }
    } else if (
        intervalEndFromReferenceDate &&
        intervalStartFromReferenceDate === 0 &&
        intervalEndFromReferenceDate > intervalStartFromReferenceDate
    ) {
        if (intervalIsInclusive) {
            return DateRangeInclusion.AtMost
        } else {
            return DateRangeInclusion.LessThan
        }
    } else if (intervalStartFromReferenceDate === intervalEndFromReferenceDate) {
        return DateRangeInclusion.Exactly
    } else {
        // Default return
        return DateRangeInclusion.MoreThan
    }
}

export function getLargestInterval(initialValue: DateRangeInterval) {
    // Interval is the max of the start and end intervals
    return initialValue ? Math.max(initialValue.intervalStartFromReferenceDate || 0, initialValue.intervalEndFromReferenceDate || 0) : 0
}

export function translateRangeInclusion(
    rangeInclusion: string,
    interval: number
): { intervalStartFromReferenceDate?: number; intervalEndFromReferenceDate?: number; intervalIsInclusive?: boolean } | undefined {
    switch (rangeInclusion) {
        case DateRangeInclusion.AtLeast:
            return {
                intervalStartFromReferenceDate: interval,
                intervalEndFromReferenceDate: undefined
            }
        case DateRangeInclusion.AtMost:
            return {
                intervalEndFromReferenceDate: interval
            }

        case DateRangeInclusion.MoreThan:
            return {
                intervalStartFromReferenceDate: interval,
                intervalEndFromReferenceDate: undefined,
                intervalIsInclusive: false
            }

        case DateRangeInclusion.LessThan:
            return {
                intervalEndFromReferenceDate: interval,
                intervalIsInclusive: false
            }
        case DateRangeInclusion.Exactly:
            return {
                intervalStartFromReferenceDate: interval,
                intervalEndFromReferenceDate: interval
            }
    }
}

const DATE_QUALIFIER_DTO_DATE_FORMAT = 'yyyy-MM-dd'

export function dateToDTODateString(date: Date): string {
    return format(date, DATE_QUALIFIER_DTO_DATE_FORMAT)
}

export function DTODateStringToDate(date: string): Date {
    return parse(date, DATE_QUALIFIER_DTO_DATE_FORMAT, new Date())
}
