import { connectRedux } from '@om1/platform-utils'
import { CohortState, CriterionNode, cohortBlocksEditActions } from '../../../state'
import { findNode } from '../../../state/edit/blocks/cohort-blocks-edit-reducer'
import { FollowUpLengthEditDialogComponent } from './FollowUpLengthEditDialogComponent'

export function createFollowUpLengthEditDialogComponent<TState extends { cohort: CohortState }>() {
    return connectRedux(
        FollowUpLengthEditDialogComponent,
        (state: TState) => {
            const nodeId = state.cohort.edit.blocks.ui.followUpLengthEditDialog.target?.nodeId

            return {
                criteria: nodeId ? (findNode(state.cohort.edit.blocks.tree, nodeId) as CriterionNode) : undefined
            }
        },
        cohortBlocksEditActions
    )
}

export type FollowUpLengthDialog = ReturnType<typeof createFollowUpLengthEditDialogComponent>
