import { debounce } from 'redux-saga/effects'
import { FalconApiActionTypes } from '../state/falcon-api-actions'
import { createGetFalconVersionSaga } from './get-falcon-version-saga'

export function createFalconApiSaga() {
    const getFalconVersionSaga = createGetFalconVersionSaga()
    return function* () {
        yield debounce(0, FalconApiActionTypes.GET_FALCON_VERSION, getFalconVersionSaga)
    }
}
