import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, DialogTitle, IconButton, Link } from '@mui/material'
import { Box } from '@mui/system'
import { NotificationState, notificationActions } from '@om1/platform-notifications'
import { FrameworkComponentProps } from '@om1/platform-utils'
import { FunctionComponent } from 'react'

export type TimeoutDialogComponentProps = FrameworkComponentProps<NotificationState, typeof notificationActions>

/**
 * A dialog for the timeout error.
 */
export const TimeoutDialogComponent: FunctionComponent<TimeoutDialogComponentProps> = ({ state, actions }) => {
    const { showTimeoutDialog, cohortId, cohortName } = state

    const handleClose = () => {
        actions.hideTimeoutDialog()
    }

    const dateFormatter = new Intl.DateTimeFormat(undefined, { dateStyle: 'medium', timeStyle: 'long' })

    const renderTechnicalSupportLink = () => {
        return (
            <Link href='https://om1inc.atlassian.net/servicedesk/customer/portal/5/group/26' underline='hover' target='_blank'>
                <Trans>technical support</Trans>
            </Link>
        )
    }

    return (
        <Dialog open={showTimeoutDialog} maxWidth='lg' fullWidth aria-labelledby='timeout-dialog-title' onClose={handleClose}>
            <DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={handleClose}
                    sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                >
                    <CloseIcon />
                </IconButton>
                <Box id='timeout-dialog-title' pr={4}>
                    <Trans>Query Timed Out</Trans>
                </Box>
            </DialogTitle>
            <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box position='relative' display='flex' flexDirection='column' flex={1} sx={{ overflowY: 'auto' }}>
                    <Box bgcolor='grey.200' sx={{ overflow: 'auto' }} p={2} m={0}>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            sx={{ overflow: 'auto', margin: '25px', backgroundColor: '#FFFFFF', padding: '40px' }}
                        >
                            <Box display={'flex'} flexDirection={'row'}>
                                <Box display={'flex'} alignItems={'center'} sx={{ marginRight: '25px' }}>
                                    <FontAwesomeIcon icon={faTriangleExclamation} size='3x' style={{ color: '#ff0000', opacity: 0.8 }} />
                                </Box>
                                <Box display={'flex'} flexDirection={'column'}>
                                    <Box marginBottom={3}>
                                        <Trans>Looks like there was a problem calculating the patient count for your cohort:</Trans> {cohortName}
                                    </Box>
                                    <Box marginBottom={3} sx={{ textWrap: 'pretty' }}>
                                        <Trans>
                                            You can try modifying your cohort criteria and re-submitting it. Overly broad criteria tend to be less
                                            performant. Consider using more specific criteria in your cohort.
                                        </Trans>
                                    </Box>
                                    <Box marginBottom={1}>
                                        <Trans>For additional information, please contact</Trans> {renderTechnicalSupportLink()}{' '}
                                        <Trans>with the following information:</Trans>
                                    </Box>
                                    <Box component='pre' bgcolor='grey.200' sx={{ overflow: 'auto', padding: '10px' }}>
                                        <Trans>Query timeout for cohort</Trans> {cohortId} {dateFormatter.format(new Date())}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
