/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InsightsReportCreateDTO } from '../models/InsightsReportCreateDTO'
import type { InsightsReportDTO } from '../models/InsightsReportDTO'
import type { InsightsReportPatchDTO } from '../models/InsightsReportPatchDTO'
import type { PaginatedDTO_InsightsReportDTO_ } from '../models/PaginatedDTO_InsightsReportDTO_'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class InsightsReportsService {
    /**
     * Index
     * @returns PaginatedDTO_InsightsReportDTO_ Successful Response
     * @throws ApiError
     */
    public static indexRwaInsightsInsightsReportsGet({
        name,
        powerbiReportId,
        powerbiDatasetId,
        powerbiWorkspaceId,
        page = 1,
        limit = 10
    }: {
        name?: string | null
        powerbiReportId?: string | null
        powerbiDatasetId?: string | null
        powerbiWorkspaceId?: string | null
        /**
         * Page number for pagination, starting at 1
         */
        page?: number
        /**
         * Number of items per page, maximum 1000
         */
        limit?: number
    }): CancelablePromise<PaginatedDTO_InsightsReportDTO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rwa_insights/insights_reports',
            query: {
                name: name,
                powerbi_report_id: powerbiReportId,
                powerbi_dataset_id: powerbiDatasetId,
                powerbi_workspace_id: powerbiWorkspaceId,
                page: page,
                limit: limit
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Create
     * @returns InsightsReportDTO Successful Response
     * @throws ApiError
     */
    public static createRwaInsightsInsightsReportsPost({
        requestBody
    }: {
        requestBody: InsightsReportCreateDTO
    }): CancelablePromise<InsightsReportDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rwa_insights/insights_reports',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Get
     * @returns InsightsReportDTO Successful Response
     * @throws ApiError
     */
    public static getRwaInsightsInsightsReportsReportIdGet({ reportId }: { reportId: string }): CancelablePromise<InsightsReportDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rwa_insights/insights_reports/{report_id}',
            path: {
                report_id: reportId
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Patch
     * @returns InsightsReportDTO Successful Response
     * @throws ApiError
     */
    public static patchRwaInsightsInsightsReportsReportIdPatch({
        reportId,
        requestBody
    }: {
        reportId: string
        requestBody: InsightsReportPatchDTO
    }): CancelablePromise<InsightsReportDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/rwa_insights/insights_reports/{report_id}',
            path: {
                report_id: reportId
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Delete
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteRwaInsightsInsightsReportsReportIdDelete({ reportId }: { reportId: string }): CancelablePromise<Record<string, string>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/rwa_insights/insights_reports/{report_id}',
            path: {
                report_id: reportId
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
}
