import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditOutlineIcon from '@mui/icons-material/EditOutlined'
import { Box, Button, IconButton } from '@mui/material'
import { ReactNode } from 'react'

interface CohortCriterionQualifierSummaryProps {
    summaryText: ReactNode
    readOnly?: boolean
    editAction?: { label: string; onClick: () => void }
    deleteAction?: { label: string; onClick: () => void }
}

export const CohortCriterionQualifierSummary = ({ summaryText, readOnly, editAction, deleteAction }: CohortCriterionQualifierSummaryProps) => {
    let actionButtons: ReactNode[] = []
    if (!readOnly) {
        if (editAction) {
            actionButtons.push(
                <IconButton key='edit' color='primary' aria-label={editAction.label} sx={{ padding: 0.5 }} onClick={editAction.onClick}>
                    <EditOutlineIcon fontSize='small' />
                </IconButton>
            )
        }
        if (deleteAction) {
            actionButtons.push(
                <IconButton key='delete' color='primary' aria-label={deleteAction.label} sx={{ padding: 0.5 }} onClick={deleteAction.onClick}>
                    <DeleteOutlineIcon fontSize='small' />
                </IconButton>
            )
        }
    }

    let summary: ReactNode = summaryText
    if (!readOnly && editAction) {
        summary = (
            <Button
                type='button'
                variant='text'
                disableTouchRipple
                sx={{
                    px: 1,
                    py: 0.25,
                    fontSize: 'inherit',
                    color: 'text.primary',
                    textAlign: 'left',
                    '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' }
                }}
                onClick={editAction.onClick}
            >
                {summary}
            </Button>
        )
    } else {
        summary = (
            <Box px={1} py={0.5}>
                {summary}
            </Box>
        )
    }
    return (
        <Box display='flex' alignItems='center' justifyContent='space-between' bgcolor='grey.200' fontSize={12}>
            <div>{summary}</div>
            <Box display='flex'>{actionButtons}</Box>
        </Box>
    )
}
