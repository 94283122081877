import { connectRedux } from '@om1/platform-utils'
import { AnalyticsRefDestination, AnalyticsRefDimension, CohortState, cohortBlocksEditActions, cohortEditActions } from '../../../state'
import { PatientAttributesDialogComponent } from './PatientAttributesDialogComponent'

export function createPatientAttributesDialog<TState extends { cohort: CohortState }>() {
    return connectRedux(
        PatientAttributesDialogComponent,
        (state: TState) => ({
            sexRefs: state.cohort.refs[AnalyticsRefDimension.SEX][AnalyticsRefDestination.SEARCH].data,
            raceRefs: state.cohort.refs[AnalyticsRefDimension.RACE][AnalyticsRefDestination.SEARCH].data,
            stateRefs: state.cohort.refs[AnalyticsRefDimension.STATE][AnalyticsRefDestination.SEARCH].data,
            refsLoading: state.cohort.edit.base.ui.demographicsDialog.loading
        }),
        { ...cohortEditActions, ...cohortBlocksEditActions }
    )
}

export type PatientAttributesDialog = ReturnType<typeof createPatientAttributesDialog>
