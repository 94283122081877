import { t } from '@lingui/macro'
import WarningIcon from '@mui/icons-material/Warning'
import { CircularProgress, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { LightTooltip } from '@om1/platform-components/Tooltip'
import { FunctionComponent, useMemo } from 'react'

export interface CohortSizeWithLoaderProps {
    cohortSize?: number
    datasetSize?: number
    sizeLoading?: boolean
    isStale?: boolean
    justifyEnd?: boolean
    isSystem?: boolean
}
export const CohortSizeWithLoader: FunctionComponent<CohortSizeWithLoaderProps> = ({
    cohortSize,
    datasetSize,
    sizeLoading,
    isStale,
    justifyEnd = false,
    isSystem = false
}) => {
    const theme = useTheme()
    const numberFormatter = new Intl.NumberFormat(undefined)

    const renderDatasetSize = useMemo(() => {
        if (isSystem || sizeLoading || !datasetSize) {
            return <></>
        }

        if (!cohortSize) {
            return '0%'
        }

        const percentFormatter = new Intl.NumberFormat(undefined, { style: 'percent' })
        const formattedValue = percentFormatter.format(cohortSize / datasetSize)

        return `(${formattedValue === '0%' ? '<1%' : formattedValue})`
    }, [isSystem, cohortSize, datasetSize, sizeLoading])

    return (
        <Box display='flex' gap={2} alignItems='center' component='span' justifyContent={justifyEnd ? 'flex-end' : 'flex-start'}>
            {sizeLoading && (
                <Box color='grey.500' component='span'>
                    <CircularProgress color='inherit' size='1em' />
                </Box>
            )}
            <Typography fontSize='inherit' fontWeight='light' textAlign='end' component='span' display='flex' gap={1} alignItems='inherit'>
                <Box component='span' data-testid='cohort-size-with-loader-value'>
                    {cohortSize === undefined ? <>&mdash;</> : numberFormatter.format(cohortSize)} {renderDatasetSize}
                </Box>
                {sizeLoading || !isStale ? null : (
                    <LightTooltip title={t`New dataset available. Refresh your cohort to get the updated patient count.`} placement='top'>
                        {/* zIndex used because dashboard SPA list uses overlay for onClick selection */}
                        <WarningIcon
                            sx={{ color: theme.palette.warning.main, zIndex: 1 }}
                            fontSize='inherit'
                            data-testid='cohort-size-with-loader-isStale'
                        />
                    </LightTooltip>
                )}
            </Typography>
        </Box>
    )
}
