import { CohortConditionDTO, CohortConditionsService } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put } from 'redux-saga/effects'
import { rwaConditionsActions } from '../state/rwa-conditions'

export function createCreateRWAConditionsSaga() {
    return function* (action: ReturnType<typeof rwaConditionsActions.createRwaCondition>) {
        try {
            const cohortConditionDTO: CohortConditionDTO = yield call(CohortConditionsService.createRwaInsightsCohortConditionsPost, {
                requestBody: action.payload.condition
            })
            yield put(rwaConditionsActions.createRwaConditionSuccess(cohortConditionDTO))
        } catch (error) {
            yield handleApiError(error)
        }
    }
}
