import { Auth0State, auth0Actions } from '@om1/platform-authentication'
import { FrameworkComponentProps, PlatformPermissions, connectRedux } from '@om1/platform-utils'
import { FunctionComponent, ReactElement } from 'react'

export type PermissionProtectedRouteComponentProps = FrameworkComponentProps<
    Auth0State,
    typeof auth0Actions,
    {
        permissions: PlatformPermissions[]
        accessDeniedComponent: ReactElement
        protectedChild: ReactElement
    }
>

export const PermissionProtectedRouteComponent: FunctionComponent<PermissionProtectedRouteComponentProps> = ({ state, props }) => {
    if (state.permissions !== undefined) {
        const hasRole = state.permissions ? props.permissions.every((permission) => state.permissions!.includes(permission)) : false
        return <>{hasRole ? props.protectedChild : props.accessDeniedComponent}</>
    } else {
        return <>{props.accessDeniedComponent}</>
    }
}

export function createPermissionProtectedRouteComponent<TState extends { auth0: Auth0State }>() {
    return connectRedux(PermissionProtectedRouteComponent, (state: TState) => state.auth0, { ...auth0Actions })
}

export type PermissionProtectedRoute = ReturnType<typeof createPermissionProtectedRouteComponent>
