import { ActionsUnion, createAction } from '@om1/platform-utils'
import { OptionsObject, SnackbarMessage } from 'notistack'

export enum NotificationActionTypes {
    SUCCESS = '@@notifications/success',
    ERROR = '@@notifications/error',
    WARNING = '@@notifications/warning',
    SHOW_HELP_DIALOG = '@@notifications/showHelpDialog',
    HIDE_HELP_DIALOG = '@@notifications/hideHelpDialog',
    SHOW_TIMEOUT_DIALOG = '@@notifications/showTimeoutDialog',
    HIDE_TIMEOUT_DIALOG = '@@notifications/hideTimeoutDialog'
}
export const notificationActions = {
    success: (message: SnackbarMessage = 'Success', options?: OptionsObject) =>
        createAction(NotificationActionTypes.SUCCESS, { message: message, options: options }),
    error: (message: SnackbarMessage, options?: OptionsObject) => createAction(NotificationActionTypes.ERROR, { message: message, options: options }),
    warning: (message: SnackbarMessage, options?: OptionsObject) =>
        createAction(NotificationActionTypes.WARNING, { message: message, options: options }),
    showHelpDialog: () => createAction(NotificationActionTypes.SHOW_HELP_DIALOG),
    hideHelpDialog: () => createAction(NotificationActionTypes.HIDE_HELP_DIALOG),
    showTimeoutDialog: (cohortId: string, cohortName: string) => createAction(NotificationActionTypes.SHOW_TIMEOUT_DIALOG, { cohortId, cohortName }),
    hideTimeoutDialog: () => createAction(NotificationActionTypes.HIDE_TIMEOUT_DIALOG)
}

export type NotificationActions = ActionsUnion<typeof notificationActions>
