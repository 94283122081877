import {
    CohortDTO,
    DeliveredDatabaseDTO,
    DeliveryCreateDTO,
    DeliveryDTO,
    DeliveryPatchDTO,
    FalconPaginationQuery,
    OrganizationMetadataCreateDTO,
    OrganizationMetadataDTO,
    OrganizationMetadataPatchDTO,
    PaginateMeta,
    RegistryTypeDTO
} from '@om1/falcon-api'
import { DataFetchParams } from '@om1/platform-routing'
import { ActionsUnion, createAction } from '@om1/platform-utils'
import { CohortOwner } from './data-delivery-state'

export enum DataDeliveryActionTypes {
    COHORTS_ACCESS = '@@data-delivery/edit/get-cohorts',
    COHORTS_LOADING = '@@data-delivery/edit/cohorts-loading',
    COHORTS_SET = '@@data-delivery/edit/set-cohorts',
    DATA_DELIVERIES_GET = '@@data-deliveries/list/get',
    DATA_DELIVERIES_LOADING = '@@data-deliveries/list/loading',
    DATA_DELIVERIES_SET = '@@data-deliveries/list/set',
    DATA_DELIVERIES_LIST_ADD_EDIT = '@@data-deliveries/list/add-edit',
    DATA_DELIVERY_CREATE = '@@data-deliveries/edit/create',
    DATA_DELIVERY_LOADING = '@@data-deliveries/edit/loading',
    DATA_DELIVERY_UPDATE = '@@data-deliveries/edit/update',
    DATA_DELIVERY_EXECUTE = '@@data-deliveries/edit/execute',
    DATA_DELIVERY_ADD_TASK_LISTENER = '@@data-deliveries/edit/add-task-listener',
    DATA_DELIVERY_UPDATE_EXECUTING_TASK_IDS = '@@data-deliveries/edit/update-executing-task-ids',
    DATA_DELIVERY_FETCH_AND_UPDATE_IN_LIST = '@@data-deliveries/edit/fetch-and-update-in-list',
    DELIVERED_DATABASES_GET = '@@data-deliveries/edit/get-delivered-databases',
    DELIVERED_DATABASES_SET = '@@data-deliveries/edit/set-delivered-databases',
    DELIVERED_DATABASES_LOADING = '@@data-deliveries/edit/delivered-databases-loading',
    ORGANIZATION_METADATA_LOADING = '@@data-delivery/edit/organization-metadata-loading',
    ORGANIZATION_METADATA_CREATE = '@@data-delivery/edit/create-organization-metadata',
    ORGANIZATION_METADATA_UPDATE = '@@data-delivery/edit/update-organization-metadata',
    ORGANIZATION_METADATA_GET = '@@data-delivery/edit/get-organization-metadata',
    ORGANIZATION_METADATA_SET = '@@data-delivery/edit/set-organization-metadata',
    ORGANIZATION_METADATA_DELETE = '@@data-delivery/edit/delete-organization-metadata',
    DATA_DELIVERY_REGISTRY_TYPES_GET = '@@data-deliveries/registry-types/get-registry-types',
    DATA_DELIVERY_REGISTRY_TYPES_SET = '@@data-deliveries/registry-types/set-registry-types',
    DATA_DELIVERY_REGISTRY_TYPES_LOADING = '@@data-deliveries/registry-types/loading'
}

export const dataDeliveryActions = {
    getCohorts: () => createAction(DataDeliveryActionTypes.COHORTS_ACCESS),
    setCohorts: (payload: { cohorts: CohortDTO[]; meta: PaginateMeta; owner: CohortOwner }) =>
        createAction(DataDeliveryActionTypes.COHORTS_SET, payload),
    setCohortsLoading: (payload: { loading: boolean }) => createAction(DataDeliveryActionTypes.COHORTS_LOADING, payload),
    dataDeliveriesCreate: (payload: DeliveryCreateDTO) => createAction(DataDeliveryActionTypes.DATA_DELIVERY_CREATE, payload),
    dataDeliveryLoading: (payload: { loading: boolean }) => createAction(DataDeliveryActionTypes.DATA_DELIVERY_LOADING, payload),
    dataDeliveriesUpdate: (
        payload: DeliveryPatchDTO & {
            deliveryId: string
        }
    ) => createAction(DataDeliveryActionTypes.DATA_DELIVERY_UPDATE, payload),
    dataDeliveryExecute: (payload: { deliveryId: string }) => createAction(DataDeliveryActionTypes.DATA_DELIVERY_EXECUTE, payload),
    dataDeliveryAddTaskListener: (payload: { deliveryId: string; taskId: string }) =>
        createAction(DataDeliveryActionTypes.DATA_DELIVERY_ADD_TASK_LISTENER, payload),
    dataDeliveryUpdateExecutingTaskIds: (payload: { taskId: string; isAdd: boolean }) =>
        createAction(DataDeliveryActionTypes.DATA_DELIVERY_UPDATE_EXECUTING_TASK_IDS, payload),
    dataDeliveryFetchAndUpdateInList: (payload: { deliveryId: string }) =>
        createAction(DataDeliveryActionTypes.DATA_DELIVERY_FETCH_AND_UPDATE_IN_LIST, payload),
    dataDeliveriesListAddEdit: (payload: { dataDelivery: DeliveryDTO; isAdd: boolean }) =>
        createAction(DataDeliveryActionTypes.DATA_DELIVERIES_LIST_ADD_EDIT, payload),
    dataDeliveriesGet: (params: DataFetchParams<FalconPaginationQuery, {}>) => createAction(DataDeliveryActionTypes.DATA_DELIVERIES_GET, { params }),
    dataDeliveriesLoading: (payload: { loading: boolean }) => createAction(DataDeliveryActionTypes.DATA_DELIVERIES_LOADING, payload),
    dataDeliveriesSet: (payload: { data: DeliveryDTO[]; meta: PaginateMeta }) => createAction(DataDeliveryActionTypes.DATA_DELIVERIES_SET, payload),
    getDeliveredDatabases: (params: DataFetchParams<FalconPaginationQuery, {}>) =>
        createAction(DataDeliveryActionTypes.DELIVERED_DATABASES_GET, { params }),
    setDeliveredDatabases: (payload: { data: DeliveredDatabaseDTO[]; meta: PaginateMeta }) =>
        createAction(DataDeliveryActionTypes.DELIVERED_DATABASES_SET, payload),
    setDeliveredDatabasesLoading: (payload: { loading: boolean }) => createAction(DataDeliveryActionTypes.DELIVERED_DATABASES_LOADING, payload),
    setOrganizationMetadataLoading: (payload: { loading: boolean }) => createAction(DataDeliveryActionTypes.ORGANIZATION_METADATA_LOADING, payload),
    createOrganizationMetadata: (payload: OrganizationMetadataCreateDTO) =>
        createAction(DataDeliveryActionTypes.ORGANIZATION_METADATA_CREATE, payload),
    updateOrganizationMetadata: (payload: { organizationMetadataId: string; dto: OrganizationMetadataPatchDTO }) =>
        createAction(DataDeliveryActionTypes.ORGANIZATION_METADATA_UPDATE, payload),
    getOrganizationMetadata: (params: DataFetchParams<{}, { organizationId: string }>) =>
        createAction(DataDeliveryActionTypes.ORGANIZATION_METADATA_GET, { organizationId: params.path.organizationId }),
    setOrganizationMetadata: (payload: { data: OrganizationMetadataDTO }) => createAction(DataDeliveryActionTypes.ORGANIZATION_METADATA_SET, payload),
    deleteOrganizationMetadata: (payload: { id: string }) => createAction(DataDeliveryActionTypes.ORGANIZATION_METADATA_DELETE, payload),
    getDataDeliveryRegistryTypes: () => createAction(DataDeliveryActionTypes.DATA_DELIVERY_REGISTRY_TYPES_GET),
    setDataDeliveryRegistryTypes: (payload: { registryTypes: RegistryTypeDTO[] }) =>
        createAction(DataDeliveryActionTypes.DATA_DELIVERY_REGISTRY_TYPES_SET, payload),
    dataDeliveryRegistryTypesLoading: (payload: { loading: boolean }) =>
        createAction(DataDeliveryActionTypes.DATA_DELIVERY_REGISTRY_TYPES_LOADING, payload)
}

export type DataDeliveryActions = ActionsUnion<typeof dataDeliveryActions>
