import { DataDeliveryAdminDeliveryService, DeliveryCreateDTO, DeliveryDTO } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { notificationActions } from '@om1/platform-notifications'
import { call, put } from 'redux-saga/effects'
import { dataDeliveryActions } from '../state/data-delivery'

const createDeliveryDTO = (action: ReturnType<typeof dataDeliveryActions.dataDeliveriesCreate>): DeliveryCreateDTO => ({
    organizationId: action.payload.organizationId,
    deliveredDatabaseId: action.payload.deliveredDatabaseId,
    cohorts: action.payload.cohorts?.map(({ cohortId, registryType, sampleSize }) => ({ cohortId, registryType, sampleSize })) || []
})

export default function createCreateDataDeliverySaga() {
    return function* (action: ReturnType<typeof dataDeliveryActions.dataDeliveriesCreate>) {
        yield put(dataDeliveryActions.dataDeliveryLoading({ loading: true }))
        try {
            const deliveryDTO: DeliveryDTO = yield call(DataDeliveryAdminDeliveryService.createDataDeliveryDeliveryPost, {
                requestBody: createDeliveryDTO(action)
            })
            yield put(notificationActions.success())
            yield put(dataDeliveryActions.dataDeliveriesListAddEdit({ dataDelivery: deliveryDTO, isAdd: true }))
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(dataDeliveryActions.dataDeliveryLoading({ loading: false }))
        }
    }
}
