import { Auth0State } from '@om1/platform-authentication'
import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { CohortState, cohortEditActions } from '../../state'
import { ReportsPageComponent } from './ReportsPageComponent'

export function createReportsPage<TState extends { cohort: CohortState; auth0: Auth0State }>() {
    return connectRedux(
        withRouter(ReportsPageComponent),
        (state: TState) => {
            return { cohort: state.cohort.edit.base.cohort, cohortLoading: state.cohort.edit.base.ui.loading, permissions: state.auth0.permissions }
        },
        cohortEditActions
    )
}

export type ReportsPage = ReturnType<typeof createReportsPage>
