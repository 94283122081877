import { Trans } from '@lingui/macro'

import { RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { FunctionComponent } from 'react'
import { PlatformState } from '../../../../apps/platform/src/platform-state'

import { createDataDeliveryListComponent } from '../components/DataDeliveryList'
import { dataDeliveryActions } from '../state/data-delivery'

const DataDeliveryList = createDataDeliveryListComponent<PlatformState>()

export type DataDeliveryPageComponentProps = RoutedFrameworkComponentProps<{}, {}, {}, typeof dataDeliveryActions, {}>

export const DataDeliveryPageComponent: FunctionComponent<DataDeliveryPageComponentProps> = () => {
    return (
        <>
            <h1>
                <Trans>Data Delivery Page</Trans>
            </h1>
            <div>
                <DataDeliveryList />
            </div>
        </>
    )
}
