import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { BackOfficeState } from '../state'
import { dataDeliveryActions } from '../state/data-delivery'
import { DataDeliveryPageComponent } from './DataDeliveryPageComponent'

export function createDataDeliveryPage<TState extends { backOffice: BackOfficeState }>() {
    return connectRedux(
        withRouter(DataDeliveryPageComponent),
        (state: TState) => {
            return {}
        },
        dataDeliveryActions
    )
}

export type DataDeliveryPage = ReturnType<typeof createDataDeliveryPage>
