/* tslint:disable */
/* eslint-disable */
import { Trans } from '@lingui/macro'
import { ArrowRightAlt } from '@mui/icons-material'
import { Box, Dialog } from '@mui/material'
import { RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { AnimatePresence, Variants, motion } from 'framer-motion'
import React, { Dispatch, FunctionComponent, PropsWithChildren, SetStateAction, useState } from 'react'
import { Wizard, useWizard } from 'react-use-wizard'
import { v4 as uuidv4 } from 'uuid'
import {
    CohortListItem,
    CohortNode,
    CriteriaOperation,
    CriteriaType,
    DateRangeIntervalUnit,
    DateRangeOperator,
    OperationNode,
    QualifierOperator,
    QualifierType,
    QueryFilterBase
} from '../../state'
import {
    DIAGNOSIS_REF_FIELD_MAPPER_CONFIG,
    MEDICATION_REF_FIELD_MAPPER_CONFIG,
    OBSERVATION_REF_FIELD_MAPPER_CONFIG,
    SPECIALTY_REF_FIELD_MAPPER_CONFIG,
    createRefTreeDialog
} from '../edit/blocks/RefTreeDialog'
import { blocksToFilters } from '../edit/utils/filter-export'
import { createCreateEditCohortComponent } from '../shared/CreateEditCohort'
import { CohortSaveValues } from '../shared/CreateEditCohortComponent'
import { CancelWizardDialog } from './CancelWizardDialog'

const RefTreeDialogComponent = createRefTreeDialog()
const CreateEditCohortComponent = createCreateEditCohortComponent()

export type CreateCohortWizardDialogComponentProps = RoutedFrameworkComponentProps<
    {},
    {},
    {},
    {},
    {
        onCancel: () => void
        onSave: (cohort: CohortSaveValues) => void
    }
>

/**
 * A wizard to create a standard build cohort
 */
export const CreateCohortWizardDialogComponent: FunctionComponent<CreateCohortWizardDialogComponentProps> = ({ state, routing, actions, props }) => {
    const [isFirstStep, setIsFirstStep] = useState(true)
    return (
        <Dialog open maxWidth={isFirstStep ? 'sm' : 'lg'} fullWidth onClose={props.onCancel}>
            <AnimatedSection onSave={props.onSave} onCancel={props.onCancel} isFirstStep={isFirstStep} setIsFirstStep={setIsFirstStep} />
        </Dialog>
    )
}

const steps = {
    Cohort: {},
    Diagnoses: {
        mapper: DIAGNOSIS_REF_FIELD_MAPPER_CONFIG,
        type: CriteriaType.Diagnosis
    },
    Medications: {
        mapper: MEDICATION_REF_FIELD_MAPPER_CONFIG,
        type: CriteriaType.Medication
    },
    Outcomes: {
        mapper: OBSERVATION_REF_FIELD_MAPPER_CONFIG,
        type: CriteriaType.Observation
    },
    'Specialist Notes': {
        mapper: SPECIALTY_REF_FIELD_MAPPER_CONFIG,
        type: CriteriaType.EhrNotes
    }
}

interface StepProps {
    number: number
    cohort?: CohortSaveValues
    setCohort: Dispatch<SetStateAction<CohortSaveValues | undefined>>
    wizardSelections: { [step: string]: QueryFilterBase[] }
    setWizardSelections: (selections: {}) => void
    onSave: (cohort: CohortSaveValues) => void
    onCancel: () => void
    setIsFirstStep: Dispatch<SetStateAction<boolean>>
}

const Step: React.FC<StepProps> = ({ number, cohort, setCohort, wizardSelections, setWizardSelections, onSave, onCancel, setIsFirstStep }) => {
    const { activeStep, nextStep, previousStep, isLastStep, isFirstStep } = useWizard()
    const step = Object.keys(steps)[activeStep]
    setIsFirstStep(isFirstStep)

    const [cancelModalVisible, setCancelModalVisible] = useState(false)

    const handleFirstStep = (cohort: CohortSaveValues) => {
        if (cohort.isStandardBuild) {
            setCohort(cohort)
            nextStep()
        } else {
            onSave(cohort)
        }
    }

    const handleNextStep = (filters: QueryFilterBase[]) => {
        let newWizardSelections = { ...wizardSelections }
        if (filters.length) {
            newWizardSelections = { ...newWizardSelections, [step]: filters }
            setWizardSelections(newWizardSelections)
        }

        if (isLastStep) {
            let queryBlocks: OperationNode = {
                id: uuidv4(),
                operation: CriteriaOperation.EXCEPT,
                children: [
                    {
                        id: uuidv4(),
                        operation: CriteriaOperation.AND,
                        children: [
                            {
                                id: uuidv4(),
                                operation: CriteriaOperation.OR,
                                children: [
                                    {
                                        id: uuidv4(),
                                        type: CriteriaType.ObservationPeriod,
                                        filters: [
                                            {
                                                table: 'observation_period',
                                                field: 'linkage_type',
                                                operator: 'in',
                                                values: ['EHR, Medical Claims, and Pharmacy Claims']
                                            }
                                        ],
                                        dateField: 'any',
                                        qualifiers: []
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
            for (let k in newWizardSelections) {
                let block: CohortNode = {
                    id: uuidv4(),
                    type: steps[k].type,
                    filters: newWizardSelections[k],
                    dateField: 'any',
                    qualifiers: []
                }
                if (steps[k].type === CriteriaType.Diagnosis) {
                    block = {
                        ...block,
                        qualifiers: [],
                        reference: {
                            criteria: {
                                ...block,
                                dateField: 'first'
                            },
                            dateRelation: {
                                dateRangeOperator: DateRangeOperator.After,
                                intervalEndFromReferenceDate: undefined,
                                intervalStartFromReferenceDate: 30,
                                intervalIsInclusive: true,
                                intervalUnitFromReferenceDate: DateRangeIntervalUnit.Day
                            }
                        }
                    }
                } else if (steps[k].type === CriteriaType.EhrNotes) {
                    block = {
                        ...block,
                        filters: [
                            {
                                field: 'has_notes',
                                operator: 'in',
                                table: 'patient_note',
                                values: ['True']
                            }
                        ],
                        qualifiers: [
                            {
                                type: QualifierType.SpecialtyQualifierDTO,
                                values: newWizardSelections[k][0].values
                            }
                        ]
                    }
                } else if (steps[k].type === CriteriaType.Observation) {
                    block = {
                        ...block,
                        qualifiers: [
                            {
                                type: QualifierType.CountDistinctQualifierDTO,
                                value: 2,
                                operator: QualifierOperator.GreaterThanOrEquals,
                                field: 'encounter_id'
                            }
                        ]
                    }
                }
                const operationBlock = {
                    id: uuidv4(),
                    operation: CriteriaOperation.OR,
                    children: [block]
                }
                // @ts-ignore
                queryBlocks.children[0].children.push(operationBlock)
            }
            const query = blocksToFilters(queryBlocks)

            onSave({
                ...cohort!,
                query
            })
        } else {
            nextStep()
        }
    }

    const handleBack = (filters: QueryFilterBase[]) => {
        let newWizardSelections = { ...wizardSelections }
        if (filters.length) {
            newWizardSelections = { ...newWizardSelections, [step]: filters }
            setWizardSelections(newWizardSelections)
        }
        previousStep()
    }

    const handleCancel = () => {
        if (Object.keys(wizardSelections).length) {
            setCancelModalVisible(true)
        } else {
            onCancel()
        }
    }

    return isFirstStep ? (
        <CreateEditCohortComponent
            cohort={
                cohort &&
                ({
                    name: cohort.name,
                    analyticsDataset: cohort.analyticsDataset,
                    description: cohort.description,
                    isPrivate: cohort.isPrivate
                } as CohortListItem)
            }
            onCancel={handleCancel}
            onSave={handleFirstStep}
            isLoading={false}
        />
    ) : (
        <>
            <h3>
                Step {number - 1} of {Object.keys(steps).length - 1}: {step}
            </h3>
            <RefTreeDialogComponent
                key={activeStep}
                initialValue={wizardSelections[step] || []}
                fieldMappers={steps[step].mapper}
                onSave={handleNextStep}
                onCancel={handleCancel}
                onHandleRemoveQualifiers={() => ({})}
                criteriaType={steps[step].type}
                saveText={isLastStep ? <Trans>Save</Trans> : <Trans>Next</Trans>}
                saveIcon={isLastStep ? undefined : <ArrowRightAlt />}
                dialog={false}
                onBack={handleBack}
                backButton
                cancelButton
                optionalFilters
            />
            {cancelModalVisible && <CancelWizardDialog onCancel={() => setCancelModalVisible(false)} onAccept={onCancel} />}
        </>
    )
}

type AnimatedStepProps = PropsWithChildren<{
    previousStep: React.MutableRefObject<number>
}>

const variants: Variants = {
    enter: (direction: number) => {
        return {
            x: direction > 0 ? 0 : 0,
            opacity: 0, // Start fully transparent
            transition: {
                opacity: { duration: 0.5 } // Gradual fade-in effect
            }
        }
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1, // Fully visible
        transition: {
            opacity: { duration: 0.5 }, // Control the speed of fade-in
            x: { delay: 0.5 } // Delay the movement until after the fade-in
        }
    },
    exit: (direction: number) => {
        return {
            zIndex: 0,
            x: direction > 0 ? -500 : 500,
            opacity: 0, // Fade out completely
            transition: {
                opacity: { duration: 0.25 } // Gradual fade-out effect
            }
        }
    }
}

const AnimatedStep: React.FC<AnimatedStepProps> = React.memo(({ children, previousStep: previousStepIndex }) => {
    const { activeStep } = useWizard()

    React.useEffect(() => {
        return () => {
            previousStepIndex.current = activeStep
        }
    }, [activeStep, previousStepIndex])

    return (
        <motion.div custom={activeStep - previousStepIndex.current} variants={variants} initial='enter' animate='center' exit='exit'>
            {children}
        </motion.div>
    )
})

const AnimatedSection: React.FC<{
    onSave: (cohort: CohortSaveValues) => void
    onCancel: () => void
    isFirstStep: boolean
    setIsFirstStep: Dispatch<SetStateAction<boolean>>
}> = ({ onSave, onCancel, isFirstStep, setIsFirstStep }) => {
    const previousStep = React.useRef<number>(0)

    const [cohort, setCohort] = useState<CohortSaveValues | undefined>(undefined)
    const [wizardSelections, setWizardSelections] = useState({})

    return (
        <Box
            sx={{
                padding: '10px',
                backgroundColor: 'white',
                marginLeft: isFirstStep ? '00px' : '20px'
            }}
        >
            <Wizard wrapper={<AnimatePresence initial={false} mode='wait' />}>
                {Array(Object.keys(steps).length)
                    .fill(null)
                    .map((_, index) => {
                        return (
                            <AnimatedStep key={index} previousStep={previousStep}>
                                <Step
                                    number={index + 1}
                                    cohort={cohort}
                                    setCohort={setCohort}
                                    wizardSelections={wizardSelections}
                                    setWizardSelections={setWizardSelections}
                                    onSave={onSave}
                                    onCancel={onCancel}
                                    setIsFirstStep={setIsFirstStep}
                                ></Step>
                            </AnimatedStep>
                        )
                    })}
            </Wizard>
        </Box>
    )
}
