/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { FrameworkComponentProps } from '@om1/platform-utils'
import { get } from 'lodash'
import { useMemo } from 'react'
import {
    CriteriaType,
    CriterionNode,
    FilterQualifier,
    QualifierType,
    QueryFilterBase,
    SpecialtyQualifier,
    SpecialtyRef,
    cohortBlocksEditActions,
    isSpecialtyQualifier
} from '../../../state'
import { RefFieldMapperConfig } from '../base/CohortEditComponent'
import { SPECIALTY_REF_FIELD_MAPPER_CONFIG, createRefTreeDialog } from './RefTreeDialog'

export type SpecialtyEditDialogComponentProps = FrameworkComponentProps<
    {
        specialtyRefs: SpecialtyRef[]
        criteria?: CriterionNode
    },
    typeof cohortBlocksEditActions
>

const RefTreeDialog = createRefTreeDialog()

export const SpecialtyEditDialogComponent = ({ state, actions }: SpecialtyEditDialogComponentProps) => {
    const { criteria } = state
    const mappers: RefFieldMapperConfig<any>[] = SPECIALTY_REF_FIELD_MAPPER_CONFIG
    const { table, filterFieldOrder } = SPECIALTY_REF_FIELD_MAPPER_CONFIG[0].mapper

    const initialValue: QueryFilterBase[] = useMemo(() => {
        return get(criteria, ['qualifiers'], [])
            .filter((qualifier) => isSpecialtyQualifier(qualifier))
            .map((qualifier: FilterQualifier) => {
                return {
                    field: filterFieldOrder[0],
                    table,
                    operator: 'or',
                    values: (qualifier as SpecialtyQualifier).values
                } as QueryFilterBase
            })
    }, [criteria, filterFieldOrder, table])

    const existingQualifiers: FilterQualifier[] = useMemo(() => {
        return get(criteria, ['qualifiers'], []).filter((q) => !isSpecialtyQualifier(q))
    }, [criteria])

    const handleDialogCancel = () => {
        actions.specialtyEditDialogTrigger({})
    }

    const handleDialogSave = (filters: QueryFilterBase[]) => {
        actions.specialtyEditDialogTrigger({})

        const saveQualifiers = filters.reduce((qualifiers: FilterQualifier[], filter: QueryFilterBase) => {
            qualifiers.push({
                type: QualifierType.SpecialtyQualifierDTO,
                values: filter.values
            })

            return qualifiers
        }, existingQualifiers)

        actions.criteriaUpdate({ target: { nodeId: criteria!.id }, qualifiers: saveQualifiers })
    }

    return (
        <RefTreeDialog
            initialValue={initialValue}
            fieldMappers={mappers}
            onCancel={handleDialogCancel}
            onSave={handleDialogSave}
            criteriaType={CriteriaType.EhrNotes}
            onHandleRemoveQualifiers={() => {}}
        />
    )
}
