import { t } from '@lingui/macro'
import { AnalyticsRefDimension, CriteriaType, ProcedureRef } from '../../../../state'
import { DepthRefFieldMapper, DepthRefGetterCallback, RefFieldMapperType } from './interfaces'

const getItemLabel: DepthRefGetterCallback<ProcedureRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.procedure
    }
}

const getItemValue: DepthRefGetterCallback<ProcedureRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.procedureConceptId
    }
}

const getItemTooltip: DepthRefGetterCallback<ProcedureRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return `${getItemValue(item, depth)}: ${getItemLabel(item, depth)}`
    }
}

export const ProcedureRefFieldMapper: DepthRefFieldMapper<ProcedureRef> = {
    type: RefFieldMapperType.Depth,
    dimension: AnalyticsRefDimension.PROCEDURE,
    criteriaType: CriteriaType.Procedure,
    table: 'patient_procedure',
    dateField: {
        first: 'first_record_date',
        last: 'last_record_date',
        any: 'any_record_date'
    },
    dateFieldInverse: {
        first_record_date: 'first',
        last_record_date: 'last',
        any_record_date: 'any'
    },
    searchPlaceholder: (() => t`Search Procedures`)(),
    noResultsMessage: (() => t`No results`)(),
    searchHintMessage: (() => t`Search to browse procedures`)(),
    filterFieldToRefField: { procedure: 'name', procedure_display_name: 'displayName', procedure_concept_id: 'procedureConceptId' },
    filterFieldOrder: ['procedure_concept_id', 'procedure', 'procedure_display_name'],
    filterLabelOrder: ['procedure', 'procedure', 'procedure'], // Needs to match the number of items in filterFieldOrder
    getItemLabel,
    getItemValue,
    getItemTooltip
}
