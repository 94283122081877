import { ExplorerReportsService } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { notificationActions } from '@om1/platform-notifications'
import { call, put } from 'redux-saga/effects'
import { reportsActions } from '../state'

export function createDeleteReportSaga() {
    return function* (action: ReturnType<typeof reportsActions.reportDelete>) {
        try {
            yield put(reportsActions.reportsLoadingSet({ loading: true }))
            yield call(ExplorerReportsService.deleteExplorerReportsReportIdDelete, {
                reportId: action.payload.reportId
            })
            yield put(notificationActions.success())
            if (action.payload.refreshReport) {
                yield put(reportsActions.reportsGet({ cohortId: action.payload.cohortId, reportType: action.payload.reportType }))
            }
        } catch (error: any) {
            // Handle lock error by posting the error message
            if (error.status === 423) {
                yield put(notificationActions.error(error.body['detail']))
            } else {
                yield handleApiError(error)
            }
        } finally {
            yield put(reportsActions.reportsLoadingSet({ loading: false }))
        }
    }
}
