import * as yup from 'yup'

// Patch Yup validation schema with Array.unique method
declare module 'yup' {
    interface ArraySchema<T> {
        unique(message: string, mapper?: (value: T) => string | number): ArraySchema<T>
    }
}

yup.addMethod(yup.array, 'unique', function <
    T extends object
>(this: yup.SchemaOf<T[]>, message: string, mapper: (obj: T) => string | number | object = (a) => a) {
    return this.test('unique', message, function (list: any) {
        const uniqueSet = new Set(list.map(mapper))
        return uniqueSet.size === list.length
    })
})

export * from './src/pages'
export * from './src/routes'
export * from './src/saga'
export * from './src/state'
