import { CohortState } from '@om1/cohort-module'
import { cohortListActions } from '@om1/cohort-module/src/state'
import { withRouter } from '@om1/platform-routing'
import { PlatformSettingsState } from '@om1/platform-settings-module'
import { rwaInsightsReportActions } from '@om1/platform-settings-module/src/state/rwa-insights-reports'
import { connectRedux } from '@om1/platform-utils'
import { insightsLibraryActions } from '../state'
import { RegisterInsightsLibraryReportModalComponent } from './RegisterInsightsLibraryReportModalComponent'

export function createRegisterInsightsLibraryReportModal<TState extends { platformSettings: PlatformSettingsState; cohort: CohortState }>() {
    return connectRedux(
        withRouter(RegisterInsightsLibraryReportModalComponent, { namespace: 'insights-library', dataFetchAction: 'getRwaInsightsReports' }),
        (state: TState) => {
            return { ...state.platformSettings.rwaInsightsReports, ...state.cohort }
        },
        { ...rwaInsightsReportActions, ...cohortListActions, ...insightsLibraryActions }
    )
}

export type RegisterInsightsLibraryReportModal = ReturnType<typeof createRegisterInsightsLibraryReportModal>
