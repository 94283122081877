import { t } from '@lingui/macro'
import { Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import { FunctionComponent, PropsWithChildren } from 'react'

/**
 * A wrapper around components that are not yet implemented. Intended to show an obvious "grayed-out" state, along with
 * a hover message indicating an obvious "coming soon" message.
 */
export const ComingSoon: FunctionComponent<PropsWithChildren> = (props) => {
    return (
        <Tooltip placement='top' title={t`Feature coming soon`}>
            <Box sx={{ opacity: 0.2 }}>{props.children}</Box>
        </Tooltip>
    )
}
