import { connectRedux } from '@om1/platform-utils'
import { CohortState, CriterionNode, cohortBlocksEditActions } from '../../../state'
import { findNode } from '../../../state/edit/blocks/cohort-blocks-edit-reducer'
import { RecencyEditDialogComponent } from './RecencyEditDialogComponent'

export function createRecencyEditDialogComponent<TState extends { cohort: CohortState }>() {
    return connectRedux(
        RecencyEditDialogComponent,
        (state: TState) => {
            const nodeId = state.cohort.edit.blocks.ui.recencyEditDialog.target.nodeId

            return {
                criteria: findNode(state.cohort.edit.blocks.tree, nodeId) as CriterionNode
            }
        },
        cohortBlocksEditActions
    )
}

export type RecencyEditDialog = ReturnType<typeof createRecencyEditDialogComponent>
