import { cohortListActions } from '@om1/cohort-module/src/state'
import { Auth0State } from '@om1/platform-authentication'
import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { insightsLibraryActions, InsightsLibraryState } from '../state'
import { PowerBIPageComponent } from './PowerBIPageComponent'

export function createPowerBIPageComponent<TState extends { insightsLibrary: InsightsLibraryState; auth0: Auth0State }>() {
    return connectRedux(
        withRouter(PowerBIPageComponent, { namespace: 'insights-library', dataFetchAction: 'getInsightsLibraryReports' }),
        (state: TState) => {
            return { insightsLibrary: state.insightsLibrary, permissions: state.auth0.permissions }
        },
        { ...insightsLibraryActions, ...cohortListActions }
    )
}

export type PowerBIPage = ReturnType<typeof createPowerBIPageComponent>
