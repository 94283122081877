import { connectRedux } from '@om1/platform-utils'
import { CohortState } from '../../../state'
import { CohortCriterionRefSummaryComponent } from './CohortCriterionRefSummaryComponent'

export function createCohortCriterionRefSummaryComponent<TState extends { cohort: CohortState }>() {
    return connectRedux(
        CohortCriterionRefSummaryComponent,
        (state: TState) => ({
            refLabels: state.cohort.edit.blocks.ui.refLabels,
            isLoading: state.cohort.edit.blocks.ui.refLabelsLoading
        }),
        {}
    )
}

export type CohortCriterionRefSummary = ReturnType<typeof createCohortCriterionRefSummaryComponent>
