import { yupResolver } from '@hookform/resolvers/yup'
import { t, Trans } from '@lingui/macro'
import CloseIcon from '@mui/icons-material/Close'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent
} from '@mui/material'
import { Box } from '@mui/system'
import { cohortListActions, CohortListItem, CohortState } from '@om1/cohort-module/src/state'
import { CohortInsightsReportDTO, InsightsReportDTO } from '@om1/falcon-api'
import { rwaInsightsReportActions, RWAInsightsReportsState } from '@om1/platform-settings-module/src/state/rwa-insights-reports'
import { RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { insightsLibraryActions } from '../state'

export type RegisterInsightsLibraryReportModalComponentProps = RoutedFrameworkComponentProps<
    {},
    {},
    RWAInsightsReportsState & CohortState,
    typeof rwaInsightsReportActions & typeof insightsLibraryActions & typeof cohortListActions,
    { showRegisterInsightsLibraryReportModal: boolean; closeModal: () => void }
>

/**
 * A dialog.
 */
export const RegisterInsightsLibraryReportModalComponent: FunctionComponent<RegisterInsightsLibraryReportModalComponentProps> = ({
    state,
    actions,
    props
}) => {
    const { showRegisterInsightsLibraryReportModal, closeModal } = props

    const [loading, setLoading] = useState(state.list.loading || state.loading)

    useEffect(() => {
        if (state.list.loading || state.loading) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [state.list.loading, state.loading])

    const handleClose = () => {
        closeModal()
    }

    const onSubmit: SubmitHandler<CohortInsightsReportDTO> = (formValues: CohortInsightsReportDTO) => {
        actions.createInsightsLibraryReport({ cohortInsightsReport: formValues })
        closeModal()
    }

    const validationSchema = Yup.object().shape({
        cohortId: Yup.string().required(t`cohortId is required`),
        insightsReportId: Yup.string().required(t`insightsReportId is required`)
    })

    const {
        handleSubmit,
        control,
        register,
        formState: { errors }
    } = useForm<CohortInsightsReportDTO>({
        defaultValues: {
            cohortId: '',
            insightsReportId: ''
        },
        resolver: yupResolver(validationSchema)
    })

    const formatPatientCount = (patientCount: number | null | undefined | undefined) => {
        const label = patientCount === 1 ? 'patient' : 'patients'
        const numberFormatter = new Intl.NumberFormat(undefined)
        return `${numberFormatter.format(patientCount || 0)} ${label}`
    }

    return (
        <Dialog
            open={showRegisterInsightsLibraryReportModal}
            maxWidth='sm'
            fullWidth
            aria-labelledby='add-condition-dialog-title'
            onClose={handleClose}
        >
            <DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={handleClose}
                    sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                >
                    <CloseIcon />
                </IconButton>
                <Box id='add-condition-dialog-title' pr={4}>
                    <Trans>Register Insights Library Report</Trans>
                </Box>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box position='relative' display='flex' flexDirection='column' flex={1} sx={{ overflowY: 'auto' }}>
                        <Box component='pre' p={2} m={0} sx={{ overflow: 'auto' }}>
                            <Box display={'flex'} flexDirection={'column'}>
                                <FormControl fullWidth sx={{ marginBottom: '20px' }}>
                                    <InputLabel required>
                                        <Trans>System Cohort</Trans>
                                    </InputLabel>
                                    <Controller
                                        control={control}
                                        name='cohortId'
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                id='cohortId'
                                                {...register('cohortId')}
                                                labelId='cohortId-label'
                                                label={<Trans>Select Cohort</Trans>}
                                                disabled={loading}
                                                value={value}
                                                error={!!errors['cohortId']}
                                                onChange={onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}
                                            >
                                                {state.list.cohorts.SYSTEM.map((item: CohortListItem) => (
                                                    <MenuItem value={item.id} key={item.id}>
                                                        {item.analyticsDataset.name} ({formatPatientCount(item.cohortSize)})
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel required>
                                        <Trans>Insight Report</Trans>
                                    </InputLabel>
                                    <Controller
                                        control={control}
                                        name='insightsReportId'
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                id='insightReportId'
                                                {...register('insightsReportId')}
                                                labelId='insightReportId-label'
                                                label={<Trans>Select Insight Report</Trans>}
                                                disabled={loading}
                                                value={value}
                                                error={!!errors['insightReportId']}
                                                onChange={onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}
                                            >
                                                {state.insightReports.map((item: InsightsReportDTO) => (
                                                    <MenuItem value={item.id} key={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' color='primary' onClick={closeModal}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button variant='contained' type='submit'>
                        <Trans>Save</Trans>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
