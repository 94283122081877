import { DataDeliveryAdminDeliveryService, DeliveryDTO, FalconPaginationQuery, PaginatedDTO_DeliveryDTO_ } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { DataFetchParams } from '@om1/platform-routing'
import { ActionWithPayload } from '@om1/platform-utils'
import { PutEffect, all, call, put } from 'redux-saga/effects'
import { DataDeliveryActionTypes, IN_PROGRESS_TASK_STATUSES, dataDeliveryActions } from '../state/data-delivery'

export function createGetDataDeliveriesSaga() {
    return function* (
        action: ActionWithPayload<DataDeliveryActionTypes.DATA_DELIVERIES_GET, { params: DataFetchParams<FalconPaginationQuery, {}> }>
    ) {
        try {
            yield put(dataDeliveryActions.dataDeliveriesLoading({ loading: true }))
            const { page, limit } = action.payload.params.query
            const response: PaginatedDTO_DeliveryDTO_ = yield call(DataDeliveryAdminDeliveryService.indexDataDeliveryDeliveryGet, {
                page,
                limit
            })
            yield put(dataDeliveryActions.dataDeliveriesSet({ data: response.data, meta: response.meta }))
            yield all(
                response.data
                    .map((d: DeliveryDTO) => {
                        if (d.task && IN_PROGRESS_TASK_STATUSES.includes(d.task?.status)) {
                            return put(dataDeliveryActions.dataDeliveryAddTaskListener({ taskId: d.task.taskId, deliveryId: d.id }))
                        } else {
                            return null
                        }
                    })
                    .filter((effect: PutEffect | null): effect is PutEffect => effect !== null)
            )
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(dataDeliveryActions.dataDeliveriesLoading({ loading: false }))
        }
    }
}
