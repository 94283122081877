import { ReportResultDTO } from '@om1/falcon-api'
import { ReportQueryFilters } from '../cohort-state'

export interface ReportSection {
    id: string
    queries: ReportQueryFilters[]
}

export interface Report {
    id: string
    reportType: ReportType
    cohortId: string
    sections: ReportSection[]
    createdDttm: string
    updatedDttm: string
    createdById: string
    lastUpdatedBy: string
    organizationId: string
}

export enum ReportType {
    DIAGNOSIS = 'diagnosis',
    MEDICATION = 'medication',
    OBSERVATION = 'observation',
    PROCEDURE = 'procedure',
    DATA_TYPE = 'data_type',
    DEMOGRAPHICS = 'demographics',
    LAB_TEST = 'lab'
}

export enum ReportName {
    DIAGNOSIS = 'Diagnoses',
    MEDICATION = 'Medications',
    OBSERVATION = 'Observations',
    PROCEDURE = 'Procedures',
    DATA_TYPE = 'Data Types',
    DEMOGRAPHICS = 'Demographics',
    LAB_TEST = 'Lab Tests'
}

export interface ReportsState {
    ui: {
        loading: boolean
    }
    reports: Report[]
    summaryReportResult: Array<ReportResultDTO> | undefined
}

export const initialReportsState: ReportsState = {
    ui: {
        loading: true
    },
    reports: [],
    summaryReportResult: undefined
}
