import { Trans, t } from '@lingui/macro'
import { ReactNode } from 'react'
import { DateRangeIntervalUnit, FollowUpLengthQualifier, ObservationPeriodCriterion, QualifierType } from '../../../state'
import { findQualifierByType } from '../utils/finders'
import { CohortCriterionQualifierSummary } from './CohortCriterionQualifierSummary'

export interface CohortCriterionFollowUpLengthSummaryProps {
    node: ObservationPeriodCriterion
    readOnly?: boolean
    onEditClick: () => void
    onDeleteClick: () => void
}

export const CohortCriterionFollowUpLengthSummary = (props: CohortCriterionFollowUpLengthSummaryProps) => {
    const { node, readOnly, onEditClick, onDeleteClick } = props

    const followUpLengthQualifier = findQualifierByType<FollowUpLengthQualifier>(node.qualifiers, QualifierType.FollowUpLengthQualifierDTO)

    const label: ReactNode = (() => {
        if (!followUpLengthQualifier) {
            return <></>
        }

        const { units, amount } = followUpLengthQualifier

        let unitsLabel = <></>
        if (units === DateRangeIntervalUnit.Day) {
            unitsLabel = amount === 1 ? <Trans>Day</Trans> : <Trans>Days</Trans>
        } else if (units === DateRangeIntervalUnit.Month) {
            unitsLabel = amount === 1 ? <Trans>Month</Trans> : <Trans>Months</Trans>
        } else if (units === DateRangeIntervalUnit.Year) {
            unitsLabel = amount === 1 ? <Trans>Year</Trans> : <Trans>Years</Trans>
        }

        return (
            <>
                <Trans>At Least</Trans> {followUpLengthQualifier.amount} {unitsLabel} <Trans>Long</Trans>
            </>
        )
    })()

    return (
        <CohortCriterionQualifierSummary
            summaryText={label}
            readOnly={readOnly}
            editAction={{ label: t`Edit Follow Up Length`, onClick: onEditClick }}
            deleteAction={{ label: t`Delete Follow Up Length`, onClick: onDeleteClick }}
        />
    )
}
