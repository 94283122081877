import { Trans } from '@lingui/macro'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { Box, Button, Dialog, DialogContent, DialogContentText } from '@mui/material'
import React from 'react'
import { reportsActions, ReportType } from '../../state'

export interface DeleteReportDialogProps {
    id: string
    type: ReportType
    cohortId: string
    actions: Pick<typeof reportsActions, 'reportDelete'>
    onCancel: () => void
}

/**
 * A dialog used to confirm and trigger the deletion of a report.
 */
export const DeleteReportDialog: React.FunctionComponent<DeleteReportDialogProps> = (props) => {
    const closeModal = () => {
        props.onCancel()
    }
    const handleConfirmNavigationClick = () => {
        props.actions.reportDelete({ reportId: props.id, refreshReport: true, reportType: props.type, cohortId: props.cohortId })
        props.onCancel()
    }
    return (
        <Dialog open onClose={closeModal} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    height: '375px',
                    width: '500px',
                    backgroundColor: '#FFFFFF',
                    boxShadow: '15px 10px 56px 0 rgba(0,0,0,0.17)'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <DeleteIcon
                        sx={{
                            width: '120px',
                            height: '120px',
                            color: '#002D72'
                        }}
                    />

                    <DialogContentText
                        id='alert-dialog-description'
                        sx={{
                            padding: '20px',
                            color: '#002D72',

                            fontSize: '20px',
                            letterSpacing: '0',
                            lineHeight: '30px',
                            textAlign: 'center'
                        }}
                    >
                        <Trans>Remove Filters</Trans>
                    </DialogContentText>
                    <DialogContentText
                        id='alert-dialog-description'
                        sx={{ padding: '20px', color: '#7F7F7F', fontSize: '14px', letterSpacing: '0', lineHeight: '20px' }}
                    >
                        <Trans>Are you sure you want to remove the filters from this report?</Trans>
                    </DialogContentText>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                    }}
                >
                    <Button
                        sx={{
                            textTransform: 'none',

                            fontSize: '16px',
                            marginLeft: '10px',
                            marginRight: '10px',
                            padding: '10px 20px 10px 20px'
                        }}
                        onClick={closeModal}
                    >
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button
                        variant='contained'
                        sx={{
                            textTransform: 'none',

                            fontSize: '16px',
                            letterSpacing: '0',
                            marginLeft: '10px',
                            marginRight: '10px',
                            padding: '10px 20px 10px 20px',
                            color: '#FFFFFF'
                        }}
                        onClick={handleConfirmNavigationClick}
                        autoFocus
                    >
                        <Trans>Yes, Remove</Trans>
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
