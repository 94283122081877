/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedDTO_DeliveredDatabaseDTO_ } from '../models/PaginatedDTO_DeliveredDatabaseDTO_'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class DataDeliveryAdminDeliveredDatabasesService {
    /**
     * Index
     * @returns PaginatedDTO_DeliveredDatabaseDTO_ Successful Response
     * @throws ApiError
     */
    public static indexDataDeliveryDeliveredDatabasesGet({
        page = 1,
        limit = 10
    }: {
        /**
         * Page number for pagination, starting at 1
         */
        page?: number
        /**
         * Number of items per page, maximum 1000
         */
        limit?: number
    }): CancelablePromise<PaginatedDTO_DeliveredDatabaseDTO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/data_delivery/delivered_databases',
            query: {
                page: page,
                limit: limit
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
}
