import { RedirectLoginOptions, WithAuthenticationRequiredOptions, useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import '@fontsource/metropolis' // Defaults to weight 400.
import { AllLocaleData, AllMessages } from '@lingui/core'
import { Trans } from '@lingui/macro'
import { Button, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, ThemeProvider, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { OpenAPI } from '@om1/falcon-api/codegen/client/core/OpenAPI'
import { Auth0PlatformProvider } from '@om1/platform-authentication'
import { platformConfigActions } from '@om1/platform-config/state'
import { NotificationState } from '@om1/platform-notifications'
import { TrackingProvider, createTrackingMiddleware, useAnalytics } from '@om1/platform-tracking'
import { createHelpDialog } from '@om1/platform-ui-kit/src/components/sidebar/HelpDialog'
import { createTimeoutDialog } from '@om1/platform-ui-kit/src/components/sidebar/TimeoutDialog'
import { platformTheme } from '@om1/platform-ui-kit/src/theme'
import { withConfig } from '@om1/platform-utils'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { en, es } from 'make-plural'
import { SnackbarProvider, useSnackbar } from 'notistack'
import React from 'react'
import { Provider } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { addMiddleware } from 'redux-dynamic-middlewares'
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga'
import packageJson from '../package.json'
import { ConfigInterface, config } from './Config'
import { messages as enMessages } from './locales/en/messages'
import { messages as esMessages } from './locales/es/messages'
import { createLandingPageComponent } from './pages/LandingPage'
import { AppStore, PlatformState, createAppStore, createReducer, createRootSaga } from './platform-state'
import LanguageProvider from './shared/providers/internationalization/LanguageProvider'
import { LocaleEnum } from './shared/providers/internationalization/language-config'

function App() {
    // create top level react contexts (router history, saga middleware, redux store etc)
    const history = createBrowserHistory()
    const appReducer = createReducer(history)
    const sagaMiddleware = createSagaMiddleware()
    const store = createAppStore(appReducer, sagaMiddleware, routerMiddleware(history))

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Auth0PlatformProvider>
                    <SnackbarProvider maxSnack={3}>
                        <TrackingProvider>
                            <ConfiguredApp sagaMiddleware={sagaMiddleware} store={store} />
                        </TrackingProvider>
                    </SnackbarProvider>
                </Auth0PlatformProvider>
            </ConnectedRouter>
        </Provider>
    )
}

interface ConfiguredAppComponentProps {
    config: ConfigInterface
    sagaMiddleware: SagaMiddleware<object>
    store: AppStore
}

const ConfiguredAppComponent: React.FunctionComponent<ConfiguredAppComponentProps> = ({
    config,
    sagaMiddleware,
    store
}: ConfiguredAppComponentProps) => {
    let { falconApiUrl, writeKey, buildNumber, environmentTag } = config
    const { isAuthenticated, isLoading, logout } = useAuth0()

    const [error_description, setErrorDescription] = React.useState<string | undefined>(undefined)

    OpenAPI.BASE = falconApiUrl

    const { enqueueSnackbar } = useSnackbar()
    const analytics = useAnalytics(writeKey)

    // create tracking middleware and add to redux
    const trackingMiddleware = createTrackingMiddleware(analytics)
    addMiddleware(trackingMiddleware)

    // start redux-sagas
    const rootsaga = createRootSaga(enqueueSnackbar, analytics)
    const [firstLoad, setFirstLoad] = React.useState(true)
    if (rootsaga && firstLoad) {
        sagaMiddleware.run(rootsaga)
        setFirstLoad(false)
    }

    // manages the auth0 authentication status, issues actions based on changes
    if (isAuthenticated && !isLoading) {
        // manages versioning and environment tags
        // store.dispatch(falconApiActions.getFalconVersion())
        store.dispatch(platformConfigActions.setBuildNumber(buildNumber))
        store.dispatch(platformConfigActions.setEnvironmentTag(environmentTag))
        store.dispatch(platformConfigActions.setSemanticVersion(packageJson.version))
    }

    // establish translations
    const messages: AllMessages = {
        en: enMessages,
        es: esMessages
    }
    const plurals: AllLocaleData = {
        en: { plurals: en },
        es: { plurals: es }
    }

    const location = useLocation()

    const loginOptions: RedirectLoginOptions<{ from: string }> = {
        openUrl: (url: string) => {
            function getSearchParams(search) {
                const params = new URLSearchParams(search)
                const obj = {}
                for (const [key, value] of params) {
                    obj[key] = value
                }
                return obj
            }
            const searchParams: { error?: string; error_description?: string; state?: string } = getSearchParams(window.location.search)
            const hasError = searchParams.error
            if (!hasError) {
                window.location.href = url
            } else {
                setErrorDescription(searchParams.error_description)
            }
        },
        appState: { from: location.pathname }
    }

    const withAuthenticationRequiredOptions: WithAuthenticationRequiredOptions = {
        onRedirecting: () => (
            <div>
                <Trans>Loading...</Trans>
            </div>
        ),
        loginOptions: loginOptions
    }

    // create landing page (base of the application, main routing, top nav etc)
    const LandingPage = withAuthenticationRequired(createLandingPageComponent<PlatformState>(), withAuthenticationRequiredOptions)

    const HelpDialog = createHelpDialog<{ notification: NotificationState }>()
    const TimeoutDialog = createTimeoutDialog<{ notification: NotificationState }>()

    const Header = styled('h1')`
        color: white;
        height: 0;
        margin: 0;
    `

    return (
        <React.StrictMode>
            <LanguageProvider language={LocaleEnum.English} messages={messages} plurals={plurals}>
                <ThemeProvider theme={platformTheme}>
                    <CssBaseline />
                    <Header>
                        <Trans>App Platform</Trans>
                    </Header>
                    <HelpDialog />
                    <TimeoutDialog />
                    <LandingPage />
                    <Dialog open={error_description !== undefined}>
                        <DialogTitle>
                            <Trans>Error</Trans>
                        </DialogTitle>
                        <DialogContent>
                            <Typography>{error_description} </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    logout()
                                }}
                                color='primary'
                                autoFocus
                            >
                                <Trans>Logout</Trans>
                            </Button>
                        </DialogActions>
                    </Dialog>
                </ThemeProvider>
            </LanguageProvider>
        </React.StrictMode>
    )
}

const ConfiguredApp = withConfig<ConfiguredAppComponentProps>(ConfiguredAppComponent, config)

export default App
