import { Box } from '@mui/material'
import { FrameworkComponentProps } from '@om1/platform-utils'
import React, { useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { CohortDraggingState, CohortTreeActionType, OperationNode, cohortBlocksEditActions } from '../../../state'
import { useCriteriaDropZone } from '../utils/useCriteriaDropZone'
import { CohortNodeRenderer } from './CohortNodeRenderer'

export type CohortOperationProps = FrameworkComponentProps<
    { node: OperationNode; dragState: CohortDraggingState },
    typeof cohortBlocksEditActions,
    { readOnly?: boolean }
>

/**
 * A sub-tree renderer that takes an operation node and renders itself and its direct descendents.
 */
export const CohortOperation: React.FunctionComponent<CohortOperationProps> = ({ state: { node, dragState }, actions, props: { readOnly } }) => {
    return (
        <Box display='flex' flexDirection='row-reverse' gap={2} data-testid='operation-node'>
            <Box position='relative'>
                <CohortTreeNodeOperationComponent state={{ node, dragState: dragState }} actions={actions} props={{ readOnly }} />
                {/* Small horizontal line emitted from the left of the operation block */}
                <Box position='absolute' width={8} height='1px' top={32} left={-8} bgcolor='primary.light' />
            </Box>
            <Box flexGrow={1} minWidth={0}>
                <Box display='flex' flexDirection='column' gap={2}>
                    {node.children.map((child, index) => (
                        <Box key={child.id} position='relative'>
                            <CohortNodeRenderer
                                state={{ node: child, parentOperation: node.operation, dragState: dragState }}
                                actions={actions}
                                props={{ readOnly }}
                            />
                            {/* Small horizontal line emitted from the right of the child block */}
                            <Box position='absolute' width={8} height='1px' top={32} right={-9} bgcolor='primary.light' />
                            {/* Half height vertical line for the first item in a list */}
                            {index === 0 && <Box position='absolute' width='1px' top={32} right={-9} bottom={-8} bgcolor='primary.light' />}
                            {/* Full height vertical line for middle items in a list */}
                            {index > 0 && index < node.children.length - 1 && (
                                <Box position='absolute' width='1px' top={-8} right={-9} bottom={-8} bgcolor='primary.light' />
                            )}
                            {/* Half height vertical line for the last item in a list */}
                            {index === node.children.length - 1 && (
                                <Box position='absolute' width='1px' height={32 + 8} top={-8} right={-9} bgcolor='primary.light' />
                            )}
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    )
}

export type CohortTreeNodeOperationComponentProps = FrameworkComponentProps<
    { node: OperationNode; dragState: CohortDraggingState },
    typeof cohortBlocksEditActions,
    { readOnly?: boolean }
>
export const CohortTreeNodeOperationComponent: React.FunctionComponent<CohortTreeNodeOperationComponentProps> = ({
    state: { node, dragState },
    actions,
    props: { readOnly }
}) => {
    const ref = useRef<HTMLDivElement>(null)

    const { dropProps, isDropTarget } = useCriteriaDropZone({
        ref,
        onAdd: (type) =>
            actions.criteriaDialogTrigger({
                type,
                action: { type: CohortTreeActionType.Insert, target: { nodeId: node.id }, uuid: uuidv4() }
            }),
        onCopy: (source) => actions.criteriaCopy({ source, target: { nodeId: node.id } }),
        onMove: (source) => actions.criteriaMove({ source, target: { nodeId: node.id } })
    })

    let sx = { borderStyle: 'none' }
    if (!readOnly) {
        sx = { borderStyle: dragState.active ? 'dashed' : 'none' }
    }

    return (
        <Box
            // Drag and drop
            ref={ref}
            role={!readOnly ? 'button' : undefined}
            tabIndex={!readOnly ? 0 : undefined}
            {...(!readOnly && dropProps)}
            // Styles
            boxSizing='border-box'
            position='relative'
            display='flex'
            alignItems='center'
            justifyContent='center'
            width={64}
            height={64}
            p={1}
            border={1}
            boxShadow={1}
            bgcolor={isDropTarget ? 'grey.400' : 'white'}
            sx={sx}
        >
            {node.operation}
        </Box>
    )
}
