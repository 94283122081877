import { ActionsUnion, createAction } from '@om1/platform-utils'

export enum CohortCommonActionTypes {
    COHORT_SIZE_GET = '@@cohort/common/cohort-size-get',
    COHORT_AUTOSAVE_SIZE_GET = '@@cohort/common/cohort-autosave-size-get',
    COHORT_SIZE_LOADING_SET = '@@cohort/common/cohort-size-loading-set',
    COHORT_GENERATE_SUMMARY_REPORT = '@@cohort/common/cohort-generate-summary-report',
    COHORT_SUMMARY_REPORT_LOADING_SET = '@@cohort/common/cohort-summary-report-loading-set'
}

export const cohortCommonActions = {
    cohortSizeGet: (payload: { cohortId: string; cohortName: string }) => createAction(CohortCommonActionTypes.COHORT_SIZE_GET, payload),
    cohortAutosaveSizeGet: (payload: { cohortId: string; cohortName: string }) =>
        createAction(CohortCommonActionTypes.COHORT_AUTOSAVE_SIZE_GET, payload),
    cohortSizeLoadingSet: (payload: { cohortId: string; loading: boolean }) => createAction(CohortCommonActionTypes.COHORT_SIZE_LOADING_SET, payload),
    cohortSummaryReportLoadingSet: (payload: { cohortId: string; loading: boolean }) =>
        createAction(CohortCommonActionTypes.COHORT_SUMMARY_REPORT_LOADING_SET, payload),
    generateSummaryReport: (payload: { cohortId: string; cohortName: string }) =>
        createAction(CohortCommonActionTypes.COHORT_GENERATE_SUMMARY_REPORT, payload)
}

export type CohortCommonActions = ActionsUnion<typeof cohortCommonActions>
