import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { FrameworkComponentProps } from '@om1/platform-utils'
import React, { useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { CohortTreeActionType, OperationNode, cohortBlocksEditActions } from '../../../state'
import { useCriteriaDropZone } from '../utils/useCriteriaDropZone'
import { CRITERIA_DEFAULTS } from '../base/CohortEditComponent'

export type EmptyDropZoneProps = FrameworkComponentProps<{ node: OperationNode }, typeof cohortBlocksEditActions, Record<string, never>>

/**
 * An always-present drop zone displayed in a criteria block when it is empty.
 */
export const EmptyDropZone: React.FunctionComponent<EmptyDropZoneProps> = ({ state, actions }) => {
    const ref = useRef<HTMLDivElement>(null)

    const { dropProps, isDropTarget } = useCriteriaDropZone({
        ref,
        onAdd: (type) => {
            const uuid = uuidv4()
            if (Object.keys(CRITERIA_DEFAULTS).includes(type)) {
                actions.criteriaInsert({ target: { nodeId: state.node.id }, criteriaType: type, filters: CRITERIA_DEFAULTS[type], uuid })
            } else {
                actions.criteriaDialogTrigger({ type, action: { type: CohortTreeActionType.Insert, target: { nodeId: state.node.id }, uuid } })
            }
        },
        onCopy: (source) => actions.criteriaCopy({ source, target: { nodeId: state.node.id } }),
        onMove: (source) => actions.criteriaMove({ source, target: { nodeId: state.node.id } })
    })

    return (
        <Box
            // Drag and drop
            ref={ref}
            role='button'
            tabIndex={0}
            {...dropProps}
            // Styles
            p={2}
            border={2}
            borderColor='primary.light'
            bgcolor={isDropTarget ? 'grey.200' : 'transparent'}
            textAlign='center'
            sx={{ borderStyle: 'dashed' }}
        >
            <Trans>Drag and drop here to add criteria</Trans>
        </Box>
    )
}
