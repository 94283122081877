import { BackOfficeState, backOfficeReducer, createBackOfficeSaga } from '@om1/back-office-module'
import { CohortState, cohortReducer, createCohortsSaga } from '@om1/cohort-module'
import { FalconApiState, createFalconApiSaga, falconApiReducer } from '@om1/falcon-api'
import { InsightsLibraryState, createInsightsLibrarySaga, insightsLibraryReducer } from '@om1/insights-library-module'
import { Auth0State, KeycloakActionTypes, auth0Reducer } from '@om1/platform-authentication'
import { PlatformConfigState, platformConfigReducer } from '@om1/platform-config/state'
import { NotificationState, createNotificationSaga, notificationActions, notificationReducer } from '@om1/platform-notifications'
import { PlatformSettingsState, createPlatformSettingsSaga, platformSettingsReducer } from '@om1/platform-settings-module'
import { createTrackingSaga } from '@om1/platform-tracking'
import { configureStore } from '@reduxjs/toolkit'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { RouterState, connectRouter } from 'connected-react-router'
import { LocationState } from 'history'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'
import { AnyAction, CombinedState, Middleware, Reducer, applyMiddleware, combineReducers } from 'redux'
import dynamicMiddlewares from 'redux-dynamic-middlewares'
import { SagaMiddleware } from 'redux-saga'
import { all, put, spawn } from 'redux-saga/effects'
import keepAlive from './keep-alive'
import { languageReducer } from './shared/providers/internationalization/language-reducer'
import { LanguageState } from './shared/providers/internationalization/language-state'

export const createReducer = (history: any) =>
    combineReducers({
        router: connectRouter(history),
        language: languageReducer,
        notification: notificationReducer,
        // modules:
        backOffice: backOfficeReducer,
        cohort: cohortReducer,
        platformConfig: platformConfigReducer,
        falconApi: falconApiReducer,
        auth0: auth0Reducer,
        platformSettings: platformSettingsReducer,
        insightsLibrary: insightsLibraryReducer
    })
export const appReducer = createReducer(window.history)

export type PlatformState = ReturnType<typeof appReducer>

export function createAppStore(
    reducer: Reducer<
        CombinedState<{
            router: RouterState<LocationState>
            language: LanguageState
            notification: NotificationState
            // modules:
            backOffice: BackOfficeState
            cohort: CohortState
            platformConfig: PlatformConfigState
            falconApi: FalconApiState
            auth0: Auth0State
            platformSettings: PlatformSettingsState
            insightsLibrary: InsightsLibraryState
        }>,
        AnyAction
    >,
    sagaMiddleware: SagaMiddleware,
    ...additionalMiddlewares: Middleware[]
) {
    const middlewares = [dynamicMiddlewares, ...additionalMiddlewares]
    return configureStore({
        reducer: reducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: { ignoredActions: ['@@notifications/success', '@@notifications/error', '@@notifications/warning'] }
            }).concat(sagaMiddleware),
        enhancers: [applyMiddleware(...middlewares)]
    })
}

export type AppStoreActions = KeycloakActionTypes

export type AppStore = ReturnType<typeof createAppStore>

function createOnFail(sagaName: string) {
    function* onFail(error) {
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        const errorMessage = `${sagaName} module has produced too many uncaught errors and is shutting down, these exceptions should be handled.`
        yield console.warn(errorMessage)
        yield console.error(error)
        yield put(notificationActions.error(errorMessage))
    }
    function* onEachError(_next, error, _saga) {
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        const errorMessage = `${sagaName} module has produced an error and is restarting, these exceptions should be handled.`
        yield console.warn(errorMessage)
        yield console.error(error)
        yield put(notificationActions.warning(errorMessage))
    }
    return { onFail, onEachError, disableWarnings: true }
}

export function createRootSaga(enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey, analytics?: AnalyticsBrowser) {
    const notificationSaga = createNotificationSaga(enqueueSnackbar)
    // modules:
    const falconApiSaga = createFalconApiSaga()
    const backOfficeSaga = createBackOfficeSaga()
    const cohortsSaga = createCohortsSaga()
    const platformSettingsSaga = createPlatformSettingsSaga()
    const insightsLibrarySaga = createInsightsLibrarySaga()

    let sagas = [
        { saga: notificationSaga, name: 'Notifications' },
        // modules:
        { saga: backOfficeSaga, name: 'BackOffice' },
        { saga: cohortsSaga, name: 'Cohorts' },
        { saga: falconApiSaga, name: 'FalconAPI' },
        { saga: platformSettingsSaga, name: 'PlatformSettings' },
        { saga: insightsLibrarySaga, name: 'InsightsLibrary' }
    ]

    if (analytics) {
        const trackingSaga = createTrackingSaga(analytics)
        sagas.push({ saga: trackingSaga, name: 'User Tracking' })
    }

    return function* () {
        yield all(sagas.map((saga) => spawn(keepAlive(saga.saga, createOnFail(saga.name)))))
    }
}
