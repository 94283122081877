import { Trans, t } from '@lingui/macro'
import { ReactNode } from 'react'
import { CountDistinctQualifier, CriteriaType, CriterionNode, isCountDistinctQualifier } from '../../../state'
import { DEFAULT_COUNT_DISTINCT_QUALIFIER } from '../utils/constants'
import { CohortCriterionQualifierSummary } from './CohortCriterionQualifierSummary'

export interface CohortCriterionOccurrenceSummaryProps {
    node: CriterionNode
    readOnly?: boolean
    onEditClick: () => void
}

export const CohortCriterionOccurrenceSummary = ({ node, readOnly, onEditClick }: CohortCriterionOccurrenceSummaryProps) => {
    let label: ReactNode
    if (node.dateField === 'first') {
        if (node.type === CriteriaType.Diagnosis) {
            label = <Trans>Initial Diagnosis</Trans>
        } else if (node.type === CriteriaType.Medication) {
            label = <Trans>Medication Initiation</Trans>
        } else if (node.type === CriteriaType.EhrNotes) {
            label = <Trans>First Note</Trans>
        } else {
            label = <Trans>First Encounter</Trans>
        }
    } else if (node.dateField === 'last') {
        label = <Trans>Latest Encounter</Trans>
        if (node.type === CriteriaType.EhrNotes) {
            label = <Trans>Latest Note</Trans>
        }
    } else if (node.dateField === 'any') {
        let atLeastUnit = <Trans>Encounter(s)</Trans>
        if (node.type === CriteriaType.EhrNotes) {
            atLeastUnit = <Trans>Note(s)</Trans>
        }

        const existingCountDistinctQualifier = node.qualifiers.filter((q) => isCountDistinctQualifier(q))[0] as CountDistinctQualifier
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        label = `At Least ${existingCountDistinctQualifier?.value || DEFAULT_COUNT_DISTINCT_QUALIFIER.value} ${atLeastUnit.props.id}`
        // TODO: add this back once i18n is working with the cohort module
        // label = <Trans>At Least {existingCountDistinctQualifier?.value || DEFAULT_COUNT_DISTINCT_QUALIFIER.value} Encounter(s)</Trans>
    } else {
        label = <Trans>Unknown</Trans>
    }

    return (
        <CohortCriterionQualifierSummary summaryText={label} readOnly={readOnly} editAction={{ label: t`Edit Occurrence`, onClick: onEditClick }} />
    )
}
