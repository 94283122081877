import { ActionsUnion, createAction } from '@om1/platform-utils'
import { LocaleEnum } from './language-config'

export enum LanguageActionTypes {
    SET_LOCALE = '@@language/set-locale'
}

export const languageActions = {
    setLanguage: (locale: LocaleEnum) =>
        createAction(LanguageActionTypes.SET_LOCALE, {
            locale
        })
}

export type LanguageActions = ActionsUnion<typeof languageActions>
