import { withConfig } from '@om1/platform-utils'
import { AnalyticsBrowser } from '@segment/analytics-next'
import React from 'react'
import { AnalyticsContext } from './tracking'
import { trackingConfig, TrackingConfigInterface } from './tracking-config'

export interface TrackingProviderComponentProps {
    config: TrackingConfigInterface
    children: React.ReactNode
}

export const TrackingProviderComponent: React.FunctionComponent<TrackingProviderComponentProps> = ({
    config,
    children
}: {
    config: TrackingConfigInterface
    children: React.ReactNode
}) => {
    let { writeKey } = config
    const analytics: AnalyticsBrowser | undefined = writeKey ? AnalyticsBrowser.load({ writeKey }) : undefined
    return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>
}

export const TrackingProvider = withConfig<TrackingProviderComponentProps>(TrackingProviderComponent, trackingConfig)
