import { BackOfficeRoutes, createDataDeliveryPage } from '@om1/back-office-module'
import { ContentContainerStyle, ContentPadding } from '@om1/platform-ui-kit/src/components/Layout'
import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { PlatformState } from '../../platform-state'
import { PlatformRoute, toRoutingConfigObject } from '@om1/cohort-module'

const DataDelivery = createDataDeliveryPage<PlatformState>()

export const BackOfficePage: React.FunctionComponent = () => {
    const match = useRouteMatch()

    if (!match) {
        return null
    }

    const { path: matchPath, url: matchUrl } = match

    const routes: PlatformRoute[] = [
        {
            route: `${BackOfficeRoutes.DATA_DELIVERY}/${BackOfficeRoutes.ORGANIZATION_METADATA}/:organizationId`,
            component: DataDelivery,
            permissions: []
        },
        {
            route: `${BackOfficeRoutes.DATA_DELIVERY}`,
            component: DataDelivery,
            permissions: []
        }
    ]

    const routingConfigObject = toRoutingConfigObject(routes, matchPath, matchUrl)

    return (
        <ContentContainerStyle>
            <ContentPadding padX padY>
                <Switch>
                    {routingConfigObject.map((r) => (
                        <Route key={`route-${r}`} {...r.matchRoute} component={r.component}>
                            <DataDelivery />
                        </Route>
                    ))}
                    <Redirect to={`/${BackOfficeRoutes.ROOT}/${BackOfficeRoutes.DATA_DELIVERY}`} />
                </Switch>
            </ContentPadding>
        </ContentContainerStyle>
    )
}
