import { ActionsUnion, createAction } from '@om1/platform-utils'

export enum FalconApiActionTypes {
    GET_FALCON_VERSION = '@@falconApi/getFalconVersion',
    SET_FALCON_VERSION = '@@falconApi/setFalconVersion'
}

export const falconApiActions = {
    getFalconVersion: () => createAction(FalconApiActionTypes.GET_FALCON_VERSION),
    setFalconVersion: (version: string, alembicVersion: string, analyticsDatabase: string) =>
        createAction(FalconApiActionTypes.SET_FALCON_VERSION, {
            version: version,
            alembicVersion: alembicVersion,
            analyticsDatabase: analyticsDatabase
        })
}

export type FalconApiActions = ActionsUnion<typeof falconApiActions>
