import { IdToken, User } from '@auth0/auth0-react'
import { identify, track } from '@om1/platform-tracking'
import { ActionsUnion, createAction } from '@om1/platform-utils'

export enum Auth0ActionTypes {
    SET_AUTH0_SET_ID_TOKEN = '@@auth0/set-id-token',
    SET_AUTH0_SET_ACCESS_TOKEN = '@@auth0/set-access-token',
    SET_AUTH0_SET_USER = '@@auth0/set-user'
}

export const auth0Actions = {
    setIdToken: (token: IdToken | undefined) => createAction(Auth0ActionTypes.SET_AUTH0_SET_ID_TOKEN, { token }),
    setAccessToken: (accessToken: string | undefined) => createAction(Auth0ActionTypes.SET_AUTH0_SET_ACCESS_TOKEN, { accessToken }),
    setUser: (user: User | undefined) =>
        track(
            createAction(Auth0ActionTypes.SET_AUTH0_SET_USER, { user }),
            identify({ email: user?.email, org_id: user?.org_id, org_name: user?.org_name, user_id: user?.sub })
        )
}

export type Auth0Actions = ActionsUnion<typeof auth0Actions>
