import { FunctionComponent, PropsWithChildren } from 'react'
import { connect } from 'react-redux'
import { ActionsObject } from './action-types'

export interface Routing<T = {}, U = {}> {
    pathParams: Partial<U>
    queryParams: Partial<T>
    /**
     * The part of the url that matched this routed component. Useful for nested links.
     */
    matchUrl: string | null
    updateQuery: (update: Partial<T>) => void
}

export interface RoutedFrameworkComponentProps<TQuery, TParams, TState, TActions extends ActionsObject = {}, TProps = {}>
    extends FrameworkComponentProps<TState, TActions, TProps> {
    routing: Routing<TQuery, TParams>
}

export interface FrameworkComponentProps<TState, TActions extends ActionsObject = {}, TProps = {}> {
    /**
     * The data managed by redux.
     *
     * Typically, we use the same interface describing a slice of the redux store
     * but you may use any interface that can be derived from the redux data
     */
    state: TState

    /**
     * The action creators object which will be passed directly to the
     * react-redux connect function (https://react-redux.js.org/api/connect#object-shorthand-form)
     */
    actions: TActions

    /**
     * The presentational properties of the connected react component
     */
    props: TProps
}

export const connectRedux = <TAppState, TState, TActions extends ActionsObject, TProps>(
    component: FunctionComponent<FrameworkComponentProps<TState, TActions, TProps>>,
    selector: (state: TAppState, ownProps: PropsWithChildren<TProps>) => TState,
    actions?: TActions
): FunctionComponent<TProps> =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    connect(
        selector,
        actions,
        (stateProps, dispatchProps, ownProps: PropsWithChildren<TProps>) => {
            const { children, ...otherOwnProps } = ownProps
            return {
                children: children,
                props: otherOwnProps,
                actions: dispatchProps,
                state: stateProps
            }
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
    )(component)
