import { Trans } from '@lingui/macro'
import { Button } from '@mui/material'
import { PlatformRoute, toRoutingConfigObject } from '@om1/cohort-module'
import { createRWAConditionsPage, createRWAInsightsReportsPage, PlatformSettingRoutes } from '@om1/platform-settings-module'
import { FilterTabBar, FilterTabBarDivider } from '@om1/platform-ui-kit/src/components/FilterTabBar'
import { ContentContainerStyle, ContentPadding } from '@om1/platform-ui-kit/src/components/Layout'
import { PlatformPermissions } from '@om1/platform-utils'
import React from 'react'
import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import { PlatformState } from '../../platform-state'
import { AccessDeniedPage } from '../AccessDeniedPage'
import { createPermissionProtectedRouteComponent } from '../PermissionProtectedRoute'

const RWAConditionsPage = createRWAConditionsPage<PlatformState>()
const RWAInsightsReportsPage = createRWAInsightsReportsPage<PlatformState>()

export const PlatformSettingsPage: React.FunctionComponent = () => {
    const match = useRouteMatch()
    const location = useLocation()

    if (!match) {
        return null
    }
    const { path: matchPathValue, url: matchUrlValue } = match

    const routes: PlatformRoute[] = [
        {
            route: PlatformSettingRoutes.RWA_CONDITIONS,
            component: RWAConditionsPage,
            permissions: [PlatformPermissions.ACCESS_RWA_INSIGHTS_CONDITIONS],
            name: <Trans>RWA Conditions</Trans>
        },
        {
            route: PlatformSettingRoutes.RWA_INSIGHTS_REPORTS,
            component: RWAInsightsReportsPage,
            permissions: [PlatformPermissions.ACCESS_RWA_INSIGHTS_REPORTS],
            name: <Trans>RWA Insights Reports</Trans>
        }
    ]

    const PermissionProtectedRoute = createPermissionProtectedRouteComponent<PlatformState>()

    const routingConfigObject = toRoutingConfigObject(routes, matchPathValue, matchUrlValue)

    const tabs = (
        <FilterTabBar>
            {routingConfigObject.map((tab) => {
                const base = tab.url.split('/')[2]
                return tab.name ? (
                    <div key={`tabbuttondiv-${tab.route}`}>
                        <Button
                            key={`tabbutton-${tab.route}`}
                            component={Link}
                            to={tab.url}
                            aria-current={location.pathname.includes(base) ? 'true' : 'false'}
                            sx={{
                                minWidth: 128,
                                borderRadius: 0,
                                borderBottomWidth: 3,
                                borderBottomStyle: 'solid',
                                borderBottomColor: location.pathname.includes(base) ? 'primary.main' : 'transparent',
                                '&:hover': {
                                    borderBottomColor: location.pathname.includes(base) ? 'primary.main' : 'secondary.main'
                                }
                            }}
                        >
                            {tab.name}
                        </Button>
                        <FilterTabBarDivider />
                    </div>
                ) : null
            })}
        </FilterTabBar>
    )

    const pages = (
        <Switch>
            {routingConfigObject.map((tab) => {
                const { route, component, permissions } = tab
                return (
                    <Route key={`route-${route}`} {...tab.matchRoute} exact={true}>
                        <PermissionProtectedRoute
                            permissions={permissions}
                            accessDeniedComponent={<AccessDeniedPage />}
                            protectedChild={React.createElement(component)}
                        />
                    </Route>
                )
            })}
            <Redirect key='cohortRedirect' to={routingConfigObject[0].url} />
        </Switch>
    )

    let content = (
        <ContentPadding padX padY>
            {pages}
        </ContentPadding>
    )

    return (
        <ContentContainerStyle>
            {tabs}
            {content}
        </ContentContainerStyle>
    )
}
