/*
 * UI interface definitions
 */
import { AnalyticsDatasetDTO } from '@om1/falcon-api'

export interface CohortListItem {
    id: string
    name: string
    cohortSize?: number
    analyticsDataset: AnalyticsDatasetDTO
    updatedDttm: string
    queryUpdatedDttm: string
    createdDttm: string
    description: string
    isSystem: boolean
    isPrivate: boolean
    query?: QueryFilters
    error?: string
    createdById: string
    updatedById: string
    organizationId: string
    createdByEmail?: string
    isStale: boolean
    summaryReportTaskId: string | null
}

export interface CohortListItemCreatedBy {
    id: string
    email?: string
}

export enum CriteriaType {
    Demographics = 'DEMOGRAPHICS',
    Diagnosis = 'DIAGNOSIS',
    PatientAttributes = 'PATIENT_ATTRIBUTES',
    Procedure = 'PROCEDURE',
    LabTest = 'LAB_TEST',
    Medication = 'MEDICATION',
    Observation = 'OBSERVATION',
    EhrNotes = 'EHR_NOTES',
    ObservationPeriod = 'OBSERVATION_PERIOD',
    ExternalCohort = 'EXTERNAL_COHORT'
}

export enum CriteriaOperation {
    AND = 'AND',
    OR = 'OR',
    EXCEPT = 'EXCEPT'
}

/*
 * API interface definitions
 */
export enum DimensionGroupingType {
    Default = 'default',
    GroupingSets = 'grouping_sets'
}

export enum FilterType {
    FilterDTO = 'FilterDTO',
    DateAwareFilterDTO = 'DateAwareFilterDTO',
    DateWindowFilterDTO = 'DateWindowFilterDTO',
    RelativeDateFilterDTO = 'RelativeDateFilterDTO',
    RelativeFollowUpFilterDTO = 'RelativeFollowUpFilterDTO'
}

export enum QualifierType {
    CountDistinctQualifierDTO = 'CountDistinctQualifierDTO',
    DateQualifierDTO = 'DateQualifierDTO',
    PatientAgeQualifierDTO = 'PatientAgeQualifierDTO',
    SpecialtyQualifierDTO = 'SpecialtyQualifierDTO',
    FollowUpLengthQualifierDTO = 'FollowUpLengthQualifierDTO'
}

export enum QualifierOperator {
    GreaterThan = 'gt',
    GreaterThanOrEquals = 'gte',
    LessThan = 'lt',
    LessThanOrEquals = 'lte',
    Equals = 'eq'
}

export enum DateRangeInclusion {
    MoreThan = 'More Than',
    LessThan = 'Less Than',
    Exactly = 'Exactly',
    AtLeast = 'At Least',
    AtMost = 'At Most',
    Between = 'Between'
}

export enum DateRangeOperator {
    Before = 'Before',
    After = 'After',
    BeforeOrAfter = 'BeforeOrAfter',
    Between = 'Between'
}

export enum DateRangeIntervalUnit {
    Day = 'day',
    Month = 'month',
    Year = 'year'
}

export enum AgeUnit {
    Year = 'year'
}

export interface DateRangeInterval {
    intervalStartFromReferenceDate?: number
    intervalEndFromReferenceDate?: number
    intervalIsInclusive: boolean
    intervalUnitFromReferenceDate: DateRangeIntervalUnit
}

export interface NumericRangeInterval {
    intervalStart?: number
    intervalEnd?: number
    intervalIsInclusive: boolean
}

export interface QueryFilters {
    filters?: ExceptFilterOperator[]
}

export interface ReportQueryFilters {
    measures?: string[]
    dimensions?: string[]
    filters: [OrFilterOperator] | []
    groupType?: DimensionGroupingType
}

export type QueryFilterNode = QueryFilterOperator | QueryFilter

export type QueryFilterOperator = AndFilterOperator | OrFilterOperator | ExceptFilterOperator

export type QueryFilter = QueryFilterCondition | RelativeDateFilterDTO | RelativeFollowUpFilterDTO

interface QueryFilterOperatorBase {
    name?: string
    disabled?: boolean
}

export interface ExceptFilterOperator extends QueryFilterOperatorBase {
    except: AndFilterOperator[]
}

export interface AndFilterOperator extends QueryFilterOperatorBase {
    and: QueryFilterNode[]
}

export interface OrFilterOperator extends QueryFilterOperatorBase {
    or: QueryFilterNode[]
}

export interface QueryFilterBase {
    table: string
    field: string
    operator: string
    values: string[]
    qualifiers?: FilterQualifier[]
}

export type QueryFilterCondition = FilterDTO | DateAwareFilterDTO | RelativeDateFilterDTO | DateWindowFilterDTO | RelativeFollowUpFilterDTO

/**
 * The raw API interface for a FilterDTO
 */
export interface FilterDTO extends QueryFilterBase {
    blockId: number
    type: FilterType.FilterDTO
    qualifiers?: FilterQualifier[]
    followUp?: RelativeDateFilterDTO
}

/**
 * The raw API interface for a DateAwareFilterDTO
 */
export interface DateAwareFilterDTO extends QueryFilterBase {
    blockId: number
    type: FilterType.DateAwareFilterDTO
    dateField: string
    qualifiers: FilterQualifier[]
}

export interface DateWindowFilterDTO extends QueryFilterBase {
    blockId: number
    type: FilterType.DateWindowFilterDTO
    startDateField: string
    endDateField: string
    qualifiers: FilterQualifier[]
}

/**
 * The raw API interface for a RelativeDateFilterDTO
 */
export interface RelativeDateFilterDTO extends DateRelationMetadata {
    blockId: number
    type: FilterType.RelativeDateFilterDTO
    subjectFilter: DateAwareFilterDTO
    referenceFilter: DateAwareFilterDTO
}

/**
 * The raw API interface for a RelativeFollowUpFilterDTO.
 */
export interface RelativeFollowUpFilterDTO extends QueryFilterBase {
    blockId: number
    type: FilterType.RelativeFollowUpFilterDTO
    baseline?: RelativeDateFilterDTO
    followUp?: RelativeDateFilterDTO
}

export interface DateRelationMetadata extends DateRangeInterval {
    dateRangeOperator: Exclude<DateRangeOperator, DateRangeOperator.Between>
}

export interface FollowUpRelationMetadata {
    baseline?: DateRelationMetadata
    followUp?: DateRelationMetadata
}

export type FilterQualifier = CountDistinctQualifier | DateQualifier | PatientAgeQualifier | SpecialtyQualifier | FollowUpLengthQualifier

/**
 * The raw API interface for a CountDistinctQualifierDTO.
 */
export interface CountDistinctQualifier {
    type: QualifierType.CountDistinctQualifierDTO
    operator: QualifierOperator
    value: number
    field: string
    qualifiers?: FilterQualifier[]
}

/**
 * The raw API interface for a DateQualifierDTO.
 */
export interface DateQualifier extends DateRangeInterval {
    type: QualifierType.DateQualifierDTO
    dateRangeOperator: DateRangeOperator
    referenceDate: string
}

/**
 * The raw API interface for a AgeRangeQualifierDTO.
 */
export interface PatientAgeQualifier extends NumericRangeInterval {
    type: QualifierType.PatientAgeQualifierDTO
    intervalUnit: AgeUnit
}

/**
 * The raw API interface for a SpecialtyQualifierDTO.
 */
export interface SpecialtyQualifier {
    type: QualifierType.SpecialtyQualifierDTO
    values: string[]
}

/**
 * The raw API interface for a FollowUpLengthQualifierDTO.
 */
export interface FollowUpLengthQualifier {
    type: QualifierType.FollowUpLengthQualifierDTO
    field: string
    operator: QualifierOperator
    units: DateRangeIntervalUnit
    amount: number
    qualifiers?: FilterQualifier[]
}

/* Type guards */

export function isApiExceptOperation(node?: QueryFilterNode): node is ExceptFilterOperator {
    return node !== undefined && Array.isArray((node as ExceptFilterOperator).except)
}

export function isApiOrOperation(node?: QueryFilterNode): node is OrFilterOperator {
    return node !== undefined && Array.isArray((node as OrFilterOperator).or)
}

export function isApiAndOperation(node?: QueryFilterNode): node is AndFilterOperator {
    return node !== undefined && Array.isArray((node as AndFilterOperator).and)
}

export function isApiOperation(node?: QueryFilterNode): node is QueryFilterOperator {
    return isApiOrOperation(node) || isApiAndOperation(node) || isApiExceptOperation(node)
}

export function isApiFilter(node: QueryFilterNode): node is QueryFilter {
    return node !== undefined && (node as QueryFilterCondition).blockId !== undefined
}

export function isApiFilterDTO(node?: QueryFilterNode): node is FilterDTO {
    return node !== undefined && (node as FilterDTO).type !== undefined && (node as FilterDTO).type === FilterType.FilterDTO
}

export function isApiDateAwareFilterDTO(node?: QueryFilterNode): node is DateAwareFilterDTO {
    return (
        node !== undefined && (node as DateAwareFilterDTO).type !== undefined && (node as DateAwareFilterDTO).type === FilterType.DateAwareFilterDTO
    )
}

export function isApiDateWindowFilterDTO(node?: QueryFilterNode): node is DateWindowFilterDTO {
    return (
        node !== undefined &&
        (node as QueryFilterCondition).type !== undefined &&
        (node as QueryFilterCondition).type === FilterType.DateWindowFilterDTO
    )
}

export function isApiRelativeDateFilterDTO(node?: QueryFilterNode): node is RelativeDateFilterDTO {
    return (
        node !== undefined &&
        (node as QueryFilterCondition).type !== undefined &&
        (node as QueryFilterCondition).type === FilterType.RelativeDateFilterDTO
    )
}

export function isApiRelativeFollowUpFilterDTO(node?: QueryFilterNode): node is RelativeFollowUpFilterDTO {
    return (
        node !== undefined &&
        (node as QueryFilterCondition).type !== undefined &&
        (node as QueryFilterCondition).type === FilterType.RelativeFollowUpFilterDTO
    )
}

export function isDateQualifier(qualifier: FilterQualifier): qualifier is DateQualifier {
    return qualifier.type === QualifierType.DateQualifierDTO
}

export function isCountDistinctQualifier(qualifier: FilterQualifier): qualifier is CountDistinctQualifier {
    return qualifier.type === QualifierType.CountDistinctQualifierDTO
}

export function isPatientAgeQualifier(qualifier: FilterQualifier): qualifier is PatientAgeQualifier {
    return qualifier.type === QualifierType.PatientAgeQualifierDTO
}

export function isSpecialtyQualifier(qualifier: FilterQualifier): qualifier is SpecialtyQualifier {
    return qualifier.type === QualifierType.SpecialtyQualifierDTO
}

export function isFollowUpLengthQualifier(qualifier: FilterQualifier): qualifier is FollowUpLengthQualifier {
    return qualifier.type === QualifierType.FollowUpLengthQualifierDTO
}
