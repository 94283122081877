import { Trans, t } from '@lingui/macro'
import BarChartIcon from '@mui/icons-material/BarChart'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import { Box, Breadcrumbs, IconButton } from '@mui/material'
import { LightTooltip } from '@om1/platform-components/Tooltip'
import { Routes, toPath } from '@om1/platform-routing'
import { BreadcrumbLink } from '@om1/platform-ui-kit/src/components/Layout'
import { FrameworkComponentProps, PlatformPermissions } from '@om1/platform-utils'
import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { CohortDashboardCohortCard } from '../../pages/CohortDashboardPage/CohortDashboardCohortCard'
import { CohortRoutes } from '../../routes'
import { CohortListItem, OperationNode, cohortBlocksEditActions, cohortCommonActions, cohortEditActions } from '../../state'
import { CohortStickyHeader } from '../shared/CohortStickyHeader'
import { ReadOnlyCohortComponent } from './ReadOnlyCohortComponent'

export type ReadOnlyCohortComponentPageProps = FrameworkComponentProps<
    { tree: OperationNode; cohort: CohortListItem; sizeLoading: boolean; permissions: string[] | undefined },
    typeof cohortEditActions & typeof cohortBlocksEditActions & typeof cohortCommonActions,
    { cohort: CohortListItem }
>

export const ReadOnlyCohortPageComponent: FunctionComponent<ReadOnlyCohortComponentPageProps> = ({ state, actions, props }) => {
    const breadcrumbs = (
        <Box sx={{ marginBottom: '20px' }}>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <BreadcrumbLink
                    to={`/${CohortRoutes.COHORTBUILD}/${CohortRoutes.DASHBOARD}/${
                        state.cohort.isSystem ? CohortRoutes.DATASETS : CohortRoutes.CUSTOM
                    }/${state.cohort.id}`}
                    sx={{ fontWeight: '600', fontSize: '16px', color: '#012D72' }}
                >
                    <Trans>Dashboard</Trans>
                </BreadcrumbLink>
                <Box sx={{ fontWeight: '600', fontSize: '16px', color: '#707171' }}>
                    {state.cohort.name.charAt(0).toUpperCase() + state.cohort.name.slice(1).toLowerCase()}
                </Box>
            </Breadcrumbs>
        </Box>
    )

    let cardActions: JSX.Element[] = []

    if (!state.cohort.isSystem && state.permissions?.includes(PlatformPermissions.UPDATE_COHORT)) {
        cardActions.push(
            <LightTooltip key={'editTooltip'} title={<Trans>Edit Cohort</Trans>} placement='top'>
                <IconButton
                    key='edit'
                    color='primary'
                    component={Link}
                    to={toPath(Routes.COHORTBUILD) + `/custom/${state.cohort.id}/edit`}
                    aria-label={t`Edit`}
                    sx={{ backgroundColor: '#D6E4F2', color: '#012D72', borderRadius: '2px', margin: '5px' }}
                >
                    <EditOutlinedIcon />
                </IconButton>
            </LightTooltip>
        )
    }

    cardActions.push(
        <LightTooltip key={'reportTooltip'} title={<Trans>Reports</Trans>} placement='top'>
            <IconButton
                key='distribution'
                color='primary'
                component={Link}
                to={toPath(Routes.COHORTBUILD) + `/${CohortRoutes.REPORTS}/${state.cohort.id}/${CohortRoutes.DEMOGRAPHICS}`}
                aria-label={t`Reports`}
                sx={{ backgroundColor: '#D6E4F2', color: '#012D72', borderRadius: '2px', margin: '5px' }}
            >
                <BarChartIcon />
            </IconButton>
        </LightTooltip>
    )

    cardActions.push(
        <LightTooltip title={t`Refresh Count`} placement='top'>
            <span>
                <IconButton
                    onClick={() => {
                        actions.cohortSizeGet({ cohortId: state.cohort.id, cohortName: state.cohort.name })
                    }}
                    disabled={state.sizeLoading}
                    aria-label={t`Refresh Cohort Size`}
                    sx={{ backgroundColor: '#D6E4F2', color: '#012D72', borderRadius: '2px', margin: '5px' }}
                >
                    <RefreshOutlinedIcon />
                </IconButton>
            </span>
        </LightTooltip>
    )

    return (
        <Box>
            {breadcrumbs}
            <CohortStickyHeader>
                {state.cohort && (
                    <CohortDashboardCohortCard
                        cohort={state.cohort}
                        actions={cardActions}
                        excludeTitleRowFromCard={true}
                        sizeLoading={state.sizeLoading}
                    />
                )}
            </CohortStickyHeader>
            <ReadOnlyCohortComponent state={state} actions={actions} props={props} />
        </Box>
    )
}
