import { connectRedux } from '@om1/platform-utils'
import { cohortBlocksEditActions } from '../../../state'
import { Auth0State } from '@om1/platform-authentication'
import { CohortCriterionComponent } from './CohortCriterionComponent'

export function createCohortCriterionComponent<TState extends { auth0: Auth0State }>() {
    return connectRedux(
        CohortCriterionComponent,
        (state: TState) => ({
            permissions: state.auth0.permissions ?? []
        }),
        cohortBlocksEditActions
    )
}

export type CohortCriterion = ReturnType<typeof createCohortCriterionComponent>
