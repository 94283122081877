import { CohortListItem } from '../../cohort-state'

/** Represents a criteria dialog in a new state. For use when adding a new criteria to a cohort. */
export interface DemographicsDialogState {
    loading: boolean
}

export interface CohortBaseEditState {
    ui: {
        demographicsDialog: DemographicsDialogState
        loading: boolean
        cohortUpdateLoading: boolean
        cohortSizeLoading: boolean
        cohortSqlLoading: boolean
    }
    cohort: CohortListItem
    sql?: string
}

export type CohortUpdateQueryValues = Pick<CohortListItem, 'id' | 'query'>

export type CohortTrackingValues = Pick<CohortListItem, 'id' | 'name' | 'analyticsDataset'>

export const initialCohortBaseEditState: CohortBaseEditState = {
    ui: {
        demographicsDialog: {
            loading: false
        },
        loading: true,
        cohortUpdateLoading: false,
        cohortSizeLoading: false,
        cohortSqlLoading: false
    },
    cohort: {
        id: '',
        analyticsDataset: {
            id: '',
            datasetId: '',
            name: '',
            description: '',
            createdDttm: '',
            updatedDttm: '',
            analyticsDatabase: {
                id: '',
                name: '',
                isActive: true,
                createdDttm: '',
                updatedDttm: ''
            }
        },
        name: '',
        description: '',
        cohortSize: 0,
        createdById: '',
        createdDttm: '',
        updatedDttm: '',
        queryUpdatedDttm: '',
        isStale: false,
        isSystem: false,
        isPrivate: false,
        query: {},
        organizationId: '',
        updatedById: '',
        summaryReportTaskId: null
    },
    sql: undefined
}
