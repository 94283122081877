import { connectRedux } from '@om1/platform-utils'
import { CohortState, datasetsActions } from '../../state'
import { CreateEditCohortComponent } from './CreateEditCohortComponent'

export function createCreateEditCohortComponent<TState extends { cohort: CohortState }>() {
    return connectRedux(
        CreateEditCohortComponent,
        (state: TState) => ({
            datasets: state.cohort.datasets
        }),
        { getDatasets: datasetsActions.getDatasets }
    )
}

export type CreateEditCohortDialog = ReturnType<typeof createCreateEditCohortComponent>
