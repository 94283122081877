import { ReportResultDTO } from '@om1/falcon-api'
import { DataFetchParams } from '@om1/platform-routing'
import { ActionsUnion, createAction } from '@om1/platform-utils'
import { ReportAnalyticsType } from '../analytics'
import { QueryFilterBase } from '../cohort-state'
import { Report, ReportType } from './reports-state'

export enum ReportsActionTypes {
    REPORTS_GET = '@@cohort/reports/get',
    REPORTS_SET = '@@cohort/reports/set',
    REPORT_ADD = '@@cohort/report/add',
    REPORT_SET = '@@cohort/report/set',
    REPORT_CREATE = '@@cohort/reports/create',
    REPORT_NO_FILTER_CREATE = '@@cohort/reports/no-filter-create',
    REPORT_UPDATE = '@@cohort/reports/update',
    REPORT_TASK_GET = '@@cohort/reports/task-get',
    LOADING_SET = '@@cohort/reports/loading-set',
    REPORT_DELETE = '@@cohort/reports/delete',
    SUMMARY_REPORT_GET = '@@cohort/summary-report/get',
    SUMMARY_REPORT_SET = '@@cohort/summary-report/set'
}

export const reportsActions = {
    reportsLoadingSet: (payload: { loading: boolean }) => createAction(ReportsActionTypes.LOADING_SET, payload),
    reportsGet: (payload: { cohortId?: string; reportType?: ReportType }) => createAction(ReportsActionTypes.REPORTS_GET, payload),
    reportsSet: (payload: { reports: Report[] }) => createAction(ReportsActionTypes.REPORTS_SET, payload),
    reportAdd: (payload: { report: Report }) => createAction(ReportsActionTypes.REPORT_ADD, payload),
    reportSet: (payload: { report: Report }) => createAction(ReportsActionTypes.REPORT_SET, payload),
    reportCreate: (payload: { cohortId: string; filters: QueryFilterBase[] }) => createAction(ReportsActionTypes.REPORT_CREATE, payload),
    reportNoFilterCreate: (payload: { cohortId: string; reportType: ReportType }) =>
        createAction(ReportsActionTypes.REPORT_NO_FILTER_CREATE, payload),
    reportUpdate: (payload: { reportId: string; cohortId: string; filters: QueryFilterBase[] }) =>
        createAction(ReportsActionTypes.REPORT_UPDATE, payload),
    reportTaskGet: (
        params: DataFetchParams<{ retryDelay: number; maxRetries: number }, { cohortId: string; reportId: string; reportType: ReportAnalyticsType }>
    ) =>
        createAction(ReportsActionTypes.REPORT_TASK_GET, {
            cohortId: params.path.cohortId,
            reportId: params.path.reportId,
            reportType: params.path.reportType,
            maxRetries: params.query.maxRetries,
            retryDelay: params.query.retryDelay
        }),
    reportDelete: (payload: { reportId: string; cohortId: string; refreshReport: boolean; reportType: ReportType }) =>
        createAction(ReportsActionTypes.REPORT_DELETE, payload),
    summaryReportGet: (payload: { cohortId: string }) => createAction(ReportsActionTypes.SUMMARY_REPORT_GET, payload),
    summaryReportSet: (payload: { cohortId: string; reportResultDTOs: Array<ReportResultDTO> }) =>
        createAction(ReportsActionTypes.SUMMARY_REPORT_SET, payload)
}

export type ReportsActions = ActionsUnion<typeof reportsActions>
