import { ExplorerReportsService, PaginatedDTO_ReportDTO_, ReportDTO, SectionDTO_Output } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put } from 'redux-saga/effects'
import { Report, ReportSection, ReportType as StateReportType, reportsActions } from '../state'

export function createGetReportsSaga() {
    return function* (action: ReturnType<typeof reportsActions.reportsGet>) {
        try {
            yield put(reportsActions.reportsLoadingSet({ loading: true }))
            const paginatedReportDTO: PaginatedDTO_ReportDTO_ = yield call(ExplorerReportsService.indexExplorerReportsGet, { ...action.payload })
            const reports: Report[] = paginatedReportDTO.data.map((report: ReportDTO) => {
                return {
                    id: report.id,
                    reportType: report.reportType as StateReportType,
                    cohortId: report.cohortId,
                    sections: report.sections.map((section: SectionDTO_Output) => {
                        return section as ReportSection
                    }),
                    createdDttm: report.createdDttm,
                    updatedDttm: report.updatedDttm,
                    createdById: report.createdById,
                    lastUpdatedBy: report.updatedById,
                    organizationId: report.organizationId
                }
            })
            yield put(reportsActions.reportsSet({ reports: reports }))
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(reportsActions.reportsLoadingSet({ loading: false }))
        }
    }
}

export type GetReportsSaga = ReturnType<typeof createGetReportsSaga>
