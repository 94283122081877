import { t } from '@lingui/macro'
import { ExplorerTasksService, OpenAPI, TaskDTO } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { notificationActions } from '@om1/platform-notifications'
import { renderSeeMoreToast } from '@om1/platform-notifications/components/SeeMoreToast'
import { EventChannel } from 'redux-saga'
import { call, put, take } from 'redux-saga/effects'
import { CohortListItem, cohortCommonActions, cohortEditActions, cohortListActions } from '../state'
import { taskSocket } from './task-socket'

export function createGetCohortSizeSaga() {
    return function* (action: ReturnType<typeof cohortCommonActions.cohortSizeGet>) {
        const { cohortId, cohortName } = action.payload

        yield put(cohortCommonActions.cohortSizeLoadingSet({ cohortId: action.payload.cohortId, loading: true }))

        yield put(cohortListActions.cohortSetSize({ cohortId: cohortId, cohortSize: undefined }))
        yield put(cohortEditActions.cohortSizeSet({ cohortId: cohortId, cohortSize: undefined }))

        let taskId
        try {
            const taskDTO: TaskDTO = yield call(ExplorerTasksService.cohortUpdateSizeExplorerTasksCohortCohortIdUpdateSizeGet, {
                cohortId: cohortId
            })
            taskId = taskDTO.taskId
        } catch (e) {
            yield put(notificationActions.error(t`Could not get task id`))
            yield put(cohortCommonActions.cohortSizeLoadingSet({ cohortId: action.payload.cohortId, loading: false }))
        }

        if (taskId) {
            try {
                const eventChannel: EventChannel<any> = yield call(taskSocket, taskId, OpenAPI.TOKEN)
                const socketResponse = JSON.parse(yield take(eventChannel)) as {
                    taskId: string
                    status: string
                    result: Pick<CohortListItem, 'cohortSize'> & { error?: string }
                }
                if (socketResponse.status === 'SUCCESS') {
                    yield put(cohortEditActions.cohortSizeSet({ cohortSize: socketResponse.result.cohortSize, cohortId }))
                    yield put(cohortListActions.cohortSetSize({ cohortId: cohortId, cohortSize: socketResponse.result.cohortSize }))
                } else if (socketResponse.status === 'FAILURE' && (socketResponse.result.error || '').includes('TimeoutError')) {
                    yield put(notificationActions.error(renderSeeMoreToast({ cohortId, cohortName }), { persist: true, key: `snackbar-${cohortId}` }))
                } else {
                    yield put(notificationActions.error(t`Could not refresh cohort size`))
                }
            } catch (error) {
                yield handleApiError(error)
            }
        }

        yield put(cohortCommonActions.cohortSizeLoadingSet({ cohortId: action.payload.cohortId, loading: false }))
    }
}

export type GetCohortSizeSaga = ReturnType<typeof createGetCohortSizeSaga>
