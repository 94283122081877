import { t, Trans } from '@lingui/macro'
import AddIcon from '@mui/icons-material/Add'
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'
import SearchIcon from '@mui/icons-material/Search'
import TableRowsRoundedIcon from '@mui/icons-material/TableRowsRounded'
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material'
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import { PaginateMeta } from '@om1/falcon-api'
import { FrameworkButton, PageHeader } from '@om1/platform-components'
import { trackingActions, TrackingPlanEvents } from '@om1/platform-tracking'
import { PlatformPermissions, RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { ChangeEventHandler, FunctionComponent, useCallback, useEffect, useState } from 'react'
import { DebounceInput } from 'react-debounce-input'
import {
    cohortCommonActions,
    cohortCreateActions,
    CohortLayout,
    cohortListActions,
    CohortListItem,
    CohortListQueryParams,
    CohortLoading,
    CohortOwner
} from '../../state'
import { cohortDeleteActions } from '../../state/delete'
import { CohortTrackingValues } from '../../state/edit'
import { createCreateCohortWizardDialogComponent } from '../create/CreateCohortWizardDialog'
import { ComingSoon } from '../shared/ComingSoon'
import { CohortSaveValues } from '../shared/CreateEditCohortComponent'
import { CohortListCardGrid } from './CohortListCardGrid'
import { CohortListDataGrid, CohortListDataGridProps } from './CohortListDataGrid'
import { DeleteCohortDialog } from './DeleteCohortDialog'

const CohortWizardDialogComponent = createCreateCohortWizardDialogComponent()

export type CohortListComponentProps = RoutedFrameworkComponentProps<
    CohortListQueryParams,
    {},
    {
        cohorts: CohortListItem[]
        paginationMeta: PaginateMeta
        createLoading: boolean
        cohortSizeLoading: CohortLoading
        permissions?: string[]
    },
    typeof cohortListActions &
        Pick<typeof cohortCommonActions, 'cohortSizeGet'> &
        Pick<typeof cohortCreateActions, 'cohortCreate'> &
        Pick<typeof cohortDeleteActions, 'cohortDelete'> &
        typeof trackingActions,
    { owner?: CohortOwner }
>

/**
 * A screen to list user and system cohorts.
 */
export const CohortListComponent: FunctionComponent<CohortListComponentProps> = ({
    state: { cohorts, cohortSizeLoading, createLoading, paginationMeta, permissions },
    routing,
    actions,
    props
}) => {
    const cohortOwner: CohortOwner = props.owner ? props.owner : CohortOwner.User
    const cohortLayout: CohortLayout = routing.queryParams[cohortOwner]?.layout || CohortLayout.Grid

    const [rowsToRender, setRowsToRender] = useState<CohortListItem[]>(Object.values(cohorts))
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [deleteModalCohort, setDeleteModalCohort] = useState<CohortListItem>()
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10
    })
    const [localPaginationMeta, setLocalPaginationMeta] = useState<PaginateMeta>(paginationMeta)
    const [sortModel, setSortModel] = useState<GridSortModel>(
        cohortOwner === CohortOwner.User ? [{ field: 'queryUpdatedDttm', sort: 'desc' }] : [{ field: 'name', sort: 'asc' }]
    )

    const handleLayoutChange = (layout: CohortLayout) =>
        routing.updateQuery({ ...routing.queryParams, [cohortOwner]: { ...routing.queryParams[cohortOwner], layout: layout } })
    const handleLayoutGridChange = () => handleLayoutChange(CohortLayout.Grid)
    const handleLayoutCardChange = () => handleLayoutChange(CohortLayout.Card)

    const handleSaveCohort = (cohort: CohortSaveValues) => {
        actions.cohortCreate(cohort)
    }

    const handleSearchChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
        routing.updateQuery({ ...routing.queryParams, [cohortOwner]: { ...routing.queryParams[cohortOwner]!, searchValue: event.target.value } })
    }

    const handleDeleteView = useCallback((id: string) => setDeleteModalCohort(cohorts.find((cohort) => cohort.id === id)), [cohorts])
    const handleDeleteHide = useCallback(() => setDeleteModalCohort(undefined), [])
    const handleCohortDuplicate = useCallback(
        (cohortId: string) => {
            actions.cohortDuplicate({ cohortId })
        },
        [actions]
    )
    const handleEditCohort = useCallback(
        (payload: CohortTrackingValues) => {
            actions.trackEvent(TrackingPlanEvents.EDIT_COHORT, payload)
        },
        [actions]
    )
    const handleRefreshCount = useCallback(
        (cohortId: string, cohortName: string) => {
            actions.cohortSizeGet({ cohortId, cohortName })
        },
        [actions]
    )

    let CohortListComponent: FunctionComponent<CohortListDataGridProps>
    if (cohortLayout === CohortLayout.Grid) {
        CohortListComponent = CohortListDataGrid
    } else {
        CohortListComponent = CohortListCardGrid
    }

    useEffect(() => {
        const lowerCaseSearch = routing.queryParams[cohortOwner]?.searchValue?.toLocaleLowerCase() || ''
        let totalItems = cohorts.length
        let totalPages = Math.ceil(cohorts.length / paginationModel.pageSize)
        if (lowerCaseSearch && lowerCaseSearch !== '') {
            let newCohorts: CohortListItem[] = [...cohorts].filter((cohort: CohortListItem) => {
                // Search term is in the cohort name, owner's email, or dataset name
                return (
                    lowerCaseSearch &&
                    lowerCaseSearch !== '' &&
                    (cohort.name.toLocaleLowerCase().includes(lowerCaseSearch) ||
                        (cohort.createdByEmail && cohort.createdByEmail.toLocaleLowerCase().includes(lowerCaseSearch)) ||
                        cohort.analyticsDataset.name.toLocaleLowerCase().includes(lowerCaseSearch))
                )
            })
            totalItems = newCohorts.length
            totalPages = Math.ceil(newCohorts.length / paginationModel.pageSize)
            setRowsToRender(newCohorts)
        } else {
            setRowsToRender(cohorts)
        }
        setLocalPaginationMeta({
            totalItems,
            totalPages,
            currentPage: 1,
            itemsPerPage: paginationModel.pageSize
        })
    }, [cohortOwner, cohorts, paginationModel, routing.queryParams])

    useEffect(() => {
        // Reset to page 0 on owner change
        setPaginationModel((prev) => {
            return { ...prev, page: 0 }
        })
    }, [])

    return (
        <>
            <Box width='100%' flexDirection='column' marginBottom={1}>
                <Box display='flex' flexDirection='row'>
                    <Box display='flex' alignItems='center' flex='1'>
                        <DebounceInput
                            minLength={2}
                            className='search'
                            debounceTimeout={300}
                            onChange={handleSearchChange}
                            element={CohortListSearchField}
                            value={routing.queryParams[cohortOwner]?.searchValue || ''}
                        />
                    </Box>
                    <Box display='flex' justifyContent='flex-end' flexDirection='row' flex='1'>
                        {cohortOwner === CohortOwner.User && permissions?.includes(PlatformPermissions.CREATE_COHORT) && (
                            <FrameworkButton
                                variant='contained'
                                color='primary'
                                onClick={() => setCreateModalVisible(true)}
                                aria-label={t`Create Cohort`}
                            >
                                <AddIcon sx={{ height: '18.5px', width: '18.5px', margin: '0.1rem' }} />
                                <Trans>Create Cohort</Trans>
                            </FrameworkButton>
                        )}
                    </Box>
                </Box>
            </Box>
            <Grid container alignItems='center' mb={2}>
                <Grid item xs marginTop={5}>
                    <PageHeader>{cohortOwner === CohortOwner.User ? <Trans>Cohort Directory</Trans> : <Trans>Dataset Directory</Trans>}</PageHeader>
                </Grid>
                <Grid item xs='auto'>
                    {cohortOwner === CohortOwner.User && (
                        <ComingSoon>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox size='small' />} label={t`Show only my cohorts`} />
                            </FormGroup>
                        </ComingSoon>
                    )}
                </Grid>
                <Grid item xs='auto'>
                    <IconButton
                        color={cohortLayout === CohortLayout.Grid ? 'primary' : 'default'}
                        aria-label={t`Grid View`}
                        onClick={handleLayoutGridChange}
                    >
                        <TableRowsRoundedIcon />
                    </IconButton>

                    <IconButton
                        color={cohortLayout === CohortLayout.Card ? 'primary' : 'default'}
                        aria-label={t`Card View`}
                        onClick={handleLayoutCardChange}
                    >
                        <GridViewRoundedIcon />
                    </IconButton>
                </Grid>
            </Grid>

            <CohortListComponent
                cohorts={rowsToRender}
                cohortSizeLoading={cohortSizeLoading}
                paginationMeta={localPaginationMeta}
                paginationModel={paginationModel}
                sortModel={sortModel}
                onPaginationModelChange={setPaginationModel}
                onSortModelChange={setSortModel}
                onDeleteView={handleDeleteView}
                onDuplicate={handleCohortDuplicate}
                onEdit={handleEditCohort}
                onRefreshCount={handleRefreshCount}
                permissions={permissions}
            />

            {createModalVisible && <CohortWizardDialogComponent onCancel={() => setCreateModalVisible(false)} onSave={handleSaveCohort} />}

            {deleteModalCohort && <DeleteCohortDialog id={deleteModalCohort.id} actions={actions} onCancel={handleDeleteHide} />}
        </>
    )
}

export const CohortListSearchField: (props: TextFieldProps) => JSX.Element = (props: TextFieldProps) => {
    return (
        <TextField
            {...props}
            autoFocus
            type='search'
            placeholder={t`Search`}
            sx={{ backgroundColor: '#FFFFFF', border: 'none', minWidth: '75%' }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <SearchIcon sx={{ color: '#79A6D6', width: '40px', height: '40px' }} />
                    </InputAdornment>
                )
            }}
        />
    )
}
