import { withConfig } from '@om1/platform-utils'
import { AuthClientInitOptions } from '@react-keycloak/core/lib/types'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import Keycloak from 'keycloak-js'
import * as React from 'react'
import { KeycloakConfigInterface, keycloakConfig } from './keycloak-config'

interface KeycloakProviderComponentProps {
    children: JSX.Element
    config: KeycloakConfigInterface
    tokenActions: (tokens: { idToken?: string | undefined; refreshToken?: string | undefined; token?: string | undefined }) => void
}

export const KeycloakProviderComponent: React.FunctionComponent<KeycloakProviderComponentProps> = ({
    children,
    config,
    tokenActions
}: {
    children: JSX.Element
    config: KeycloakConfigInterface
    tokenActions: (tokens: { idToken?: string | undefined; refreshToken?: string | undefined; token?: string | undefined }) => void
}) => {
    let { keycloakRealm, keycloakUrl, keycloakClientId } = config
    const keycloak = new Keycloak({
        realm: keycloakRealm,
        url: keycloakUrl,
        clientId: keycloakClientId
    })
    const initOptions: AuthClientInitOptions = {
        checkLoginIframe: false,
        onLoad: 'login-required',
        response_type: 'code'
    }
    return (
        <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions} onTokens={tokenActions} LoadingComponent={<></>}>
            {children}
        </ReactKeycloakProvider>
    )
}

export const KeycloakProvider = withConfig<KeycloakProviderComponentProps>(KeycloakProviderComponent, keycloakConfig)
