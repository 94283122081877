import { PlatformPermissions } from '@om1/platform-utils'
import { PropsWithChildren } from 'react'

export const PermissionProtectedSideBarItem = (
    props: PropsWithChildren<{ permissions: PlatformPermissions[]; userPermissions: string[] | undefined }>
) => {
    let hasRole = false
    for (let i in props.permissions) {
        hasRole = props.userPermissions ? props.userPermissions.includes(props.permissions[i]) : false
        if (hasRole) {
            break
        }
    }

    return <>{hasRole ? props.children : <></>}</>
}

export default PermissionProtectedSideBarItem
