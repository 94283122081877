import { t } from '@lingui/macro'
import { AnalyticsRefDimension, CriteriaType, StateRef } from '../../../../state'
import { DepthRefFieldMapper, DepthRefGetterCallback, RefFieldMapperType } from './interfaces'

const getItemLabel: DepthRefGetterCallback<StateRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.region
        case 1:
            return item.subRegion
        case 2:
            return item.stateName
    }
}

const getItemValue: DepthRefGetterCallback<StateRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.region
        case 1:
            return item.subRegion
        case 2:
            return item.stateName
    }
}

const getItemTooltip: DepthRefGetterCallback<StateRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
        case 1:
        case 2:
            return getItemLabel(item, depth)
    }
}

export const StateRefFieldMapper: DepthRefFieldMapper<StateRef> = {
    type: RefFieldMapperType.Depth,
    dimension: AnalyticsRefDimension.STATE,
    criteriaType: CriteriaType.PatientAttributes,
    table: 'patient',
    dateField: {
        first: 'null_date',
        last: 'null_date',
        any: 'null_date'
    },
    dateFieldInverse: {},
    searchPlaceholder: (() => t`Search Locations`)(),
    noResultsMessage: (() => t`No results`)(),
    searchHintMessage: (() => t`Search to browse locations`)(),
    filterFieldToRefField: { region: 'region', sub_region: 'subRegion', state: 'stateName' },
    filterFieldOrder: ['region', 'sub_region', 'state'],
    filterLabelOrder: ['region', 'sub_region', 'state_name'],
    getItemLabel,
    getItemValue,
    getItemTooltip
}
