/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TaskDTO } from '../models/TaskDTO'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class DataDeliveryAdminTasksService {
    /**
     * Update Delivered Databases
     * @returns TaskDTO Successful Response
     * @throws ApiError
     */
    public static updateDeliveredDatabasesDataDeliveryTasksUpdateDeliveredDatabasesGet(): CancelablePromise<TaskDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/data_delivery/tasks/update_delivered_databases'
        })
    }
    /**
     * Execute
     * @returns TaskDTO Successful Response
     * @throws ApiError
     */
    public static executeDataDeliveryTasksExecuteDeliveryIdGet({ deliveryId }: { deliveryId: string }): CancelablePromise<TaskDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/data_delivery/tasks/execute/{delivery_id}',
            path: {
                delivery_id: deliveryId
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
}
