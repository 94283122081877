import { Trans, t } from '@lingui/macro'
import CloseIcon from '@mui/icons-material/Close'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { notificationActions } from '@om1/platform-notifications'
import { FrameworkComponentProps } from '@om1/platform-utils'
import { FunctionComponent, ReactNode, useEffect, useMemo } from 'react'
import { cohortEditActions } from '../../../state'

export type CohortSqlDialogComponentProps = FrameworkComponentProps<
    { sql?: string; loading: boolean },
    typeof cohortEditActions & typeof notificationActions,
    { cohortId: string; onCancel: () => void }
>

/**
 * A dialog that fetches and formats SQL that represents the last saved version of the cohort.
 */
export const CohortSqlDialogComponent: FunctionComponent<CohortSqlDialogComponentProps> = ({ state, actions, props }) => {
    const { sql, loading } = state
    const { cohortId, onCancel } = props

    useEffect(() => {
        actions.cohortSqlGet({ cohortId })
    }, [cohortId, actions])

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(sql || '')
            actions.success(t`Copied`)
        } catch (err) {
            actions.error(t`Failed to copy`)
        }
    }

    const handleClose = () => {
        onCancel()
    }

    const highlightedSql = useMemo(() => syntaxHighlightSql(sql), [sql])

    let loadingOverlay: ReactNode = null
    if (loading) {
        loadingOverlay = (
            <Box position='absolute' top={0} left={0} right={0} bottom={0} display='flex' alignItems='center' justifyContent='center'>
                <Box position='absolute' zIndex={1} top={0} left={0} right={0} bottom={0} bgcolor='grey.100' sx={{ opacity: 0.9 }} />
                <Box
                    position='relative'
                    zIndex={2}
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    gap={2}
                    p={4}
                    color='grey.600'
                >
                    <CircularProgress color='inherit' />
                    <Typography>
                        <strong>
                            <Trans>We&apos;re gathering your sql query. Queries typically return in less than 30 seconds.</Trans>
                        </strong>
                    </Typography>
                </Box>
            </Box>
        )
    }

    return (
        <Dialog open maxWidth='lg' fullWidth aria-labelledby='cohort-sql-dialog-title' onClose={handleClose}>
            <DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={handleClose}
                    sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                >
                    <CloseIcon />
                </IconButton>
                <Box id='cohort-sql-dialog-title' pr={4}>
                    <Trans>SQL</Trans>
                </Box>
            </DialogTitle>

            <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box
                    position='relative'
                    display='flex'
                    flexDirection='column'
                    flex={1}
                    sx={{ overflowY: 'auto', '&:hover .cohort-sql-copy-container': { opacity: 1 } }}
                >
                    <Box component='pre' p={2} m={0} bgcolor='grey.200' sx={{ overflow: 'auto' }}>
                        {loading ? (
                            <Box height={`${window.innerHeight * 0.5}px`}> {loadingOverlay}</Box>
                        ) : (
                            <code data-testid='cohort-sql-dialog-sql' dangerouslySetInnerHTML={{ __html: highlightedSql }} />
                        )}
                    </Box>
                    <Box className='cohort-sql-copy-container' position='absolute' top='0.5rem' right='0.5rem' sx={{ opacity: 0 }}>
                        <Tooltip placement='top' title={t`Copy`}>
                            <span>
                                <IconButton disabled={loading} aria-label={t`Copy`} onClick={handleCopy}>
                                    <ContentCopyIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions>
                <Button variant='contained' disabled={loading} onClick={handleClose}>
                    <Trans>Close</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

/**
 * A simple function to syntax highlight SQL. May fail on cases that are unlikely to be produced by server-generated
 * SQL, such as single quotes within single quotes, or operators within single quotes.
 *
 * @param sql raw SQL string
 * @returns syntax highlighted SQL HTML
 */
function syntaxHighlightSql(sql?: string) {
    if (!sql) {
        return ''
    }

    sql = sql.replace(/</g, `&lt;`)
    sql = sql.replace(/>/g, `&gt;`)
    sql = sql.replace(/"/g, `&quot;`)
    sql = sql.replace(/'([^']+)'/gi, highlightStringLiteral)
    // Basic SQL keywords from https://www.w3schools.com/sql/sql_ref_keywords.asp
    sql = sql.replace(
        /\b(?:ADD|ADD CONSTRAINT|ALL|ALTER|ALTER COLUMN|ALTER TABLE|AND|ANY|AS|ASC|BACKUP DATABASE|BETWEEN|CASE|CHECK|COLUMN|CONSTRAINT|CREATE|CREATE DATABASE|CREATE INDEX|CREATE OR REPLACE VIEW|CREATE TABLE|CREATE PROCEDURE|CREATE UNIQUE INDEX|CREATE VIEW|DATABASE|DEFAULT|DELETE|DESC|DISTINCT|DROP|DROP COLUMN|DROP CONSTRAINT|DROP DATABASE|DROP DEFAULT|DROP INDEX|DROP TABLE|DROP VIEW|EXEC|EXISTS|FOREIGN KEY|FROM|FULL OUTER JOIN|GROUP BY|HAVING|IN|INDEX|INNER JOIN|INSERT INTO|INSERT INTO SELECT|IS NULL|IS NOT NULL|JOIN|LEFT JOIN|LIKE|LIMIT|NOT|NOT NULL|OR|ORDER BY|OUTER JOIN|PRIMARY KEY|PROCEDURE|RIGHT JOIN|ROWNUM|SELECT|SELECT DISTINCT|SELECT INTO|SELECT TOP|SET|TABLE|TOP|TRUNCATE TABLE|UNION|UNION ALL|UNIQUE|UPDATE|VALUES|VIEW|WHERE)\b/gi,
        highlightKeyword
    )
    // Additional PostgreSQL keywords used in queries
    sql = sql.replace(/\b(?:USING|INTERSECT|EXCEPT|DATEDIFF|ABS|ON)\b/gi, highlightKeyword)
    return sql

    function highlightStringLiteral(match: string) {
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        return `<span style="color: red;">${match}</span>`
    }

    function highlightKeyword(match: string) {
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        return `<span style="color: blue;">${match}</span>`
    }
}
