import React from 'react'
import ReactDOM from 'react-dom/client'
import { Config, config } from './Config'
import App from './Platform'

declare global {
    interface Window {
        appVersion: string
    }
}

if (process.env.NODE_ENV !== 'production') {
    const axe = require('@axe-core/react')
    axe(React, ReactDOM, 1000)
}
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <Config config={config}>
        <App />
    </Config>
)
