import { connectRedux } from '@om1/platform-utils'
import { AnalyticsRefDestination, AnalyticsRefDimension, CohortState, CriterionNode, cohortBlocksEditActions } from '../../../state'
import { findNode } from '../../../state/edit/blocks/cohort-blocks-edit-reducer'
import { SpecialtyEditDialogComponent } from './SpecialtyEditDialogComponent'

export function createSpecialtyEditDialogComponent<TState extends { cohort: CohortState }>() {
    return connectRedux(
        SpecialtyEditDialogComponent,
        (state: TState) => {
            const nodeId = state.cohort.edit.blocks.ui.specialtyEditDialog.target?.nodeId

            return {
                specialtyRefs: state.cohort.refs[AnalyticsRefDimension.SPECIALTY][AnalyticsRefDestination.SEARCH].data,
                criteria: nodeId ? (findNode(state.cohort.edit.blocks.tree, nodeId) as CriterionNode) : undefined
            }
        },
        cohortBlocksEditActions
    )
}

export type SpecialtyEditDialog = ReturnType<typeof createSpecialtyEditDialogComponent>
