import topology from '@highcharts/map-collection/countries/us/us-all.topo.json'
import { Trans } from '@lingui/macro'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { CircularProgress, Grid, Skeleton, Stack, Typography } from '@mui/material'
import { Box, BoxProps, SxProps } from '@mui/system'
import { LightTooltip } from '@om1/platform-components/Tooltip'
import { FrameworkComponentProps } from '@om1/platform-utils'
import Highcharts, { AxisLabelsFormatterCallbackFunction } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsBoost from 'highcharts/modules/boost'
import HighchartsMap from 'highcharts/modules/map'
import { get } from 'lodash'
import React, { FunctionComponent, MutableRefObject, PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
    AnalyticsCategoryData,
    AnalyticsDataState,
    AnalyticsDimension,
    AnalyticsTaskStatus,
    CohortListItem,
    DemographicsAnalyticsData,
    Report,
    ReportAnalyticsType,
    ReportType,
    ReportsState,
    cohortAnalyticsActions,
    reportsActions
} from '../../state'
import { useChart } from './ChartProvider'
HighchartsBoost(Highcharts)

// TODO THESE ARE GLOBALS
HighchartsMap(Highcharts)
Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    },
    chart: {
        style: {
            fontFamily: ['Metropolis', 'sans-serif'].join(',')
        }
    },
    yAxis: {
        labels: {
            style: {
                // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                color: 'rgba(0, 0, 0, 0.87)'
            }
        }
    },
    xAxis: {
        labels: {
            style: {
                // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                color: 'rgba(0, 0, 0, 0.87)'
            }
        }
    },
    exporting: {
        enabled: false, // This will hide the export button
        buttons: {
            contextButton: {
                enabled: false // Ensuring the button is completely hidden
            }
        }
    }
})

const CONTAINER_PROPS = { style: { width: '100%', height: '100%' } }

// Highcharts Y axes don't format with a thousands seperator, so override it in that case.
const axisLabelFormatter: AxisLabelsFormatterCallbackFunction = (context) => {
    let label = context.axis.defaultLabelFormatter.call(context)
    // Use thousands separator for four-digit numbers too
    if (/^[0-9]{4}$/.test(label)) {
        label = Highcharts.numberFormat(Number(context.value), 0)
    }
    return label
}

export const BAR_CHART_OPTIONS: Highcharts.Options = {
    chart: {
        inverted: false
    },
    title: {
        text: undefined
    },
    yAxis: {
        min: 0,
        title: undefined,
        labels: {
            overflow: 'justify',
            formatter: axisLabelFormatter
        }
    },
    legend: {
        enabled: false
    },
    credits: undefined,
    series: [
        {
            type: 'column',
            // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
            name: 'Patients',
            color: '#002D72',
            groupPadding: 0.1,
            dataLabels: {
                enabled: true
            }
        }
    ],
    boost: {
        useGPUTranslations: true,
        enabled: true,
        usePreallocated: true
    },
    exporting: {
        enabled: false, // This will hide the export button
        buttons: {
            contextButton: {
                enabled: false // Ensuring the button is completely hidden
            }
        }
    }
}

export type DemographicsReportRefs = {
    age: React.MutableRefObject<Highcharts.Chart | null>
    sex: React.MutableRefObject<Highcharts.Chart | null>
    race: React.MutableRefObject<Highcharts.Chart | null>
    ethnicity: React.MutableRefObject<Highcharts.Chart | null>
    state: React.MutableRefObject<Highcharts.Chart | null>
    stateMap: React.MutableRefObject<Highcharts.Chart | null>
}

export type DemographicsReportChartComponentProps = FrameworkComponentProps<
    { analytics?: AnalyticsDataState<DemographicsAnalyticsData> | null; reports: ReportsState | null; reportsLoading: boolean },
    typeof reportsActions & typeof cohortAnalyticsActions,
    {
        cohort: CohortListItem
        disableFetching?: boolean
        demographicsReportRefs?: DemographicsReportRefs
        renderSexAsBarChart?: boolean
    }
>

export const DemographicsReportChartComponent: FunctionComponent<DemographicsReportChartComponentProps> = ({
    state: { analytics, reports, reportsLoading },
    props: { cohort, disableFetching, demographicsReportRefs, renderSexAsBarChart },
    actions
}) => {
    const report = useMemo<Report | undefined>(
        () => get(reports, ['reports'], []).find((rep) => rep.reportType === ReportType.DEMOGRAPHICS && rep.cohortId === cohort.id),
        [cohort.id, reports]
    )

    useEffect(() => {
        if (cohort.id && report && !analytics && !disableFetching) {
            actions.reportTaskGet({
                path: { cohortId: report.cohortId, reportId: report.id, reportType: ReportAnalyticsType.DEMOGRAPHICS },
                query: {}
            })
        }
    }, [actions, analytics, cohort.id, disableFetching, report])

    const [ageData, ethnicityData, stateData, raceData, sexData]: AnalyticsCategoryData[] = useMemo(() => {
        return [AnalyticsDimension.Age, AnalyticsDimension.Ethnicity, AnalyticsDimension.State, AnalyticsDimension.Race, AnalyticsDimension.Sex].map(
            (dimension) => analytics?.data[dimension] ?? {}
        )
    }, [analytics])

    const [patientsByRaceOptions, setPatientsByRaceOptions] = useState<Highcharts.Options | null>(null)
    const [patientsByAgeOptions, setPatientsByAgeOptions] = useState<Highcharts.Options | null>(null)
    const [patientsByEthnicityOptions, setPatientsByEthnicityOptions] = useState<Highcharts.Options | null>(null)
    const [patientByStateOptions, setPatientByStateOptions] = useState<Highcharts.Options | null>(null)
    const [patientByStateHeight, setPatientByStateHeight] = useState<number>(75)
    const [status, setStatus] = useState<AnalyticsTaskStatus>(AnalyticsTaskStatus.Pending)

    useEffect(() => {
        setPatientsByAgeOptions({
            ...BAR_CHART_OPTIONS,
            xAxis: {
                ...BAR_CHART_OPTIONS.xAxis,
                categories: Object.entries(ageData)
                    .filter(([age, count]) => parseInt(age) >= 3 && parseInt(age) <= 88)
                    .map(([age, count]) => age)
            },
            series: [
                {
                    ...{
                        ...BAR_CHART_OPTIONS.series![0],
                        dataLabels: {
                            enabled: false
                        }
                    },
                    data: Object.entries(ageData)
                        .filter(([age, count]) => parseInt(age) >= 3 && parseInt(age) <= 88)
                        .map(([age, count]) => count)
                }
            ] as Highcharts.SeriesXrangeOptions[],
            exporting: {
                enabled: false, // This will hide the export button
                buttons: {
                    contextButton: {
                        enabled: false // Ensuring the button is completely hidden
                    }
                }
            }
        })

        setPatientsByRaceOptions({
            ...BAR_CHART_OPTIONS,
            chart: { ...BAR_CHART_OPTIONS.chart, inverted: true },
            xAxis: {
                ...BAR_CHART_OPTIONS.xAxis,
                categories: Object.entries(raceData)
                    .sort((a, b) => b[1] - a[1])
                    .map((value) => value[0])
            },
            series: [
                {
                    ...BAR_CHART_OPTIONS.series![0],
                    data: Object.entries(raceData)
                        .sort((a, b) => b[1] - a[1])
                        .map((value) => value[1])
                }
            ] as Highcharts.SeriesXrangeOptions[],
            exporting: {
                enabled: false, // This will hide the export button
                buttons: {
                    contextButton: {
                        enabled: false // Ensuring the button is completely hidden
                    }
                }
            }
        })

        setPatientsByEthnicityOptions({
            ...BAR_CHART_OPTIONS,
            chart: { ...BAR_CHART_OPTIONS.chart, inverted: true },
            xAxis: {
                ...BAR_CHART_OPTIONS.xAxis,
                categories: Object.entries(ethnicityData)
                    .sort((a, b) => b[1] - a[1])
                    .map((value) => value[0])
            },
            series: [
                {
                    ...BAR_CHART_OPTIONS.series![0],
                    data: Object.entries(ethnicityData)
                        .sort((a, b) => b[1] - a[1])
                        .map((value) => value[1])
                }
            ] as Highcharts.SeriesXrangeOptions[],
            exporting: {
                enabled: false, // This will hide the export button
                buttons: {
                    contextButton: {
                        enabled: false // Ensuring the button is completely hidden
                    }
                }
            }
        })
        setPatientByStateOptions({
            ...BAR_CHART_OPTIONS,
            chart: { ...BAR_CHART_OPTIONS.chart, inverted: true },
            colorAxis: { maxColor: '#002D72', minColor: '#CCD5E3', min: 0, startOnTick: false, endOnTick: false },
            xAxis: {
                ...BAR_CHART_OPTIONS.xAxis,
                lineWidth: 0,
                gridLineWidth: 0,
                categories: Object.entries(stateData)
                    .sort((a, b) => b[1] - a[1])
                    .map((value) => value[0])
            },
            yAxis: { ...BAR_CHART_OPTIONS.yAxis, visible: false },
            series: [
                {
                    ...BAR_CHART_OPTIONS.series![0],
                    data: Object.entries(stateData)
                        .sort((a, b) => b[1] - a[1])
                        .map((value) => value[1])
                }
            ] as Highcharts.SeriesXrangeOptions[],
            exporting: {
                enabled: false, // This will hide the export button
                buttons: {
                    contextButton: {
                        enabled: false // Ensuring the button is completely hidden
                    }
                }
            }
        })

        // Need to directly set the height of the patient by state bar chart to allow all state labels to show
        // If the container is not tall enough, labels will be skipped by highcharts
        const MIN_STATE_LABEL_HEIGHT = 25 // The minimum height needed to ensure all state labels are visible in the chart
        const MIN_PATIENT_BY_STATE_HEIGHT = 75 // A minimum height to ensure that bars will show for between 1 and 3 states
        const values = Object.entries(stateData)
        let height = MIN_PATIENT_BY_STATE_HEIGHT
        if (values.length * MIN_STATE_LABEL_HEIGHT > MIN_PATIENT_BY_STATE_HEIGHT) {
            height = values.length * MIN_STATE_LABEL_HEIGHT
        }

        setPatientByStateHeight(height)

        setStatus(analytics?.status ?? AnalyticsTaskStatus.Pending)
    }, [ageData, analytics?.status, ethnicityData, raceData, stateData])

    useEffect(() => {}, [analytics?.status])

    let loadingOverlay: ReactNode = null
    if (status === AnalyticsTaskStatus.Pending) {
        const overlayStyles: SxProps = { alignItems: 'flex-start', paddingTop: 12 }
        loadingOverlay = (
            <Box position='absolute' top={0} left={0} right={0} bottom={0} display='flex' justifyContent='center' sx={overlayStyles}>
                <Box position='absolute' zIndex={1} top={0} left={0} right={0} bottom={0} bgcolor='grey.100' sx={{ opacity: 0.9 }} />
                <Box
                    position='relative'
                    zIndex={2}
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    gap={2}
                    p={4}
                    color='grey.600'
                >
                    <CircularProgress color='inherit' />
                    <Typography>
                        <strong>
                            <Trans>We&apos;re gathering your data. Queries typically finish in less than 30 seconds.</Trans>
                        </strong>
                    </Typography>
                </Box>
            </Box>
        )
    }

    const innerPatientAgeChartRef = useRef<Highcharts.Chart | null>(null)
    const patientAgeChartRef = demographicsReportRefs?.age ?? innerPatientAgeChartRef
    const innerPatientsBySexChartRef = useRef<Highcharts.Chart | null>(null)
    const patientsBySexChartRef = demographicsReportRefs?.sex ?? innerPatientsBySexChartRef
    const innerPatientRaceChartRef = useRef<Highcharts.Chart | null>(null)
    const patientRaceChartRef = demographicsReportRefs?.race ?? innerPatientRaceChartRef
    const innerPatientEthnicityChartRef = useRef<Highcharts.Chart | null>(null)
    const patientEthnicityChartRef = demographicsReportRefs?.ethnicity ?? innerPatientEthnicityChartRef
    const innerPatientStateChartRef = useRef<Highcharts.Chart | null>(null)
    const patientStateChartRef = demographicsReportRefs?.state ?? innerPatientStateChartRef
    const innerPatientsByStateMapChartRef = useRef<Highcharts.Chart | null>(null)
    const patientsByStateMapChartRef = demographicsReportRefs?.stateMap ?? innerPatientsByStateMapChartRef

    const handleResize = useCallback(
        (chartRef) => {
            if (chartRef.current) {
                const chartContainer = chartRef.current.container
                if (chartContainer) {
                    const newOptions = {
                        ...chartRef.current.options,
                        chart: {
                            ...chartRef.current.options.chart,
                            width: chartRef.current.options.chart?.width,
                            height: chartRef.current.options.chart?.height
                        }
                    }
                    chartRef.current.update(newOptions, true, true)
                    chartRef.current.reflow()
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    useEffect(() => {
        handleResize(patientAgeChartRef)
        handleResize(patientsBySexChartRef)
        handleResize(patientRaceChartRef)
        handleResize(patientEthnicityChartRef)
        handleResize(patientStateChartRef)
        handleResize(patientsByStateMapChartRef)

        const resizeListener = () => {
            handleResize(patientAgeChartRef)
            handleResize(patientsBySexChartRef)
            handleResize(patientRaceChartRef)
            handleResize(patientEthnicityChartRef)
            handleResize(patientStateChartRef)
            handleResize(patientsByStateMapChartRef)
        }

        window.addEventListener('resize', resizeListener)

        return () => {
            window.removeEventListener('resize', resizeListener)
        }
    }, [
        handleResize,
        patientAgeChartRef,
        patientEthnicityChartRef,
        patientRaceChartRef,
        patientStateChartRef,
        patientsBySexChartRef,
        patientsByStateMapChartRef
    ])

    const patientByAgeInfoText = (
        <Typography>
            <Trans>
                Patient privacy is a top priority at OM1. To reduce risk of re-identification, this figure excludes patients under 3 years or over 88
                years of age.
            </Trans>
        </Typography>
    )

    const chartRef = useChart()

    const ReportContentsComponent = (
        <Box minHeight={window.innerHeight} display='flex' alignItems='center' justifyContent='center' fontSize={18} maxWidth='100%'>
            <Grid container spacing={2} alignItems='center'>
                <Grid item xs sm={6}>
                    <Box>
                        <Box p={2} display='flex' alignContent='center' columnGap={1}>
                            <Trans>Patients by Current Age</Trans>
                            <LightTooltip maxWidth='500px' placement='top-start' title={patientByAgeInfoText}>
                                <InfoOutlinedIcon />
                            </LightTooltip>
                        </Box>
                        <Box px={2} py={1}>
                            <ChartSkeleton height={326} status={status}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    ref={chartRef}
                                    containerProps={CONTAINER_PROPS}
                                    options={patientsByAgeOptions}
                                    callback={(chart) => {
                                        patientAgeChartRef.current = chart
                                    }}
                                />
                            </ChartSkeleton>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs sm={6}>
                    <Box>
                        <Box p={2}>
                            <Trans>Patients by Sex</Trans>
                        </Box>
                        <Box px={2} py={1}>
                            <PatientsBySex
                                data={sexData}
                                status={status}
                                patientsBySexChartRef={patientsBySexChartRef}
                                renderAsBarChart={renderSexAsBarChart}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs sm={6}>
                    <Box>
                        <Box p={2}>
                            <Trans>Patients by Race</Trans>
                        </Box>
                        <Box px={2} py={1}>
                            <ChartSkeleton height={326} status={status}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    containerProps={CONTAINER_PROPS}
                                    options={patientsByRaceOptions}
                                    callback={(chart) => {
                                        patientRaceChartRef.current = chart
                                    }}
                                />
                            </ChartSkeleton>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs sm={6}>
                    <Box>
                        <Box p={2}>
                            <Trans>Patients by Ethnicity</Trans>
                        </Box>
                        <Box px={2} py={1}>
                            <ChartSkeleton height={326} status={status}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    containerProps={CONTAINER_PROPS}
                                    options={patientsByEthnicityOptions}
                                    callback={(chart) => {
                                        patientEthnicityChartRef.current = chart
                                    }}
                                />
                            </ChartSkeleton>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs>
                    <Box>
                        <Box p={2}>
                            <Trans>Patients by State</Trans>
                        </Box>
                        <Box px={2} py={1}>
                            <Grid container spacing={2}>
                                <Grid item xs sm={7}>
                                    <PatientsByStateMap data={stateData} status={status} patientsByStateMapChartRef={patientsByStateMapChartRef} />
                                </Grid>
                                <Grid item xs sm={5}>
                                    <ChartSkeleton height={patientByStateHeight} status={status}>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            containerProps={CONTAINER_PROPS}
                                            options={patientByStateOptions}
                                            callback={(chart) => {
                                                patientStateChartRef.current = chart
                                            }}
                                        />
                                    </ChartSkeleton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )

    return (
        <Box
            sx={{
                position: 'relative',
                overflow: 'auto',
                marginBottom: '10px'
            }}
        >
            {loadingOverlay}
            {ReportContentsComponent}
        </Box>
    )
}

/**
 * A map from plain text state names to ISO state names, e.g. Georgia -> us-ga
 */
const STATE_NAME_TO_CODE: Record<string, string> = Object.fromEntries(
    topology.objects.default.geometries.map((geo) => [geo.properties.name, geo.properties['hc-key']])
)

interface PatientsByStateMapProps {
    data: AnalyticsCategoryData
    status: AnalyticsTaskStatus
    patientsByStateMapChartRef: MutableRefObject<Highcharts.Chart | null>
}
const PatientsByStateMap: FunctionComponent<PatientsByStateMapProps> = ({ data, status, patientsByStateMapChartRef }) => {
    const options = useMemo(() => {
        // Map state names to ISO state codes that Highcharts utilizes
        const seriesData = Object.entries(data).map((entry) => [STATE_NAME_TO_CODE[entry[0]], entry[1]])

        return {
            chart: {
                type: 'map',
                map: topology
            },

            title: {
                text: undefined
            },
            mapNavigation: {
                enabled: false
            },
            credits: { enabled: false },
            legend: { enabled: false },
            colorAxis: {
                maxColor: '#002D72',
                minColor: '#CCD5E3',
                min: 0,
                startOnTick: false,
                endOnTick: false
            },
            series: [
                {
                    data: seriesData,
                    nullColor: '#c1c6c8',
                    borderColor: '#ffffff',
                    dataLabels: {
                        enabled: false
                    },
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            ],
            tooltip: {
                // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                headerFormat: '<div style="fill: {point.color};">●</div>'
            },
            exporting: {
                enabled: false, // This will hide the export button
                buttons: {
                    contextButton: {
                        enabled: false // Ensuring the button is completely hidden
                    }
                }
            }
        }
    }, [data])

    return (
        <ChartSkeleton height={450} status={status}>
            <HighchartsReact
                highcharts={Highcharts}
                constructorType='mapChart'
                containerProps={CONTAINER_PROPS}
                options={options}
                callback={(chart) => {
                    patientsByStateMapChartRef.current = chart
                }}
            />
        </ChartSkeleton>
    )
}

interface PatientsBySexProps {
    data: AnalyticsCategoryData
    status: AnalyticsTaskStatus
    patientsBySexChartRef: MutableRefObject<Highcharts.Chart | null>
    renderAsBarChart?: boolean
}
const PatientsBySex: FunctionComponent<PatientsBySexProps> = ({ data, status, patientsBySexChartRef, renderAsBarChart }) => {
    const [hoverState, setHoverState] = useState<PatientsBySexTooltipProps | undefined>()

    const setInnerLabel = useCallback((dataPoint: { name: string; percentage: number }) => {
        setHoverState({
            name: dataPoint.name,
            percentage: Highcharts.numberFormat(dataPoint.percentage, 1, '.', ',') + '%'
        })
    }, [])

    const columnOptions: Highcharts.Options = useMemo(() => {
        return {
            ...BAR_CHART_OPTIONS,
            chart: { ...BAR_CHART_OPTIONS.chart, inverted: true },
            xAxis: {
                ...BAR_CHART_OPTIONS.xAxis,
                categories: Object.entries(data)
                    .sort((a, b) => b[1] - a[1])
                    .map((value) => value[0])
            },
            series: [
                {
                    ...BAR_CHART_OPTIONS.series![0],
                    data: Object.entries(data)
                        .sort((a, b) => b[1] - a[1])
                        .map((value) => value[1])
                }
            ] as Highcharts.SeriesXrangeOptions[],
            exporting: {
                enabled: false, // This will hide the export button
                buttons: {
                    contextButton: {
                        enabled: false // Ensuring the button is completely hidden
                    }
                }
            }
        }
    }, [data])

    const options = useMemo(() => {
        const colors = ['#002D72', '#8BB8E8', '#C1C6C8']
        const seriesData = Object.entries(data).map(([label, count], index) => ({
            name: label,
            y: count,
            color: colors[index % colors.length]
        }))
        // Sort largest first
        seriesData.sort((a, b) => b.y - a.y)

        // When new data comes in, make sure we reset the inner label
        if (seriesData.length > 0) {
            const seriesTotal = seriesData.reduce((acc, data) => acc + data.y, 0)
            const initialPercentage = seriesTotal > 0 ? (seriesData[0].y / seriesTotal) * 100 : 0
            setInnerLabel({ name: seriesData[0].name, percentage: initialPercentage })
        } else {
            setHoverState(undefined)
        }

        return {
            chart: { reflow: true },
            title: { text: undefined },
            accessibility: {
                point: { valueSuffix: '%' }
            },
            legend: { enabled: false },
            credits: undefined,
            plotOptions: {
                pie: {
                    showInLegend: false,
                    size: '100%',
                    dataLabels: { enabled: false }
                }
            },
            series: [
                {
                    type: 'pie',
                    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                    name: 'Patients',
                    innerSize: '75%',
                    borderWidth: 2,
                    states: {
                        hover: {
                            enabled: false
                        },
                        inactive: {
                            enabled: false
                        }
                    },
                    point: {
                        events: {
                            mouseOver: (event) => {
                                setInnerLabel({ name: event.target.name, percentage: event.target.percentage })
                            }
                        }
                    },
                    data: seriesData
                }
            ]
        }
    }, [data, setInnerLabel])

    return (
        <ChartSkeleton height={326} status={status}>
            <Box display='flex' flexDirection='column' gap='2' alignItems='center' minWidth={0}>
                <Box position='relative' width='100%' height={306} style={{ width: '100%', maxWidth: '100%' }}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        containerProps={CONTAINER_PROPS}
                        options={renderAsBarChart ? columnOptions : options}
                        callback={(chart) => {
                            patientsBySexChartRef.current = chart
                        }}
                    />
                    {hoverState && <PatientsBySexTooltip {...hoverState} />}
                </Box>
                {!renderAsBarChart && (
                    <Box display='flex' gap={2} alignItems='center'>
                        {options.series[0].data.map((data) => (
                            <PatientsBySexLegendItem key={data.name} color={data.color}>
                                {data.name}
                            </PatientsBySexLegendItem>
                        ))}
                    </Box>
                )}
            </Box>
        </ChartSkeleton>
    )
}

interface PatientsBySexLegendItemProps {
    color: BoxProps['bgcolor']
}

const PatientsBySexLegendItem = (props: PropsWithChildren<PatientsBySexLegendItemProps>) => (
    <Box display='flex' gap={1} alignItems='center' fontSize='0.825rem'>
        <Box width={10} height={10} borderRadius='100%' bgcolor={props.color} />
        <span>{props.children}</span>
    </Box>
)

interface PatientsBySexTooltipProps {
    name: ReactNode
    percentage: string
}

const PatientsBySexTooltip = (props: PropsWithChildren<PatientsBySexTooltipProps>) => {
    return (
        <Box
            position='absolute'
            top={0}
            right={0}
            bottom={0}
            left={0}
            sx={{ pointerEvents: 'none' }}
            display='flex'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
        >
            <Stack>
                <Box fontSize='2rem' color='grey.600'>
                    {props.percentage}
                </Box>
                <div>{props.name}</div>
            </Stack>
        </Box>
    )
}

const ChartSkeleton: FunctionComponent<PropsWithChildren<{ height: number; status: AnalyticsTaskStatus }>> = ({ height, status, children }) => {
    let chartContent: ReactNode = null
    if (status === AnalyticsTaskStatus.Complete) {
        chartContent = children
    } else if (status === AnalyticsTaskStatus.Failed) {
        chartContent = (
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                width='100%'
                height='100%'
                borderRadius={4}
                bgcolor='grey.100'
                color='grey.700'
            >
                <Trans>Error loading chart</Trans>
            </Box>
        )
    } else {
        chartContent = <Skeleton animation='wave' variant='rounded' width='100%' height='100%' />
    }
    return <Box height={height}>{chartContent}</Box>
}
