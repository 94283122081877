/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrganizationMetadataCreateDTO } from '../models/OrganizationMetadataCreateDTO'
import type { OrganizationMetadataDTO } from '../models/OrganizationMetadataDTO'
import type { OrganizationMetadataPatchDTO } from '../models/OrganizationMetadataPatchDTO'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class DataDeliveryAdminOrganizationMetadataService {
    /**
     * Index
     * @returns OrganizationMetadataDTO Successful Response
     * @throws ApiError
     */
    public static indexDataDeliveryOrganizationMetadataGet(): CancelablePromise<Array<OrganizationMetadataDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/data_delivery/organization_metadata'
        })
    }
    /**
     * Create
     * @returns OrganizationMetadataDTO Successful Response
     * @throws ApiError
     */
    public static createDataDeliveryOrganizationMetadataPost({
        requestBody
    }: {
        requestBody: OrganizationMetadataCreateDTO
    }): CancelablePromise<OrganizationMetadataDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/data_delivery/organization_metadata',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Get
     * @returns OrganizationMetadataDTO Successful Response
     * @throws ApiError
     */
    public static getDataDeliveryOrganizationMetadataOrganizationIdGet({
        organizationId
    }: {
        organizationId: string
    }): CancelablePromise<OrganizationMetadataDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/data_delivery/organization_metadata/{organization_id}',
            path: {
                organization_id: organizationId
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Patch
     * @returns OrganizationMetadataDTO Successful Response
     * @throws ApiError
     */
    public static patchDataDeliveryOrganizationMetadataOrganizationMetadataIdPatch({
        organizationMetadataId,
        requestBody
    }: {
        organizationMetadataId: string
        requestBody: OrganizationMetadataPatchDTO
    }): CancelablePromise<OrganizationMetadataDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/data_delivery/organization_metadata/{organization_metadata_id}',
            path: {
                organization_metadata_id: organizationMetadataId
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Delete
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteDataDeliveryOrganizationMetadataOrganizationMetadataIdDelete({
        organizationMetadataId
    }: {
        organizationMetadataId: string
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/data_delivery/organization_metadata/{organization_metadata_id}',
            path: {
                organization_metadata_id: organizationMetadataId
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
}
