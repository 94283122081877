import { yupResolver } from '@hookform/resolvers/yup'
import { t, Trans } from '@lingui/macro'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Switch,
    TextField
} from '@mui/material'
import { AnalyticsDatasetDTO, CohortDTO } from '@om1/falcon-api'
import { FrameworkComponentProps } from '@om1/platform-utils'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { CohortListItem, datasetsActions, DatasetsState, QueryFilters } from '../../state'
import { ComingSoon } from './ComingSoon'

const numberFormatter = new Intl.NumberFormat(undefined)

type CohortFormValues = {
    id?: string
    name: string
    description: string
    isPrivate: boolean
    analyticsDatasetId: string
    analyticsDatasetName?: string
}

export type CohortSaveValues = {
    name: string
    description: string
    isPrivate: boolean
    analyticsDataset: AnalyticsDatasetDTO
    id?: string
    query?: QueryFilters | null
    isStandardBuild?: boolean | null
}

export type CreateEditCohortDialogProps = FrameworkComponentProps<
    { datasets: DatasetsState },
    Pick<typeof datasetsActions, 'getDatasets'>,
    {
        cohort?: CohortListItem
        onCancel: () => void
        onSave: (cohort: CohortSaveValues) => void
        isLoading: boolean
    }
>

/**
 * A component to edit the primary metadata fields regarding a cohort, such as its name and description.
 * Meant to be used within a dialog
 */
export const CreateEditCohortComponent = ({ state, props: { cohort, isLoading, onCancel, onSave }, actions }: CreateEditCohortDialogProps) => {
    const [isStandardBuild, setIsStandardBuild] = useState(false)
    const editMode = useMemo(() => cohort?.id !== undefined, [cohort])
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t`Name is required`),
        analyticsDatasetId: Yup.string().required(t`Dataset is required`),
        description: Yup.string(),
        isPrivate: Yup.boolean()
    })
    const { handleSubmit, register, control } = useForm<CohortFormValues>({
        defaultValues: {
            name: cohort ? cohort.name : '',
            description: cohort ? cohort.description : '',
            isPrivate: cohort ? cohort.isPrivate : false,
            analyticsDatasetId: cohort ? cohort.analyticsDataset?.id : '',
            analyticsDatasetName: cohort ? cohort.analyticsDataset?.name : ''
        },
        resolver: yupResolver(validationSchema)
    })
    const handleCancel = () => onCancel()
    const onSubmit: SubmitHandler<CohortFormValues> = (cohortToSave: CohortFormValues) => {
        let cohortSaveValues: CohortSaveValues
        delete cohortToSave.analyticsDatasetName

        if (editMode) {
            cohortSaveValues = {
                ...cohortToSave,
                id: cohort!.id,
                analyticsDataset: state.datasets.data.find((d) => d.analyticsDataset.id === cohortToSave.analyticsDatasetId)?.analyticsDataset!
            }
        } else {
            cohortSaveValues = {
                ...cohortToSave,
                query: undefined,
                analyticsDataset: state.datasets.data.find((d) => d.analyticsDataset.id === cohortToSave.analyticsDatasetId)?.analyticsDataset!,
                isStandardBuild
            }
        }

        onSave(cohortSaveValues)
    }

    const saveDisabled = useMemo(() => {
        return state.datasets.ui.loading || isLoading
    }, [state.datasets.ui.loading, isLoading])

    useEffect(() => {
        actions.getDatasets()
    }, [actions])

    const formatPatientCount = (patientCount: number | null | undefined | undefined) => {
        const label = patientCount === 1 ? 'patient' : 'patients'

        return `${numberFormatter.format(patientCount || 0)} ${label}`
    }

    const sortedDatasets: CohortDTO[] = useMemo(() => {
        const REAL_WORLD_NAME = 'OM1 Real World Data Cloud'
        const datasetsCopy = [...state.datasets.data]

        return datasetsCopy.sort(sortDatasets)

        function sortDatasets(a: CohortDTO, b: CohortDTO) {
            if (a.analyticsDataset.name === REAL_WORLD_NAME) {
                return -1
            }
            if (b.analyticsDataset.name === REAL_WORLD_NAME) {
                return 1
            }
            return a.analyticsDataset.name.localeCompare(b.analyticsDataset.name)
        }
    }, [state.datasets.data])

    return (
        <>
            <DialogTitle>
                {editMode ? (
                    <>
                        <Trans>Edit</Trans> {cohort!.name}
                    </>
                ) : (
                    <Trans>Create a New Cohort</Trans>
                )}
            </DialogTitle>
            {sortedDatasets.length === 0 && (
                <div data-testid='loading'>
                    <Trans>Loading...</Trans>
                </div>
            )}
            {sortedDatasets.length > 0 && (
                <form onSubmit={handleSubmit(onSubmit)} data-testid='form'>
                    <DialogContent>
                        <Box paddingTop={2}>
                            <Stack spacing={3}>
                                <TextField id='name' label={t`Cohort Name`} {...register('name')} fullWidth required />
                                <FormControl fullWidth>
                                    <InputLabel id='dataset-label' required>
                                        <Trans>Select Dataset</Trans>
                                    </InputLabel>
                                    <Controller
                                        control={control}
                                        name='analyticsDatasetId'
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                id='dataset'
                                                labelId='dataset-label'
                                                label={<Trans>Select Dataset</Trans>}
                                                required
                                                disabled={state.datasets.ui.loading}
                                                value={value}
                                                onChange={onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}
                                            >
                                                {sortedDatasets.map((d: CohortDTO) => {
                                                    return (
                                                        // The value of the MenuItem is set here, not in the Select component above. This is a workaround for the issue described in the comment above.
                                                        <MenuItem value={d.analyticsDataset.id} key={d.analyticsDataset.id}>
                                                            {/* This is the rendered view */}
                                                            {d.analyticsDataset.name} ({formatPatientCount(d.cohortSize)})
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                <TextField id='cohort-description' label={t`Description`} fullWidth multiline rows={4} {...register('description')} />
                            </Stack>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <ComingSoon>
                            <FormControlLabel
                                id='is-private'
                                value='isPrivate'
                                control={
                                    <Controller
                                        control={control}
                                        name='isPrivate'
                                        render={({ field }) => {
                                            return <Switch color='primary' {...field} defaultChecked={cohort ? cohort.isPrivate : false} />
                                        }}
                                    />
                                }
                                label={
                                    <Trans>
                                        Make this cohort <strong>private:</strong>
                                    </Trans>
                                }
                                labelPlacement='start'
                            />
                        </ComingSoon>
                        <Box flexGrow={1} />
                        <Button variant='text' color='primary' onClick={handleCancel} disabled={state.datasets.ui.loading}>
                            <Trans>Cancel</Trans>
                        </Button>
                        {editMode ? (
                            <Button variant='contained' disabled={saveDisabled} type='submit'>
                                <Trans>Save & Add Criteria</Trans>
                            </Button>
                        ) : (
                            <Button
                                name='standard'
                                variant='contained'
                                disabled={saveDisabled}
                                endIcon={<ArrowForwardIcon />}
                                type='submit'
                                onClick={() => setIsStandardBuild(true)}
                            >
                                <Trans>Standard Build</Trans>
                            </Button>
                        )}
                    </DialogActions>
                    {!editMode && (
                        <DialogActions>
                            <Button
                                name='manual'
                                type='submit'
                                disabled={saveDisabled}
                                variant='text'
                                sx={{ fontSize: 12, textDecoration: 'underline', ':hover': { backgroundColor: 'inherit' }, padding: 0 }}
                            >
                                <Trans>No thanks, I&apos;ll build my cohort manually.</Trans>
                            </Button>
                        </DialogActions>
                    )}
                </form>
            )}
        </>
    )
}
