import { NotificationActionTypes, NotificationActions } from './notification-actions'

export interface NotificationState {
    showHelpDialog: boolean
    showTimeoutDialog: boolean
    cohortId?: string
    cohortName?: string
}

export const initialNotificationState: NotificationState = { showHelpDialog: false, showTimeoutDialog: false }

export function notificationReducer(previousState: NotificationState | undefined, action: NotificationActions): NotificationState {
    const state = previousState || initialNotificationState

    switch (action.type) {
        case NotificationActionTypes.SHOW_HELP_DIALOG:
            return {
                ...state,
                showHelpDialog: true
            }
        case NotificationActionTypes.HIDE_HELP_DIALOG:
            return {
                ...state,
                showHelpDialog: false
            }
        case NotificationActionTypes.SHOW_TIMEOUT_DIALOG:
            return {
                ...state,
                showTimeoutDialog: true,
                cohortId: action.payload.cohortId,
                cohortName: action.payload.cohortName
            }
        case NotificationActionTypes.HIDE_TIMEOUT_DIALOG:
            return {
                ...state,
                showTimeoutDialog: false,
                cohortId: undefined,
                cohortName: undefined
            }
        default:
            return state
    }
}
