import { CohortAnalyticsActionTypes, CohortAnalyticsActions } from './cohort-analytics-actions'
import {
    AnalyticsDimension,
    AnalyticsTaskStatus,
    CohortAnalyticsState,
    ReportAnalyticsType,
    initialCohortAnalyticsState
} from './cohort-analytics-state'

export function cohortAnalyticsReducer(previousState: CohortAnalyticsState | undefined, action: CohortAnalyticsActions): CohortAnalyticsState {
    const state = previousState || initialCohortAnalyticsState

    switch (action.type) {
        case CohortAnalyticsActionTypes.DELETE_COHORT: {
            const data = { ...state.data }
            delete data[action.payload.cohortId]
            return { ...state, data }
        }
        case CohortAnalyticsActionTypes.GET_DIMENSIONS: {
            const { cohortId, taskId, reportType } = action.payload

            let cohortAnalyticsReports = state.data[cohortId]

            if (cohortAnalyticsReports === undefined) {
                cohortAnalyticsReports = {}
            }

            const initialData =
                reportType === ReportAnalyticsType.DEMOGRAPHICS
                    ? {
                          [AnalyticsDimension.Sex]: {},
                          [AnalyticsDimension.Race]: {},
                          [AnalyticsDimension.Age]: {},
                          [AnalyticsDimension.Ethnicity]: {},
                          [AnalyticsDimension.State]: {}
                      }
                    : []

            cohortAnalyticsReports = {
                ...cohortAnalyticsReports,
                [reportType]: {
                    taskId,
                    status: AnalyticsTaskStatus.Pending,
                    data: initialData
                }
            }

            return { ...state, data: { ...state.data, [cohortId]: cohortAnalyticsReports } }
        }
        case CohortAnalyticsActionTypes.SET_DIMENSIONS:
        case CohortAnalyticsActionTypes.SET_DIMENSIONS_ERROR: {
            const { cohortId, taskId, reportType } = action.payload

            let cohortAnalyticsReports = state.data[cohortId]
            if (
                cohortAnalyticsReports === undefined ||
                cohortAnalyticsReports[reportType] === undefined ||
                cohortAnalyticsReports[reportType]?.taskId !== taskId
            ) {
                return state
            }

            if (action.type === CohortAnalyticsActionTypes.SET_DIMENSIONS) {
                const { data } = action.payload
                cohortAnalyticsReports = {
                    ...cohortAnalyticsReports,
                    [reportType]: { ...cohortAnalyticsReports[reportType], status: AnalyticsTaskStatus.Complete, data }
                }
            } else if (action.type === CohortAnalyticsActionTypes.SET_DIMENSIONS_ERROR) {
                cohortAnalyticsReports = {
                    ...cohortAnalyticsReports,
                    [reportType]: { ...cohortAnalyticsReports[reportType], status: AnalyticsTaskStatus.Failed }
                }
            }

            return { ...state, data: { ...state.data, [cohortId]: cohortAnalyticsReports } }
        }
        default: {
            return state
        }
    }
}
