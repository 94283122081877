import { t } from '@lingui/macro'
import { AnalyticsRefDimension, CriteriaType, MedicationCuiRef } from '../../../../state'
import { DepthRefFieldMapper, DepthRefGetterCallback, RefFieldMapperType } from './interfaces'

const getItemLabel: DepthRefGetterCallback<MedicationCuiRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.bocDisplayName
    }
}

const getItemValue: DepthRefGetterCallback<MedicationCuiRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.bocCui
    }
}

const getItemTooltip: DepthRefGetterCallback<MedicationCuiRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return getItemLabel(item, depth)
    }
}

export const MedicationCuiRefFieldMapper: DepthRefFieldMapper<MedicationCuiRef> = {
    type: RefFieldMapperType.Depth,
    dimension: AnalyticsRefDimension.MEDICATION_CUI,
    criteriaType: CriteriaType.Medication,
    table: 'patient_medication',
    dateField: {
        first: 'first_record_date',
        last: 'last_record_date',
        any: 'any_record_date'
    },
    dateFieldInverse: {
        first_record_date: 'first',
        last_record_date: 'last',
        any_record_date: 'any'
    },
    searchPlaceholder: (() => t`Search OM1 Coding System`)(),
    noResultsMessage: (() => t`No results`)(),
    searchHintMessage: (() => t`Search to browse OM1 codes`)(),
    filterFieldToRefField: { boc_cui: 'bocCui' },
    filterFieldOrder: ['boc_cui'],
    filterLabelOrder: ['boc_name'],
    getItemLabel,
    getItemValue,
    getItemTooltip
}
