import { DataDeliveryAdminDeliveryService, DeliveryDTO } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put } from 'redux-saga/effects'
import { dataDeliveryActions } from '../state/data-delivery'

export function createFetchAndUpdateDataDeliveryInListSaga() {
    return function* (action: ReturnType<typeof dataDeliveryActions.dataDeliveryFetchAndUpdateInList>) {
        const { deliveryId } = action.payload
        try {
            const delivery: DeliveryDTO = yield call(DataDeliveryAdminDeliveryService.getDataDeliveryDeliveryDeliveryIdGet, {
                deliveryId: deliveryId
            })
            yield put(dataDeliveryActions.dataDeliveriesListAddEdit({ dataDelivery: delivery, isAdd: false }))
        } catch (error: any) {
            yield handleApiError(error)
        }
    }
}

export type FetchAndUpdateDataDeliveryInListSaga = ReturnType<typeof createFetchAndUpdateDataDeliveryInListSaga>
