import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

export interface LightTooltipProps extends TooltipProps {
    maxWidth?: string
}

export const LightTooltip = styled(({ className, ...props }: LightTooltipProps) => <Tooltip {...props} classes={{ popper: className }} />, {
    shouldForwardProp: (prop) => prop !== 'maxWidth'
})(({ maxWidth, theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        color: 'rgba(0, 0, 0, 0.87)',
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        padding: '0.5rem 1rem',
        boxShadow: theme.shadows[1],
        fontSize: 14,
        maxWidth
    }
}))
