import { InsightsReportDTO, InsightsReportsService } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put } from 'redux-saga/effects'
import { rwaInsightsReportActions } from '../state/rwa-insights-reports'

export function createCreateRWAInsightsReportSaga() {
    return function* (action: ReturnType<typeof rwaInsightsReportActions.createRwaInsightsReport>) {
        try {
            const insightsReportDTO: InsightsReportDTO = yield call(InsightsReportsService.createRwaInsightsInsightsReportsPost, {
                requestBody: action.payload.report
            })
            yield put(rwaInsightsReportActions.createRwaInsightsReportSuccess(insightsReportDTO))
        } catch (error) {
            yield handleApiError(error)
        }
    }
}
