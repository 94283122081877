import { CriteriaType } from '../../../../state'
import { DataType } from '../../blocks/ObservationPeriodDialogComponent'
import { NestedRefFieldMapper, RefFieldMapperType } from './interfaces'

export const ObservationPeriodFieldMapper: Omit<NestedRefFieldMapper<DataType>, 'dimension'> & { dimension: string } = {
    table: 'observation_period',
    type: RefFieldMapperType.Nested,
    criteriaType: CriteriaType.ObservationPeriod,
    getItemId: function (item: DataType): string {
        throw new Error('Function not implemented.')
    },
    getItemParentId: function (item: DataType): string | null | undefined {
        throw new Error('Function not implemented.')
    },
    getItemPathToRoot: function (item: DataType): string[] {
        throw new Error('Function not implemented.')
    },
    getItemLabel: function (item: DataType): string | null | undefined {
        throw new Error('Function not implemented.')
    },
    getItemValue: function (item: DataType): string | null | undefined {
        throw new Error('Function not implemented.')
    },
    getItemTooltip: function (item: DataType): string | null | undefined {
        throw new Error('Function not implemented.')
    },
    dateField: {
        first: 'start_date',
        last: 'end_date',
        any: 'start_date',
        start: 'start_date',
        end: 'end_date'
    },
    dateFieldInverse: {
        start_date: 'start',
        end_date: 'end'
    },
    dimension: 'PATIENT',
    searchPlaceholder: '',
    noResultsMessage: '',
    searchHintMessage: '',
    filterFieldToRefField: {},
    filterFieldOrder: [],
    filterLabelOrder: []
}
