import { DataDeliveryAdminOrganizationMetadataService, OrganizationMetadataDTO } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { notificationActions } from '@om1/platform-notifications'
import { call, put } from 'redux-saga/effects'
import { dataDeliveryActions } from '../state/data-delivery'

export function createUpdateOrganizationMetadataSaga() {
    return function* (action: ReturnType<typeof dataDeliveryActions.updateOrganizationMetadata>) {
        yield put(dataDeliveryActions.setOrganizationMetadataLoading({ loading: true }))
        try {
            const organizationMetadataDTO: OrganizationMetadataDTO = yield call(
                DataDeliveryAdminOrganizationMetadataService.patchDataDeliveryOrganizationMetadataOrganizationMetadataIdPatch,
                {
                    organizationMetadataId: action.payload.organizationMetadataId,
                    requestBody: {
                        deliveryDatabase: action.payload.dto.deliveryDatabase,
                        deliveryShareName: action.payload.dto.deliveryShareName
                    }
                }
            )
            yield put(notificationActions.success())
            yield put(dataDeliveryActions.setOrganizationMetadata({ data: organizationMetadataDTO }))
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(dataDeliveryActions.setOrganizationMetadataLoading({ loading: false }))
        }
    }
}
