import { CohortDTO } from '@om1/falcon-api'

export interface DatasetsState {
    ui: {
        loading: boolean
    }
    data: CohortDTO[]
}

export const initialDatasetsState: DatasetsState = {
    ui: {
        loading: false
    },
    data: []
}
