import { DatasetsActionTypes, DatasetsActions } from './datasets-actions'
import { DatasetsState, initialDatasetsState } from './datasets-state'

export function datasetsReducer(previousState: DatasetsState | undefined, action: DatasetsActions): DatasetsState {
    const state = previousState || initialDatasetsState

    switch (action.type) {
        case DatasetsActionTypes.LOADING_SET: {
            return { ...state, ui: { ...state.ui, loading: action.payload.loading } }
        }
        case DatasetsActionTypes.SET: {
            return {
                ...state,
                data: [...action.payload.datasets]
            }
        }
        default: {
            return state
        }
    }
}
