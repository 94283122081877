import { Auth0State } from '@om1/platform-authentication'
import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { PlatformSettingsState } from '../state'
import { rwaConditionsActions } from '../state/rwa-conditions'
import { RWAConditionsPageComponent } from './RWAConditionsPageComponent'

export function createRWAConditionsPage<TState extends { platformSettings: PlatformSettingsState; auth0: Auth0State }>() {
    return connectRedux(
        withRouter(RWAConditionsPageComponent, { namespace: 'rwa-conditions', dataFetchAction: 'getRwaConditions' }),
        (state: TState) => {
            return { rwaConditions: state.platformSettings.rwaConditions, permissions: state.auth0.permissions }
        },
        { ...rwaConditionsActions }
    )
}

export type RWAConditionsPage = ReturnType<typeof createRWAConditionsPage>
