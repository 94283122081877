import { yupResolver } from '@hookform/resolvers/yup'
import { Trans, t } from '@lingui/macro'
import CloseIcon from '@mui/icons-material/Close'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField
} from '@mui/material'
import { FrameworkComponentProps } from '@om1/platform-utils'
import { ReactNode, useMemo } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import {
    AgeUnit,
    CriteriaType,
    CriterionNode,
    DateRangeInclusion,
    NumericRangeInterval,
    PatientAgeQualifier,
    QualifierType,
    cohortBlocksEditActions
} from '../../../state'
import { findQualifierByType } from '../utils/finders'

interface PatientAgeEditFormValues {
    ageOperator: DateRangeInclusion // More than, less than, equal, at least, at most
    primaryInput: number
    secondaryInput?: number
}

export type PatientAgeEditDialogComponentProps = FrameworkComponentProps<{ criteria: CriterionNode }, typeof cohortBlocksEditActions>
export const PatientAgeEditDialogComponent = ({ state, actions }: PatientAgeEditDialogComponentProps) => {
    const { criteria } = state

    const validationSchema = Yup.object().shape({
        secondaryInput: Yup.number().when('ageOperator', {
            is: (ageOperator: string) => ageOperator === DateRangeInclusion.Between,
            then: (schema) => {
                return schema
                    .typeError(t`Must be a valid age`)
                    .min(Yup.ref('primaryInput'), t`Must be greater than start age`)
                    .max(89, t`Must be at most 89`)
            },
            otherwise: () => Yup.number().notRequired()
        }),
        primaryInput: Yup.number()
            .required(t`required`)
            .integer(t`Must be an integer`)
            .min(4, t`Must be at least 4`)
            .max(89, t`Must be at most 89`)
            .typeError(t`Must be a valid age`),
        ageOperator: Yup.string()
    })

    const initialValue = useMemo(() => {
        const existingQualifier = findQualifierByType<PatientAgeQualifier>(criteria.qualifiers, QualifierType.PatientAgeQualifierDTO)

        if (!existingQualifier) {
            return {
                primaryInput: 18,
                secondaryInput: 18,
                ageOperator: DateRangeInclusion.AtLeast
            }
        }

        const { intervalStart, intervalEnd, intervalIsInclusive } = existingQualifier

        if (!intervalEnd) {
            return {
                primaryInput: intervalStart,
                secondaryInput: intervalStart,
                ageOperator: intervalIsInclusive ? DateRangeInclusion.AtLeast : DateRangeInclusion.MoreThan
            }
        }

        if (!intervalStart) {
            return {
                primaryInput: intervalEnd,
                secondaryInput: intervalEnd,
                ageOperator: intervalIsInclusive ? DateRangeInclusion.AtMost : DateRangeInclusion.LessThan
            }
        }

        return {
            primaryInput: intervalStart,
            secondaryInput: intervalEnd,
            ageOperator: intervalStart === intervalEnd ? DateRangeInclusion.Exactly : DateRangeInclusion.Between
        }
    }, [criteria])

    const {
        control,
        handleSubmit,
        register,
        watch,
        formState: { errors }
    } = useForm<PatientAgeEditFormValues>({
        defaultValues: {
            primaryInput: initialValue.primaryInput || 0, // intervalStart can be null. Default it to a numerical value to satisfy typechecking
            secondaryInput: initialValue.secondaryInput,
            ageOperator: initialValue.ageOperator
        },
        resolver: yupResolver(validationSchema)
    })

    const onCancel = () => {
        actions.patientAgeEditDialogTrigger({})
    }

    const onSubmit: SubmitHandler<PatientAgeEditFormValues> = (patientAgeValues: PatientAgeEditFormValues) => {
        let qualifiers = [...criteria.qualifiers]
        const newQualifier: PatientAgeQualifier = formValuesToAgeRangeQualifier(patientAgeValues)

        qualifiers = qualifiers.filter((q) => q.type !== QualifierType.PatientAgeQualifierDTO)
        qualifiers.push(newQualifier)

        actions.patientAgeEditDialogTrigger({})
        actions.criteriaUpdate({ target: { nodeId: criteria.id }, qualifiers })
    }

    const ageOperatorWatcher = watch('ageOperator')

    const renderSingleAgeSelection = (fieldName: 'primaryInput' | 'secondaryInput') => (
        <>
            <Controller
                control={control}
                name={fieldName}
                render={({ field: { onChange } }) => (
                    <TextField
                        id={fieldName}
                        type='number'
                        {...register(fieldName)}
                        sx={{ marginRight: 1, flex: 1 }}
                        error={!!errors[fieldName]}
                        helperText={errors[fieldName]?.message}
                        inputProps={{
                            inputMode: 'numeric',
                            pattern: '[1-9][0-9]*',
                            'data-testid': `input-age-range-${fieldName === 'primaryInput' ? 'primary' : 'secondary'}`
                        }}
                    />
                )}
            />
        </>
    )

    const renderAgeRangeSelection = () => (
        <>
            <Box flexShrink={0} marginRight={1}>
                <Trans>Between</Trans>
            </Box>
            {renderSingleAgeSelection('primaryInput')}
            <Box flexShrink={0} marginRight={1}>
                <Trans>and</Trans>
            </Box>
            {renderSingleAgeSelection('secondaryInput')}
        </>
    )

    const renderFormFields = () => {
        if (ageOperatorWatcher === DateRangeInclusion.Between) {
            return renderAgeRangeSelection()
        }

        return renderSingleAgeSelection('primaryInput')
    }

    return (
        <Dialog
            open
            fullWidth
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: '700px'
                    }
                }
            }}
        >
            <DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={onCancel}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Box paddingTop={2} display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                        <Box flexShrink={0} marginRight={1}>
                            <Trans>Patient Age at</Trans> <br /> {getOccurenceCriterionText(criteria)}
                        </Box>
                        <Controller
                            control={control}
                            name='ageOperator'
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    id='age-operator'
                                    labelId='range-operator-label'
                                    sx={{ flex: 1, marginRight: 1 }}
                                    required
                                    value={value}
                                    onChange={onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}
                                >
                                    <MenuItem value={DateRangeInclusion.AtLeast}>
                                        <Trans>At Least</Trans>
                                    </MenuItem>
                                    <MenuItem value={DateRangeInclusion.AtMost}>
                                        <Trans>At Most</Trans>
                                    </MenuItem>
                                    <MenuItem value={DateRangeInclusion.Exactly}>
                                        <Trans>Exactly</Trans>
                                    </MenuItem>
                                    <MenuItem value={DateRangeInclusion.MoreThan}>
                                        <Trans>More Than</Trans>
                                    </MenuItem>
                                    <MenuItem value={DateRangeInclusion.LessThan}>
                                        <Trans>Less Than</Trans>
                                    </MenuItem>

                                    <MenuItem value={DateRangeInclusion.Between}>
                                        <Trans>Age Range</Trans>
                                    </MenuItem>
                                </Select>
                            )}
                        />
                        {renderFormFields()}
                        <Box flexShrink={0} marginRight={1}>
                            <Trans>Years Old</Trans>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' color='primary' onClick={onCancel}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button variant='contained' type='submit'>
                        <Trans>Save</Trans>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

function getOccurenceCriterionText(node: CriterionNode): string {
    if (node.dateField === 'first') {
        if (node.type === CriteriaType.Diagnosis) {
            return t`Initial Diagnosis Date`
        }
        if (node.type === CriteriaType.Medication) {
            return t`Medication Initiation Date`
        }
    }

    if (node.dateField === 'last') {
        return t`Latest Encounter Date`
    }

    return t`Encounter(s)`
}

function formValuesToAgeRangeQualifier(formValues: PatientAgeEditFormValues): PatientAgeQualifier {
    const { primaryInput, secondaryInput, ageOperator } = formValues

    let qualifier: PatientAgeQualifier = {
        type: QualifierType.PatientAgeQualifierDTO,
        intervalUnit: AgeUnit.Year,
        intervalStart: 18,
        intervalEnd: undefined,
        intervalIsInclusive: true
    }

    const diff = translateAgeInclusion(ageOperator, primaryInput, secondaryInput)
    if (diff) {
        qualifier = {
            ...qualifier,
            ...diff
        }
    }

    return qualifier
}

function translateAgeInclusion(ageOperator: DateRangeInclusion, primaryInput: number, secondaryInput?: number): NumericRangeInterval | undefined {
    switch (ageOperator) {
        case DateRangeInclusion.AtLeast:
            return {
                intervalStart: primaryInput,
                intervalIsInclusive: true
            }
        case DateRangeInclusion.AtMost:
            return {
                intervalStart: undefined,
                intervalEnd: primaryInput,
                intervalIsInclusive: true
            }
        case DateRangeInclusion.MoreThan:
            return {
                intervalStart: primaryInput,
                intervalIsInclusive: false
            }
        case DateRangeInclusion.LessThan:
            return {
                intervalStart: undefined,
                intervalEnd: primaryInput,
                intervalIsInclusive: false
            }
        case DateRangeInclusion.Exactly:
            return {
                intervalStart: primaryInput,
                intervalEnd: primaryInput,
                intervalIsInclusive: true
            }
        case DateRangeInclusion.Between:
            return {
                intervalStart: primaryInput,
                intervalEnd: secondaryInput,
                intervalIsInclusive: true
            }
    }
}
