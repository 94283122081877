import { Trans } from '@lingui/macro'
import LockIcon from '@mui/icons-material/LockOutlined'
import { styled } from '@mui/system'
import { ContentContainerStyle, ContentPadding } from '@om1/platform-ui-kit/src/components/Layout'
import { FunctionComponent } from 'react'

const TextStyle = styled('p')({
    margin: '10px auto 0 auto'
})

export const AccessDeniedPage: FunctionComponent = () => {
    return (
        <ContentContainerStyle>
            <ContentPadding padX padY sx={{ textAlign: 'center' }}>
                <LockIcon fontSize='large' />
                <TextStyle>
                    <Trans>You do not have permission to access this page.</Trans>
                </TextStyle>
                <TextStyle>
                    <Trans>Please contact your system administrator.</Trans>
                </TextStyle>
            </ContentPadding>
        </ContentContainerStyle>
    )
}
