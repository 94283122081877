import { t } from '@lingui/macro'
import { FrameworkComponentProps } from '@om1/platform-utils'
import { get } from 'lodash'
import { useMemo } from 'react'
import { CriterionNode, QualifierType, RefLabelsState, SpecialtyQualifier } from '../../../state'
import { findQualifierByType } from '../utils/finders'
import { SpecialtyRefFieldMapper } from '../utils/ref-field-mappers'
import { CohortCriterionQualifierSummary } from './CohortCriterionQualifierSummary'

export type CohortCriterionSpecialtySummaryComponentProps = FrameworkComponentProps<
    {
        refLabels: RefLabelsState
        refLabelsLoading: boolean
    },
    {},
    {
        node: CriterionNode
        readOnly?: boolean
        onEditClick: () => void
        onDeleteClick: () => void
    }
>

export const CohortCriterionSpecialtySummaryComponent = ({ state, props }: CohortCriterionSpecialtySummaryComponentProps) => {
    const { node, readOnly, onDeleteClick, onEditClick } = props
    const { refLabels, refLabelsLoading } = state

    const qualifier = findQualifierByType<SpecialtyQualifier>(node.qualifiers, QualifierType.SpecialtyQualifierDTO)
    const refTable = SpecialtyRefFieldMapper.table
    const refField = SpecialtyRefFieldMapper.filterLabelOrder[0]

    const label = useMemo(() => {
        if (!qualifier) {
            return t`Unknown`
        }

        const idMap = get(refLabels, [refTable, refField])

        if (refLabelsLoading || !idMap) {
            return t`Loading...`
        }

        const selections = qualifier.values.map((value) => idMap[value]).join(', ')
        return t`Notes from` + ` ${selections}`
    }, [qualifier, refField, refLabels, refTable, refLabelsLoading])

    const handleDeleteClick = () => {
        onDeleteClick()
    }

    const handleEditClick = () => {
        onEditClick()
    }

    return (
        <CohortCriterionQualifierSummary
            summaryText={label}
            readOnly={readOnly}
            editAction={{ label: t`Edit Specialties`, onClick: handleEditClick }}
            deleteAction={{ label: t`Delete Specialties`, onClick: handleDeleteClick }}
        />
    )
}
