import { END, EventChannel, eventChannel } from 'redux-saga'
import { SweetPath } from 'sweet-path'
import { ApiRequestOptions } from './codegen/client/core/ApiRequestOptions'
import { falconApiConfig } from './falcon-api-config'

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>

export function createFalconTaskSocket(path: SweetPath<'taskId'>) {
    const FALCON_WSS_URL = falconApiConfig.falconApiUrl?.replace(new RegExp(/^http/), 'ws')
    return function (taskId: string, token: string | Resolver<string> | undefined): EventChannel<any> {
        const url = FALCON_WSS_URL + path.insert({ taskId })
        const socket = new WebSocket(url)
        return eventChannel((emitter) => {
            socket.onmessage = ({ data }: MessageEvent) => emitter(data)
            socket.onclose = () => emitter(END)
            socket.onerror = () => emitter(END)
            socket.onopen = () => {
                url.startsWith(FALCON_WSS_URL) && socket.send(JSON.stringify({ token: token }))
            }
            return () => socket.close()
        })
    }
}
