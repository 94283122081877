import { connectRedux } from '@om1/platform-utils'
import { CohortState, cohortBlocksEditActions, cohortEditActions } from '../../state'
import { ReadOnlyCohortComponent } from './ReadOnlyCohortComponent'

export function createReadOnlyCohortComponent<TState extends { cohort: CohortState }>() {
    return connectRedux(
        ReadOnlyCohortComponent,
        (state: TState) => ({
            tree: state.cohort.edit.blocks.tree
        }),
        { ...cohortEditActions, ...cohortBlocksEditActions }
    )
}

export type ReadOnlyCohort = ReturnType<typeof createReadOnlyCohortComponent>
