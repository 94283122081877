import { t } from '@lingui/macro'
import { ReactNode } from 'react'
import { CriterionNode, DateQualifier, DateRangeIntervalUnit, DateRangeOperator, QualifierType } from '../../../state'
import {
    DTODateStringToDate,
    getDateRangeInclusion,
    getDateRangeInclusionTranslation,
    getDateRangeIntervalUnitTranslation,
    getDateRangeOperatorTranslation,
    getLargestInterval
} from '../utils/date-utils'
import { findQualifierByType } from '../utils/finders'
import { ObservationPeriodFieldMapper } from '../utils/ref-field-mappers'
import { CohortCriterionQualifierSummary } from './CohortCriterionQualifierSummary'

const dateFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
})

export interface CohortCriterionRecencySummaryProps {
    node: CriterionNode
    readOnly?: boolean
    onEditClick: () => void
    onDeleteClick: () => void
}

export const CohortCriterionRecencySummary = (props: CohortCriterionRecencySummaryProps) => {
    const { node, readOnly, onDeleteClick, onEditClick } = props

    // @ts-ignore
    const observationPeriodFilters = node.filters.filter((f) => f.table === ObservationPeriodFieldMapper.table)

    // @ts-ignore
    const qualifier = findQualifierByType<DateQualifier>(node.qualifiers, QualifierType.DateQualifierDTO)

    let label: ReactNode = null
    if (observationPeriodFilters.length > 0) {
        // Date Window Filter
        if (!qualifier) {
            label = t`Unknown`
            // TODO fix other recency qualifiers besides Time Range
        } else if (qualifier.dateRangeOperator === DateRangeOperator.Between) {
            label = getTimeRangeLabel(qualifier)
        } else {
            label = getLabel(qualifier)
        }
    } else if (qualifier && qualifier.dateRangeOperator === DateRangeOperator.Between) {
        label = getBetweenLabel(qualifier)
    } else if (qualifier) {
        label = getLabel(qualifier)
    } else {
        label = t`Unknown`
    }

    const handleDeleteClick = () => {
        onDeleteClick()
    }

    const handleEditClick = () => {
        onEditClick()
    }

    return (
        <CohortCriterionQualifierSummary
            summaryText={label}
            readOnly={readOnly}
            editAction={{ label: t`Edit Recency`, onClick: handleEditClick }}
            deleteAction={{ label: t`Delete Recency`, onClick: handleDeleteClick }}
        />
    )
}

function getBetweenLabel(qualifier: DateQualifier): string {
    const startDate = DTODateStringToDate(qualifier.referenceDate)
    const endDate = new Date(startDate)
    if (qualifier.intervalUnitFromReferenceDate === DateRangeIntervalUnit.Year) {
        endDate.setFullYear(endDate.getFullYear() + (qualifier.intervalEndFromReferenceDate || 0))
    } else if (qualifier.intervalUnitFromReferenceDate === DateRangeIntervalUnit.Month) {
        endDate.setMonth(endDate.getMonth() + (qualifier.intervalEndFromReferenceDate || 0))
    } else {
        endDate.setDate(endDate.getDate() + (qualifier.intervalEndFromReferenceDate || 0))
    }

    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
    return `At any time between ${dateFormatter.format(startDate)} and ${dateFormatter.format(endDate)}`
}

function getTimeRangeLabel(qualifier: DateQualifier): string {
    const startDate = DTODateStringToDate(qualifier.referenceDate)
    const endDate = new Date(startDate)
    if (qualifier.intervalUnitFromReferenceDate === DateRangeIntervalUnit.Year) {
        endDate.setFullYear(endDate.getFullYear() + (qualifier.intervalEndFromReferenceDate || 0))
    } else if (qualifier.intervalUnitFromReferenceDate === DateRangeIntervalUnit.Month) {
        endDate.setMonth(endDate.getMonth() + (qualifier.intervalEndFromReferenceDate || 0))
    } else {
        endDate.setDate(endDate.getDate() + (qualifier.intervalEndFromReferenceDate || 0))
    }

    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
    return `Starts any time between ${dateFormatter.format(startDate)} and ${dateFormatter.format(endDate)}`
}

function getLabel(qualifier: DateQualifier): string {
    const rangeInclusion = getDateRangeInclusion(qualifier)
    const interval = getLargestInterval(qualifier)
    const rangeInclusionText = getDateRangeInclusionTranslation(rangeInclusion)
    const intervalUnitText = getDateRangeIntervalUnitTranslation(qualifier.intervalUnitFromReferenceDate)
    const rangeOperatorText = getDateRangeOperatorTranslation(qualifier.dateRangeOperator)
    const date = dateFormatter.format(DTODateStringToDate(qualifier.referenceDate))
    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
    return `${rangeInclusionText} ${interval} ${intervalUnitText} ${rangeOperatorText} ${date}`
}
