import { t } from '@lingui/macro'
import { AnalyticsRefDimension, CriteriaType, MedicationRef } from '../../../../state'
import { DepthRefFieldMapper, DepthRefGetterCallback, RefFieldMapperType } from './interfaces'

const getItemLabel: DepthRefGetterCallback<MedicationRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.level1Name
        case 1:
            return item.level2Name
        case 2:
            return item.level3Name
        case 3:
            return item.level4Name
    }
}

const getItemValue: DepthRefGetterCallback<MedicationRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.level1Id
        case 1:
            return item.level2Id
        case 2:
            return item.level3Id
        case 3:
            return item.level4Id
    }
}

const getItemTooltip: DepthRefGetterCallback<MedicationRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
        case 1:
        case 2:
        case 3:
            return getItemLabel(item, depth)
    }
}

export const MedicationRefFieldMapper: DepthRefFieldMapper<MedicationRef> = {
    type: RefFieldMapperType.Depth,
    dimension: AnalyticsRefDimension.MEDICATION,
    criteriaType: CriteriaType.Medication,
    table: 'patient_medication',
    dateField: {
        first: 'first_record_date',
        last: 'last_record_date',
        any: 'any_record_date'
    },
    dateFieldInverse: {
        first_record_date: 'first',
        last_record_date: 'last',
        any_record_date: 'any'
    },
    searchPlaceholder: (() => t`Search Medications`)(),
    noResultsMessage: (() => t`No results`)(),
    searchHintMessage: (() => t`Search to browse medications`)(),
    filterFieldToRefField: { level_1_id: 'level1Id', level_2_id: 'level2Id', level_3_id: 'level3Id', level_4_id: 'level4Id' },
    filterFieldOrder: ['level_1_id', 'level_2_id', 'level_3_id', 'level_4_id'],
    filterLabelOrder: ['level_1_name', 'level_2_name', 'level_3_name', 'level_4_name'],
    getItemLabel,
    getItemValue,
    getItemTooltip
}
