export interface Auth0ConfigInterface {
    auth0Domain: string
    auth0ClientId: string
    auth0AudienceDomain: string
    auth0PlatformScopes: string
}

export const auth0Config: Auth0ConfigInterface = {
    auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
    auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
    auth0AudienceDomain: process.env.REACT_APP_AUTH0_AUDIENCE_DOMAIN as string,
    auth0PlatformScopes: process.env.REACT_APP_AUTH0_PLATFORM_SCOPES as string
}
