import { Trans } from '@lingui/macro'
import React from 'react'
import { CriteriaType } from '../../state'

interface CriteriaTypeLabelProps {
    criteriaType: CriteriaType
}

/**
 * An plain text label representation of a criteria type.
 */
export const CriteriaTypeLabel: React.FunctionComponent<CriteriaTypeLabelProps> = ({ criteriaType }) => {
    switch (criteriaType) {
        case CriteriaType.PatientAttributes:
            return <Trans>Patient Attributes</Trans>
        case CriteriaType.Diagnosis:
            return <Trans>Diagnosis</Trans>
        case CriteriaType.Procedure:
            return <Trans>Procedure</Trans>
        case CriteriaType.LabTest:
            return <Trans>Lab Test</Trans>
        case CriteriaType.Medication:
            return <Trans>Medication</Trans>
        case CriteriaType.Observation:
            return <Trans>Observation</Trans>
        case CriteriaType.ObservationPeriod:
            return <Trans>Observation Period</Trans>
        case CriteriaType.EhrNotes:
            return <Trans>EHR Note</Trans>
        case CriteriaType.ExternalCohort:
            return <Trans>External Cohort</Trans>
        default:
            return null
    }
}
