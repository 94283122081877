import { CohortListActions, CohortListActionTypes } from './cohort-list-actions'
import { CohortListState, CohortOwner, initialCohortListState } from './cohort-list-state'

export function cohortListReducer(previousState: CohortListState | undefined, action: CohortListActions): CohortListState {
    const state = previousState || initialCohortListState

    switch (action.type) {
        case CohortListActionTypes.LOADING_SET: {
            return { ...state, loading: action.payload.loading }
        }
        case CohortListActionTypes.COHORTS_SET: {
            return { ...state, paginationMeta: action.payload.meta, cohorts: { ...state.cohorts, [action.payload.owner]: action.payload.cohorts } }
        }
        case CohortListActionTypes.COHORT_SET_SIZE: {
            return {
                ...state,
                cohorts: {
                    ...state.cohorts,
                    [CohortOwner.User]: state.cohorts[CohortOwner.User].map((item) =>
                        item.id === action.payload.cohortId ? { ...item, cohortSize: action.payload.cohortSize, isStale: false } : item
                    )
                }
            }
        }
        case CohortListActionTypes.COHORT_SET_SUMMARY_REPORT_TASK_ID: {
            return {
                ...state,
                cohorts: {
                    ...state.cohorts,
                    [CohortOwner.User]: state.cohorts[CohortOwner.User].map((item) =>
                        item.id === action.payload.cohortId ? { ...item, summaryReportTaskId: action.payload.summaryReportTaskId } : item
                    ),
                    [CohortOwner.System]: state.cohorts[CohortOwner.System].map((item) =>
                        item.id === action.payload.cohortId ? { ...item, summaryReportTaskId: action.payload.summaryReportTaskId } : item
                    )
                }
            }
        }
        case CohortListActionTypes.COHORTS_ADD: {
            const cohorts = [...state.cohorts[action.payload.owner], ...action.payload.cohorts]
            const paginationMeta = { ...state.paginationMeta, totalItems: state.paginationMeta.totalItems + action.payload.cohorts.length }
            return {
                ...state,
                paginationMeta,
                cohorts: { ...state.cohorts, [action.payload.owner]: cohorts }
            }
        }
        default: {
            return state
        }
    }
}
