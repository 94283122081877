import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'
import { debounce, put } from 'redux-saga/effects'
import { NotificationActionTypes, notificationActions } from '../state/notification-actions'

export function createNotificationSaga(enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey) {
    const successNotificationSaga = function* (action: ReturnType<typeof notificationActions.success>) {
        yield enqueueSnackbar(action.payload.message, { variant: 'success', ...action.payload.options })
    }

    const errorNotificationSaga = function* (action: ReturnType<typeof notificationActions.error>) {
        yield enqueueSnackbar(action.payload.message, { variant: 'error', ...action.payload.options })
    }

    const warningNotificationSaga = function* (action: ReturnType<typeof notificationActions.warning>) {
        yield enqueueSnackbar(action.payload.message, { variant: 'warning', ...action.payload.options })
    }

    const timeoutDialogSaga = function* (action: ReturnType<typeof notificationActions.showTimeoutDialog>) {
        yield put(notificationActions.hideHelpDialog())
    }

    const helpDialogSaga = function* (action: ReturnType<typeof notificationActions.showHelpDialog>) {
        yield put(notificationActions.hideTimeoutDialog())
    }

    return function* () {
        yield debounce(0, NotificationActionTypes.SUCCESS, successNotificationSaga)
        yield debounce(0, NotificationActionTypes.ERROR, errorNotificationSaga)
        yield debounce(0, NotificationActionTypes.WARNING, warningNotificationSaga)
        yield debounce(0, NotificationActionTypes.SHOW_TIMEOUT_DIALOG, timeoutDialogSaga)
        yield debounce(0, NotificationActionTypes.SHOW_HELP_DIALOG, helpDialogSaga)
    }
}
