import { connectRedux } from '@om1/platform-utils'
import { CohortState } from '../../../state'
import { CohortCriterionSpecialtySummaryComponent } from './CohortCriterionSpecialtySummaryComponent'

export function createCohortCriterionSpecialtySummaryComponent<TState extends { cohort: CohortState }>() {
    return connectRedux(CohortCriterionSpecialtySummaryComponent, (state: TState) => ({
        refLabels: state.cohort.edit.blocks.ui.refLabels,
        refLabelsLoading: state.cohort.edit.blocks.ui.refLabelsLoading
    }))
}

export type CohortCriterionSpecialtySummary = ReturnType<typeof createCohortCriterionSpecialtySummaryComponent>
