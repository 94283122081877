/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HealthCheckDTO } from '../models/HealthCheckDTO'
import type { VersionDTO } from '../models/VersionDTO'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class CoreService {
    /**
     * Healthcheck
     * Service endpoint to periodically ping the snowflake database and queue a ping task
     * to keep the auth token alive in both the web service and the worker services
     * @returns HealthCheckDTO Successful Response
     * @throws ApiError
     */
    public static healthcheckHealthcheckGet(): CancelablePromise<HealthCheckDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/healthcheck'
        })
    }
    /**
     * Version
     * @returns VersionDTO Successful Response
     * @throws ApiError
     */
    public static versionVersionGet(): CancelablePromise<VersionDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/version'
        })
    }
}
