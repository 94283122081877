import { debounce } from 'redux-saga/effects'
import { InsightsLibraryActionTypes } from '../state/insights-library'
import { createCreateInsightsLbraryReportsSaga } from './create-insights-library-reports-saga'
import { createDeleteInsightsLbraryReportsSaga } from './delete-insights-library-reports-saga'
import { createGetInsightsLbraryReportsSaga } from './get-insights-library-reports-saga'

export function createInsightsLibrarySaga() {
    const getInsightsLbraryReportsSaga = createGetInsightsLbraryReportsSaga()
    const deleteInsightsLibraryReportsSaga = createDeleteInsightsLbraryReportsSaga()
    const createInsightsLbraryReportsSaga = createCreateInsightsLbraryReportsSaga()

    return function* () {
        yield debounce(0, InsightsLibraryActionTypes.INDEX_INSIGHTS_LIBARY_REPORTS, getInsightsLbraryReportsSaga)
        yield debounce(0, InsightsLibraryActionTypes.DELETE_INSIGHTS_LIBRARY_REPORT, deleteInsightsLibraryReportsSaga)
        yield debounce(0, InsightsLibraryActionTypes.CREATE_INSIGHTS_LIBRARY_REPORT, createInsightsLbraryReportsSaga)
    }
}
