import { connectRedux } from '@om1/platform-utils'
import { CohortState, CriterionNode, cohortBlocksEditActions } from '../../../state'
import { findNode } from '../../../state/edit/blocks/cohort-blocks-edit-reducer'
import { OccurrenceEditDialogComponent } from './OccurrenceEditDialogComponent'

export function createOccurrenceEditDialogComponent<TState extends { cohort: CohortState }>() {
    return connectRedux(
        OccurrenceEditDialogComponent,
        (state: TState) => {
            const nodeId = state.cohort.edit.blocks.ui.occurenceEditDialog.target?.nodeId
            const relatedNodeId = state.cohort.edit.blocks.ui.occurenceEditDialog.relatedTarget?.nodeId

            return {
                criteria: nodeId ? (findNode(state.cohort.edit.blocks.tree, nodeId) as CriterionNode) : undefined,
                relatedCriteria: relatedNodeId ? (findNode(state.cohort.edit.blocks.tree, relatedNodeId) as CriterionNode) : undefined
            }
        },
        cohortBlocksEditActions
    )
}

export type OccurrenceEditDialog = ReturnType<typeof createOccurrenceEditDialogComponent>
