import { CohortDTO, CohortPatchDTO, ExplorerCohortsService } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put, select } from 'redux-saga/effects'
import { CohortListItem, CohortState, cohortAnalyticsActions, cohortBlocksEditActions, cohortCommonActions, cohortEditActions } from '../state'

export function createUpdateCohortQuerySaga() {
    return function* (action: ReturnType<typeof cohortEditActions.cohortUpdateQuery>) {
        try {
            if (action.payload.refreshCohortMeta) {
                yield put(cohortAnalyticsActions.deleteCohort({ cohortId: action.payload.cohort.id }))
                yield put(cohortEditActions.cohortUpdateLoadingSet({ loading: true }))
                const cohortDTO: CohortDTO = yield call(ExplorerCohortsService.patchExplorerCohortsCohortIdPatch, {
                    cohortId: action.payload.cohort.id!,
                    requestBody: action.payload.cohort as CohortPatchDTO
                })
                // Update the cohort size after the query is updated, though it's likely to be null
                yield put(cohortEditActions.cohortSizeSet({ cohortSize: cohortDTO.cohortSize || undefined, cohortId: cohortDTO.id }))
                const builtBlocks = yield select((store: { cohort: CohortState }) => store.cohort.edit.blocks.tree)
                yield put(cohortBlocksEditActions.cohortSetLastSavedBlocks({ blocks: builtBlocks }))

                if (cohortDTO.cohortSize === undefined || cohortDTO.cohortSize === null) {
                    yield put(cohortCommonActions.cohortAutosaveSizeGet({ cohortId: cohortDTO.id, cohortName: cohortDTO.name }))
                }
                yield put(cohortEditActions.cohortSet({ cohort: cohortDTO as CohortListItem }))
            } else {
                const cohortDTO: CohortDTO = yield call(ExplorerCohortsService.patchExplorerCohortsCohortIdPatch, {
                    cohortId: action.payload.cohort.id!,
                    requestBody: action.payload.cohort as CohortPatchDTO
                })
                yield put(cohortEditActions.cohortSet({ cohort: cohortDTO as CohortListItem }))
            }
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(cohortEditActions.cohortUpdateLoadingSet({ loading: false }))
        }
    }
}
