import { t } from '@lingui/macro'
import { AnalyticsRefDimension, CriteriaType, LabRef } from '../../../../state'
import { NestedRefFieldMapper, NestedRefGetterCallback, RefFieldMapperType } from './interfaces'

const getItemId: NestedRefGetterCallback<LabRef, string> = (item) => item.labConceptId
const getItemParentId: NestedRefGetterCallback<LabRef> = (item) => item.parentLabConceptId
const getItemPathToRoot: NestedRefGetterCallback<LabRef, string[]> = (item) => (item.pathToRoot ? item.pathToRoot.split('.') : [])
const getItemLabel: NestedRefGetterCallback<LabRef> = (item) => item.lab
const getItemValue: NestedRefGetterCallback<LabRef> = (item) => item.labConceptId
const getItemTooltip: NestedRefGetterCallback<LabRef> = (item) => item.displayName
const getItemChildCount: NestedRefGetterCallback<LabRef> = (item) => item.childCount

export const LabRefFieldMapper: NestedRefFieldMapper<LabRef> = {
    type: RefFieldMapperType.Nested,
    dimension: AnalyticsRefDimension.LAB,
    criteriaType: CriteriaType.LabTest,
    table: 'patient_lab',
    dateField: {
        first: 'first_record_date',
        last: 'last_record_date',
        any: 'any_record_date'
    },
    dateFieldInverse: {
        first_record_date: 'first',
        last_record_date: 'last',
        any_record_date: 'any'
    },
    searchPlaceholder: (() => t`Search Labs`)(),
    noResultsMessage: (() => t`No results`)(),
    searchHintMessage: (() => t`Search to browse labs`)(),
    filterFieldToRefField: {
        associated_lab_concept_id: 'labConceptId',
        anchor_lab_concept_id: 'labConceptId',
        lab: 'lab',
        anchor_lab_concept: 'anchorLabConcept',
        child_count: 'childCount'
    },
    filterFieldOrder: ['anchor_lab_concept_id'],
    filterLabelOrder: ['anchor_lab_concept'],
    getItemId,
    getItemParentId,
    getItemPathToRoot,
    getItemLabel,
    getItemValue,
    getItemTooltip,
    getItemChildCount
}

export const AnyLabRefFieldMapper: NestedRefFieldMapper<LabRef> = {
    type: RefFieldMapperType.Nested,
    dimension: AnalyticsRefDimension.LAB,
    criteriaType: CriteriaType.LabTest,
    table: 'patient',
    dateField: {
        first: 'first_record_date',
        last: 'last_record_date',
        any: 'any_record_date'
    },
    dateFieldInverse: {
        first_record_date: 'first',
        last_record_date: 'last',
        any_record_date: 'any'
    },
    searchPlaceholder: (() => t`Search Labs`)(),
    noResultsMessage: (() => t`No results`)(),
    searchHintMessage: (() => t`Search to browse labs`)(),
    filterFieldToRefField: {
        associated_lab_concept_id: 'labConceptId',
        anchor_lab_concept_id: 'labConceptId',
        lab: 'lab',
        anchor_lab_concept: 'anchorLabConcept',
        child_count: 'childCount'
    },
    filterFieldOrder: ['anchor_lab_concept_id'],
    filterLabelOrder: ['anchor_lab_concept'],
    getItemId,
    getItemParentId,
    getItemPathToRoot,
    getItemLabel,
    getItemValue,
    getItemTooltip,
    getItemChildCount
}
