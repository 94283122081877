import { CohortState } from '@om1/cohort-module'
import { cohortListActions } from '@om1/cohort-module/src/state'
import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { rwaInsightsReportActions } from '../state/rwa-insights-reports'
import { AddRWAInsightsReportsModalComponent } from './AddRWAInsightsReportModalComponent'

export function createAddRWAInsightsReportsModal<TState extends { cohort: CohortState }>() {
    return connectRedux(
        withRouter(AddRWAInsightsReportsModalComponent, { namespace: 'rwa-InsightsReports', dataFetchAction: 'cohortsGetSystemCohorts' }),
        (state: TState) => state.cohort,
        { ...cohortListActions, ...rwaInsightsReportActions }
    )
}

export type AddRWAInsightsReportsModal = ReturnType<typeof createAddRWAInsightsReportsModal>
