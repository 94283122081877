import { t } from '@lingui/macro'
import { ExplorerTasksService, OpenAPI, TaskDTO } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { notificationActions } from '@om1/platform-notifications'
import { renderSeeMoreToast } from '@om1/platform-notifications/components/SeeMoreToast'
import { EventChannel } from 'redux-saga'
import { call, delay, put, take } from 'redux-saga/effects'
import { cohortCommonActions, cohortListActions } from '../state'
import { taskSocket } from './task-socket'

const MAX_RETRIES = 3
const RETRY_DELAY = 5000 // 5 seconds

export function createGenerateSummaryReportSaga() {
    return function* (action: ReturnType<typeof cohortCommonActions.generateSummaryReport>) {
        const { cohortId, cohortName } = action.payload
        yield put(cohortCommonActions.cohortSummaryReportLoadingSet({ cohortId: action.payload.cohortId, loading: true }))
        let taskId
        try {
            const taskDTO: TaskDTO = yield call(ExplorerTasksService.summaryReportGenerateExplorerTasksCohortCohortIdSummaryReportGenerateGet, {
                cohortId: action.payload.cohortId
            })
            taskId = taskDTO.taskId
        } catch (e) {
            yield put(notificationActions.error(t`Could not get task id`))
            yield put(cohortCommonActions.cohortSummaryReportLoadingSet({ cohortId: action.payload.cohortId, loading: false }))
        }
        if (taskId) {
            let retries = 0
            let success = false
            while (retries < MAX_RETRIES && !success) {
                try {
                    const eventChannel: EventChannel<any> = yield call(taskSocket, taskId, OpenAPI.TOKEN)
                    const socketResponse = JSON.parse(yield take(eventChannel)) as {
                        taskId: string
                        status: string
                        result: { cohortId: string } & { error?: string }
                    }
                    if (socketResponse.status === 'SUCCESS') {
                        yield put(cohortCommonActions.cohortSummaryReportLoadingSet({ cohortId: action.payload.cohortId, loading: false }))
                        yield put(cohortListActions.cohortSetSummaryReportTaskId({ cohortId: action.payload.cohortId, summaryReportTaskId: taskId }))
                        success = true
                    } else if (socketResponse.status === 'FAILURE' && (socketResponse.result.error || '').includes('TimeoutError')) {
                        yield put(
                            notificationActions.error(renderSeeMoreToast({ cohortId, cohortName }), { persist: true, key: `snackbar-${cohortId}` })
                        )
                    } else if (socketResponse.status === 'FAILURE' && (socketResponse.result.error || '').includes('No task with id')) {
                        retries++
                        if (retries < MAX_RETRIES) {
                            yield delay(RETRY_DELAY)
                        } else {
                            yield put(notificationActions.error(t`Could not generate analytics report due to task not found error`))
                        }
                    }
                } catch (error) {
                    yield handleApiError(error)
                } finally {
                    if (!success && retries >= MAX_RETRIES) {
                        yield put(cohortCommonActions.cohortSummaryReportLoadingSet({ cohortId: action.payload.cohortId, loading: false }))
                    }
                }
            }
        }
    }
}

export type GenerateSummaryReportSaga = ReturnType<typeof createGenerateSummaryReportSaga>
