import { AnalyticsRefDimension, CriteriaType, SexRef } from '../../../../state'
import { DepthRefFieldMapper, DepthRefGetterCallback, RefFieldMapperType } from './interfaces'

const getItemLabel: DepthRefGetterCallback<SexRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.sex
    }
}

const getItemValue: DepthRefGetterCallback<SexRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.sex
    }
}

const getItemTooltip: DepthRefGetterCallback<SexRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return getItemLabel(item, depth)
    }
}

export const SexRefFieldMapper: DepthRefFieldMapper<SexRef> = {
    type: RefFieldMapperType.Depth,
    dimension: AnalyticsRefDimension.SEX,
    criteriaType: CriteriaType.PatientAttributes,
    table: 'patient',
    dateField: {
        first: 'null_date',
        last: 'null_date',
        any: 'null_date'
    },
    dateFieldInverse: {},
    searchPlaceholder: '',
    noResultsMessage: '',
    searchHintMessage: '',
    filterFieldToRefField: { sex: 'sex' },
    filterFieldOrder: ['sex'],
    filterLabelOrder: ['sex'],
    getItemLabel,
    getItemValue,
    getItemTooltip
}
