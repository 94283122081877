import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import exporting from 'highcharts/modules/exporting'
import React, { PropsWithChildren, createContext, useContext, useRef } from 'react'

// This is necessary for exporting features
exporting(Highcharts)

const ChartContext = createContext<React.MutableRefObject<HighchartsReact.RefObject | null> | null>(null)

export const useChart = () => useContext(ChartContext)

export const ChartProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const chartRef = useRef<HighchartsReact.RefObject | null>(null)

    return <ChartContext.Provider value={chartRef}>{children}</ChartContext.Provider>
}
