import { PlatformConfigActionTypes, PlatformConfigActions } from './platform-config-actions'

export interface PlatformConfigState {
    buildNumber: string
    environmentTag: string
    semanticVersion: string
    showHelpDialog: boolean
}

export const initialPlatformConfigState: PlatformConfigState = { buildNumber: '', environmentTag: '', semanticVersion: '', showHelpDialog: false }

export function platformConfigReducer(previousState: PlatformConfigState | undefined, action: PlatformConfigActions): PlatformConfigState {
    const state = previousState || initialPlatformConfigState

    switch (action.type) {
        case PlatformConfigActionTypes.SET_BUILD_NUMBER:
            return {
                ...state,
                buildNumber: action.payload.buildNumber
            }
        case PlatformConfigActionTypes.SET_ENVIRONMENT_TAG:
            return {
                ...state,
                environmentTag: action.payload.environmentTag
            }
        case PlatformConfigActionTypes.SET_SEMANTIC_VERSION:
            return {
                ...state,
                semanticVersion: action.payload.version
            }
        default:
            return state
    }
}
