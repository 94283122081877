import { CohortCreateActionTypes, CohortCreateActions } from './cohort-create-actions'
import { CohortCreateState, initialCohortCreateState } from './cohort-create-state'

export function cohortCreateReducer(previousState: CohortCreateState | undefined, action: CohortCreateActions) {
    const state = previousState || initialCohortCreateState

    switch (action.type) {
        case CohortCreateActionTypes.SET_IS_LOADING: {
            return { ...state, ui: { ...state.ui, loading: action.payload.loading } }
        }
        default: {
            return state
        }
    }
}
