import { CohortDTO } from '@om1/falcon-api'
import { ActionsUnion, createAction } from '@om1/platform-utils'

export enum DatasetsActionTypes {
    LOADING_SET = '@@cohort/datasets/loading-set',
    GET = '@@cohort/datasets/get',
    SET = '@@cohort/datasets/set'
}

export const datasetsActions = {
    setDatasetsLoading: (payload: { loading: boolean }) => createAction(DatasetsActionTypes.LOADING_SET, payload),
    getDatasets: () => createAction(DatasetsActionTypes.GET),
    setDatasets: (payload: { datasets: CohortDTO[] }) => createAction(DatasetsActionTypes.SET, payload)
}
export type DatasetsActions = ActionsUnion<typeof datasetsActions>
