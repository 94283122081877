import { styled } from '@mui/material'

export const getStyledGrid = (component: any) => {
    return styled(component)(() => ({
        marginBottom: 50,
        border: 0,
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none'
        },
        '.MuiDataGrid-iconSeparator': {
            display: 'none'
        },
        '.MuiDataGrid-row.Mui-selected': {
            background: '#FFFFFF'
        },
        '.MuiDataGrid-cell': {
            boxSizing: 'none',
            borderBottom: 0,
            padding: '0 16px'
        },
        '.MuiDataGrid-columnHeader': {
            padding: '0 16px'
        },
        '.MuiDataGrid-columnHeaders': {
            border: 0
        },
        '.MuiDataGrid-footerContainer': {
            border: 0
        },
        '.MuiDataGrid-row': {
            backgroundColor: '#FFFFFF',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)'
        },
        '.MuiDataGrid-row:hover': {
            backgroundColor: '#FFFFFF'
        },
        '.MuiDataGrid-columnHeaderTitle': {
            textTransform: 'uppercase',
            fontSize: '10px'
        },
        '.MuiDataGrid-overlayWrapper': {
            height: '250px'
        },
        '.MuiDataGrid-overlayWrapperInner': {
            height: '400px !important'
        }
    }))
}
