import { connectRedux } from '@om1/platform-utils'
import { BackOfficeState } from '../state'
import { dataDeliveryActions } from '../state/data-delivery'
import { DataDeliveryCohortInputComponent } from './DataDeliveryCohortInputComponent'

export function createDataDeliveryCohortInputComponent<TState extends { backOffice: BackOfficeState }>() {
    return connectRedux(
        DataDeliveryCohortInputComponent,
        (state: TState) => ({
            registryTypes: state.backOffice.dataDelivery.registryTypes.registryTypes,
            registryTypesLoading: state.backOffice.dataDelivery.registryTypes.isLoading
        }),
        dataDeliveryActions
    )
}

export type DataDeliveryCohortInput = ReturnType<typeof createDataDeliveryCohortInputComponent>
