import { DataDeliveryAdminDeliveredDatabasesService, PaginatedDTO_DeliveredDatabaseDTO_ } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put } from 'redux-saga/effects'
import { dataDeliveryActions } from '../state/data-delivery'

export function createGetDeliveredDatabasesSaga() {
    return function* (action: ReturnType<typeof dataDeliveryActions.getDeliveredDatabases>) {
        yield put(dataDeliveryActions.setDeliveredDatabasesLoading({ loading: true }))
        try {
            const paginatedDeliveredDatabaseDTO: PaginatedDTO_DeliveredDatabaseDTO_ = yield call(
                DataDeliveryAdminDeliveredDatabasesService.indexDataDeliveryDeliveredDatabasesGet,
                {
                    page: 1,
                    limit: 1000
                }
            )
            yield put(
                dataDeliveryActions.setDeliveredDatabases({ data: paginatedDeliveredDatabaseDTO.data, meta: paginatedDeliveredDatabaseDTO.meta })
            )
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(dataDeliveryActions.setDeliveredDatabasesLoading({ loading: false }))
        }
    }
}

export type GetDataDeliveriesSaga = ReturnType<typeof createGetDeliveredDatabasesSaga>
