import { CohortInsightsReportDTO } from '@om1/falcon-api'
import { ActionsUnion, createAction } from '@om1/platform-utils'

export enum InsightsLibraryActionTypes {
    INDEX_INSIGHTS_LIBARY_REPORTS = '@@insightsLibrary/INDEX_INSIGHTS_LIBARY_REPORTS',
    SET_INSIGHTS_LIBARY_REPORTS = '@@insightsLibrary/SET_INSIGHTS_LIBARY_REPORTS',
    SET_INSIGHTS_LIBARY_REPORTS_LOADING = '@@insightsLibrary/SET_INSIGHTS_LIBARY_REPORTS_LOADING',
    OPEN_REGISTER_RWA_INSIGHTS_REPORTS = '@@insightsLibrary/OPEN_REGISTER_RWA_INSIGHTS_REPORTS',
    CREATE_INSIGHTS_LIBRARY_REPORT = '@@insightsLibrary/CREATE_INSIGHTS_LIBRARY_REPORT',
    CREATE_INSIGHTS_LIBRARY_REPORT_SUCCESS = '@@insightsLibrary/CREATE_INSIGHTS_LIBRARY_REPORT_SUCCESS',
    DELETE_INSIGHTS_LIBRARY_REPORT = '@@insightsLibrary/DELETE_INSIGHTS_LIBRARY_REPORT',
    DELETE_INSIGHTS_LIBRARY_REPORT_SUCCESS = '@@insightsLibrary/DELETE_INSIGHTS_LIBRARY_REPORT_SUCCESS'
}

export const insightsLibraryActions = {
    getInsightsLibraryReports: () => createAction(InsightsLibraryActionTypes.INDEX_INSIGHTS_LIBARY_REPORTS),
    setCohortInsightsLibraryReports: (payload: { cohortInsightsReports: CohortInsightsReportDTO[] }) =>
        createAction(InsightsLibraryActionTypes.SET_INSIGHTS_LIBARY_REPORTS, payload),
    setCohortInsightsLibraryReportsLoading: (payload: { loading: boolean }) =>
        createAction(InsightsLibraryActionTypes.SET_INSIGHTS_LIBARY_REPORTS_LOADING, payload),
    createInsightsLibraryReport: (payload: { cohortInsightsReport: CohortInsightsReportDTO }) =>
        createAction(InsightsLibraryActionTypes.CREATE_INSIGHTS_LIBRARY_REPORT, payload),
    createInsightsLibraryReportSuccess: (payload: { cohortInsightsReport: CohortInsightsReportDTO }) =>
        createAction(InsightsLibraryActionTypes.CREATE_INSIGHTS_LIBRARY_REPORT_SUCCESS, payload),
    deleteInsightsLibraryReport: (payload: { organization_report_id: string }) =>
        createAction(InsightsLibraryActionTypes.DELETE_INSIGHTS_LIBRARY_REPORT, payload),
    deleteInsightsLibraryReportSuccess: (payload: { organization_report_id: string }) =>
        createAction(InsightsLibraryActionTypes.DELETE_INSIGHTS_LIBRARY_REPORT_SUCCESS, payload)
}

export type InsightsLibraryAction = ActionsUnion<typeof insightsLibraryActions>
