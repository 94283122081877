import { ExplorerCohortsService, ReportResultDTO } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put } from 'redux-saga/effects'
import { cohortCommonActions, reportsActions } from '../state'

export function createGetSummaryReportSaga() {
    return function* (action: ReturnType<typeof reportsActions.summaryReportGet>) {
        yield put(cohortCommonActions.cohortSummaryReportLoadingSet({ cohortId: action.payload.cohortId, loading: true }))
        try {
            const reportResultDTOs: Array<ReportResultDTO> = yield call(
                ExplorerCohortsService.getSummaryReportDataExplorerCohortsCohortIdSummaryReportDataGet,
                {
                    cohortId: action.payload.cohortId!
                }
            )
            yield put(reportsActions.summaryReportSet({ cohortId: action.payload.cohortId!, reportResultDTOs }))
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(cohortCommonActions.cohortSummaryReportLoadingSet({ cohortId: action.payload.cohortId, loading: false }))
        }
    }
}

export type GetSummaryReportSaga = ReturnType<typeof createGetSummaryReportSaga>
