import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import React, { useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { cohortBlocksEditActions, CohortDraggingState, CohortNodeTargetAddress, CohortTreeActionType, CriteriaRelationType } from '../../../state'
import { CRITERIA_DEFAULTS } from '../base/CohortEditComponent'
import { useCriteriaDropZone } from '../utils/useCriteriaDropZone'

export interface CohortCriterionRelateDropZoneProps {
    target: CohortNodeTargetAddress
    dragState: CohortDraggingState
    actions: typeof cohortBlocksEditActions
}

/**
 * A drop zone that initiates a "relate" action between two criteria.
 */
export const CohortCriterionRelateDropZone: React.FunctionComponent<CohortCriterionRelateDropZoneProps> = (props) => {
    const { target, dragState, actions } = props
    const ref = useRef<HTMLDivElement>(null)
    const { dropProps, isDropTarget } = useCriteriaDropZone({
        ref,
        onAdd: (type) => {
            const uuid = uuidv4()
            if (Object.keys(CRITERIA_DEFAULTS).includes(type)) {
                actions.criteriaInsert({ target, criteriaType: type, filters: CRITERIA_DEFAULTS[type], uuid })
                actions.criteriaRelationDialogTrigger({
                    type: target.relateType ? target.relateType : CriteriaRelationType.Date,
                    target: { nodeId: target.nodeId!, relateType: target.relateType }
                })
            } else {
                actions.criteriaDialogTrigger({ type, action: { type: CohortTreeActionType.Insert, target, uuid } })
            }
        },
        onCopy: (source) => {
            actions.criteriaCopy({ source, target })
            actions.criteriaRelationDialogTrigger({
                type: target.relateType ? target.relateType : CriteriaRelationType.Date,
                target: { nodeId: target.nodeId!, relateType: target.relateType }
            })
        },
        onMove: (source) => {
            actions.criteriaMove({ source, target })
            actions.criteriaRelationDialogTrigger({
                type: target.relateType ? target.relateType : CriteriaRelationType.Date,
                target: { nodeId: target.nodeId!, relateType: target.relateType }
            })
        }
    })

    return (
        <Box
            // Drag and drop
            ref={ref}
            role='button'
            tabIndex={0}
            {...dropProps}
            // Styles
            boxSizing='border-box'
            display='flex'
            alignItems='center'
            justifyContent='center'
            overflow='hidden'
            visibility={dragState.active ? 'visible' : 'hidden'}
            width={dragState.active ? 64 : 0}
            p={dragState.active ? 1 : 0}
            border={dragState.active ? 1 : 0}
            bgcolor={isDropTarget ? 'gray' : 'white'}
            textAlign='center'
            sx={{ borderStyle: 'dashed', transition: 'all 150ms ease-in-out' }}
        >
            <Trans>Relate</Trans>
        </Box>
    )
}
