import { CohortCreateDTO, CohortDTO, ExplorerCohortsService } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { notificationActions } from '@om1/platform-notifications'
import { Routes, toPath } from '@om1/platform-routing'
import { push } from 'connected-react-router'
import { call, put } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'
import { blocksToFilters } from '../components/edit/utils/filter-export'
import { CriteriaOperation, cohortCommonActions, cohortCreateActions } from '../state'

export function createCreateCohortSaga() {
    return function* (action: ReturnType<typeof cohortCreateActions.cohortCreate>) {
        try {
            yield put(cohortCreateActions.cohortCreateLoadingSet({ loading: true }))
            const request: CohortCreateDTO = {
                ...action.payload,
                analyticsDatasetId: action.payload.analyticsDataset.id,
                query:
                    action.payload.query ||
                    blocksToFilters({
                        id: uuidv4(),
                        operation: CriteriaOperation.EXCEPT,
                        children: [
                            {
                                id: uuidv4(),
                                operation: CriteriaOperation.AND,
                                children: [
                                    {
                                        id: uuidv4(),
                                        operation: CriteriaOperation.OR,
                                        children: []
                                    }
                                ]
                            },
                            {
                                id: uuidv4(),
                                operation: CriteriaOperation.OR,
                                children: []
                            }
                        ]
                    })
            }
            const cohortDTO: CohortDTO = yield call(ExplorerCohortsService.createExplorerCohortsPost, {
                requestBody: request
            })
            yield put(cohortCommonActions.cohortSizeGet({ cohortId: cohortDTO.id, cohortName: cohortDTO.name }))
            yield put(push(toPath(Routes.COHORTBUILD) + `/custom/${cohortDTO.id}/edit`))
            yield put(notificationActions.success())
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(cohortCreateActions.cohortCreateLoadingSet({ loading: false }))
        }
    }
}
