import { Box, Button } from '@mui/material'
import { FunctionComponent, useEffect } from 'react'
import { PlatformState } from '../../../../../apps/platform/src/platform-state'
import {
    DIAGNOSES_REPORT_CONFIG,
    LAB_TESTS_REPORT_CONFIG,
    MEDICATIONS_REPORT_CONFIG,
    OBSERVATIONS_REPORT_CONFIG,
    PROCEDURES_REPORT_CONFIG
} from '../../components/reports/CohortReportsComponent'
import { createDemographicsReportChart } from '../../components/reports/DemographicsReportChart'
import { createReadOnlyCohortComponent } from '../../components/reports/ReadOnlyCohort'
import { createReportChartComponent } from '../../components/reports/ReportChart'
import { createDataTypeReportChart } from '../../components/shared/DataTypeReportChart'
import { CohortListItem, ReportAnalyticsType, getReportTabLabel, reportsActions } from '../../state'

const DemographicsReportChartComponent = createDemographicsReportChart<PlatformState>()
const DataTypeReportChart = createDataTypeReportChart<PlatformState>()
const ReportChartComponent = createReportChartComponent<PlatformState>()
const ReadOnlyCohort = createReadOnlyCohortComponent<PlatformState>()

export interface DashboardCohortReportProps {
    reportsActions: typeof reportsActions
    cohortListItem: CohortListItem
    reportAnalyticsType: ReportAnalyticsType
    onReportAnalyticsTypeSelected: (reportAnalyticsType: ReportAnalyticsType) => void
}

export const DashboardCohortReports: FunctionComponent<DashboardCohortReportProps> = (props) => {
    useEffect(() => {
        if (props.cohortListItem.id) {
            props.reportsActions.reportsSet({ reports: [] })
            props.reportsActions.reportsGet({ cohortId: props.cohortListItem.id })
        }
    }, [props.cohortListItem.id, props.reportsActions])

    const tabs = Object.values(ReportAnalyticsType)
        .filter((type) => {
            if (!props.cohortListItem.isSystem) {
                return true
            } else {
                return type !== ReportAnalyticsType.COHORT
            }
        })
        .map((value: ReportAnalyticsType) => (
            <Button
                key={'report-tab-' + value}
                onClick={() => {
                    props.onReportAnalyticsTypeSelected(value)
                }}
                sx={{
                    minWidth: '110px',
                    width: '7vw',
                    borderRadius: 0,
                    borderBottomWidth: 3,
                    borderBottomStyle: 'solid',
                    borderBottomColor: props.reportAnalyticsType === value ? 'primary.main' : 'transparent',
                    '&:hover': {
                        borderBottomColor: props.reportAnalyticsType === value ? 'primary.main' : 'secondary.main'
                    },
                    backgroundColor: 'white'
                }}
            >
                {getReportTabLabel(value)}
            </Button>
        ))

    return (
        <Box width='100%' flexDirection='column' marginBottom={1} overflow={'hidden'}>
            <Box paddingTop='16px' style={{ backgroundColor: '#f5f5f5' }} display='flex' justifyContent='space-between' alignItems='center'>
                <Box sx={{ boxShadow: 2, width: '100%', overflowX: 'auto' }}>
                    <Box display='flex' width='fit-content'>
                        {tabs}
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{ paddingRight: '60px', paddingLeft: '60px', paddingBottom: '12px', paddingTop: '32px' }}
                boxShadow={2}
                p={2}
                zIndex={1}
                position='relative'
                style={{ backgroundColor: '#ffffff', minHeight: '1600px' }}
            >
                {!props.reportAnalyticsType ||
                    (props.reportAnalyticsType === ReportAnalyticsType.DEMOGRAPHICS && (
                        <DemographicsReportChartComponent cohort={props.cohortListItem} />
                    ))}
                {props.reportAnalyticsType === ReportAnalyticsType.DATA_TYPE && <DataTypeReportChart cohort={props.cohortListItem} />}
                {props.reportAnalyticsType === ReportAnalyticsType.DIAGNOSES && (
                    <ReportChartComponent
                        config={DIAGNOSES_REPORT_CONFIG}
                        reportAnalyticsType={ReportAnalyticsType.DIAGNOSES}
                        cohort={props.cohortListItem}
                        renderInDashboard={true}
                    />
                )}
                {props.reportAnalyticsType === ReportAnalyticsType.MEDICATIONS && (
                    <ReportChartComponent
                        config={MEDICATIONS_REPORT_CONFIG}
                        reportAnalyticsType={ReportAnalyticsType.MEDICATIONS}
                        cohort={props.cohortListItem}
                        renderInDashboard={true}
                    />
                )}
                {props.reportAnalyticsType === ReportAnalyticsType.OBSERVATIONS && (
                    <ReportChartComponent
                        config={OBSERVATIONS_REPORT_CONFIG}
                        reportAnalyticsType={ReportAnalyticsType.OBSERVATIONS}
                        cohort={props.cohortListItem}
                        renderInDashboard={true}
                    />
                )}
                {props.reportAnalyticsType === ReportAnalyticsType.PROCEDURES && (
                    <ReportChartComponent
                        config={PROCEDURES_REPORT_CONFIG}
                        reportAnalyticsType={ReportAnalyticsType.PROCEDURES}
                        cohort={props.cohortListItem}
                        renderInDashboard={true}
                    />
                )}
                {props.reportAnalyticsType === ReportAnalyticsType.LAB_TESTS && (
                    <ReportChartComponent
                        config={LAB_TESTS_REPORT_CONFIG}
                        reportAnalyticsType={ReportAnalyticsType.LAB_TESTS}
                        cohort={props.cohortListItem}
                        renderInDashboard={true}
                    />
                )}
                {props.reportAnalyticsType === ReportAnalyticsType.COHORT && <ReadOnlyCohort cohort={props.cohortListItem} />}
            </Box>
        </Box>
    )
}
