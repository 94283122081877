import { t } from '@lingui/macro'
import { ReactNode } from 'react'
import { CriteriaType, CriterionNode, DateRangeInclusion, PatientAgeQualifier, QualifierType } from '../../../state'
import { findQualifierByType } from '../utils/finders'
import { CohortCriterionQualifierSummary } from './CohortCriterionQualifierSummary'

export interface CohortCriterionPatientAgeSummaryProps {
    node: CriterionNode
    readOnly?: boolean
    onEditClick: () => void
    onDeleteClick: () => void
}

const translateQualifierDateType = (node: CriterionNode): string => {
    if (node.dateField === 'first') {
        if (node.type === CriteriaType.Diagnosis) {
            return t`Initial Diagnosis`
        }
        if (node.type === CriteriaType.Medication) {
            return t`Medication Initiation`
        }
    }

    if (node.dateField === 'last') {
        return t`Latest Encounter`
    }

    return t`Encounter(s)`
}

export const CohortCriterionPatientAgeSummary = (props: CohortCriterionPatientAgeSummaryProps) => {
    const { node, readOnly, onDeleteClick, onEditClick } = props

    const ageQualifier = findQualifierByType<PatientAgeQualifier>(node.qualifiers, QualifierType.PatientAgeQualifierDTO)

    let label: ReactNode = null
    if (!ageQualifier) {
        label = t`Unknown`
    } else {
        const { intervalStart, intervalEnd, intervalIsInclusive } = ageQualifier
        const qualifierDateTypeText = translateQualifierDateType(node)

        if (!intervalStart) {
            const inclusionText = intervalIsInclusive ? DateRangeInclusion.AtMost : DateRangeInclusion.LessThan

            // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
            label = `Patient Age ${inclusionText} ${intervalEnd} Years Old At ${qualifierDateTypeText}`
        } else if (!intervalEnd) {
            const inclusionText = intervalIsInclusive ? DateRangeInclusion.AtLeast : DateRangeInclusion.MoreThan
            // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
            label = `Patient Age ${inclusionText} ${intervalStart} Years Old At ${qualifierDateTypeText}`
        } else if (intervalStart === intervalEnd) {
            // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
            label = `Patient Age Exactly ${intervalStart} Years Old At ${qualifierDateTypeText}`
        } else {
            // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
            label = `Patient Age Between ${intervalStart} and ${intervalEnd} Years Old At ${qualifierDateTypeText}`
        }
    }

    const handleDeleteClick = () => {
        onDeleteClick()
    }

    const handleEditClick = () => {
        onEditClick()
    }

    return (
        <CohortCriterionQualifierSummary
            summaryText={label}
            readOnly={readOnly}
            editAction={{ label: t`Edit Patient Age`, onClick: handleEditClick }}
            deleteAction={{ label: t`Delete Patient Age`, onClick: handleDeleteClick }}
        />
    )
}
