import { Auth0State } from '@om1/platform-authentication'
import { withRouter } from '@om1/platform-routing'
import { trackingActions } from '@om1/platform-tracking'
import { connectRedux } from '@om1/platform-utils'
import { CohortState, cohortBlocksEditActions, cohortCommonActions, cohortEditActions } from '../../../state'
import { CohortEditComponent } from './CohortEditComponent'

export function createCohortEditComponent<TState extends { cohort: CohortState; auth0: Auth0State }>() {
    return connectRedux(
        withRouter(CohortEditComponent, { namespace: 'cohorts', dataFetchAction: 'cohortGet' }),
        (state: TState) => ({
            cohort: state.cohort.edit.base.cohort,
            tree: state.cohort.edit.blocks.tree,
            cohortLoading: state.cohort.edit.base.ui.loading,
            lastSavedBlocks: state.cohort.edit.blocks.ui.lastSavedBlocks,
            criteriaDialog: state.cohort.edit.blocks.ui.criteriaDialog,
            criteriaRelationDialog: state.cohort.edit.blocks.ui.criteriaRelationDialog,
            occurrenceEditDialog: state.cohort.edit.blocks.ui.occurenceEditDialog,
            specialtyEditDialog: state.cohort.edit.blocks.ui.specialtyEditDialog,
            recencyEditDialog: state.cohort.edit.blocks.ui.recencyEditDialog,
            patientAgeEditDialog: state.cohort.edit.blocks.ui.patientAgeEditDialog,
            followUpLengthEditDialog: state.cohort.edit.blocks.ui.followUpLengthEditDialog,
            dragState: state.cohort.edit.blocks.ui.dragging,
            updateLoading: state.cohort.edit.base.ui.cohortUpdateLoading,
            sizeLoading: state.cohort.common.ui.cohortSizeLoading[state.cohort.edit.base.cohort.id],
            refLabels: state.cohort.edit.blocks.ui.refLabels,
            isOM1User: (state.auth0.user?.email || '').endsWith('@om1.com'),
            permissions: state.auth0.permissions ?? []
        }),
        { ...cohortEditActions, ...cohortBlocksEditActions, ...cohortCommonActions, ...trackingActions }
    )
}

export type CohortEdit = ReturnType<typeof createCohortEditComponent>
