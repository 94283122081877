/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedDTO_ReportDTO_ } from '../models/PaginatedDTO_ReportDTO_'
import type { ReportCreateDTO } from '../models/ReportCreateDTO'
import type { ReportDTO } from '../models/ReportDTO'
import type { ReportPatchDTO } from '../models/ReportPatchDTO'
import type { ReportType } from '../models/ReportType'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class ExplorerReportsService {
    /**
     * Index
     * @returns PaginatedDTO_ReportDTO_ Successful Response
     * @throws ApiError
     */
    public static indexExplorerReportsGet({
        cohortId,
        reportType,
        page = 1,
        limit = 10
    }: {
        cohortId?: string | null
        reportType?: ReportType | null
        /**
         * Page number for pagination, starting at 1
         */
        page?: number
        /**
         * Number of items per page, maximum 1000
         */
        limit?: number
    }): CancelablePromise<PaginatedDTO_ReportDTO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/explorer/reports',
            query: {
                cohort_id: cohortId,
                report_type: reportType,
                page: page,
                limit: limit
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Create
     * @returns ReportDTO Successful Response
     * @throws ApiError
     */
    public static createExplorerReportsPost({ requestBody }: { requestBody?: ReportCreateDTO }): CancelablePromise<ReportDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/explorer/reports',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Patch
     * @returns ReportDTO Successful Response
     * @throws ApiError
     */
    public static patchExplorerReportsPatch({ requestBody }: { requestBody: ReportPatchDTO }): CancelablePromise<ReportDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/explorer/reports',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Get
     * @returns ReportDTO Successful Response
     * @throws ApiError
     */
    public static getExplorerReportsReportIdGet({ reportId }: { reportId: string }): CancelablePromise<ReportDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/explorer/reports/{report_id}',
            path: {
                report_id: reportId
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Delete
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteExplorerReportsReportIdDelete({ reportId }: { reportId: string }): CancelablePromise<Record<string, string>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/explorer/reports/{report_id}',
            path: {
                report_id: reportId
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
}
