import { Trans, t } from '@lingui/macro'
import ApprovalIcon from '@mui/icons-material/Approval'
import BuildIcon from '@mui/icons-material/Build'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import PublishIcon from '@mui/icons-material/Publish'
import { Box, CircularProgress, IconButton, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/system'
import {
    DataGrid,
    GridActionsCellItem,
    GridActionsCellItemProps,
    GridColDef,
    GridPaginationModel,
    GridRowParams,
    GridRowSpacingParams
} from '@mui/x-data-grid'
import { DeliveryCohortDTO, DeliveryDTO, FalconPaginationMeta, FalconPaginationQuery } from '@om1/falcon-api'
import { GridPagination } from '@om1/platform-components'
import { LightTooltip } from '@om1/platform-components/Tooltip'
import { getStyledGrid } from '@om1/platform-ui-kit/src/styles/grids'
import { FunctionComponent, ReactElement, ReactNode, useCallback, useMemo, useState } from 'react'
import { DataDeliveryTaskStatus, IN_PROGRESS_TASK_STATUSES } from '../state/data-delivery'
import { DataDeliveryExecutionConfirmationDialog } from './DataDeliveryExecutionConfirmationDialog'

type ActionableDataDeliveryTaskStatus = Extract<
    DataDeliveryTaskStatus,
    DataDeliveryTaskStatus.New | DataDeliveryTaskStatus.Candidate | DataDeliveryTaskStatus.Approved
>
const STATUS_LABELS: { status: DataDeliveryTaskStatus; label: ReactNode; isProcessing: boolean }[] = [
    { status: DataDeliveryTaskStatus.New, isProcessing: false, label: <Trans>New</Trans> },
    { status: DataDeliveryTaskStatus.New, isProcessing: true, label: <Trans>Building</Trans> },
    { status: DataDeliveryTaskStatus.Candidate, isProcessing: false, label: <Trans>Candidate</Trans> },
    { status: DataDeliveryTaskStatus.Candidate, isProcessing: true, label: <Trans>Approving</Trans> },
    { status: DataDeliveryTaskStatus.Approved, isProcessing: false, label: <Trans>Approved</Trans> },
    { status: DataDeliveryTaskStatus.Approved, isProcessing: true, label: <Trans>Publishing</Trans> },
    { status: DataDeliveryTaskStatus.Published, isProcessing: false, label: <Trans>Published</Trans> }
]

export type DataDeliveryDataGridProps = {
    data: DeliveryDTO[]
    isLoading: boolean
    paginationMeta: FalconPaginationMeta
    onPaginationChange: (params: FalconPaginationQuery) => void
    onEdit: (payload: DeliveryDTO) => void
    onStatusTransition: (payload: DeliveryDTO) => void
    handleCopy: (text?: string | null) => void
}

const StyledDataGrid = getStyledGrid(DataGrid) as typeof DataGrid

/**
 * A tabular / grid view used to display data deliveries
 */
export const DataDeliveryDataGrid: FunctionComponent<DataDeliveryDataGridProps> = ({
    data,
    isLoading,
    paginationMeta,
    onPaginationChange,
    onEdit,
    onStatusTransition,
    handleCopy
}) => {
    const theme = useTheme()
    const isAtLeastWideScreen = useMediaQuery(theme.breakpoints.up('xl'))

    const [confirmationModalState, setConfirmationModalState] = useState<{
        isVisible: boolean
        pendingAction: { delivery: DeliveryDTO; action: string } | null
    }>({ isVisible: false, pendingAction: null })
    const handleEdit = useCallback((delivery: DeliveryDTO) => onEdit(delivery), [onEdit])
    const handleStatusTransition = useCallback((delivery: DeliveryDTO) => onStatusTransition(delivery), [onStatusTransition])
    const handleConfirmAction = useCallback(() => {
        if (confirmationModalState.pendingAction !== null) {
            handleStatusTransition(confirmationModalState.pendingAction.delivery)
        }
        setConfirmationModalState({ isVisible: false, pendingAction: null })
    }, [confirmationModalState.pendingAction, handleStatusTransition])
    const ACTION_LABELS: Record<ActionableDataDeliveryTaskStatus, { key: string; label: ReactNode; icon: GridActionsCellItemProps['icon'] }> =
        useMemo(() => {
            return {
                [DataDeliveryTaskStatus.New]: { key: 'build', label: t`Build`, icon: <BuildIcon color='primary' /> },
                [DataDeliveryTaskStatus.Candidate]: { key: 'approve', label: t`Approve`, icon: <ApprovalIcon color='primary' /> },
                [DataDeliveryTaskStatus.Approved]: { key: 'publish', label: t`Publish`, icon: <PublishIcon color='primary' /> }
            }
        }, [])

    const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
        return {
            top: params.isFirstVisible ? 0 : 4,
            bottom: params.isLastVisible ? 0 : 4
        }
    }, [])

    const paginationModel = useMemo<GridPaginationModel>(
        () => ({ page: paginationMeta.currentPage - 1, pageSize: paginationMeta.itemsPerPage }),
        [paginationMeta]
    )

    const handlePaginationModelChange = useCallback<(model: GridPaginationModel) => void>(
        (paginationModel) => {
            onPaginationChange({ page: paginationModel.page + 1, limit: paginationModel.pageSize })
        },
        [onPaginationChange]
    )

    // Render createdAt and createdBy using 2 columns on larger screens.
    const renderCreatedByColumns = useMemo<GridColDef<DeliveryDTO>[]>(() => {
        const dateFormatter = new Intl.DateTimeFormat(undefined, { dateStyle: 'short' })

        if (isAtLeastWideScreen) {
            return [
                {
                    field: 'createdDttm',
                    valueFormatter: ({ value }) => dateFormatter.format(new Date(value)),
                    headerName: t`Created On`,
                    flex: 1,
                    sortable: false,
                    filterable: false,
                    headerClassName: 'hide-small',
                    cellClassName: 'hide-small'
                },
                {
                    field: 'createdBy',
                    headerName: t`Created By`,
                    renderCell: (params) => params.row.createdByEmail || '',
                    width: 225,
                    sortable: false,
                    filterable: false
                }
            ]
        }

        return [
            {
                field: 'created',
                headerName: t`Created`,
                renderCell: (params) => {
                    return (
                        <Box display='flex' gap={1} alignItems={'flex-start'} flexDirection={'column'}>
                            <span>{params.row.createdByEmail}</span>
                            <span>{dateFormatter.format(new Date(params.row.createdDttm))}</span>
                        </Box>
                    )
                },
                flex: 1,
                minWidth: 150,
                sortable: false,
                filterable: false
            }
        ]
    }, [isAtLeastWideScreen])

    const renderMenuActions = useCallback<(params: GridRowParams<DeliveryDTO>) => ReactElement<GridActionsCellItemProps>[]>(
        (params) => {
            const delivery = params.row
            const options: ReactElement<GridActionsCellItemProps>[] = []

            // Don't show any options if the task is processing
            if (delivery.task !== null && delivery.task !== undefined && IN_PROGRESS_TASK_STATUSES.includes(delivery.task?.status)) {
                return options
            }

            if (delivery.deliveryStatus === DataDeliveryTaskStatus.New) {
                options.push(
                    <GridActionsCellItem
                        // MUI typing does not recognize the ability to assign the `component` prop here
                        // @ts-ignore
                        icon={<EditOutlinedIcon color='primary' />}
                        key='edit'
                        label={t`Edit`}
                        showInMenu
                        onClick={() => handleEdit(delivery)}
                    />
                )
            }

            if (ACTION_LABELS[delivery.deliveryStatus]) {
                options.push(
                    <GridActionsCellItem
                        // MUI typing does not recognize the ability to assign the `component` prop here
                        // @ts-ignore
                        {...ACTION_LABELS[delivery.deliveryStatus]}
                        showInMenu
                        onClick={() =>
                            setConfirmationModalState({
                                isVisible: true,
                                pendingAction: { delivery: delivery, action: ACTION_LABELS[delivery.deliveryStatus].label }
                            })
                        }
                    />
                )
            }

            return options
        },
        [ACTION_LABELS, handleEdit]
    )

    const columns = useMemo<GridColDef<DeliveryDTO>[]>(() => {
        return [
            {
                field: 'cohorts',
                headerName: t`Cohort(s)`,
                renderCell: (params) => {
                    const renderCohortSampleSize = (x: DeliveryCohortDTO) => {
                        if (!x.sampleSize) {
                            return `${x.cohort.name} (${x.registryType})`
                        }

                        return (
                            <span>
                                {x.cohort.name} ({x.registryType}) {t`Sample`} ({x.sampleSize} {t`patients`})
                            </span>
                        )
                    }
                    const cohort_names = params.row.cohorts.map(renderCohortSampleSize)

                    return (
                        <Box display='flex' gap={2} alignItems='flex-start' flexDirection={'column'} sx={{ padding: '10px 0px' }}>
                            <Box display='flex' columnGap={0.5} alignItems='flex-start' flexDirection='row' flexWrap={'wrap'} sx={{ width: '100%' }}>
                                {cohort_names.map((cohort, index) => (
                                    <span key={`cohort-${cohort}-${index}`}>
                                        {cohort}
                                        {index === cohort_names.length - 1 ? '' : ', '}
                                    </span>
                                ))}
                            </Box>
                            <span>{params.row.deliveredDatabase.name}</span>
                        </Box>
                    )
                },
                flex: 3,
                minWidth: 250,
                sortable: false,
                filterable: false
            },
            {
                field: 'tenant',
                headerName: t`Customer`,
                renderCell: (params) => params.row.createdByOrganizationName || '',
                flex: 1,
                minWidth: 100,
                sortable: false,
                filterable: false
            },
            {
                field: 'deliveryStatus',
                headerName: t`Status`,
                renderCell: (params) => {
                    const delivery = params.row
                    const isProcessing =
                        delivery.task !== null && delivery.task !== undefined && IN_PROGRESS_TASK_STATUSES.includes(delivery.task.status)
                    const statusLabel = STATUS_LABELS.find((x) => x.status === delivery.deliveryStatus && x.isProcessing === isProcessing)
                    const hasError = delivery.task !== null && delivery.task !== undefined && delivery.task.status === 'FAILURE'
                    return (
                        <Box display='flex' gap={1} alignItems='center'>
                            <span>{statusLabel ? statusLabel.label : ''}</span>
                            {isProcessing && <CircularProgress size='1em' />}
                            {hasError && (
                                <LightTooltip title={<>{delivery.task?.result?.error || t`Task Failed`}</>}>
                                    <ErrorOutlineIcon sx={{ fontSize: '1em', color: '#d32f2f' }} />
                                </LightTooltip>
                            )}
                        </Box>
                    )
                },
                flex: 1,
                minWidth: 125,
                maxWidth: 250,
                sortable: false,
                filterable: false
            },
            {
                field: 'location',
                headerName: t`Database Name`,
                renderCell: (params) => {
                    if (!params.row.location) {
                        return <span>&mdash;</span>
                    }

                    return (
                        <Box display='flex' gap={1} alignItems={'center'} width={'100%'} overflow={'hidden'}>
                            <LightTooltip placement='top' title={params.row.location}>
                                <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{params.row.location}</span>
                            </LightTooltip>

                            <LightTooltip placement='top' title={t`Copy`}>
                                <IconButton aria-label={t`Copy`} onClick={() => handleCopy(params.row.location)}>
                                    <ContentCopyIcon />
                                </IconButton>
                            </LightTooltip>
                        </Box>
                    )
                },
                flex: 3,
                minWidth: 300,
                sortable: false,
                filterable: false
            },
            ...renderCreatedByColumns,
            {
                field: 'actions',
                type: 'actions',
                headerName: '',
                width: 50,
                getActions: renderMenuActions,
                sortable: false,
                filterable: false
            }
        ]
    }, [renderMenuActions, handleCopy, renderCreatedByColumns])

    return (
        <>
            <Box>
                <StyledDataGrid
                    autoHeight
                    disableColumnMenu
                    getRowHeight={() => 'auto'}
                    disableRowSelectionOnClick
                    getRowSpacing={getRowSpacing}
                    columns={columns}
                    rows={data}
                    slots={{ pagination: GridPagination }}
                    slotProps={{ pagination: { totalItems: paginationMeta.totalItems } }}
                    paginationMode='server'
                    loading={isLoading}
                    pageSizeOptions={[5, 10, 20]}
                    paginationModel={paginationModel}
                    rowCount={paginationMeta.totalItems}
                    onPaginationModelChange={handlePaginationModelChange}
                />
            </Box>
            {confirmationModalState.isVisible && confirmationModalState.pendingAction !== null && (
                <DataDeliveryExecutionConfirmationDialog
                    action={confirmationModalState.pendingAction.action}
                    onCancel={() => setConfirmationModalState({ isVisible: false, pendingAction: null })}
                    onConfirm={handleConfirmAction}
                />
            )}
        </>
    )
}
