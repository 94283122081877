import { t, Trans } from '@lingui/macro'
import AddIcon from '@mui/icons-material/Add'
import { GridPaginationModel } from '@mui/x-data-grid'
import { DeliveryDTO, FalconPaginationMeta, FalconPaginationQuery } from '@om1/falcon-api'
import { FrameworkButton } from '@om1/platform-components'
import { PlatformPermissions, RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { createCreateEditDataDeliveryDialogComponent } from '../components/CreateEditDataDeliveryDialog'

import { notificationActions } from '@om1/platform-notifications'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { dataDeliveryActions } from '../state/data-delivery'
import { DataDeliveryDataGrid } from './DataDeliveryDataGrid'
import { Grid } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import { createCreateEditOrganizationMetadataDialogComponent } from './CreateEditOrganizationMetadataDialog'
import { BackOfficeRoutes } from '../routes'
import { useHistory } from 'react-router-dom'

const CreateEditDataDeliveryDialog = createCreateEditDataDeliveryDialogComponent()
const CreateEditOrganizationMetadataDialog = createCreateEditOrganizationMetadataDialogComponent()

export type DataDeliveryListComponentProps = RoutedFrameworkComponentProps<
    Partial<GridPaginationModel>,
    Record<string, never>,
    { dataDeliveries: DeliveryDTO[]; paginationMeta: FalconPaginationMeta; isLoading: boolean; permissions?: string[]; organizationId: string },
    typeof dataDeliveryActions & typeof notificationActions,
    Record<string, never>
>

/**
 * A component to list data deliveries
 */
export const DataDeliveryListComponent: FunctionComponent<DataDeliveryListComponentProps> = ({
    state: { dataDeliveries, paginationMeta, isLoading, permissions, organizationId },
    actions,
    routing
}) => {
    const history = useHistory()

    const { updateQuery, queryParams } = routing

    const [createEditModalState, setCreateEditModalState] = useState<{ isVisible: boolean; dataDelivery?: DeliveryDTO }>({ isVisible: false })
    const [createEditMetadataModalState, setCreateEditMetadataModalState] = useState<{
        isVisible: boolean
    }>({ isVisible: history.location.pathname.includes(BackOfficeRoutes.ORGANIZATION_METADATA) || false })

    useEffect(() => {
        if (history.location.pathname.includes(BackOfficeRoutes.ORGANIZATION_METADATA)) {
            setCreateEditMetadataModalState({ isVisible: true })
        }
    }, [history.location.pathname])

    const onPaginationChange = useCallback(
        (paginationQuery: FalconPaginationQuery) => {
            updateQuery({ ...queryParams, ...paginationQuery })
        },
        [updateQuery, queryParams]
    )

    const handleDataDeliveryEdit = useCallback((dataDelivery: DeliveryDTO) => {
        setCreateEditModalState({ isVisible: true, dataDelivery })
    }, [])

    const handleDataDeliveryStatusTransition = useCallback(
        (dataDelivery: DeliveryDTO) => {
            actions.dataDeliveryExecute({ deliveryId: dataDelivery.id })
        },
        [actions]
    )

    const handleCopy = async (text: string | null | undefined) => {
        try {
            if (text) {
                await navigator.clipboard.writeText(`${text}`)
                actions.success(t`Copied`)
            }
        } catch (err) {
            actions.error(t`Failed to copy`)
        }
    }

    return (
        <>
            <Grid container justifyContent='flex-end' flexDirection='row'>
                <Grid item xs={6} textAlign={'left'}>
                    {permissions?.includes(PlatformPermissions.MANAGE_ORGANIZATION_METADATA) && (
                        <FrameworkButton
                            variant='outlined'
                            color='primary'
                            onClick={() => {
                                history.replace(
                                    `/${BackOfficeRoutes.ROOT}/${BackOfficeRoutes.DATA_DELIVERY}/${BackOfficeRoutes.ORGANIZATION_METADATA}/${organizationId}`
                                )
                            }}
                            aria-label={t`Manage`}
                        >
                            <SettingsIcon sx={{ height: '18.5px', width: '18.5px', marginRight: '0.5rem' }} />
                            <span style={{ flexShrink: 0 }}>
                                <Trans>Manage</Trans>
                            </span>
                        </FrameworkButton>
                    )}
                </Grid>
                <Grid item xs={6} textAlign={'right'}>
                    <FrameworkButton
                        variant='contained'
                        color='primary'
                        onClick={() => setCreateEditModalState({ isVisible: true })}
                        aria-label={t`Create Delivery`}
                    >
                        <AddIcon sx={{ height: '18.5px', width: '18.5px', margin: '0.1rem' }} />
                        <span style={{ flexShrink: 0 }}>
                            <Trans>Create Delivery</Trans>
                        </span>
                    </FrameworkButton>
                </Grid>
            </Grid>
            <DataDeliveryDataGrid
                data={dataDeliveries}
                isLoading={isLoading}
                paginationMeta={paginationMeta}
                onPaginationChange={onPaginationChange}
                onEdit={handleDataDeliveryEdit}
                onStatusTransition={handleDataDeliveryStatusTransition}
                handleCopy={handleCopy}
            />
            {createEditModalState.isVisible && (
                <CreateEditDataDeliveryDialog
                    dataDelivery={createEditModalState.dataDelivery}
                    onCancel={() => setCreateEditModalState({ isVisible: false })}
                    onSave={() => setCreateEditModalState({ isVisible: false })}
                />
            )}
            {permissions?.includes(PlatformPermissions.MANAGE_ORGANIZATION_METADATA) && createEditMetadataModalState.isVisible && (
                <CreateEditOrganizationMetadataDialog
                    onCancel={() => {
                        setCreateEditMetadataModalState({ isVisible: false })
                        history.replace(`/${BackOfficeRoutes.ROOT}/${BackOfficeRoutes.DATA_DELIVERY}`)
                    }}
                    onSave={() => {
                        setCreateEditMetadataModalState({ isVisible: false })
                        history.replace(`/${BackOfficeRoutes.ROOT}/${BackOfficeRoutes.DATA_DELIVERY}`)
                    }}
                />
            )}
        </>
    )
}
