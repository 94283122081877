import { Trans } from '@lingui/macro'
import { Button } from '@mui/material'
import {
    CohortRoutes,
    PlatformRoute,
    createCohortDashboardPageComponent,
    createCohortEditComponent,
    createCohortListComponent,
    createCreateCohortWizardDialogComponent,
    createDatasetsListComponent,
    createReportsPage,
    toRoutingConfigObject
} from '@om1/cohort-module'
import { createReadOnlyCohortPageComponent } from '@om1/cohort-module/src/components/reports/ReadOnlyCohortPage'
import { createSummaryReportPageComponent } from '@om1/cohort-module/src/components/reports/SummaryReportPage'
import { FilterTabBar, FilterTabBarDivider } from '@om1/platform-ui-kit/src/components/FilterTabBar'
import { ContentContainerStyle, ContentPadding } from '@om1/platform-ui-kit/src/components/Layout'
import { PlatformPermissions } from '@om1/platform-utils'
import { FunctionComponent } from 'react'
import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import { PlatformState } from '../../platform-state'

export function isCohortBuilderPrintViewUrl(url: string) {
    const regex = /^\/cohort-builder\/print\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/?$/i
    return regex.test(url)
}

const CohortEdit = createCohortEditComponent<PlatformState>()
const CohortList = createCohortListComponent<PlatformState>()
const CohortDashboardPage = createCohortDashboardPageComponent<PlatformState>()
const DatasetsList = createDatasetsListComponent<PlatformState>()
const ReportsPage = createReportsPage<PlatformState>()
const ReadOnlyCohortPage = createReadOnlyCohortPageComponent<PlatformState>()
const SummaryReportPage = createSummaryReportPageComponent<PlatformState>()
const CreateCohortWizardComponent = createCreateCohortWizardDialogComponent<PlatformState>()

export const CohortPage: FunctionComponent = () => {
    const match = useRouteMatch()
    const location = useLocation()

    if (!match) {
        return null
    }

    const { path: matchPathValue, url: matchUrlValue } = match

    const routes: PlatformRoute[] = [
        {
            route: `${CohortRoutes.DASHBOARD}/:cohortOwner?/:cohortId?/:reportType?`,
            component: CohortDashboardPage,
            permissions: [PlatformPermissions.ACCESS_COHORTS, PlatformPermissions.INDEX_COHORTS]
        },
        {
            route: `${CohortRoutes.DASHBOARD}/`,
            component: CohortDashboardPage,
            permissions: [PlatformPermissions.ADMIN_EXPLORER, PlatformPermissions.INDEX_COHORTS]
        },
        {
            route: `${CohortRoutes.PRINT}/:cohortId`,
            component: SummaryReportPage,
            permissions: [PlatformPermissions.ACCESS_COHORTS, PlatformPermissions.GET_COHORT, PlatformPermissions.ACCESS_SUMMARY_REPORTS_FEATURES]
        },
        {
            route: CohortRoutes.CUSTOM,
            component: CohortList,
            name: <Trans>Custom Cohorts</Trans>,
            permissions: [PlatformPermissions.ACCESS_COHORTS, PlatformPermissions.INDEX_COHORTS]
        },
        {
            route: `${CohortRoutes.CUSTOM}/:cohortId/${CohortRoutes.EDIT}`,
            component: CohortEdit,
            permissions: [PlatformPermissions.ACCESS_COHORTS, PlatformPermissions.UPDATE_COHORT]
        },
        {
            route: `${CohortRoutes.CUSTOM}/:cohortId`,
            component: ReadOnlyCohortPage,
            permissions: [PlatformPermissions.ACCESS_COHORTS, PlatformPermissions.GET_COHORT]
        },
        {
            route: `${CohortRoutes.DATASETS}/:cohortId`,
            component: ReadOnlyCohortPage,
            permissions: [PlatformPermissions.ACCESS_COHORTS, PlatformPermissions.GET_COHORT]
        },
        {
            route: CohortRoutes.DATASETS,
            component: DatasetsList,
            permissions: [PlatformPermissions.ACCESS_COHORTS, PlatformPermissions.INDEX_COHORTS],
            name: <Trans>Datasets</Trans>
        },
        {
            route: CohortRoutes.WIZARD,
            component: CreateCohortWizardComponent,
            permissions: [PlatformPermissions.ACCESS_COHORTS, PlatformPermissions.CREATE_COHORT]
        },
        {
            route: `${CohortRoutes.REPORTS}/:cohortId/:reportType?`,
            component: ReportsPage,
            permissions: [PlatformPermissions.ACCESS_COHORTS, PlatformPermissions.INDEX_COHORTS]
        }
    ]

    const routingConfigObject = toRoutingConfigObject(routes, matchPathValue, matchUrlValue)

    function isCohortBuilderDashboardUrl(url: string) {
        return url.includes('dashboard')
    }

    function isCohortBuilderEditUrl(url: string) {
        return url.includes('edit')
    }

    function isCohortViewUrl(url: string) {
        const regex = /^\/cohort-builder\/custom\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
        return regex.test(url)
    }

    function isCohortReportsViewUrl(url: string) {
        const regex = /^\/cohort-builder\/reports\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/.+$/i
        return regex.test(url)
    }

    const tabs = !isCohortBuilderDashboardUrl(location.pathname) &&
        !isCohortBuilderEditUrl(location.pathname) &&
        !isCohortViewUrl(location.pathname) &&
        !isCohortReportsViewUrl(location.pathname) &&
        !isCohortBuilderPrintViewUrl(location.pathname) && (
            <FilterTabBar>
                {routingConfigObject.map((tab) => {
                    const base = tab.url.split('/')[2]
                    return tab.name ? (
                        <div key={`tabbuttondiv-${tab.route}`}>
                            <Button
                                key={`tabbutton-${tab.route}`}
                                component={Link}
                                to={tab.url}
                                aria-current={location.pathname.includes(base) ? 'true' : 'false'}
                                sx={{
                                    minWidth: 128,
                                    borderRadius: 0,
                                    borderBottomWidth: 3,
                                    borderBottomStyle: 'solid',
                                    borderBottomColor: location.pathname.includes(base) ? 'primary.main' : 'transparent',
                                    '&:hover': {
                                        borderBottomColor: location.pathname.includes(base) ? 'primary.main' : 'secondary.main'
                                    }
                                }}
                            >
                                {tab.name}
                            </Button>
                            <FilterTabBarDivider />
                        </div>
                    ) : null
                })}
            </FilterTabBar>
        )

    const pages = (
        <Switch>
            {routingConfigObject.map((tab) => (
                <Route key={`route-${tab.name}`} {...tab.matchRoute} exact={true} component={tab.component} />
            ))}
            <Redirect key='cohortRedirect' to={routingConfigObject[1].url} />
        </Switch>
    )

    let content = pages
    if (!isCohortBuilderDashboardUrl(location.pathname)) {
        content = (
            <ContentPadding padX padY>
                {content}
            </ContentPadding>
        )
    }

    return (
        <ContentContainerStyle>
            {tabs}
            {content}
        </ContentContainerStyle>
    )
}
