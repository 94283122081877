import { ReportsActions, ReportsActionTypes } from './reports-actions'
import { initialReportsState, ReportsState } from './reports-state'

export function reportsReducer(previousState: ReportsState | undefined, action: ReportsActions) {
    const state = previousState || initialReportsState

    switch (action.type) {
        case ReportsActionTypes.LOADING_SET: {
            return { ...state, ui: { ...state.ui, loading: action.payload.loading } }
        }
        case ReportsActionTypes.REPORTS_SET: {
            return { ...state, reports: action.payload.reports }
        }
        case ReportsActionTypes.REPORT_ADD: {
            let reports = state.reports
            const newReport = action.payload.report
            reports = [...reports, newReport]
            return { ...state, reports }
        }
        case ReportsActionTypes.REPORT_SET: {
            let reports = state.reports
            const newReport = action.payload.report
            const replacementIndex = reports.findIndex((x) => x.id === newReport.id)

            if (replacementIndex === -1) {
                return state
            }

            reports = [...reports]
            reports[replacementIndex] = newReport
            return { ...state, reports }
        }
        case ReportsActionTypes.SUMMARY_REPORT_SET: {
            return { ...state, summaryReportResult: action.payload.reportResultDTOs }
        }
        default: {
            return state
        }
    }
}
