import { Box } from '@mui/material'
import React, { useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { cohortBlocksEditActions, CohortDraggingState, CohortNodeTargetAddress, CohortTreeActionType, CriteriaOperation } from '../../../state'
import { useCriteriaDropZone } from '../utils/useCriteriaDropZone'
import { CRITERIA_DEFAULTS } from '../base/CohortEditComponent'

export interface CohortCriterionForkDropZoneProps {
    target: CohortNodeTargetAddress
    parentOperation: CriteriaOperation
    dragState: CohortDraggingState
    actions: typeof cohortBlocksEditActions
}

/**
 * A drop zone that initiates a "fork" action between the dragging criteria and the existing criteria.
 */
export const CohortCriterionForkDropZone: React.FunctionComponent<CohortCriterionForkDropZoneProps> = (props) => {
    const { target, parentOperation, dragState, actions } = props

    const ref = useRef<HTMLDivElement>(null)

    const { dropProps, isDropTarget } = useCriteriaDropZone({
        ref,
        onAdd: (type) => {
            const uuid = uuidv4()
            if (Object.keys(CRITERIA_DEFAULTS).includes(type)) {
                actions.criteriaInsert({ target, criteriaType: type, filters: CRITERIA_DEFAULTS[type], uuid })
            } else {
                actions.criteriaDialogTrigger({ type, action: { type: CohortTreeActionType.Insert, target, uuid } })
            }
        },
        onCopy: (source) => actions.criteriaCopy({ source, target: target }),
        onMove: (source) => actions.criteriaMove({ source, target: target })
    })

    return (
        <Box
            // Drag and drop
            ref={ref}
            role='button'
            tabIndex={0}
            {...dropProps}
            // Styles
            boxSizing='border-box'
            display='flex'
            flexGrow={1}
            alignItems='center'
            justifyContent='center'
            overflow='hidden'
            visibility={dragState.active ? 'visible' : 'hidden'}
            width={dragState.active ? 64 : 0}
            p={dragState.active ? 1 : 0}
            border={dragState.active ? 1 : 0}
            bgcolor={isDropTarget ? 'grey.400' : 'white'}
            textAlign='center'
            sx={{ borderStyle: 'dashed', transition: 'all 150ms ease-in-out' }}
        >
            {parentOperation === CriteriaOperation.AND ? CriteriaOperation.OR : CriteriaOperation.AND}
        </Box>
    )
}
