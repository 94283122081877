/* eslint-disable string-to-lingui/missing-lingui-transformation */
import styled from '@emotion/styled'
import { t } from '@lingui/macro'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import { Box, Button, CircularProgress, Grid, IconButton, Typography } from '@mui/material'
import { cohortListActions } from '@om1/cohort-module/src/state'
import { CohortInsightsReportDTO, PowerBIEmbedTokenResponse, PowerBiService } from '@om1/falcon-api'
import { LightTooltip } from '@om1/platform-components/Tooltip'
import { Routes, toPath } from '@om1/platform-routing'
import { ContentPadding } from '@om1/platform-ui-kit/src/components/Layout'
import { RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { Embed, Page, Report } from 'powerbi-client'
import { PowerBIEmbed } from 'powerbi-client-react'
import { BackgroundType, TokenType } from 'powerbi-models'
import { FunctionComponent, useState } from 'react'
import { Link } from 'react-router-dom'
import { PlatformState } from '../../../../apps/platform/src/platform-state'
import { insightsLibraryActions, InsightsLibraryState } from '../state'
import { createInsightsLibraryReportComponent } from './InsightsLibraryReport'

const PowerBIPageContainer = styled(Box)({
    padding: '16px 36px',
    height: '92%',
    iframe: {
        border: 'none'
    }
})

export type PowerBIPageComponentProps = RoutedFrameworkComponentProps<
    {},
    {},
    { insightsLibrary: InsightsLibraryState; permissions?: string[] | undefined },
    typeof insightsLibraryActions & typeof cohortListActions,
    {}
>

export const PowerBIPageComponent: FunctionComponent<PowerBIPageComponentProps> = ({ state, actions }) => {
    const [embedToken, setEmbedToken] = useState<string>()
    const [embedUrl, setEmbedUrl] = useState<string>()
    const [error, setError] = useState<string>()
    const [report, setReport] = useState<Report>()
    const [reportId, setReportId] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [view, setView] = useState<'tiles' | 'report'>('tiles')

    const fetchEmbedToken = async (id: string) => {
        try {
            console.log('fetching embed token for report:', id)
            setLoading(true)
            setError('')
            try {
                const res: PowerBIEmbedTokenResponse = await PowerBiService.getPowerBiReportPowerBiEmbedCohortInsightsReportIdGet({
                    cohortInsightsReportId: id
                })
                setEmbedToken(res.token)
                setEmbedUrl(res.embedUrl)
                setReportId(res.report_id)
                setView('report')
            } catch (e) {
                console.error('Error fetching PowerBI report:', e)
                setError(JSON.stringify(e?.['body']?.['detail']))
            } finally {
                setLoading(false)
            }
        } catch (error) {
            console.error('Error fetching PowerBI report:', error)
            setError(JSON.stringify(error?.['body']?.['detail']))
            setLoading(false)
        }
    }

    const getReportPages = async () => {
        if (report) {
            const pages: Page[] = await report.getPages()
            console.log(pages)
        }
    }

    const handleBackClick = () => {
        setView('tiles')
        setReport(undefined)
        setEmbedToken(undefined)
        setEmbedUrl(undefined)
        setReportId('')
    }

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: `${window.innerHeight}px`
                }}
            >
                <CircularProgress />
            </Box>
        )
    }

    const InsightsLibraryReportComponent = createInsightsLibraryReportComponent<PlatformState>()

    if (view === 'tiles') {
        return (
            <>
                <Box width={1} display='flex' justifyContent='flex-end' marginBottom={'-40px'}>
                    <LightTooltip title={t`Manage Insights Library`} placement='top'>
                        <IconButton
                            key='distribution'
                            color='primary'
                            component={Link}
                            to={toPath(Routes.INSIGHTS_LIBRARY + '/manage')}
                            sx={{
                                backgroundColor: '#D6E4F2',
                                color: '#012D72',
                                borderRadius: '2px',
                                marginTop: '20px',
                                marginBottom: '-40px',
                                marginRight: '15px'
                            }}
                        >
                            {<SettingsRoundedIcon />}
                        </IconButton>
                    </LightTooltip>
                </Box>
                <Grid container direction='column' spacing={2} alignItems='flex-start' paddingLeft={5} paddingTop={2.5}>
                    <Grid item xs={12}>
                        {error && <Box>{error}</Box>}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h4' gutterBottom color='primary'>
                            OM1 Insights Library
                        </Typography>
                    </Grid>
                    <ContentPadding padX padY>
                        {state.insightsLibrary.cohortInsightsReports.map((report: CohortInsightsReportDTO, index) => (
                            <InsightsLibraryReportComponent
                                key={`InsightsLibraryReportComponent${index}`}
                                cohortInsightsReport={report}
                                fetchEmbedToken={fetchEmbedToken}
                            />
                        ))}
                    </ContentPadding>
                </Grid>
            </>
        )
    }

    return (
        <>
            <Button
                variant='contained'
                onClick={handleBackClick}
                sx={{ marginBottom: '10px', marginTop: '10px', marginLeft: '10px', padding: '5px' }}
            >
                Back
            </Button>
            <PowerBIPageContainer>
                <PowerBIEmbed
                    embedConfig={{
                        type: 'report',
                        id: reportId,
                        accessToken: embedToken,
                        embedUrl: embedUrl,
                        tokenType: TokenType.Embed,
                        settings: {
                            panes: {
                                filters: {
                                    visible: true
                                },
                                pageNavigation: {
                                    visible: true,
                                    position: 1
                                }
                            },
                            background: BackgroundType.Transparent
                        }
                    }}
                    eventHandlers={
                        new Map([
                            [
                                'loaded',
                                function () {
                                    getReportPages()
                                }
                            ],
                            ['rendered', function () {}],
                            [
                                'error',
                                function (event) {
                                    console.log(event?.detail)
                                }
                            ],
                            ['visualClicked', () => {}],
                            ['pageChanged', () => {}]
                        ])
                    }
                    cssClassName={'reportClass'}
                    getEmbeddedComponent={(embedObject: Embed) => {
                        setReport(embedObject as Report)
                    }}
                />
            </PowerBIPageContainer>
        </>
    )
}
