import { yupResolver } from '@hookform/resolvers/yup'
import { t, Trans } from '@lingui/macro'
import CloseIcon from '@mui/icons-material/Close'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField
} from '@mui/material'
import { Box } from '@mui/system'
import { cohortListActions, CohortListItem, CohortState } from '@om1/cohort-module/src/state'
import { CohortConditionCreateDTO } from '@om1/falcon-api'
import { RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { FunctionComponent, ReactNode } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { rwaConditionsActions } from '../state/rwa-conditions'

export type AddRWAConditionModalComponentProps = RoutedFrameworkComponentProps<
    {},
    {},
    CohortState,
    typeof cohortListActions & typeof rwaConditionsActions,
    { showAddRWAConditionModal: boolean; closeModal: () => void }
>

/**
 * A dialog.
 */
export const AddRWAConditionModalComponent: FunctionComponent<AddRWAConditionModalComponentProps> = ({ state, actions, props }) => {
    const { showAddRWAConditionModal, closeModal } = props

    const handleClose = () => {
        closeModal()
    }

    const onSubmit: SubmitHandler<CohortConditionCreateDTO> = (formValues: CohortConditionCreateDTO) => {
        actions.createRwaCondition({ condition: formValues })
        closeModal()
    }

    const validationSchema = Yup.object().shape({
        cohortId: Yup.string().required(t`cohortId is required`),
        powerbiFilterKey: Yup.string().required(t`powerbiFilterKey is required`),
        powerbiFilterValue: Yup.string().required(t`powerbiFilterValue is required`)
    })

    const {
        handleSubmit,
        control,
        register,
        formState: { errors }
    } = useForm<CohortConditionCreateDTO>({
        defaultValues: {
            cohortId: '',
            powerbiFilterKey: '',
            powerbiFilterValue: ''
        },
        resolver: yupResolver(validationSchema)
    })

    const formatPatientCount = (patientCount: number | null | undefined | undefined) => {
        const label = patientCount === 1 ? 'patient' : 'patients'
        const numberFormatter = new Intl.NumberFormat(undefined)
        return `${numberFormatter.format(patientCount || 0)} ${label}`
    }

    return (
        <Dialog open={showAddRWAConditionModal} maxWidth='sm' fullWidth aria-labelledby='add-condition-dialog-title' onClose={handleClose}>
            <DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={handleClose}
                    sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                >
                    <CloseIcon />
                </IconButton>
                <Box id='add-condition-dialog-title' pr={4}>
                    <Trans>Add Condition</Trans>
                </Box>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box position='relative' display='flex' flexDirection='column' flex={1} sx={{ overflowY: 'auto' }}>
                        <Box component='pre' p={2} m={0} sx={{ overflow: 'auto' }}>
                            <Box display={'flex'} flexDirection={'column'}>
                                <FormControl fullWidth>
                                    <InputLabel required>
                                        <Trans>System Cohort</Trans>
                                    </InputLabel>
                                    <Controller
                                        control={control}
                                        name='cohortId'
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                id='cohortId'
                                                {...register('cohortId')}
                                                labelId='cohortId-label'
                                                label={<Trans>Select Cohort</Trans>}
                                                disabled={state.list.loading}
                                                value={value}
                                                error={!!errors['cohortId']}
                                                onChange={onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}
                                            >
                                                {state.list.cohorts.SYSTEM.map((item: CohortListItem) => (
                                                    <MenuItem value={item.id} key={item.id}>
                                                        {item.analyticsDataset.name} ({formatPatientCount(item.cohortSize)})
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name='powerbiFilterKey'
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                id='powerbiFilterKey'
                                                {...register('powerbiFilterKey')}
                                                required
                                                label={<Trans>PowerBI Filter Key</Trans>}
                                                inputProps={{ inputMode: 'text' }}
                                                sx={{ flex: 1, marginTop: 2 }}
                                                onChange={onChange}
                                                value={value}
                                                error={!!errors['powerbiFilterKey']}
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name='powerbiFilterValue'
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                label={<Trans>PowerBI Filter Value</Trans>}
                                                id='powerbiFilterValue'
                                                {...register('powerbiFilterValue')}
                                                required
                                                inputProps={{ inputMode: 'text' }}
                                                sx={{ flex: 1, marginTop: 2 }}
                                                onChange={onChange}
                                                value={value}
                                                error={!!errors['powerbiFilterValue']}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Box>{' '}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' color='primary' onClick={closeModal}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button variant='contained' type='submit'>
                        <Trans>Save</Trans>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
