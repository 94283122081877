import { DataDeliveryActionTypes, DataDeliveryActions } from './data-delivery-actions'
import { DataDeliveryState, initialDataDeliveryState } from './data-delivery-state'

export function dataDeliveryReducer(previousState: DataDeliveryState | undefined, action: DataDeliveryActions): DataDeliveryState {
    const state = previousState || initialDataDeliveryState

    switch (action.type) {
        case DataDeliveryActionTypes.COHORTS_LOADING: {
            return { ...state, edit: { ...state.edit, ui: { ...state.edit.ui, cohortsLoading: action.payload.loading } } }
        }
        case DataDeliveryActionTypes.COHORTS_SET: {
            return {
                ...state,
                edit: {
                    ...state.edit,
                    cohortsPaginationMeta: action.payload.meta,
                    cohorts: { ...state.edit.cohorts, [action.payload.owner]: action.payload.cohorts }
                }
            }
        }
        case DataDeliveryActionTypes.DATA_DELIVERIES_SET: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload.data,
                    paginationMeta: action.payload.meta
                }
            }
        }
        case DataDeliveryActionTypes.DATA_DELIVERIES_LIST_ADD_EDIT: {
            const { dataDelivery, isAdd } = action.payload
            const paginationMeta = { ...state.list.paginationMeta }
            const data = state.list.data
            const shouldShift = isAdd && paginationMeta.currentPage === 1 && data.length === paginationMeta.itemsPerPage
            let newData = [...data]
            let newPaginationMeta = { ...paginationMeta }
            if (isAdd) {
                if (shouldShift) {
                    newData = [dataDelivery, ...newData.filter((d, i) => i !== state.list.data.length - 1)]
                } else {
                    newData = [dataDelivery, ...newData]
                }
                // increment the total items because we are adding a new data delivery
                const totalItems = paginationMeta.totalItems + 1
                // increment the number of pages if the final page was already full
                const totalPages =
                    paginationMeta.totalPages + (paginationMeta.totalItems === paginationMeta.itemsPerPage * paginationMeta.totalPages ? 1 : 0)
                newPaginationMeta = { ...paginationMeta, totalItems, totalPages }
            } else {
                newData = newData.map((d) => (d.id === dataDelivery.id ? dataDelivery : d))
            }

            return {
                ...state,
                list: {
                    ...state.list,
                    data: newData,
                    paginationMeta: newPaginationMeta
                }
            }
        }
        case DataDeliveryActionTypes.DATA_DELIVERIES_LOADING: {
            return {
                ...state,
                list: {
                    ...state.list,
                    ui: {
                        loading: action.payload.loading
                    }
                }
            }
        }
        case DataDeliveryActionTypes.DATA_DELIVERY_LOADING: {
            return {
                ...state,
                edit: {
                    ...state.edit,
                    ui: {
                        ...state.edit.ui,
                        dataDeliveryLoading: action.payload.loading
                    }
                }
            }
        }
        case DataDeliveryActionTypes.DELIVERED_DATABASES_LOADING: {
            return {
                ...state,
                edit: {
                    ...state.edit,
                    ui: {
                        ...state.edit.ui,
                        deliveredDatabasesLoading: action.payload.loading
                    }
                }
            }
        }
        case DataDeliveryActionTypes.DELIVERED_DATABASES_SET: {
            return {
                ...state,
                edit: {
                    ...state.edit,
                    deliveredDatabases: action.payload.data,
                    deliveredDatabasesPaginationMeta: action.payload.meta
                }
            }
        }
        case DataDeliveryActionTypes.DATA_DELIVERY_UPDATE_EXECUTING_TASK_IDS: {
            const { taskId, isAdd } = action.payload
            if (isAdd) {
                return { ...state, edit: { ...state.edit, executingTaskIds: [...state.edit.executingTaskIds, taskId] } }
            } else {
                return { ...state, edit: { ...state.edit, executingTaskIds: [...state.edit.executingTaskIds.filter((id) => id !== taskId)] } }
            }
        }
        case DataDeliveryActionTypes.ORGANIZATION_METADATA_SET: {
            return {
                ...state,
                organizationMetadata: {
                    ...state.organizationMetadata,
                    metadata: {
                        ...state.organizationMetadata.metadata,
                        ...action.payload.data
                    }
                }
            }
        }
        case DataDeliveryActionTypes.ORGANIZATION_METADATA_LOADING: {
            return {
                ...state,
                organizationMetadata: {
                    ...state.organizationMetadata,
                    isLoading: action.payload.loading
                }
            }
        }
        case DataDeliveryActionTypes.DATA_DELIVERY_REGISTRY_TYPES_SET: {
            return {
                ...state,
                registryTypes: {
                    ...state.registryTypes,
                    registryTypes: action.payload.registryTypes
                }
            }
        }
        case DataDeliveryActionTypes.DATA_DELIVERY_REGISTRY_TYPES_LOADING: {
            return {
                ...state,
                registryTypes: {
                    ...state.registryTypes,
                    isLoading: action.payload.loading
                }
            }
        }
        default: {
            return state
        }
    }
}
