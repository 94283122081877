import { NestedRefGetterCallback } from '../edit/utils/ref-field-mappers'
import { Checked, TreeItem, TreeSelection } from './CheckTree'

export interface NestedCheckTreeManagerOptions<T> {
    getItemValue: NestedRefGetterCallback<T>
    getItemLabel: NestedRefGetterCallback<T>
    getItemTooltip: NestedRefGetterCallback<T>
    getItemId: NestedRefGetterCallback<T, string>
    getItemParentId: NestedRefGetterCallback<T>
    getItemPathToRoot: NestedRefGetterCallback<T, string[]>
    getItemChildCount?: NestedRefGetterCallback<T>
    collapseAtDepth: number
    /** When clicking on an item in the tree, skip the staged status and directly commit the selection */
    skipStaged?: boolean
}

/**
 * Manages the state of a tree for use in a CheckTree component. Handles, selection, deselection, addition of new items,
 * and the calculation of status for each node in the tree.
 *
 * The tree has a concept of "staged" and "commited" to support the left / right panes that typically appear with the
 * tree in the UI. A staged check tree item is an item that has been checked, but has not ultimately been added to the
 * committed result set. Staged items in the tree can be unchecked, where as committed items display as disabled and
 * need to be uncommitted to remove their selected status. Confirming the currently checked (staged) items in the tree
 * will move the currently staged items into a committed state.
 *
 * The data added to the tree is expected to have a parent / child relationship. Each node should have a reference back
 * to its parent id, as well as the ability to generate an array of ids that is a full path back to the root node.
 *
 * Note that `depth` is used in this tree due to the existence of the `DepthCheckTreeManager`, though depth in this case
 * should always be set to `0` as each item added to the tree creates exactly one node in the tree, thus the depth is
 * mostly irrelevent here.
 */
export class NestedCheckTreeManager<T> {
    tree: TreeItem<T> = {
        id: '',
        order: [],
        children: new Map(),
        expanded: true,
        // Source is always undefined for the root node, just ignore the type error
        // @ts-ignore
        source: undefined
    }
    staged: TreeSelection<T>[] = []
    committed: TreeSelection<T>[] = []

    getItemValue: NestedRefGetterCallback<T>
    getItemLabel: NestedRefGetterCallback<T>
    getItemTooltip: NestedRefGetterCallback<T>
    getItemId: NestedRefGetterCallback<T, string>
    getItemParentId: NestedRefGetterCallback<T>
    getItemPathToRoot: NestedRefGetterCallback<T, string[]>

    getItemChildCount?: NestedRefGetterCallback<T>
    collapseAtDepth: number
    skipStaged: boolean
    listener?: () => void

    constructor(options: NestedCheckTreeManagerOptions<T>) {
        this.getItemValue = options.getItemValue
        this.getItemLabel = options.getItemLabel
        this.getItemTooltip = options.getItemTooltip
        this.getItemId = options.getItemId
        this.getItemParentId = options.getItemParentId
        this.getItemPathToRoot = options.getItemPathToRoot
        this.getItemChildCount = options.getItemChildCount

        this.collapseAtDepth = options.collapseAtDepth
        this.skipStaged = options.skipStaged || false
    }

    /**
     * Registers a change listener with the tree manager. Listeners will be called every time the tree status is
     * updated. For example, when an item is added, or when checkbox is checked.
     */
    registerChangeListener(listener: () => void) {
        this.listener = listener
    }

    /**
     * Removes the previously registered change listener.
     */
    clearChangeListener() {
        this.listener = undefined
    }

    /**
     * Adds a new item to the tree. Determines the proper placement of the item in the tree using the depth callbacks,
     * and auto-generates an id for each item inserted.
     */
    addNode(item: T, searchParams: string) {
        this.addSingleNode(item, searchParams)
        this.notify()
    }

    /**
     * Add multiple items to the tree, and only broadcast a notification update once all items are added.
     */
    addNodes(items: T[], searchParams: string) {
        items.forEach((i) => {
            this.addSingleNode(i, searchParams)
        })
        this.notify()
    }

    /**
     * Calculates the status of a single checkbox in the tree.
     *
     * A checkbox is checked if:
     * - It is staged
     * - One of its parents is staged
     * - It is committed
     * - One of its parents is committed
     *
     * A checkbox is indeterminate if:
     * - One of its children is staged and none of its parents are staged or committed
     * - One of its children is committed and none of its parents are staged or committed
     *
     * A checkbox is disabled if:
     * - It is committed
     * - One of its parents is committed
     */
    displayStatus(item: T, depth: number): Checked {
        const statusItemId = this.getItemId(item)
        const statusItemPathToRoot = this.getItemPathToRoot(item)

        let committed = false
        for (let i = 0; !committed && i < this.committed.length; i++) {
            const existingItem = this.committed[i]
            const existingId = this.getItemId(existingItem.source)
            const existingPathToRoot = this.getItemPathToRoot(existingItem.source)
            existingPathToRoot.push(existingId)
            if (statusItemId === existingId) {
                committed = true
            } else if (pathStartsWith(statusItemPathToRoot, existingPathToRoot)) {
                committed = true
            }
        }

        if (committed) {
            return Checked.D
        }

        let staged = false
        for (let i = 0; !staged && i < this.staged.length; i++) {
            const existingItem = this.staged[i]
            const existingId = this.getItemId(existingItem.source)
            const existingPathToRoot = this.getItemPathToRoot(existingItem.source)
            existingPathToRoot.push(existingId)
            if (statusItemId === existingId) {
                staged = true
            } else if (pathStartsWith(statusItemPathToRoot, existingPathToRoot)) {
                staged = true
            }
        }

        if (staged) {
            return Checked.Y
        }

        let stagedOrCommittedBelow = false
        const stagedOrCommitted = [...this.staged, ...this.committed]
        for (let i = 0; !stagedOrCommittedBelow && i < stagedOrCommitted.length; i++) {
            const existingItem = stagedOrCommitted[i]
            const existingPathToRoot = this.getItemPathToRoot(existingItem.source)
            if (existingPathToRoot?.includes(statusItemId)) {
                stagedOrCommittedBelow = true
            }
        }

        if (stagedOrCommittedBelow) {
            return Checked.I
        }

        return Checked.N
    }

    /**
     * Removes all of the current items in the tree, and all previously selected staged items. Committed items are not
     * removed. Typically utilized when a new tree needs to be displayed due to a search term change.
     */
    clearTree() {
        this.tree = { ...this.tree, order: [], children: new Map() }
        this.staged = []
        this.notify()
    }

    /**
     * Adds a checked item to the list of staged items. If a parent of previously staged items is selected, the child
     * staged items are removed, and the newly selected item is added in their place.
     */
    stage(item: TreeSelection<T>) {
        item.depth = 0
        if (this.skipStaged) {
            this.commit(item)
            return
        }

        const idToStage = this.getItemId(item.source)
        // Filter out staged children that are children of the added item by check if the new item's id is in their path
        const keep: TreeSelection<T>[] = this.staged.filter((x) => {
            const pathToRoot = this.getItemPathToRoot(x.source)
            if (pathToRoot && !pathToRoot.includes(idToStage)) {
                return true
            }
            return false
        })
        this.staged = [...keep, item]
        this.notify()
    }

    /**
     * Removes a checked item from the list of staged items. Also handles the use case where a parent with many children
     * is staged and one of its children is unstaged. This unstage action requires a recursive clean up where the single
     * parent is removed from the staged list and is replaced by the minimal subset of nodes that excludes the unstaged
     * item.
     */
    unstage(item: TreeSelection<T>) {
        // Remove any exact matches
        const idToUnstage = this.getItemId(item.source)
        this.staged = this.staged.filter((x) => this.getItemId(x.source) !== idToUnstage)

        const pathToRootToUnstage = this.getItemPathToRoot(item.source)

        // Staged item that is the closet parent of what is being unselected
        const closestParentSelection = this.staged.find((x) => {
            const fullPathToRoot = this.getItemPathToRoot(x.source)
            fullPathToRoot.push(this.getItemId(x.source))
            return pathStartsWith(pathToRootToUnstage, fullPathToRoot)
        })

        // The tree node that represents the staged item found above
        let closestParentNode: TreeItem<T> | undefined
        if (closestParentSelection) {
            const parentId = this.getItemId(closestParentSelection.source)
            const parentPathToRoot = this.getItemPathToRoot(closestParentSelection.source)
            this.staged = this.staged.filter((x) => this.getItemId(x.source) !== parentId)

            let currentNode = this.tree
            for (let i = 0; i < parentPathToRoot.length; i++) {
                const segment = parentPathToRoot[i]
                if (currentNode.children.has(segment)) {
                    currentNode = currentNode.children.get(segment)!
                }
            }
            if (currentNode.children.has(parentId)) {
                currentNode = currentNode.children.get(parentId)!
            }
            closestParentNode = currentNode
        }

        if (closestParentNode) {
            const itemsToAdd: TreeSelection<T>[] = []
            const parentPathToRoot = this.getItemPathToRoot(closestParentNode.source)
            const fullPathToRootToUnstage = [...pathToRootToUnstage, idToUnstage]
            let node = closestParentNode
            for (let i = parentPathToRoot.length + 1; i < fullPathToRootToUnstage.length; i++) {
                const currentSegment = fullPathToRootToUnstage[i]

                for (let j = 0; j < node.order.length; j++) {
                    const key = node.order[j]
                    if (key !== currentSegment) {
                        itemsToAdd.push({ source: node.children.get(key)!.source, depth: 0 })
                    }
                }
                node = node.children.get(currentSegment)!
            }
            this.staged = [...this.staged, ...itemsToAdd]
            this.notify()
        }
    }

    /**
     * Adds a single item directly to the committed list.
     */
    commit(item: TreeSelection<T>) {
        item.depth = 0
        this.commitSingle(item)
        this.notify()
    }

    /**
     * Removes a single item from the committed list. If any children of the uncommitted item also have been committed,
     * those children are removed as well.
     */
    uncommit(item: TreeSelection<T>) {
        item.depth = 0
        const idToUncommit = this.getItemId(item.source)
        this.committed = this.committed.filter((x) => {
            const existingId = this.getItemId(x.source)
            if (idToUncommit === existingId) {
                return false
            }
            return true
        })
        this.notify()
    }

    /**
     * Clears all committed items from the committed array.
     */
    uncommitAll() {
        this.committed = this.committed.filter(() => false)
        this.notify()
    }

    /**
     * Convert all currently staged items to committed items.
     */
    commitStaged() {
        for (let i = 0; i < this.staged.length; i++) {
            this.commitSingle(this.staged[i])
        }
        this.staged = []
        this.notify()
    }

    /**
     * Expand a node in the tree to see its children.
     */
    expand(item: TreeSelection<T>) {
        this.toggleExpand(item, true)
        this.notify()
    }

    /**
     * Collapse a node in the tree to hide its children.
     */
    collapse(item: TreeSelection<T>) {
        this.toggleExpand(item, false)
        this.notify()
    }

    private toggleExpand(item: TreeSelection<T>, expand: boolean) {
        const idToExpand = this.getItemId(item.source)
        const fullPathToRootToExpand = this.getItemPathToRoot(item.source)
        fullPathToRootToExpand.push(idToExpand)

        let expandableNode = this.tree
        for (let i = 0; i < fullPathToRootToExpand.length; i++) {
            const segment = fullPathToRootToExpand[i]
            if (expandableNode.children.has(segment)) {
                expandableNode = expandableNode.children.get(segment)!
            }
        }
        if (expandableNode.id === idToExpand) {
            expandableNode.expanded = expand
        }
    }

    private commitSingle(item: TreeSelection<T>) {
        const idToCommit = this.getItemId(item.source)
        const keep: TreeSelection<T>[] = this.committed.filter((x) => {
            const pathToRoot = this.getItemPathToRoot(x.source)
            if (pathToRoot && !pathToRoot.includes(idToCommit)) {
                return true
            }
            return false
        })
        this.committed = [...keep, item]
    }

    private addSingleNode(item: T, searchParams: string) {
        const searchParamsLower = searchParams ? searchParams.toLowerCase() : ''
        const pathToRootToAdd = this.getItemPathToRoot(item)
        if (pathToRootToAdd.length === 0) {
            const id = this.getItemId(item)
            this.tree.children.set(id, {
                id,
                order: [],
                children: new Map(),
                expanded: true,
                source: item
            })
            // insert children in sorted order accoring to their label. must be done after inserting into children map.
            this.tree.order = [...this.tree.order, id].sort((a, b) =>
                this.sortCompareItemLabels(this.tree.children.get(a)!.source, this.tree.children.get(b)!.source)
            )
            return
        }

        let parentNodeToAddTo = this.tree
        const idToAdd = this.getItemId(item)
        for (let i = 0; i < pathToRootToAdd.length; i++) {
            const segment = pathToRootToAdd[i]
            if (parentNodeToAddTo.children.has(segment)) {
                parentNodeToAddTo = parentNodeToAddTo.children.get(segment)!
            } else {
                console.error(
                    `Failed to find child node with ID: ${segment}. Current parent: ${parentNodeToAddTo.id}, Available children: ${Array.from(
                        parentNodeToAddTo.children.keys()
                    ).join(', ')}. Aborting addition.`
                )
                return // Early return if path segment is not found
            }
        }
        const value = this.getItemLabel(item)?.toLocaleLowerCase() || ''
        const tooltip = this.getItemTooltip(item)?.toLowerCase() || ''
        const parentId = this.getItemParentId(item)
        if (parentNodeToAddTo.id === parentId) {
            parentNodeToAddTo.children.set(idToAdd, {
                id: idToAdd,
                order: [],
                children: new Map(),
                expanded:
                    (!value.includes(searchParamsLower) && !tooltip.includes(searchParamsLower)) || pathToRootToAdd.length < this.collapseAtDepth,
                source: item
            })
            // insert children in sorted order accoring to their label. must be done after inserting into children map.
            parentNodeToAddTo.order = [...parentNodeToAddTo.order, idToAdd].sort((a, b) =>
                this.sortCompareItemLabels(parentNodeToAddTo.children.get(a)!.source, parentNodeToAddTo.children.get(b)!.source)
            )
        } else {
            console.error(`Parent ID mismatch. Found parent ID ${parentNodeToAddTo.id}, expected ${parentId}.`)
        }
    }

    /*
     * Sort two Item objects by their item label.
     */
    private sortCompareItemLabels(a?: T, b?: T) {
        if (!a && !b) {
            return 0
        }

        if (a && !b) {
            return 1
        }

        if (b && !a) {
            return -1
        }

        const aLabel = this.getItemLabel(a!) || ''
        const bLabel = this.getItemLabel(b!) || ''

        if (aLabel === bLabel) {
            return 0
        }

        if (aLabel === 'Other') {
            return 1
        }

        if (bLabel === 'Other') {
            return -1
        }

        return aLabel.localeCompare(bLabel)
    }

    private notify() {
        if (this.listener) {
            this.listener()
        }
    }
}

function pathStartsWith(path: string[], prefixPath: string[]) {
    if (path.length < prefixPath.length) {
        return false
    }

    for (let i = 0; i < prefixPath.length; i++) {
        if (prefixPath[i] !== path[i]) {
            return false
        }
    }
    return true
}
