import { TrackingPlanEvents, track, trackingEvent } from '@om1/platform-tracking'
import { ActionsUnion, createAction } from '@om1/platform-utils'
import { CohortSaveValues } from '../../components/shared/CreateEditCohortComponent'

export enum CohortCreateActionTypes {
    SET_IS_LOADING = '@@cohorts/create/loading-set',
    COHORT_CREATE = '@@cohorts/create/new'
}

export const cohortCreateActions = {
    cohortCreateLoadingSet: (payload: { loading: boolean }) => createAction(CohortCreateActionTypes.SET_IS_LOADING, payload),
    cohortCreate: (payload: CohortSaveValues) =>
        track(
            createAction(CohortCreateActionTypes.COHORT_CREATE, payload),
            trackingEvent(TrackingPlanEvents.COHORT_CREATE, {
                cohortName: payload.name,
                analyticsDataset: payload.analyticsDataset,
                isPrivate: payload.isPrivate,
                isStandardBuild: payload.isStandardBuild
            })
        )
}

export type CohortCreateActions = ActionsUnion<typeof cohortCreateActions>
