import { notificationActions } from '@om1/platform-notifications'
import { connectRedux } from '@om1/platform-utils'
import { cohortEditActions, CohortState } from '../../../state'
import { CohortSqlDialogComponent } from './CohortSqlDialogComponent'

export function createCohortSqlDialog<TState extends { cohort: CohortState }>() {
    return connectRedux(
        CohortSqlDialogComponent,
        (state: TState) => ({
            sql: state.cohort.edit.base.sql,
            loading: state.cohort.edit.base.ui.cohortSqlLoading
        }),
        { ...cohortEditActions, ...notificationActions }
    )
}

export type CohortSqlDialog = ReturnType<typeof createCohortSqlDialog>
