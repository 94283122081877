import { CriteriaType, EhrNotesRef } from '../../../../state'
import { DepthRefFieldMapper, RefFieldMapperType } from './interfaces'

export const EhrNotesRefFieldMapper: Omit<DepthRefFieldMapper<EhrNotesRef>, 'dimension'> & { dimension: string } = {
    type: RefFieldMapperType.Depth,
    dimension: 'PATIENT',
    criteriaType: CriteriaType.EhrNotes,
    table: 'patient_note',
    dateField: {
        first: 'first_record_date',
        last: 'last_record_date',
        any: 'any_record_date'
    },
    dateFieldInverse: {
        first_record_date: 'first',
        last_record_date: 'last',
        any_record_date: 'any'
    },
    searchPlaceholder: '',
    noResultsMessage: '',
    searchHintMessage: '',
    filterFieldToRefField: {},
    filterFieldOrder: [],
    filterLabelOrder: [],
    getItemLabel: function (item: EhrNotesRef): string | null | undefined {
        throw new Error('Function not implemented.')
    },
    getItemValue: function (item: EhrNotesRef): string | null | undefined {
        throw new Error('Function not implemented.')
    },
    getItemTooltip: function (item: EhrNotesRef): string | null | undefined {
        throw new Error('Function not implemented.')
    }
}
