import React, { FunctionComponent, useContext } from 'react'

type ConfigType = {
    [key: string]: any
}

export function withConfig<T extends { config: Partial<ConfigType> }>(
    Component: FunctionComponent<T>,
    config: Partial<ConfigType>
): FunctionComponent<Omit<T, 'config'>> {
    const WithConfig = (props: any) => {
        const mergedConfig = { ...config, ...useContext(React.createContext<Partial<ConfigType>>({})) }
        return <Component config={mergedConfig} {...props} />
    }
    return WithConfig
}
