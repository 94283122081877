import { t } from '@lingui/macro'
import { DeliveryDTO, OpenAPI, TaskStatusEnum } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { notificationActions } from '@om1/platform-notifications'
import { EventChannel } from 'redux-saga'
import { call, put, select, take } from 'redux-saga/effects'
import { BackOfficeState } from '../state'
import { DataDeliveryTaskStatus, dataDeliveryActions } from '../state/data-delivery'
import { taskSocket } from './task-socket'

const STATUS_TRANSITIONS: Record<
    DataDeliveryTaskStatus.New | DataDeliveryTaskStatus.Candidate | DataDeliveryTaskStatus.Approved,
    DataDeliveryTaskStatus
> = {
    [DataDeliveryTaskStatus.New]: DataDeliveryTaskStatus.Candidate,
    [DataDeliveryTaskStatus.Candidate]: DataDeliveryTaskStatus.Approved,
    [DataDeliveryTaskStatus.Approved]: DataDeliveryTaskStatus.Published
}

export function createAddDataDeliveryTaskListenerSaga() {
    return function* (action: ReturnType<typeof dataDeliveryActions.dataDeliveryAddTaskListener>) {
        const { deliveryId, taskId } = action.payload
        const executingTaskIds = yield select((store: { backOffice: BackOfficeState }) => {
            return store.backOffice.dataDelivery.edit.executingTaskIds
        })

        if (!executingTaskIds.includes(taskId)) {
            // Do listener
            yield put(dataDeliveryActions.dataDeliveryUpdateExecutingTaskIds({ taskId, isAdd: true }))
            try {
                const eventChannel: EventChannel<any> = yield call(taskSocket, taskId, OpenAPI.TOKEN)
                const socketResponse = JSON.parse(yield take(eventChannel)) as { taskId: string; status: TaskStatusEnum; result: any }

                let dataDelivery: DeliveryDTO | undefined = yield select((store: { backOffice: BackOfficeState }) => {
                    return store.backOffice.dataDelivery.list.data.find((d) => d.id === deliveryId)
                })

                if (socketResponse.status === 'SUCCESS' && socketResponse.result.msg === 'SUCCESS') {
                    if (dataDelivery) {
                        dataDelivery = {
                            ...dataDelivery,
                            deliveryStatus: STATUS_TRANSITIONS[dataDelivery.deliveryStatus]
                        }
                    }
                } else if (socketResponse.status === 'FAILURE' && (socketResponse.result.error || '').includes('TimeoutError')) {
                    yield put(notificationActions.error(t`Data delivery execution timed out`))
                } else {
                    yield put(notificationActions.error(t`Could not execute data delivery`))
                }
                if (dataDelivery) {
                    dataDelivery = {
                        ...dataDelivery,
                        task: socketResponse
                    }
                    yield put(dataDeliveryActions.dataDeliveryFetchAndUpdateInList({ deliveryId }))
                }
            } catch (error) {
                let dataDelivery: DeliveryDTO | undefined = yield select((store: { backOffice: BackOfficeState }) => {
                    return store.backOffice.dataDelivery.list.data.find((d) => d.id === deliveryId)
                })
                if (dataDelivery) {
                    dataDelivery = {
                        ...dataDelivery,
                        task: null
                    }
                    yield put(dataDeliveryActions.dataDeliveriesListAddEdit({ dataDelivery, isAdd: false }))
                }
                yield handleApiError(error)
            } finally {
                yield put(dataDeliveryActions.dataDeliveryUpdateExecutingTaskIds({ taskId, isAdd: false }))
            }
        }
    }
}
