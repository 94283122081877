import { Trans, t } from '@lingui/macro'
import { Delete } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    Radio,
    RadioGroup,
    TextField,
    TextFieldProps,
    Typography
} from '@mui/material'
import { Box, alpha } from '@mui/system'
import { FalconPaginationMeta } from '@om1/falcon-api'
import { FrameworkComponentProps } from '@om1/platform-utils'
import { ChangeEventHandler, FunctionComponent, PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DebounceInput } from 'react-debounce-input'
import { AnalyticsRefDestination, CriteriaType, QueryFilterBase, QueryRefsActionPayload, cohortBlocksEditActions } from '../../../state'
import { analyticsRefActions } from '../../../state/refs/analytics-refs-actions'
import { CheckTree, TreeItem, TreeSelection } from '../../shared/CheckTree'
import { DepthCheckTreeManager } from '../../shared/DepthCheckTreeManager'
import { NestedCheckTreeManager } from '../../shared/NestedCheckTreeManager'
import { RefFieldMapperConfig } from '../base/CohortEditComponent'
import { deduceDimensionFromTableAndField, generateUICriteriaFilters } from '../utils/filter-utils'
import { DepthRefFieldMapper, NestedRefFieldMapper, extractRefLabels, isNestedRefFieldMapper } from '../utils/ref-field-mappers'
import { useOnScreen } from '../utils/useOnScreen'

/** The amount a user must type before a search is performed. Not applicable when searching is not required. */
const MIN_SEARCH_LENGTH = 3
/** When searching, the page size to fetch initially and on each subsequent infinite scroll event. */
const REQUEST_PAGE_LIMIT = 100
/** When searching is not required, a higher page size is needed to guarantee we get all available values. */
const REQUEST_ALL_LIMIT = 500

export type RefTreeDialogComponentProps<T> = FrameworkComponentProps<
    {
        refs: { [k: string]: T[] }
        refsRestore: { [k: string]: T[] }
        searchLoading: { [k: string]: boolean }
        restoreLoading: { [k: string]: boolean }
        searchPaginationMeta: { [k: string]: FalconPaginationMeta }
        isOM1User: boolean
    },
    typeof cohortBlocksEditActions & Pick<typeof analyticsRefActions, 'setRefs'>,
    {
        initialValue: QueryFilterBase[]
        fieldMappers: RefFieldMapperConfig<T>[]
        onSave: (filters: QueryFilterBase[]) => void
        onCancel: () => void
        onBack?: (filters: QueryFilterBase[]) => void
        onHandleRemoveQualifiers: () => void
        criteriaType: CriteriaType
        saveText?: ReactNode
        saveIcon?: ReactNode
        dialog?: boolean
        cancelButton?: boolean
        backButton?: boolean
        backButtonDisabled?: boolean
        optionalFilters?: boolean
        reportView?: boolean
    }
>

/**
 * A dialog for editing non-Patient Attributes criterion types, such as Diagnosis, Medication, Lab Test, and Procedure.
 */
export const RefTreeDialogComponent = <T,>({ state, props, actions }: RefTreeDialogComponentProps<T>) => {
    const {
        criteriaType,
        initialValue,
        fieldMappers,
        saveText = <Trans>Save Criteria</Trans>,
        saveIcon = <AddIcon />,
        dialog = true,
        cancelButton,
        backButton,
        backButtonDisabled,
        optionalFilters,
        reportView
    } = props
    const initialAnyLabs =
        initialValue &&
        initialValue.length > 0 &&
        initialValue[0].field === 'has_labs' &&
        (initialValue[0].table === 'patient' || initialValue[0].table === 'patient_lab')
    const [fieldMapperConfig, setFieldMapperConfig] = useState<RefFieldMapperConfig<T>>(() => {
        const userMappers = fieldMappers.filter((fm) => state.isOM1User || !fm.isInternal)
        if (initialValue.length) {
            const dimension = deduceDimensionFromTableAndField(initialValue[0])
            const mapperIndex = userMappers.findIndex((fm) => fm.mapper.dimension === dimension)
            return userMappers[mapperIndex > -1 ? mapperIndex : 0]
        } else {
            return userMappers[0]
        }
    })
    const [anyLabToggleCommitted, setAnyLabToggleCommitted] = useState<TreeSelection<T>[] | undefined>(undefined)
    const dimension = fieldMapperConfig.mapper.dimension
    const disableRequireSearch = fieldMapperConfig.mapper.disableRequireSearch
    const pageLimit = disableRequireSearch ? REQUEST_ALL_LIMIT : REQUEST_PAGE_LIMIT
    const refs = state.refs[dimension]
    const refsRestore = state.refsRestore[dimension]
    const searchLoading = state.searchLoading[dimension]
    const restoreLoading = state.restoreLoading[dimension]
    const searchPaginationMeta = state.searchPaginationMeta[dimension]
    const availableMappers = useMemo(() => {
        /* TODO: replace this email check with a more robust role/permissions check */
        return fieldMappers.filter((fm) => !fm.isInternal || state.isOM1User)
    }, [fieldMappers, state.isOM1User])
    const [anyLabs, setAnyLabs] = useState(initialAnyLabs)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setRenderTrigger] = useState(0)
    const treeManager = useMemo(() => {
        if (isNestedRefFieldMapper(fieldMapperConfig.mapper)) {
            const { getItemId, getItemLabel, getItemParentId, getItemPathToRoot, getItemTooltip, getItemValue, getItemChildCount } =
                fieldMapperConfig.mapper as NestedRefFieldMapper<T>
            return new NestedCheckTreeManager<T>({
                collapseAtDepth: 6,
                skipStaged: true,
                getItemId,
                getItemLabel,
                getItemParentId,
                getItemPathToRoot,
                getItemTooltip,
                getItemValue,
                getItemChildCount
            })
        } else {
            const { getItemLabel, getItemTooltip, getItemValue } = fieldMapperConfig.mapper as DepthRefFieldMapper<T>
            return new DepthCheckTreeManager<T>({
                collapseAtDepth: 4,
                skipStaged: true,
                getItemLabel,
                getItemTooltip,
                getItemValue
            })
        }
    }, [fieldMapperConfig])
    const numberFormatter = new Intl.NumberFormat(undefined)
    useEffect(() => {
        treeManager.registerChangeListener(() => {
            setRenderTrigger((prevValue) => prevValue + 1)
        })
        return () => {
            treeManager.clearChangeListener()
        }
    }, [treeManager])

    const [searchParams, setSearchParams] = useState<{ page: number; limit: number; query: string }>({ page: 1, limit: pageLimit, query: '' })

    // On mount of dialog, take previously selected filter values and attempt to fetch their associated refs
    useEffect(() => {
        // Always send an array when restoring
        const query = initialValue.filter((x) => x.values.length > 0).reduce((acc: string[], current) => [...acc, ...current.values], [])
        if (query.length > 0 && !restoreLoading) {
            // Check dimension before calling. Prevents making an unnecessary call that may result in an error
            if (deduceDimensionFromTableAndField(initialValue[0]) === dimension) {
                actions.queryRefs({ dimension, destination: AnalyticsRefDestination.RESTORE, page: 1, limit: pageLimit, query })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions, dimension, pageLimit, initialValue])

    // When the dialog unmounts, clear the store of all refs
    useEffect(() => {
        return () => {
            actions.setRefs({
                dimension,
                destination: AnalyticsRefDestination.SEARCH,
                data: [],
                paginationMeta: { currentPage: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 }
            })
            actions.setRefs({
                dimension,
                destination: AnalyticsRefDestination.RESTORE,
                data: [],
                paginationMeta: { currentPage: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 }
            })
        }
    }, [actions, dimension])

    // Fetch refs with query string when search params are updated
    useEffect(() => {
        if (disableRequireSearch || searchParams.query.length >= MIN_SEARCH_LENGTH) {
            // Send the plain string if there are not comma separated values, otherwise send an array
            const queryArray = searchParams.query
                .split(',')
                .map((str) => str.trim())
                .filter((str) => str !== '')
            let payload: Omit<QueryRefsActionPayload, 'dimension' | 'destination'> = { ...searchParams }
            if (queryArray.length > 1) {
                payload = { ...payload, query: queryArray }
            }
            if (!anyLabs) {
                actions.queryRefs({ dimension, destination: AnalyticsRefDestination.SEARCH, ...payload })
            }
        }
    }, [dimension, searchParams, disableRequireSearch, actions, anyLabs])

    // On mount if searching is not required, call search with an empty query to populate the left side with all options
    useEffect(() => {
        if (disableRequireSearch) {
            actions.queryRefs({ dimension, destination: AnalyticsRefDestination.SEARCH, page: 1, limit: pageLimit, query: '' })
        }
    }, [dimension, pageLimit, disableRequireSearch, actions])

    // When new refs are available, parse them into a tree for display.
    useEffect(() => {
        treeManager.addNodes(refs, searchParams.query)
    }, [fieldMapperConfig.mapper.dimension, treeManager, refs, searchParams.query])

    // When values are available to repopulate the right hand side, parse results into the appropriate format
    useEffect(() => {
        if (refsRestore.length > 0) {
            // Sort the initial values to make sure we always check for the most specific (highest depth) fields first to check for matches
            const initialValueSortedByDepth = [...initialValue].sort(
                (a, b) => fieldMapperConfig.mapper.filterFieldOrder.indexOf(b.field) - fieldMapperConfig.mapper.filterFieldOrder.indexOf(a.field)
            )
            // For each persisted ref row received from the API, try to find a matching filter value from the ref query
            refsRestore.forEach((ref) => {
                let found = false
                for (let i = 0; !found && i < initialValueSortedByDepth.length; i++) {
                    const filterValueGroup = initialValueSortedByDepth[i]
                    const refField = fieldMapperConfig.mapper.filterFieldToRefField[filterValueGroup.field]
                    for (let j = 0; !found && j < filterValueGroup.values.length; j++) {
                        const value = filterValueGroup.values[j]
                        if (refField && ref[refField] === value) {
                            const depth = fieldMapperConfig.mapper.filterFieldOrder.indexOf(filterValueGroup.field)
                            if (depth > -1) {
                                treeManager.commit({ source: ref, depth })
                                found = true
                            }
                        }
                    }
                }
            })
        }
    }, [initialValue, fieldMapperConfig.mapper, refsRestore, treeManager])

    // Close the dialog without saving
    const handleCancel = () => {
        props.onCancel()
    }

    const mapTreeToFilters = () => {
        const fields = new Map<string, string[]>(
            Array.from(new Set(treeManager.committed.map((x) => fieldMapperConfig.mapper.filterFieldOrder[x.depth]!))).map((x) => [x, []])
        )
        for (let i = 0; i < treeManager.committed.length; i++) {
            const field = fieldMapperConfig.mapper.filterFieldOrder[treeManager.committed[i].depth]
            const value = fieldMapperConfig.mapper.getItemValue(treeManager.committed[i].source, treeManager.committed[i].depth)
            if (field && value) {
                const values = fields.get(field)!
                values.push(value)
            }
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (let [_field, values] of fields) {
            values.sort()
        }

        const filters = generateUICriteriaFilters({
            fields: Object.fromEntries(fields),
            type: criteriaType
        })

        actions.mergeRefLabels(
            extractRefLabels(
                treeManager.committed.map((x) => x.source),
                fieldMapperConfig.mapper
            )
        )

        return filters
    }

    // Close the dialog and save selected values
    const handleSave = () => {
        if (anyLabs) {
            let filter: QueryFilterBase = {
                table: 'patient',
                field: 'has_labs',
                operator: 'in',
                values: ['true']
            }
            props.onHandleRemoveQualifiers()
            props.onSave([filter])
        } else {
            const filters = mapTreeToFilters()
            props.onSave(filters)
        }
    }

    // Delete a single item from the right hand side
    const handleDelete = (item: { source: T; depth: number }) => {
        treeManager.uncommit(item)
    }

    const handleBack = () => {
        const filters = mapTreeToFilters()
        props.onBack && props.onBack(filters)
    }

    // Load more search results if available
    const handleNextPage = useCallback(() => {
        if (!searchLoading && searchPaginationMeta.totalPages > searchPaginationMeta.currentPage) {
            setSearchParams((prev) => ({ ...prev, page: prev.page + 1 }))
        }
    }, [searchPaginationMeta, searchLoading])

    // Detect changes in the search box and reset various elements to empty out the search results
    const handleQueryChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        treeManager.clearTree()
        actions.setRefs({
            dimension,
            destination: AnalyticsRefDestination.SEARCH,
            data: [],
            paginationMeta: { currentPage: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 }
        })
        setSearchParams((prev) => ({ ...prev, query: event.target.value, page: 1 }))
    }

    const handleFieldMapperChange = (e) => {
        const newFieldMapper = availableMappers.find((fm) => fm.id === e.target.value)
        if (newFieldMapper) {
            treeManager.uncommitAll()
            setFieldMapperConfig(newFieldMapper)
        }
    }

    const checkLowestChildren = (source: TreeItem<T>, depth: number) => {
        if (source.order.length) {
            source.order.forEach((value) => {
                const children = source.children.get(value)
                if (children !== undefined) {
                    checkLowestChildren(children, depth + 1)
                }
            })
        } else {
            treeManager.stage({ source: source.source, depth: depth - 1 })
        }
    }

    const handleSelectAll = () => {
        checkLowestChildren(treeManager.tree, 0)
    }

    const handleAnyLabsToggle = () => {
        if (!anyLabs) {
            setAnyLabToggleCommitted(treeManager.committed)
            handleRemoveAll()
        } else {
            if (anyLabToggleCommitted) {
                anyLabToggleCommitted?.forEach((x) => {
                    treeManager.commit(x)
                })
            }
        }
        setAnyLabs(!anyLabs)
    }

    const handleRemoveAll = () => {
        treeManager.uncommitAll()
    }

    const renderDimensionDisclaimer = useCallback(() => {
        if (fieldMapperConfig.searchInfo) {
            return (
                <Grid item>
                    <Box fontSize={14}>{fieldMapperConfig.searchInfo}</Box>
                </Grid>
            )
        } else {
            return null
        }
    }, [fieldMapperConfig])

    const renderFieldMapperSelection = (mappers: RefFieldMapperConfig<T>[], hasSelections: boolean, restoreLoading: boolean) => {
        if (mappers.length > 1) {
            return (
                <Grid item width='100%'>
                    <FormControl>
                        <RadioGroup
                            defaultValue={availableMappers[0].id}
                            name='field-mapper-selector'
                            row
                            value={fieldMapperConfig.id}
                            onChange={handleFieldMapperChange}
                        >
                            {availableMappers.map((fm) => {
                                const radioDisabled = restoreLoading || (hasSelections && fm.id !== fieldMapperConfig.id)
                                return (
                                    <FormControlLabel
                                        key={fm.id}
                                        value={fm.id}
                                        control={<Radio disabled={radioDisabled} size='small' />}
                                        label={fm.label}
                                    ></FormControlLabel>
                                )
                            })}
                        </RadioGroup>
                    </FormControl>
                </Grid>
            )
        } else {
            return null
        }
    }

    const disabled = !anyLabs && treeManager.committed.length === 0
    const hasSelections = treeManager.committed.length > 0
    let searchBoxMessage = ''
    if (!searchLoading) {
        if (searchParams.query.length > 0 && searchPaginationMeta.currentPage !== 0 && refs.length === 0) {
            searchBoxMessage = fieldMapperConfig.mapper.noResultsMessage
        } else if (!disableRequireSearch && searchParams.query.length < MIN_SEARCH_LENGTH) {
            searchBoxMessage = fieldMapperConfig.mapper.searchHintMessage
        }
    }
    if (anyLabs) {
        searchBoxMessage = t`Any labs selected.`
    }

    const dialogContent = (
        <DialogContent dividers>
            <Grid container spacing={1}>
                {renderFieldMapperSelection(availableMappers, hasSelections, restoreLoading)}
                {renderDimensionDisclaimer()}
                <Grid item xs display='flex' minWidth={0} flexDirection='column' data-testid='unselected'>
                    <Box mb={1} fontWeight='bold' color='secondary.contrastText'>
                        <Trans>Search Results</Trans>
                        {searchParams.query.length >= MIN_SEARCH_LENGTH && (
                            <Link mx={2} fontSize={10} onClick={handleSelectAll}>
                                <Trans>Select all</Trans>
                            </Link>
                        )}
                    </Box>
                    <Box position='relative'>
                        <TreeContainer loadCallback={handleNextPage}>
                            <CheckTree tree={treeManager.tree} manager={treeManager} />
                            {searchBoxMessage.length > 0 && (
                                <Box
                                    position='absolute'
                                    top={0}
                                    right={0}
                                    bottom={0}
                                    left={0}
                                    display='flex'
                                    alignItems='center'
                                    justifyContent='center'
                                    color='grey.600'
                                >
                                    {searchBoxMessage}
                                </Box>
                            )}
                        </TreeContainer>
                        {searchLoading && <PaneBackdrop />}
                    </Box>
                </Grid>

                <Grid item xs display='flex' minWidth={0} flexDirection='column' data-testid='selected'>
                    <Box mb={1} fontWeight='bold' color='secondary.contrastText'>
                        <Trans>Selected</Trans>
                        {(treeManager.committed.length > 0 || treeManager.staged.length > 0) && (
                            <Link mx={2} fontSize={10} onClick={handleRemoveAll}>
                                <Trans>Remove all</Trans>
                            </Link>
                        )}
                    </Box>
                    <TreeContainer>
                        {treeManager.committed.map((x) => {
                            const value = fieldMapperConfig.mapper.getItemValue(x.source, x.depth)
                            const label = fieldMapperConfig.mapper.getItemLabel(x.source, x.depth) || ''
                            const tooltip = fieldMapperConfig.mapper.getItemTooltip(x.source, x.depth) || ''
                            return (
                                <Box key={value} display='flex' alignItems='center' gap={0.5}>
                                    <IconButton
                                        size='small'
                                        sx={{ width: 14, height: 14 }}
                                        onClick={() => handleDelete(x)}
                                        aria-label={t`Delete ${label}`}
                                    >
                                        <Delete fontSize='small' />
                                    </IconButton>
                                    <Typography noWrap title={tooltip}>
                                        {label}
                                    </Typography>
                                </Box>
                            )
                        })}
                        {restoreLoading && <PaneBackdrop />}
                    </TreeContainer>
                </Grid>
            </Grid>
        </DialogContent>
    )

    const dialogActions = (
        <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Box>
                {searchPaginationMeta.totalPages !== 0 && (
                    <Box paddingLeft={2} data-testid='itemcount'>
                        <Trans>Showing</Trans>{' '}
                        {numberFormatter.format(
                            Math.min(searchPaginationMeta.itemsPerPage * searchPaginationMeta.currentPage || 0, searchPaginationMeta.totalItems)
                        )}{' '}
                        <Trans>of</Trans> {numberFormatter.format(searchPaginationMeta.totalItems)} <Trans>items</Trans>
                    </Box>
                )}
            </Box>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '20px',
                    justifyContent: 'flex-end',
                    alignItems: 'baseline',
                    margin: '10px'
                }}
            >
                {cancelButton && (
                    <Button onClick={handleCancel}>
                        <Trans>Cancel</Trans>
                    </Button>
                )}
                {backButton && (
                    <Button onClick={handleBack} disabled={backButtonDisabled}>
                        <Trans>Back</Trans>
                    </Button>
                )}
                <Box>
                    <Button variant='contained' startIcon={saveIcon} onClick={handleSave} disabled={!optionalFilters && disabled}>
                        {saveText}
                    </Button>
                </Box>
            </div>
        </DialogActions>
    )

    if (dialog) {
        return (
            <Dialog open maxWidth='xl' fullWidth data-testid='dialog'>
                <DialogTitle minHeight='3.5rem'>
                    <IconButton
                        aria-label={t`Close`}
                        onClick={handleCancel}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {props.criteriaType === CriteriaType.LabTest && !reportView && (
                        <Box mb={1} fontWeight='bold' color='secondary.contrastText'>
                            <Typography>
                                <input
                                    type='checkbox'
                                    checked={anyLabs}
                                    onChange={handleAnyLabsToggle}
                                    style={{ color: '#012D72', cursor: 'pointer' }}
                                />
                                <Trans>Any labs</Trans>
                            </Typography>
                        </Box>
                    )}
                    <Box width={500} maxWidth='100%' pr={4}>
                        <DebounceInput
                            sx={{ width: '100%' }}
                            className='search'
                            placeholder={fieldMapperConfig.mapper.searchPlaceholder}
                            debounceTimeout={500}
                            onChange={handleQueryChange}
                            element={SearchField}
                            value={searchParams.query}
                        />
                    </Box>
                </DialogTitle>
                {dialogContent}
                {dialogActions}
            </Dialog>
        )
    } else {
        return (
            <div data-testid='nondialogdiv'>
                <Box width={500} maxWidth='100%' pr={4} mb={3}>
                    {props.criteriaType === CriteriaType.LabTest && !reportView && (
                        <Box mb={1} fontWeight='bold' color='secondary.contrastText'>
                            <Typography>
                                <input
                                    type='checkbox'
                                    checked={anyLabs}
                                    onChange={handleAnyLabsToggle}
                                    style={{ color: '#012D72', cursor: 'pointer' }}
                                />
                                <Trans>Any labs</Trans>
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Box width={500} maxWidth='100%' pr={4}>
                    <DebounceInput
                        sx={{ width: '100%' }}
                        className='search'
                        placeholder={fieldMapperConfig.mapper.searchPlaceholder}
                        debounceTimeout={500}
                        onChange={handleQueryChange}
                        element={SearchField}
                        value={searchParams.query}
                        disabled={anyLabs}
                    />
                </Box>
                {dialogContent}
                {dialogActions}
            </div>
        )
    }
}

const SearchField: (props: TextFieldProps) => JSX.Element = (props: TextFieldProps) => {
    return (
        <TextField
            {...props}
            size='small'
            type='search'
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <SearchIcon />
                    </InputAdornment>
                )
            }}
        />
    )
}

const PaneBackdrop: FunctionComponent = () => (
    <Backdrop
        sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: (theme) => alpha(theme.palette.grey['400'], 0.5),
            color: 'gray.400'
        }}
        open
    >
        <CircularProgress color='inherit' />
    </Backdrop>
)

interface TreeContainerProps {
    loadCallback?: () => void
}

const TreeContainer: FunctionComponent<PropsWithChildren<TreeContainerProps>> = ({ children, loadCallback }) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const loadMoreRef = useRef<HTMLDivElement>(null)
    const loadMoreRefIsVisible = useOnScreen(loadMoreRef)
    const [userScrolled, setUserScrolled] = useState(false)

    // Handle wheel event to detect user scroll interactions
    const handleWheel = () => {
        if (!userScrolled) {
            setUserScrolled(true)
        }
    }

    useEffect(() => {
        const scrollElement = containerRef.current
        if (scrollElement) {
            scrollElement.addEventListener('wheel', handleWheel)
        }
        return () => {
            if (scrollElement) {
                scrollElement.removeEventListener('wheel', handleWheel)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (loadMoreRefIsVisible && userScrolled && loadCallback) {
            loadCallback()
            setUserScrolled(false)
        }
    }, [loadMoreRefIsVisible, userScrolled, setUserScrolled, loadCallback])

    return (
        <Box
            data-testid='container-ref'
            ref={containerRef}
            position='relative'
            width='100%'
            minWidth={0}
            height={320}
            flexGrow={1}
            px={2}
            py={1}
            border={1}
            borderRadius={1}
            borderColor='grey.300'
            sx={{ overflowX: 'hidden', overflowY: 'auto' }}
        >
            {children}
            {<div data-testid='observed-ref' ref={loadMoreRef}></div>}
        </Box>
    )
}
