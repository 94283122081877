import { GridSortDirection } from '@mui/x-data-grid'
import { CohortListItem } from '../../../state'

export function sortByCohortField(field: string, sort: GridSortDirection) {
    switch (field) {
        case 'name':
            return (a: CohortListItem, b: CohortListItem) => (sort === 'asc' ? a[field].localeCompare(b[field]) : b[field].localeCompare(a[field]))
        case 'cohortSize':
            return (a: CohortListItem, b: CohortListItem) => (sort === 'asc' ? (a[field] || 0) - (b[field] || 0) : (b[field] || 0) - (a[field] || 0))
        case 'updatedDttm':
        case 'createdDttm':
        case 'queryUpdatedDttm':
            return (a: CohortListItem, b: CohortListItem) =>
                sort === 'asc'
                    ? new Date(a[field]).getTime() - new Date(b[field]).getTime()
                    : new Date(b[field]).getTime() - new Date(a[field]).getTime()
    }
}
