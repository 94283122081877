import { t, Trans } from '@lingui/macro'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { CircularProgress, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { DataGrid, GridColDef, GridRowSpacingParams } from '@mui/x-data-grid'
import { CohortConditionDTO } from '@om1/falcon-api'
import { FrameworkButton } from '@om1/platform-components'
import { getStyledGrid } from '@om1/platform-ui-kit/src/styles'
import { PlatformPermissions, RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { rwaConditionsActions, RWAConditionsState } from '../state/rwa-conditions'
import { createAddRWAConditionModal } from './AddRWAConditionModal'
import { DeleteRWAConditionDialog } from './DeleteRWAConditionDialog'
import { createRWAConditionsPage } from './RWAConditionsPage'

const AddRWAConditionModal = createAddRWAConditionModal()

export type RWAConditionsPageComponentProps = RoutedFrameworkComponentProps<
    {},
    {},
    { rwaConditions: RWAConditionsState; permissions?: string[] | undefined },
    typeof rwaConditionsActions,
    {}
>

export const RWAConditionsPageComponent: FunctionComponent<RWAConditionsPageComponentProps> = ({ state, actions }) => {
    const { permissions } = state
    const [addRWAConditionModalVisible, setAddRWAConditionModalVisible] = useState(false)

    const handleAddRWAConditionModalOpen = () => {
        setAddRWAConditionModalVisible(true)
    }

    const handleAddRWAConditionModalClose = () => {
        setAddRWAConditionModalVisible(false)
    }

    const [deleteModalCondition, setDeleteModalCondition] = useState<CohortConditionDTO>()
    const handleDeleteHide = useCallback(() => setDeleteModalCondition(undefined), [])

    const StyledDataGrid = getStyledGrid(DataGrid) as typeof DataGrid

    const columns = useMemo<GridColDef<CohortConditionDTO>[]>(() => {
        const columns = [
            {
                field: 'cohortId',
                headerName: t`Cohort ID`,
                renderCell: (params) => (
                    <Box display='flex' flexDirection='column' gap={1} minWidth={0} maxWidth='100%'>
                        <Typography color='primary' fontWeight='bold' className='grid-content' noWrap>
                            <>{params.row.cohortId}</>
                        </Typography>
                    </Box>
                ),
                flex: 3,
                sortable: false,
                filterable: false
            },
            {
                field: 'powerbiFilterValue',
                headerName: t`PowerBI Filter Value`,
                renderCell: (params) => (
                    <Box display='flex' flexDirection='column' gap={1} minWidth={0} maxWidth='100%'>
                        <Typography color='primary' fontWeight='bold' className='grid-content' noWrap>
                            <>{params.row.powerbiFilterValue}</>
                        </Typography>
                    </Box>
                ),
                flex: 3,
                sortable: false,
                filterable: false
            },
            {
                field: 'powerbiFilterKey',
                headerName: t`PowerBI Filter Key`,
                renderCell: (params) => (
                    <Box display='flex' flexDirection='column' gap={1} minWidth={0} maxWidth='100%'>
                        <Typography color='primary' fontWeight='bold' className='grid-content' noWrap>
                            <>{params.row.powerbiFilterKey}</>
                        </Typography>
                    </Box>
                ),
                flex: 3,
                sortable: false,
                filterable: false
            }
        ]
        if (permissions?.includes(PlatformPermissions.DELETE_RWA_INSIGHTS_CONDITIONS)) {
            columns.push({
                field: 'delete',
                headerName: t`Delete`,
                renderCell: (params) => (
                    <Box
                        display='flex'
                        flexDirection='column'
                        gap={1}
                        minWidth={0}
                        maxWidth='100%'
                        paddingTop={0}
                        paddingBottom={0}
                        sx={{ border: 'none' }}
                    >
                        <IconButton
                            key='delete-condition'
                            color='primary'
                            aria-label={t`Delete`}
                            sx={{ backgroundColor: '#D6E4F2', color: '#012D72', borderRadius: '2px' }}
                            onClick={() => setDeleteModalCondition(params.row)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                ),
                flex: 3,
                sortable: false,
                filterable: false
            })
        }
        return columns
    }, [permissions])

    if (state.rwaConditions.loading) {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' flex='1'>
                <CircularProgress />
            </Box>
        )
    } else {
        return (
            <>
                <AddRWAConditionModal showAddRWAConditionModal={addRWAConditionModalVisible} closeModal={handleAddRWAConditionModalClose} />
                <Box display='flex' justifyContent='space-between' alignItems='center' flex='1'>
                    <h1>
                        <Trans>RWA Conditions Page</Trans>
                    </h1>
                    {permissions?.includes(PlatformPermissions.CREATE_RWA_INSIGHTS_CONDITIONS) && (
                        <FrameworkButton
                            variant='contained'
                            color='primary'
                            onClick={handleAddRWAConditionModalOpen}
                            sx={{ width: '180px' }}
                            aria-label={t`Create Cohort`}
                        >
                            <AddIcon sx={{ height: '18.5px', width: '18.5px', margin: '0.1rem', marginRight: '10px' }} />
                            <Trans>Create Condition</Trans>
                        </FrameworkButton>
                    )}
                </Box>
                {state.rwaConditions.conditions.length === 0 ? (
                    <Trans>No Conditions</Trans>
                ) : (
                    <Box>
                        <StyledDataGrid
                            autoHeight
                            disableColumnMenu
                            disableRowSelectionOnClick
                            rowHeight={80}
                            getRowSpacing={(params: GridRowSpacingParams) => {
                                return {
                                    top: params.isFirstVisible ? 0 : 4,
                                    bottom: params.isLastVisible ? 0 : 4
                                }
                            }}
                            columns={columns}
                            hideFooter={true}
                            rows={state.rwaConditions.conditions}
                        />
                    </Box>
                )}
                {deleteModalCondition && <DeleteRWAConditionDialog id={deleteModalCondition.id} actions={actions} onCancel={handleDeleteHide} />}
            </>
        )
    }
}

export type RWAConditionsPage = ReturnType<typeof createRWAConditionsPage>
