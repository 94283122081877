import { t } from '@lingui/macro'
import { AnalyticsRefDimension, CriteriaType, SpecialtyRef } from '../../../../state'
import { NestedRefFieldMapper, NestedRefGetterCallback, RefFieldMapperType } from './interfaces'

const getItemId: NestedRefGetterCallback<SpecialtyRef, string> = (item) => item.specialtyConceptId
const getItemParentId: NestedRefGetterCallback<SpecialtyRef> = (item) => item.parentSpecialtyConceptId
const getItemPathToRoot: NestedRefGetterCallback<SpecialtyRef, string[]> = (item) => (item.pathToRoot ? item.pathToRoot.split('.') : [])
const getItemLabel: NestedRefGetterCallback<SpecialtyRef> = (item) => item.specialty
const getItemValue: NestedRefGetterCallback<SpecialtyRef> = (item) => item.specialtyConceptId
const getItemTooltip: NestedRefGetterCallback<SpecialtyRef> = (item) => item.specialtyConceptId

export const SpecialtyRefFieldMapper: NestedRefFieldMapper<SpecialtyRef> = {
    type: RefFieldMapperType.Nested,
    dimension: AnalyticsRefDimension.SPECIALTY,
    criteriaType: CriteriaType.EhrNotes,
    table: 'patient_specialist_note',
    dateField: {
        first: 'first_record_date',
        last: 'last_record_date',
        any: 'any_record_date'
    },
    dateFieldInverse: {
        first_record_date: 'first',
        last_record_date: 'last',
        any_record_date: 'any'
    },
    disableRequireSearch: true,
    searchPlaceholder: (() => t`Search Provider Specialty`)(),
    noResultsMessage: (() => t`No results`)(),
    searchHintMessage: (() => t`Search to browse provider specialties`)(),
    filterFieldToRefField: { specialty: 'specialty', specialty_concept_id: 'specialtyConceptId' },
    filterFieldOrder: ['specialty_concept_id'],
    filterLabelOrder: ['specialty'],
    getItemId,
    getItemParentId,
    getItemPathToRoot,
    getItemLabel,
    getItemValue,
    getItemTooltip
}
