import { css } from '@mui/material'
import { platformTheme } from '../theme'

const BodyText = css`
    color: '#FFFFFF';
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
`

export const BodyBoldStyle = css`
    ${BodyText}
    font-weight: ${platformTheme.typography.fontWeightBold};
`

export const BodySemiboldStyle = css`
    ${BodyText}
    font-weight: ${platformTheme.typography.fontWeightMedium};
`

export const BodyRegularStyle = css`
    ${BodyText}
    font-weight: ${platformTheme.typography.fontWeightRegular};
`
