/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { FunctionComponent } from 'react'
import { DropDownMenu } from './CohortCriterionComponent'
import { CriterionNode } from '../../../state'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { t, Trans } from '@lingui/macro'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { MenuItem } from '@mui/material'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

export interface CohortCriterionOptionsMenuProps {
    node: CriterionNode
    editable: boolean
    dragStateEnabled: boolean
    handleCriteriaEdit: () => void
    handleCriteriaCopy: () => void
    handleCriteriaDelete: () => void
}

/**
 * Options menu for cohort criteria
 */
export const CohortCriterionOptionsMenu: FunctionComponent<CohortCriterionOptionsMenuProps> = ({
    node,
    editable,
    dragStateEnabled,
    handleCriteriaEdit,
    handleCriteriaCopy,
    handleCriteriaDelete
}) => {
    if (!editable) {
        return null
    }

    if (dragStateEnabled) {
        return (
            <DropDownMenu
                toggleId={`criteria-menu-toggle-${node.id}`}
                toggleTestId='criteria-options-menu-toggle'
                toggleIcon={<MoreHorizIcon />}
                toggleLabel={t`Criteria Options`}
                menuId={`criteria-menu-${node.id}`}
                menuTestId='criteria-options-menu'
            >
                <MenuItem onClick={handleCriteriaEdit} sx={{ gap: 0.5 }}>
                    <EditOutlinedIcon />
                    <Trans>Edit</Trans>
                </MenuItem>
                <MenuItem onClick={handleCriteriaCopy} sx={{ gap: 0.5 }}>
                    <ContentCopyOutlinedIcon />
                    <Trans>Copy</Trans>
                </MenuItem>
                <MenuItem onClick={handleCriteriaDelete} sx={{ gap: 0.5 }}>
                    <DeleteOutlineIcon />
                    <Trans>Delete</Trans>
                </MenuItem>
            </DropDownMenu>
        )
    } else {
        return (
            <DropDownMenu
                toggleId={`criteria-menu-toggle-${node.id}`}
                toggleTestId='criteria-options-menu-toggle'
                toggleIcon={<MoreHorizIcon />}
                toggleLabel={t`Criteria Options`}
                menuId={`criteria-menu-${node.id}`}
                menuTestId='criteria-options-menu'
            >
                <MenuItem onClick={handleCriteriaEdit} sx={{ gap: 0.5 }}>
                    <EditOutlinedIcon />
                    <Trans>Edit</Trans>
                </MenuItem>
            </DropDownMenu>
        )
    }
}
