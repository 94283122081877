import { initialInsightsLibraryState, InsightsLibraryState } from '../platform-settings-state'
import { InsightsLibraryAction, InsightsLibraryActionTypes } from './insights-library-actions'

export function insightsLibraryReducer(previousState: InsightsLibraryState | undefined, action: InsightsLibraryAction): InsightsLibraryState {
    const state = previousState || initialInsightsLibraryState
    switch (action.type) {
        case InsightsLibraryActionTypes.SET_INSIGHTS_LIBARY_REPORTS:
            return { ...state, cohortInsightsReports: action.payload.cohortInsightsReports }
        case InsightsLibraryActionTypes.SET_INSIGHTS_LIBARY_REPORTS_LOADING:
            return { ...state, loading: action.payload.loading }
        case InsightsLibraryActionTypes.DELETE_INSIGHTS_LIBRARY_REPORT_SUCCESS:
            return {
                ...state,
                cohortInsightsReports: state.cohortInsightsReports.filter((report) => report.id !== action.payload.organization_report_id)
            }
        case InsightsLibraryActionTypes.CREATE_INSIGHTS_LIBRARY_REPORT_SUCCESS:
            return {
                ...state,
                cohortInsightsReports: [...state.cohortInsightsReports, action.payload.cohortInsightsReport]
            }
        default: {
            return state
        }
    }
}
