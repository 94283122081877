import { RWAConditionsActions, RWAConditionsActionTypes } from './rwa-conditions-actions'
import { initialRWAConditionsState, RWAConditionsState } from './rwa-conditions-state'

export function rwaConditionsReducer(previousState: RWAConditionsState | undefined, action: RWAConditionsActions): RWAConditionsState {
    const state = previousState || initialRWAConditionsState
    switch (action.type) {
        case RWAConditionsActionTypes.SET_RWA_INSIGHTS_REPORTS:
            return { ...state, conditions: action.payload.conditions }
        case RWAConditionsActionTypes.CREATE_RWA_CONDITION_SUCCESS:
            return { ...state, conditions: [...state.conditions, action.payload] }
        case RWAConditionsActionTypes.DELETE_RWA_CONDITION_SUCCESS:
            return { ...state, conditions: state.conditions.filter((c) => c.id !== action.payload.id) }
        case RWAConditionsActionTypes.SET_RWA_INSIGHTS_REPORTS_LOADING:
            return { ...state, loading: action.payload.loading }
        default: {
            return state
        }
    }
}
