/* eslint-disable string-to-lingui/missing-lingui-transformation */
import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import { CohortInsightsReportDTO } from '@om1/falcon-api'
import { PlatformSettingsState } from '@om1/platform-settings-module'
import { rwaInsightsReportActions, RWAInsightsReportsState } from '@om1/platform-settings-module/src/state/rwa-insights-reports'
import { connectRedux, FrameworkComponentProps } from '@om1/platform-utils'
import { FunctionComponent, useEffect, useMemo } from 'react'
import { insightsLibraryActions } from '../state'

const PreviewImage = styled('img')(({ theme }) => ({
    width: '30%',
    border: `4px solid white`,
    borderRadius: '2px',
    boxShadow: '0 0 3px #ccc',
    marginTop: '10px',
    ':hover': {
        cursor: 'pointer',
        outline: '2px solid white',
        boxShadow: '0 0 10px #ccc'
    },
    maxWidth: '750px'
}))

export type InsightsLibraryReportComponentProps = FrameworkComponentProps<
    { rwaInsightsReports: RWAInsightsReportsState },
    typeof rwaInsightsReportActions & typeof insightsLibraryActions,
    { cohortInsightsReport: CohortInsightsReportDTO; fetchEmbedToken: (id: string) => Promise<void> }
>

export const InsightsLibraryReportComponent: FunctionComponent<InsightsLibraryReportComponentProps> = ({ state, actions, props }) => {
    useEffect(() => {
        actions.getRwaInsightsReport({ id: props.cohortInsightsReport.id })
    }, [actions, props.cohortInsightsReport.id])

    const insightReport = useMemo(
        () => state.rwaInsightsReports.insightReports.find((report) => report.id === props.cohortInsightsReport.insightsReportId),
        [state.rwaInsightsReports.insightReports, props.cohortInsightsReport.insightsReportId]
    )

    return (
        <>
            <Typography variant='h5'>{insightReport?.name}</Typography>
            {insightReport && (
                <Box
                    sx={{
                        marginLeft: '15px',
                        marginBottom: '15px'
                    }}
                >
                    <PreviewImage src={insightReport.imageUri} onClick={() => props.fetchEmbedToken(props.cohortInsightsReport.id)} />
                </Box>
            )}
        </>
    )
}

export function createInsightsLibraryReportComponent<TState extends { platformSettings: PlatformSettingsState }>() {
    return connectRedux(
        InsightsLibraryReportComponent,
        (state: TState) => {
            return { rwaInsightsReports: state.platformSettings.rwaInsightsReports }
        },
        { ...rwaInsightsReportActions, ...insightsLibraryActions }
    )
}

export type InsightsLibraryReport = ReturnType<typeof createInsightsLibraryReportComponent>
