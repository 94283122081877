import { faChartGantt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import GroupsIcon from '@mui/icons-material/Groups'
import HealingOutlinedIcon from '@mui/icons-material/HealingOutlined'
import LocalPharmacyOutlinedIcon from '@mui/icons-material/LocalPharmacyOutlined'
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { SvgIconProps } from '@mui/material'
import React from 'react'
import { CriteriaType } from '../../state'

interface CriteriaTypeIconProps extends SvgIconProps {
    criteriaType: CriteriaType
    isPill?: boolean
}

/**
 * An icon representation of a criteria type.
 */
export const CriteriaTypeIcon: React.FunctionComponent<CriteriaTypeIconProps> = ({ criteriaType, ...props }) => {
    switch (criteriaType) {
        case CriteriaType.PatientAttributes:
            return <AssignmentIndOutlinedIcon {...props} />
        case CriteriaType.Diagnosis:
            return <AssignmentTurnedInOutlinedIcon {...props} />
        case CriteriaType.Procedure:
            return <HealingOutlinedIcon {...props} />
        case CriteriaType.LabTest:
            return <ScienceOutlinedIcon {...props} />
        case CriteriaType.Medication:
            return <LocalPharmacyOutlinedIcon {...props} />
        case CriteriaType.Observation:
            return <VisibilityOutlinedIcon {...props} />
        case CriteriaType.EhrNotes:
            return <AssignmentOutlinedIcon {...props} />
        case CriteriaType.ObservationPeriod:
            return <FontAwesomeIcon style={{ marginLeft: '2px', marginRight: '2px' }} icon={faChartGantt} width={14} />
        case CriteriaType.ExternalCohort:
            return <GroupsIcon {...props} />
        default:
            return null
    }
}
