import { notificationActions } from '@om1/platform-notifications'
import { connectRedux } from '@om1/platform-utils'
import { ReactNode } from 'react'
import { CohortDuplicateSuccessToastComponent, CohortDuplicateSuccessToastComponentProps } from './CohortDuplicateSuccessToastComponent'

export function createCohortDuplicateSuccessToast() {
    return connectRedux(CohortDuplicateSuccessToastComponent, () => ({}), notificationActions)
}

export const renderCohortDuplicateSuccessToast = (props: CohortDuplicateSuccessToastComponentProps['props']): ReactNode => {
    const Component = createCohortDuplicateSuccessToast()
    return <Component {...props} />
}

export type CohortDuplicateSuccessToast = ReturnType<typeof createCohortDuplicateSuccessToast>
