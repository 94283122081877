/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Trans } from '@lingui/macro'
import CloseIcon from '@mui/icons-material/Close'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormControlLabelProps,
    FormGroup,
    Grid,
    IconButton
} from '@mui/material'
import { FrameworkComponentProps } from '@om1/platform-utils'
import { useState } from 'react'
import { QueryFilterBase, cohortBlocksEditActions } from '../../../state'
import { mapValueToDataType } from './CohortCriterionComponent'

export type ObservationPeriodDialogComponentProps = FrameworkComponentProps<
    {},
    typeof cohortBlocksEditActions,
    {
        onSave: (dataType: DataType) => void
        onCancel: () => void
        initialValue: QueryFilterBase
    }
>

// eslint-disable-next-line string-to-lingui/missing-lingui-transformation
export type DataType =
    | 'EHR Linked Medical and Rx Claims Data'
    | 'EHR Linked Medical or Rx Claims Data'
    | 'EHR Linked Medical Claims Data'
    | 'EHR Linked Rx Claims Data'
    | 'EHR Data'
    | 'Medical Claims Data'
    | 'Rx Claims Data'

export const ObservationPeriodDialogComponent = ({ props }: ObservationPeriodDialogComponentProps) => {
    const [value, setValue] = useState<DataType>(mapValueToDataType(props.initialValue.values))

    const CheckTreeLabel = ({ label, ...props }: FormControlLabelProps) => {
        return (
            <FormGroup>
                <FormControlLabel
                    sx={{ marginLeft: 0, marginTop: 0.25, marginBottom: 0.25 }}
                    label={
                        <Box component='span' pl={0.5}>
                            {label}
                        </Box>
                    }
                    {...props}
                />
            </FormGroup>
        )
    }

    return (
        <Dialog open maxWidth='xs' fullWidth>
            <DialogTitle>
                <Trans>Select Data Types needed in the Observation Period</Trans>
            </DialogTitle>
            <IconButton
                aria-label='close'
                onClick={props.onCancel}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500]
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ pt: 0 }}>
                <Box display='flex' flexDirection='row' alignItems='center'>
                    <Box
                        px={1}
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        fontSize={12}
                        sx={{
                            px: 1,
                            color: 'text.primary',
                            textAlign: 'left'
                        }}
                    >
                        <Grid container flexDirection='column'>
                            <p>
                                <b>
                                    <Trans>Linked Data Types</Trans>
                                </b>
                            </p>
                            {/* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */}
                            {[
                                'EHR Linked Medical and Rx Claims Data',
                                'EHR Linked Medical or Rx Claims Data',
                                'EHR Linked Medical Claims Data',
                                'EHR Linked Rx Claims Data'
                            ].map((item) => (
                                <Grid item xs='auto' key={item}>
                                    <CheckTreeLabel
                                        control={
                                            <input
                                                value={item}
                                                type='checkbox'
                                                checked={value === item}
                                                onChange={(e) => {
                                                    setValue(e.target.value as DataType)
                                                }}
                                            />
                                        }
                                        label={item}
                                    />
                                </Grid>
                            ))}
                            <p>
                                <b>
                                    <Trans>Data Types</Trans>
                                </b>
                            </p>
                            {/* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */}
                            {['EHR Data', 'Medical Claims Data', 'Rx Claims Data'].map((item) => (
                                <Grid item xs='auto' key={item}>
                                    <CheckTreeLabel
                                        control={
                                            <input
                                                value={item}
                                                type='checkbox'
                                                checked={value === item}
                                                onChange={(e) => {
                                                    setValue(e.target.value as DataType)
                                                }}
                                            />
                                        }
                                        label={item}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant='text' color='primary' onClick={props.onCancel}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button
                    variant='contained'
                    type='submit'
                    disabled={value === undefined}
                    onClick={() => {
                        props.onSave(value)
                    }}
                >
                    <Trans>Save</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    )
}
