import { ActionsUnion, createAction } from '@om1/platform-utils'

export enum PlatformConfigActionTypes {
    SET_BUILD_NUMBER = '@@platformConfigs/setBuildNumber',
    SET_ENVIRONMENT_TAG = '@@platformConfigs/setEnvironmentTag',
    SET_SEMANTIC_VERSION = '@@platformConfigs/setSemanticVersion'
}

export const platformConfigActions = {
    setBuildNumber: (buildNumber: string) => createAction(PlatformConfigActionTypes.SET_BUILD_NUMBER, { buildNumber: buildNumber }),
    setEnvironmentTag: (environmentTag: string) => createAction(PlatformConfigActionTypes.SET_ENVIRONMENT_TAG, { environmentTag: environmentTag }),
    setSemanticVersion: (version: string) => createAction(PlatformConfigActionTypes.SET_SEMANTIC_VERSION, { version: version })
}

export type PlatformConfigActions = ActionsUnion<typeof platformConfigActions>
