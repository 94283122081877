import { notificationActions } from '@om1/platform-notifications'
import { call, put } from 'redux-saga/effects'
import { ApiError, CoreService, VersionDTO } from '../codegen/client'
import { falconApiActions } from '../state'

export function createGetFalconVersionSaga() {
    return function* (_action: ReturnType<typeof falconApiActions.getFalconVersion>) {
        try {
            const response: VersionDTO = yield call(CoreService.versionVersionGet)
            yield put(falconApiActions.setFalconVersion(response.version, response.alembicVersion, response.analyticsDatabase))
        } catch (error: unknown) {
            const apiError: ApiError = error as unknown as ApiError
            yield put(notificationActions.error(apiError.message))
        }
    }
}

export type GetFalconVersionSaga = ReturnType<typeof createGetFalconVersionSaga>
