import { CohortState } from '@om1/cohort-module'
import { cohortListActions } from '@om1/cohort-module/src/state'
import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { rwaConditionsActions } from '../state/rwa-conditions'
import { AddRWAConditionModalComponent } from './AddRWAConditionModalComponent'

export function createAddRWAConditionModal<TState extends { cohort: CohortState }>() {
    return connectRedux(
        withRouter(AddRWAConditionModalComponent, { namespace: 'rwa-conditions', dataFetchAction: 'cohortsGetSystemCohorts' }),
        (state: TState) => state.cohort,
        { ...cohortListActions, ...rwaConditionsActions }
    )
}

export type AddRWAConditionModal = ReturnType<typeof createAddRWAConditionModal>
