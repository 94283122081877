import { RefFieldMapper } from './interfaces'

export function extractRefLabels<T>(refs: T[], fieldMapper: RefFieldMapper<T>) {
    const refFieldToFilterField = invertObjectKeys(fieldMapper.filterFieldToRefField)
    const tableRefLabels: Record<string, Record<string, string | null>> = {}
    for (const ref of refs) {
        for (const refField in refFieldToFilterField) {
            const filterField = refFieldToFilterField[refField]
            const depth = fieldMapper.filterFieldOrder.indexOf(filterField)
            const label = fieldMapper.getItemLabel(ref, depth)
            const value = fieldMapper.getItemValue(ref, depth)
            if (!tableRefLabels[filterField]) {
                tableRefLabels[filterField] = {}
            }
            if (label && value) {
                tableRefLabels[filterField][value] = label
            }
        }
    }
    return { [fieldMapper.table]: tableRefLabels }
}

export function extractFieldLabel<T>(field: string, fieldMapper: RefFieldMapper<T>) {
    const fieldIndex = fieldMapper.filterFieldOrder.indexOf(field)

    if (fieldIndex === -1) {
        return ''
    }

    return fieldMapper.filterLabelOrder[fieldIndex]
}

function invertObjectKeys(object: {}) {
    return Object.fromEntries(Object.entries(object).map(([a, b]) => [b, a]))
}
