/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PowerBIEmbedTokenResponse } from '../models/PowerBIEmbedTokenResponse'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class PowerBiService {
    /**
     * Get Power Bi Report
     * @returns PowerBIEmbedTokenResponse Successful Response
     * @throws ApiError
     */
    public static getPowerBiReportPowerBiEmbedCohortInsightsReportIdGet({
        cohortInsightsReportId
    }: {
        cohortInsightsReportId: string
    }): CancelablePromise<PowerBIEmbedTokenResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/power-bi/embed/{cohort_insights_report_id}',
            path: {
                cohort_insights_report_id: cohortInsightsReportId
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
}
