import { CohortDTO, ExplorerCohortsService, PaginatedDictDTO_str_Any_ } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import camelcaseKeys from 'camelcase-keys'
import { call, put, select } from 'redux-saga/effects'
import { CohortState, datasetsActions } from '../state'

export function createGetDatasetsSaga() {
    return function* () {
        yield put(datasetsActions.setDatasetsLoading({ loading: true }))
        try {
            const datasets = yield select((store: { cohort: CohortState }) => store.cohort.datasets)
            if (datasets.data.length === 0) {
                const paginatedDictDTOAny: PaginatedDictDTO_str_Any_ = yield call(ExplorerCohortsService.indexExplorerCohortsGet, {
                    isSystem: true,
                    page: 1,
                    limit: 100
                })
                const datasets: CohortDTO[] = paginatedDictDTOAny.data as CohortDTO[]
                yield put(datasetsActions.setDatasets({ datasets: camelcaseKeys(datasets) }))
            }
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(datasetsActions.setDatasetsLoading({ loading: false }))
        }
    }
}
