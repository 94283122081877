import { CohortDTO, CohortPatchDTO, ExplorerCohortsService } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { isEqual } from 'lodash'
import { call, put, select } from 'redux-saga/effects'
import { CohortState, cohortCommonActions, cohortEditActions } from '../state'

export function createUpdateCohortSaga() {
    return function* (action: ReturnType<typeof cohortEditActions.cohortUpdate>) {
        try {
            yield put(cohortEditActions.cohortUpdateLoadingSet({ loading: true }))
            const cohort = yield select((store: { cohort: CohortState }) => store.cohort.edit.base.cohort)
            const cohortDTO: CohortDTO = yield call(ExplorerCohortsService.patchExplorerCohortsCohortIdPatch, {
                cohortId: action.payload.cohort.id!,
                requestBody: action.payload.cohort as CohortPatchDTO
            })
            if (action.payload.refreshCohortMeta) {
                yield put(
                    cohortEditActions.cohortSet({
                        cohort: {
                            ...cohort,
                            name: cohortDTO.name,
                            description: cohortDTO.description,
                            isPrivate: cohortDTO.isPrivate,
                            analyticsDataset: cohortDTO.analyticsDataset,
                            updatedDttm: cohortDTO.updatedDttm,
                            cohortSize: cohortDTO.cohortSize,
                            queryUpdatedDttm: cohortDTO.queryUpdatedDttm
                        }
                    })
                )
                if (!isEqual(cohort.analyticsDataset, cohortDTO.analyticsDataset)) {
                    yield put(cohortCommonActions.cohortSizeGet({ cohortId: cohortDTO.id, cohortName: cohortDTO.name }))
                }
            }
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(cohortEditActions.cohortUpdateLoadingSet({ loading: false }))
        }
    }
}
