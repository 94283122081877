import { DropResult, TextDropItem, useDrop } from '@react-aria/dnd'
import { RefObject } from 'react'
import { CohortNodeSourceAddress, CriteriaType } from '../../../state'

export interface UseCriteriaDropZoneParams {
    ref: RefObject<HTMLDivElement>
    onAdd: (type: CriteriaType) => void
    onCopy: (source: CohortNodeSourceAddress) => void
    onMove: (source: CohortNodeSourceAddress) => void
}
export const useCriteriaDropZone = (props: UseCriteriaDropZoneParams): DropResult => {
    return useDrop({
        ref: props.ref,
        async onDrop(event) {
            if (event.dropOperation === 'move') {
                const textItems = event.items.filter((item) => item.kind === 'text' && item.types.has('custom')) as TextDropItem[]
                const values = await Promise.all(textItems.map((item) => item.getText('custom')))
                if (values.length > 0) {
                    const source: CohortNodeSourceAddress = JSON.parse(values[0])
                    props.onMove(source)
                }
            }
            if (event.dropOperation === 'copy') {
                const textItems = event.items.filter((item) => item.kind === 'text' && item.types.has('custom')) as TextDropItem[]
                const values = await Promise.all(textItems.map((item) => item.getText('custom')))

                if (values.length > 0) {
                    const payload = JSON.parse(values[0])
                    if (isNewCriteria(payload)) {
                        props.onAdd(payload.type)
                    } else if (isExistingCriteria(payload)) {
                        props.onCopy(payload)
                    }
                }
            }
        }
    })
}

function isNewCriteria(payload: any): payload is { type: CriteriaType } {
    return payload !== undefined && payload.type !== undefined
}

function isExistingCriteria(payload: any): payload is CohortNodeSourceAddress {
    return payload !== undefined && payload.nodeId !== undefined
}
