import { handleApiError } from '@om1/falcon-api/utils'
import { call, put, select } from 'redux-saga/effects'
import { filtersToBlocksRoot } from '../components/edit/utils/filter-import'
import { CohortListItem, CohortState, cohortBlocksEditActions, cohortEditActions } from '../state'

export function createParseCohortTreeSaga() {
    return function* (action: ReturnType<typeof cohortEditActions.cohortParseTree>) {
        const storeCohort: CohortListItem = action.payload.cohort
        try {
            if (storeCohort.query && Array.isArray(storeCohort.query.filters) && storeCohort.query.filters.length > 0) {
                yield call(filtersToBlocksRoot, JSON.parse(JSON.stringify(storeCohort.query.filters)), cohortBlocksEditActions)
            } else {
                yield call(filtersToBlocksRoot, [{ except: [{ and: [{ or: [] }] }, { and: [] }] }], cohortBlocksEditActions)
            }
        } catch (error) {
            yield handleApiError(error)
        } finally {
            const builtBlocks = yield select((store: { cohort: CohortState }) => store.cohort.edit.blocks.tree)
            yield put(cohortBlocksEditActions.cohortSetLastSavedBlocks({ blocks: builtBlocks }))
            yield put(cohortBlocksEditActions.getRefLabels({ query: storeCohort.query }))
        }
    }
}

export type ParseCohortTreeSaga = ReturnType<typeof createParseCohortTreeSaga>
