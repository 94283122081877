import { ActionsUnion, createAction } from '@om1/platform-utils'
import { AnalyticsData, ReportAnalyticsType } from './cohort-analytics-state'

export enum CohortAnalyticsActionTypes {
    GET_COHORT = '@@cohort/analytics/get-cohort',
    DELETE_COHORT = '@@cohort/analytics/delete-cohort',
    GET_DIMENSIONS = '@@cohort/analytics/get-dimensions',
    SET_DIMENSIONS = '@@cohort/analytics/set-dimensions',
    SET_DIMENSIONS_ERROR = '@@cohort/analytics/set-dimensions-error'
}

export const cohortAnalyticsActions = {
    deleteCohort: (payload: { cohortId: string }) => createAction(CohortAnalyticsActionTypes.DELETE_COHORT, payload),
    getDimensions: (payload: { cohortId: string; reportType: ReportAnalyticsType; taskId: string }) =>
        createAction(CohortAnalyticsActionTypes.GET_DIMENSIONS, payload),
    setDimensions: (payload: { cohortId: string; reportType: ReportAnalyticsType; taskId: string; data: AnalyticsData }) =>
        createAction(CohortAnalyticsActionTypes.SET_DIMENSIONS, payload),
    setDimensionsError: (payload: { cohortId: string; reportType: ReportAnalyticsType; taskId: string }) =>
        createAction(CohortAnalyticsActionTypes.SET_DIMENSIONS_ERROR, payload)
}
export type CohortAnalyticsActions = ActionsUnion<typeof cohortAnalyticsActions>
