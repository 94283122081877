import { ExplorerReportsService, ReportCreateDTO, ReportDTO, SectionDTO_Input, SectionDTO_Output } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put } from 'redux-saga/effects'
import { getReportSectionsFromReportType } from '../components/edit/utils/report-utils'
import { ReportQueryFilters } from '../state'
import { Report, ReportType as StateReportType, reportsActions } from '../state/reports'

export function createCreateReportNoFilterSaga() {
    return function* (action: ReturnType<typeof reportsActions.reportNoFilterCreate>) {
        const {
            payload: { cohortId, reportType }
        } = action
        try {
            yield put(reportsActions.reportsLoadingSet({ loading: true }))
            const reportCreateDTO: ReportCreateDTO = {
                cohortId,
                reportType,
                sections: getReportSectionsFromReportType(reportType) as SectionDTO_Input[]
            }

            const reportDTO: ReportDTO = yield call(ExplorerReportsService.createExplorerReportsPost, {
                requestBody: reportCreateDTO
            })

            const report: Report = {
                id: reportDTO.id,
                reportType: reportDTO.reportType as StateReportType,
                cohortId: reportDTO.cohortId,
                sections: reportDTO.sections.map((section: SectionDTO_Output) => ({
                    id: section.id,
                    queries: section.queries as ReportQueryFilters[]
                })),
                createdDttm: reportDTO.createdDttm,
                updatedDttm: reportDTO.updatedDttm,
                createdById: reportDTO.createdById,
                lastUpdatedBy: reportDTO.updatedById,
                organizationId: reportDTO.organizationId
            }
            yield put(reportsActions.reportAdd({ report: report }))
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(reportsActions.reportsLoadingSet({ loading: false }))
        }
    }
}
