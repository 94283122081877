/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CohortConditionCreateDTO } from '../models/CohortConditionCreateDTO'
import type { CohortConditionDTO } from '../models/CohortConditionDTO'
import type { CohortConditionPatchDTO } from '../models/CohortConditionPatchDTO'
import type { PaginatedDTO_CohortConditionDTO_ } from '../models/PaginatedDTO_CohortConditionDTO_'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class CohortConditionsService {
    /**
     * Index
     * @returns PaginatedDTO_CohortConditionDTO_ Successful Response
     * @throws ApiError
     */
    public static indexRwaInsightsCohortConditionsGet({
        cohortId,
        page = 1,
        limit = 10
    }: {
        cohortId?: string | null
        /**
         * Page number for pagination, starting at 1
         */
        page?: number
        /**
         * Number of items per page, maximum 1000
         */
        limit?: number
    }): CancelablePromise<PaginatedDTO_CohortConditionDTO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rwa_insights/cohort_conditions',
            query: {
                cohort_id: cohortId,
                page: page,
                limit: limit
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Create
     * @returns CohortConditionDTO Successful Response
     * @throws ApiError
     */
    public static createRwaInsightsCohortConditionsPost({
        requestBody
    }: {
        requestBody?: CohortConditionCreateDTO
    }): CancelablePromise<CohortConditionDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rwa_insights/cohort_conditions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Get
     * @returns CohortConditionDTO Successful Response
     * @throws ApiError
     */
    public static getRwaInsightsCohortConditionsConditionIdGet({ conditionId }: { conditionId: string }): CancelablePromise<CohortConditionDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rwa_insights/cohort_conditions/{condition_id}',
            path: {
                condition_id: conditionId
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Patch
     * @returns CohortConditionDTO Successful Response
     * @throws ApiError
     */
    public static patchRwaInsightsCohortConditionsConditionIdPatch({
        conditionId,
        requestBody
    }: {
        conditionId: string
        requestBody: CohortConditionPatchDTO
    }): CancelablePromise<CohortConditionDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/rwa_insights/cohort_conditions/{condition_id}',
            path: {
                condition_id: conditionId
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Delete
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteRwaInsightsCohortConditionsConditionIdDelete({
        conditionId
    }: {
        conditionId: string
    }): CancelablePromise<Record<string, string>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/rwa_insights/cohort_conditions/{condition_id}',
            path: {
                condition_id: conditionId
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
}
