import { Box } from '@mui/material'
import React, { PropsWithChildren } from 'react'

export interface CohortCriterionActionsMenuProps {
    menuId: string
    menuTestId: string
    disabled?: boolean
}

export const CohortCriterionActionsMenu: React.FunctionComponent<PropsWithChildren<CohortCriterionActionsMenuProps>> = (props) => {
    if (props.disabled) {
        return null
    }

    return (
        <Box id={props.menuId} data-testid={props.menuTestId} display='flex' flexDirection='row' columnGap={0.5}>
            {props.children}
        </Box>
    )
}
