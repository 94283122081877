import { t } from '@lingui/macro'
import { AnalyticsRefDimension, CriteriaType, PhenotypeRef } from '../../../../state'
import { DepthRefFieldMapper, DepthRefGetterCallback, RefFieldMapperType } from './interfaces'

const getItemLabel: DepthRefGetterCallback<PhenotypeRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.phenotype
    }
}

const getItemValue: DepthRefGetterCallback<PhenotypeRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.phenotype
    }
}

const getItemTooltip: DepthRefGetterCallback<PhenotypeRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return getItemLabel(item, depth)
    }
}

export const PhenotypeRefFieldMapper: DepthRefFieldMapper<PhenotypeRef> = {
    type: RefFieldMapperType.Depth,
    dimension: AnalyticsRefDimension.PHENOTYPE,
    criteriaType: CriteriaType.ExternalCohort,
    table: 'patient_external_cohort',
    dateField: {},
    dateFieldInverse: {},
    disableRequireSearch: true,
    searchPlaceholder: (() => t`Search PhenOM Characteristics`)(),
    noResultsMessage: (() => t`No results`)(),
    searchHintMessage: (() => t`Search to browse PhenOM characteristics`)(),
    filterFieldToRefField: { phenotype: 'phenotype' },
    filterFieldOrder: ['phenotype'],
    filterLabelOrder: ['phenotype'],
    getItemLabel,
    getItemValue,
    getItemTooltip
}
