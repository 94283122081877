import { Trans } from '@lingui/macro'
import { Box, Skeleton } from '@mui/material'
import { FrameworkComponentProps } from '@om1/platform-utils'
import { ReactNode, useMemo } from 'react'
import { CriteriaType, QueryFilterBase, RefLabelsState } from '../../../state'
import {
    DiagnosisCuiRefFieldMapper,
    GemMappedIcd10RefFieldMapper,
    Icd10RefFieldMapper,
    LabRefFieldMapper,
    MedicationCuiRefFieldMapper,
    MedicationNdcRefFieldMapper,
    MedicationRefFieldMapper,
    ObservationPeriodFieldMapper,
    ObservationRefFieldMapper,
    PhenotypeRefFieldMapper,
    ProcedureRefFieldMapper,
    RaceRefFieldMapper,
    SexRefFieldMapper,
    StateRefFieldMapper
} from '../utils/ref-field-mappers'
import { ProductCustomCohortRefFieldMapper } from '../utils/ref-field-mappers/product-custom-cohort-ref-field-mapper'

interface LabelGroup {
    id: string
    label?: ReactNode
    emptyLabel?: ReactNode
    fields: string[]
}

const LABEL_GROUPS: Record<CriteriaType, LabelGroup[]> = {
    [CriteriaType.PatientAttributes]: [
        { id: 'sex', label: <Trans>Sex</Trans>, emptyLabel: <Trans>Any</Trans>, fields: SexRefFieldMapper.filterFieldOrder },
        { id: 'race', label: <Trans>Race</Trans>, emptyLabel: <Trans>Any</Trans>, fields: RaceRefFieldMapper.filterFieldOrder },
        { id: 'location', label: <Trans>Location</Trans>, emptyLabel: <Trans>Any</Trans>, fields: StateRefFieldMapper.filterFieldOrder }
    ],
    [CriteriaType.Demographics]: [
        { id: 'sex', label: <Trans>Sex</Trans>, emptyLabel: <Trans>Any</Trans>, fields: SexRefFieldMapper.filterFieldOrder },
        { id: 'race', label: <Trans>Race</Trans>, emptyLabel: <Trans>Any</Trans>, fields: RaceRefFieldMapper.filterFieldOrder },
        { id: 'location', label: <Trans>Location</Trans>, emptyLabel: <Trans>Any</Trans>, fields: StateRefFieldMapper.filterFieldOrder }
    ],
    [CriteriaType.Diagnosis]: [
        {
            id: 'diagnosis',
            emptyLabel: <Trans>Any</Trans>,
            fields: [
                ...Icd10RefFieldMapper.filterFieldOrder,
                ...DiagnosisCuiRefFieldMapper.filterFieldOrder,
                ...GemMappedIcd10RefFieldMapper.filterFieldOrder
            ]
        }
    ],
    [CriteriaType.Medication]: [
        {
            id: 'medication',
            emptyLabel: <Trans>Any</Trans>,
            fields: [
                ...MedicationRefFieldMapper.filterFieldOrder,
                ...MedicationNdcRefFieldMapper.filterFieldOrder,
                ...MedicationCuiRefFieldMapper.filterFieldOrder
            ]
        }
    ],
    [CriteriaType.LabTest]: [{ id: 'lab', emptyLabel: <Trans>Any</Trans>, fields: LabRefFieldMapper.filterFieldOrder }],
    [CriteriaType.Procedure]: [
        {
            id: 'procedure',
            emptyLabel: <Trans>Any</Trans>,
            fields: ProcedureRefFieldMapper.filterFieldOrder
        }
    ],
    [CriteriaType.Observation]: [
        {
            id: 'observation',
            emptyLabel: <Trans>Any</Trans>,
            fields: ObservationRefFieldMapper.filterFieldOrder
        }
    ],
    [CriteriaType.EhrNotes]: [],
    [CriteriaType.ObservationPeriod]: [
        {
            id: 'observation_period',
            fields: ObservationPeriodFieldMapper.filterFieldOrder
        }
    ],
    [CriteriaType.ExternalCohort]: [
        {
            id: 'external_cohort',
            fields: [...ProductCustomCohortRefFieldMapper.filterFieldOrder, ...PhenotypeRefFieldMapper.filterFieldOrder]
        }
    ]
}

export type CohortCriterionRefSummaryComponentProps = FrameworkComponentProps<
    { refLabels: RefLabelsState; isLoading: boolean },
    {},
    { filters: QueryFilterBase[]; type: CriteriaType }
>

/**
 * Displays a summary of a criterion's selected filter values in a human-friendly format. Utilizes globally cached refs
 * to convert selected values into labels.
 *
 * @example Converts `{ values: ["E08.0"] }` to `Diabetes mellitus due to underlying condition with hyperosmolarity`
 */
export const CohortCriterionRefSummaryComponent = ({
    state: { refLabels, isLoading },
    props: { filters, type }
}: CohortCriterionRefSummaryComponentProps) => {
    const labelGroups = useMemo(() => {
        const groups = LABEL_GROUPS[type]

        if (isLoading) {
            return groups.map((group) => (
                <Box key={group.id}>
                    <Skeleton variant='text' sx={{ fontSize: '12px' }} />{' '}
                </Box>
            ))
        }

        return groups.map((group) => {
            const { id: groupId, label, emptyLabel, fields } = group
            const labels: string[] = []

            for (const field of fields) {
                for (const filter of filters) {
                    if (filter.field !== field) {
                        continue
                    }
                    for (const value of filter.values) {
                        let label = refLabels[filter.table]?.[filter.field]?.[value] || value
                        labels.push(label)
                    }
                }
            }

            return (
                <Box key={groupId} fontSize={12} textOverflow='ellipsis' overflow='hidden' whiteSpace='nowrap'>
                    {label && (
                        <>
                            <Box component='span' fontWeight='bold'>
                                {label}
                            </Box>
                            :{' '}
                        </>
                    )}
                    {labels.length === 0 ? emptyLabel : labels.join(', ')}
                </Box>
            )
        })
    }, [type, filters, refLabels, isLoading])

    return <div>{labelGroups}</div>
}
