import { withRouter } from '@om1/platform-routing'
import { trackingActions } from '@om1/platform-tracking'
import { connectRedux } from '@om1/platform-utils'
import { FunctionComponent } from 'react'
import { cohortCommonActions, cohortCreateActions, cohortListActions, CohortOwner, CohortState } from '../../state'
import { cohortDeleteActions } from '../../state/delete'
import { CohortListComponent, CohortListComponentProps } from './CohortListComponent'

export const DatasetsListComponent: FunctionComponent<
    Omit<
        CohortListComponentProps,
        keyof {
            owner: never
        }
    >
> = (props) => {
    const cohortListComponentPropsWithSystem: CohortListComponentProps = { ...props, props: { owner: CohortOwner.System } }
    return <CohortListComponent {...cohortListComponentPropsWithSystem} />
}

export function createDatasetsListComponent<
    TState extends {
        cohort: CohortState
    }
>() {
    return connectRedux(
        withRouter(DatasetsListComponent, { namespace: 'cohorts', dataFetchAction: 'cohortsGetSystemCohorts' }),
        (state: TState) => ({
            cohorts: state.cohort.list.cohorts[CohortOwner.System],
            cohortSizeLoading: state.cohort.common.ui.cohortSizeLoading,
            paginationMeta: state.cohort.list.paginationMeta,
            createLoading: state.cohort.create.ui.loading
        }),
        {
            ...cohortListActions,
            cohortCreate: cohortCreateActions.cohortCreate,
            cohortDelete: cohortDeleteActions.cohortDelete,
            cohortSizeGet: cohortCommonActions.cohortSizeGet,
            ...trackingActions
        }
    )
}

export type DatasetsList = ReturnType<typeof createDatasetsListComponent>
