import { FalconApiActionTypes, FalconApiActions } from './falcon-api-actions'

export interface FalconApiState {
    version: string
    alembicVersion: string
    analyticsDatabase: string
}

export const initialFalconApiState: FalconApiState = { version: '', alembicVersion: '', analyticsDatabase: '' }

export function falconApiReducer(previousState: FalconApiState | undefined, action: FalconApiActions): FalconApiState {
    const state = previousState || initialFalconApiState

    switch (action.type) {
        case FalconApiActionTypes.SET_FALCON_VERSION:
            return {
                ...state,
                version: action.payload.version,
                alembicVersion: action.payload.alembicVersion,
                analyticsDatabase: action.payload.analyticsDatabase
            }
        default:
            return state
    }
}
