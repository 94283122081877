import { yupResolver } from '@hookform/resolvers/yup'
import { t, Trans } from '@lingui/macro'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { cohortListActions, CohortState } from '@om1/cohort-module/src/state'
import { InsightsReportCreateDTO } from '@om1/falcon-api'
import { RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { FunctionComponent } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { rwaInsightsReportActions } from '../state/rwa-insights-reports'

export type AddRWAInsightsReportsModalComponentProps = RoutedFrameworkComponentProps<
    {},
    {},
    CohortState,
    typeof cohortListActions & typeof rwaInsightsReportActions,
    { showAddRWAInsightsReportsModal: boolean; closeModal: () => void }
>

/**
 * A dialog.
 */
export const AddRWAInsightsReportsModalComponent: FunctionComponent<AddRWAInsightsReportsModalComponentProps> = ({ state, actions, props }) => {
    const { showAddRWAInsightsReportsModal, closeModal } = props

    const handleClose = () => {
        closeModal()
    }

    const onSubmit: SubmitHandler<InsightsReportCreateDTO> = (formValues: InsightsReportCreateDTO) => {
        actions.createRwaInsightsReport({ report: formValues })
        closeModal()
    }

    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t`name is required`),
        description: Yup.string().required(t`description is required`),
        powerbiReportId: Yup.string()
            .required(t`PowerBI Report ID is required`)
            .matches(uuidRegex, t`Must be a valid UUID`),
        powerbiDatasetId: Yup.string()
            .required(t`PowerBI Dataset ID is required`)
            .matches(uuidRegex, t`Must be a valid UUID`),
        powerbiWorkspaceId: Yup.string()
            .required(t`PowerBI Workspace ID is required`)
            .matches(uuidRegex, t`Must be a valid UUID`),
        imageUri: Yup.string().required(t`imageUri is required`)
    })

    const {
        handleSubmit,
        control,
        register,
        formState: { errors }
    } = useForm<InsightsReportCreateDTO>({
        defaultValues: {
            name: '',
            description: '',
            powerbiReportId: '',
            powerbiDatasetId: '',
            powerbiWorkspaceId: '',
            imageUri: ''
        },
        resolver: yupResolver(validationSchema)
    })

    return (
        <Dialog
            open={showAddRWAInsightsReportsModal}
            maxWidth='sm'
            fullWidth
            aria-labelledby='add-InsightsReports-dialog-title'
            onClose={handleClose}
        >
            <DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={handleClose}
                    sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                >
                    <CloseIcon />
                </IconButton>
                <Box id='add-InsightsReports-dialog-title' pr={4}>
                    <Trans>Add Insights Report</Trans>
                </Box>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box position='relative' display='flex' flexDirection='column' flex={1} sx={{ overflowY: 'auto' }}>
                        <Box component='pre' p={2} m={0} sx={{ overflow: 'auto' }}>
                            <Box display={'flex'} flexDirection={'column'}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name='name'
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                id='name'
                                                {...register('name')}
                                                required
                                                label={<Trans>Name</Trans>}
                                                inputProps={{ inputMode: 'text' }}
                                                sx={{ flex: 1, marginTop: 2 }}
                                                onChange={onChange}
                                                value={value}
                                                error={!!errors['name']}
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name='description'
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                id='description'
                                                {...register('description')}
                                                required
                                                label={<Trans>Description</Trans>}
                                                inputProps={{ inputMode: 'text' }}
                                                sx={{ flex: 1, marginTop: 2 }}
                                                onChange={onChange}
                                                value={value}
                                                error={!!errors['description']}
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name='powerbiReportId'
                                        render={({ field: { onChange, value } }) => (
                                            <>
                                                <TextField
                                                    label={<Trans>PowerBI Report ID</Trans>}
                                                    id='powerbiReportId'
                                                    {...register('powerbiReportId')}
                                                    required
                                                    inputProps={{ inputMode: 'text' }}
                                                    sx={{ flex: 1, marginTop: 2 }}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!errors['powerbiReportId']}
                                                />
                                                {errors['powerbiReportId']?.message && (
                                                    <Typography color='error'>{errors['powerbiReportId']?.message}</Typography>
                                                )}
                                            </>
                                        )}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name='powerbiDatasetId'
                                        render={({ field: { onChange, value } }) => (
                                            <>
                                                <TextField
                                                    label={<Trans>PowerBI Dataset ID</Trans>}
                                                    id='powerbiDatasetId'
                                                    {...register('powerbiDatasetId')}
                                                    required
                                                    inputProps={{ inputMode: 'text' }}
                                                    sx={{ flex: 1, marginTop: 2 }}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!errors['powerbiDatasetId']}
                                                />
                                                {errors['powerbiDatasetId']?.message && (
                                                    <Typography color='error'>{errors['powerbiDatasetId']?.message}</Typography>
                                                )}
                                            </>
                                        )}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name='powerbiWorkspaceId'
                                        render={({ field: { onChange, value } }) => (
                                            <>
                                                <TextField
                                                    label={<Trans>PowerBI Workspace ID</Trans>}
                                                    id='powerbiWorkspaceId'
                                                    {...register('powerbiWorkspaceId')}
                                                    required
                                                    inputProps={{ inputMode: 'text' }}
                                                    sx={{ flex: 1, marginTop: 2 }}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!errors['powerbiWorkspaceId']}
                                                />
                                                {errors['powerbiWorkspaceId']?.message && (
                                                    <Typography color='error'>{errors['powerbiWorkspaceId']?.message}</Typography>
                                                )}
                                            </>
                                        )}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name='imageUri'
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                label={<Trans>Image URI</Trans>}
                                                id='imageUri'
                                                {...register('imageUri')}
                                                required
                                                inputProps={{ inputMode: 'text' }}
                                                sx={{ flex: 1, marginTop: 2 }}
                                                onChange={onChange}
                                                value={value}
                                                error={!!errors['imageUri']}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' color='primary' onClick={closeModal}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button variant='contained' type='submit'>
                        <Trans>Save</Trans>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
