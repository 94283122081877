import { Trans } from '@lingui/macro'
import { Typography } from '@mui/material'
import { styled } from '@mui/system'
import LogoHorizontal from '../shared/assets/OM1_Logo_Horizontal_rev_right.png'

const Logo = styled('img')({
    margin: '0px 32px 0px 32px',
    lineHeight: 0,
    cursor: 'pointer',
    width: '83px',
    height: '21px'
})

export default function logo() {
    return (
        <>
            <Logo
                alt='logo'
                src={LogoHorizontal}
                onClick={() => {
                    window.open('https://www.om1.com/')
                }}
            />
            <Typography
                sx={{
                    height: '16px',
                    width: '154px',
                    color: '#FFFFFF',
                    fontSize: '20px',
                    fontWeight: '200',
                    letterSpacing: '0',
                    lineHeight: '16px',
                    cursor: 'default',
                    minWidth: 'fit-content',
                    paddingRight: '15px'
                }}
            >
                <Trans>Insights Platform</Trans>
            </Typography>
        </>
    )
}
