import { t, Trans } from '@lingui/macro'
import { Box, MenuItem, Pagination, PaginationItem, Select, SelectChangeEvent } from '@mui/material'
import { gridPageSelector, gridPageSizeSelector, GridSlotsComponentsProps, useGridApiContext, useGridSelector } from '@mui/x-data-grid'

declare module '@mui/x-data-grid' {
    interface PaginationPropsOverrides {
        totalItems: number
        pageSizeOptions?: number[]
    }
}

interface StyledPaginationProps {
    onPageChange: (page: number) => void
    onPageSizeChange: (pageSize: number) => void
    page: number
    pageSize: number
    totalItems: number
    pageSizeOptions: number[]
}

export const StyledPagination = (props: StyledPaginationProps) => {
    const { onPageChange, onPageSizeChange, page, pageSize, pageSizeOptions, totalItems } = props
    const handleChange = (event: SelectChangeEvent) => {
        onPageSizeChange(parseInt(event.target.value))
    }
    let count = 0
    try {
        count = Math.ceil(totalItems / pageSize)
    } catch (e) {
        console.log(JSON.stringify(e))
    }

    return (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', paddingTop: '20px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Pagination
                    count={count}
                    page={page + 1}
                    onChange={(event, value) => onPageChange(value - 1)}
                    renderItem={(item) => <PaginationItem {...item} />}
                    shape='rounded'
                    size='large'
                    showLastButton
                    showFirstButton
                />
                <Box sx={{ marginLeft: '35px' }}>
                    {page * pageSize + 1}-{(page + 1) * pageSize > totalItems ? totalItems : (page + 1) * pageSize} {t` of `} {totalItems}
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ marginRight: '10px' }}>
                    <Trans>Rows per page:</Trans>
                </Box>
                <Select value={pageSize.toString()} label={t`Rows per page:`} onChange={handleChange} sx={{ height: '40px', fontSize: '14px' }}>
                    {pageSizeOptions.map((p) => {
                        return (
                            <MenuItem key={p} sx={{ fontSize: '14px' }} value={p}>
                                {p}
                            </MenuItem>
                        )
                    })}
                </Select>
            </Box>
        </Box>
    )
}

export const GridPagination = (props: GridSlotsComponentsProps['pagination'] & { totalItems: number; pageSizeOptions?: number[] }) => {
    const { pageSizeOptions = [5, 10, 20], totalItems } = props
    const apiRef = useGridApiContext()
    const page: number = useGridSelector(apiRef, gridPageSelector)
    const pageSize: number = useGridSelector(apiRef, gridPageSizeSelector)

    const handlePageSizeChange = (pageSize: number) => {
        apiRef.current.setPaginationModel({ page: 0, pageSize })
    }

    const handlePageChange = (page: number) => {
        apiRef.current.setPage(page)
    }

    return (
        <StyledPagination
            onPageSizeChange={handlePageSizeChange}
            onPageChange={handlePageChange}
            page={page}
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            totalItems={totalItems}
        />
    )
}
