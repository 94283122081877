import { DimensionGroupingType, ReportName, ReportSection, ReportType } from '../../../state'
import { DATA_TYPE_CHART_ID, DEMOGRAPHICS_CHART_ID, REPORT_SECTION_ID } from './filter-utils'

export const getReportSectionsFromReportType = (reportType: ReportType): ReportSection[] => {
    switch (reportType) {
        case ReportType.DATA_TYPE:
            return [
                {
                    id: DATA_TYPE_CHART_ID,
                    queries: [
                        {
                            groupType: DimensionGroupingType.GroupingSets,
                            dimensions: [
                                'patient.has_ehr_data',
                                'patient.has_medical_claims_data',
                                'patient.has_rx_claims_data',
                                'patient.has_ehr_linked_claims_data'
                            ],
                            filters: []
                        }
                    ]
                }
            ]
        case ReportType.DEMOGRAPHICS:
            return [
                {
                    id: DEMOGRAPHICS_CHART_ID,
                    queries: [
                        {
                            groupType: DimensionGroupingType.GroupingSets,
                            dimensions: ['patient.race', 'patient.sex', 'patient.current_age', 'patient.ethnicity', 'patient.state'],
                            filters: []
                        }
                    ]
                }
            ]
        default:
            return [
                {
                    id: REPORT_SECTION_ID,
                    queries: [
                        {
                            groupType: DimensionGroupingType.Default,
                            dimensions: [],
                            filters: []
                        }
                    ]
                }
            ]
    }
}

export const getReportNameFromType = (reportType: string): ReportName => {
    switch (reportType) {
        case ReportType.DEMOGRAPHICS:
            return ReportName.DEMOGRAPHICS
        case ReportType.DIAGNOSIS:
            return ReportName.DIAGNOSIS
        case ReportType.MEDICATION:
            return ReportName.MEDICATION
        case ReportType.OBSERVATION:
            return ReportName.OBSERVATION
        case ReportType.PROCEDURE:
            return ReportName.PROCEDURE
        case ReportType.LAB_TEST:
            return ReportName.LAB_TEST
        case ReportType.DATA_TYPE:
            return ReportName.DATA_TYPE
        default:
            return (reportType.charAt(0).toUpperCase() + reportType.slice(1).toLowerCase()) as ReportName
    }
}
