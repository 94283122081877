import { FalconPaginationMeta } from '@om1/falcon-api'
import { ActionsUnion, createAction } from '@om1/platform-utils'
import {
    AnalyticsRefDestination,
    AnalyticsRefDimension,
    DiagnosisIcd10Ref,
    LabRef,
    MedicationCuiRef,
    MedicationNdcRef,
    MedicationRef,
    PhenotypeRef,
    ProductCustomCohortRef,
    RaceRef,
    SexRef,
    SpecialtyRef,
    StateRef
} from './analytics-refs-state'

export enum AnalyticsRefActionTypes {
    REFS_SET = '@@cohort/refs/set'
}

export const analyticsRefActions = {
    setRefs: (payload: {
        dimension: AnalyticsRefDimension
        destination: AnalyticsRefDestination
        data:
            | RaceRef[]
            | SexRef[]
            | StateRef[]
            | DiagnosisIcd10Ref[]
            | DiagnosisIcd10Ref[]
            | MedicationRef[]
            | MedicationCuiRef[]
            | MedicationNdcRef[]
            | LabRef[]
            | SpecialtyRef[]
            | PhenotypeRef[]
            | ProductCustomCohortRef[]
        paginationMeta: FalconPaginationMeta
    }) => createAction(AnalyticsRefActionTypes.REFS_SET, payload)
}

export type AnalyticsRefActions = ActionsUnion<typeof analyticsRefActions>
